import React, { useState } from "react";

import "./ResultBar3.css";
import images from "../../../../../../../Utils/importImages";
import StopsSummary from "../../../../../../Components/Popups/Hoverers/StopsSummary";
import {
  getAllAirlinesWithindex1,
  getTags,
} from "../../../../../../../Utils/Flight_results_func";
import {
  formatTime,
  getTotalDays,
  getTotalMinutes,
} from "../../../../../../../Utils/Date_Time_Calendar_Functions";
import AirlinesLogContainer from "../../../Components/AirlinesLogContainer";
import MainPopup from "../../../MainPopup/MainPopup";
import { setdataMainPopup, setResultTypeMainPopup } from "../../../../../../../Redux/Slices/Flight_Slices/ResultPage-Flights-SLICE";
import { useDispatch } from "react-redux";

// result bar without stconnect or tags

function ResultBar3({
  data,
  airlineLogos,
  ViewBy,
  selectedData,
  SelectTheData,
  mode,
  setopenMainPopup,
  Theme
}) {

  let dispatch = useDispatch();

  let allAirlines = getAllAirlinesWithindex1(data, 1, airlineLogos);

  let hasCoupon = data?.Fare?.DiscountsAndLess?.Coupon?.Available;

  // let CloseThePopup = () => {
  //   setOpenPopup(false)
  // }

  // let setPopupSelectData = () => {
  //   SelectTheData(mode, data, allAirlines);
  //   CloseThePopup();
  // }
  const TagsData = getTags(data, 1);

  const tagMapping = {
    TerminalChange: {
      imgSrc: images["terminal-change.svg"],
      displayText: "Terminal Change",
    },
    ChangeOfAirport: {
      imgSrc: images["terminal-change.svg"],
      displayText: "Airport Changed",
    },
    ChangeOfPlanes: {
      imgSrc: images["terminal-change.svg"],
      displayText: "Planes Changed",
    },
    RecheckInbaggage: {
      imgSrc: images["recheckin-icon.svg"],
      displayText: "Recheck-in Baggage",
    },
    SelfTransferBaggage: {
      imgSrc: images["self-transfter-icon.svg"],
      displayText: "Self Transfer Baggage",
    },
    BagSame: {
      imgSrc: images["self-transfter-icon.svg"],
      displayText: "Bags Quantity Changed",
    },
    TransitVisa: {
      imgSrc: images["transit-visa-required.svg"],
      displayText: "Transit Visa Required",
    },
    TechnicalStopage: {
      imgSrc: images["transit-visa-required.svg"],
      displayText: "Technical Stopage",
    },
  };

  let form = 3;

  let setOpenPopup = (info) => {
    
    setopenMainPopup(info)
    dispatch(setdataMainPopup(data));
    dispatch(setResultTypeMainPopup(1));

  }

  return (
    <>
    <div
      className={`result-bar-3 ${Theme}`}
      
    >
      <div className="frame-201-3">
        <div className="frame-17-2 frame-17-3">
          <div className="frame-104">
            <div className="frame-104-1 frame-104-5">
              <div className="frame-103">
                <AirlinesLogContainer allAirlines={allAirlines} />
                <div className="frame-168-2 frame-168-5">
                  <div className="frame-173-2">
                    <div className={`indigo-spi ${Theme}_medium color_eerie-black font16`}>
                      {allAirlines?.map((item, index) => (
                        <span key={index}>
                          {item.name}
                          {index < allAirlines?.length - 1 ? ", " : ""}
                        </span>
                      ))}
                    </div>
                  </div>
                  <div className="frame-4">
                    <div className={`in-123-sj-123 ${Theme}_light color_gravel font13`}>
                      {allAirlines?.map((item, index) => (
                        <span key={index}>
                          {item.Number}
                          {index < allAirlines?.length - 1 ? ", " : ""}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="frame-214">
            <div className="frame-202">
              <div className="frame-170-1 frame-170-4">
                <div className={`text-561 text-4 ${Theme}_semibold color_eerie-black font22`}>
                  {data.Segments[0].Origin.DepTime.substring(11, 16)}
                </div>
                <div className="frame-9">
                  <div className={`del ${Theme}_regular color_${Theme == "proxima" ? "eerie-black" : "light-gray-black"} font14`}>
                    {data.Segments[0].Origin.Airport.AirportCode} ,
                  </div>
                  <div className={`place ${Theme}_regular color_${Theme == "proxima" ? "eerie-black" : "gravel"} font14`}>
                    {data.Segments[0].Origin.Airport.CityName}
                  </div>
                </div>
              </div>

              <div className="frame-1-5">
                <div className="frame-10">
                  <div className="frame-8">
                    <div className={`line ${Theme}`}></div>
                    <div className="frame-202-1">
                      <div className="group-206">
                        <img
                          src={images["result-bar-clock-icon.svg"]}
                          alt="Vector 50"
                        />
                      </div>
                      <div className={`x28h-40m ${Theme}_regular color_${Theme == "proxima" ? "eerie-black" : "light-gray-black"} font13`}>
                        {formatTime(
                          getTotalMinutes(
                            data.Segments[0].Origin.DepTime,
                            data.Segments[data.Segments.length - 1].Destination
                              .ArrTime
                          )
                        )}
                      </div>
                    </div>
                    <div className={`line ${Theme}`}></div>
                  </div>
                </div>
                <div className={`frame-11 ${data.Segments.length > 1
                          ? "tooltip-container"
                          : ""}`}>
                  <div className="frame-11">
                    <div className="frame-175-2">
                      <div className={`address-2 address-17 ${Theme}_regular ${Theme}_regular color_${Theme == "proxima" ? "endeavour" : "optera-galaxy"} font14`}>
                        {data.Segments.length > 1
                          ? `${data.Segments.length - 1} Stops`
                          : "Non Stop"}
                      </div>
                    </div>
                    {data.Segments.length > 1 && (
                      <div className="frame-214-1 frame-214-3">
                        <div className="frame-203"></div>
                      </div>
                    )}
                  </div>
                  <div className="frame-202-2">
                    <div className="frame-9">
                      <div>
                        <div className={`tashkent-2 ${Theme}_regular color_${Theme == "proxima" ? "eerie-black" : "light-gray-black"} font13`}>
                          {data.Segments.length > 0 &&
                            data?.Segments?.map((item, index) => {
                              return index < data?.Segments.length - 1 ? (
                                <span key={index}>
                                  {item.Destination.Airport.CityName}
                                  {index < data?.Segments.length - 2
                                    ? ", "
                                    : ""}
                                </span>
                              ) : (
                                ""
                              );
                            })}
                        </div>
                        <StopsSummary data={data} ResultType={1} Theme={Theme}/>
                      </div>
                    </div>
                    <div className="frame-177"></div>
                  </div>
                </div>
              </div>

              <div className="frame-169">
                <div className="frame-12">
                  <div className={`text-562 text-4 ${Theme}_semibold color_eerie-black font22`}>
                    {data.Segments[
                      data.Segments.length - 1
                    ].Destination.ArrTime.substring(11, 16)}
                  </div>
                </div>
                <div className="frame-9">
                  <div className={`atq ${Theme}_regular color_${Theme == "proxima" ? "eerie-black" : "light-gray-black"} font14`}>
                    {
                      data.Segments[data.Segments.length - 1].Destination
                        .Airport.AirportCode
                    }{" "}
                    ,
                  </div>
                  <div className={`place-1 ${Theme}_regular color_${Theme == "proxima" ? "gravel" : "light-gray-black"} font14`}>
                    {
                      data.Segments[data.Segments.length - 1].Destination
                        .Airport.CityName
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-202-3">
              <div className={`x1-day ${Theme}_regular color_crimson font12`}>
                {getTotalDays(
                  data.Segments[0].Origin.DepTime,
                  data.Segments[data.Segments.length - 1].Destination.ArrTime
                )}
              </div>
            </div>
          </div>
          <div className="frame-104-2 frame-104-5">
            <div className="frame-202-4">
              <div className="frame-103-1">
                <div className="frame-172-1">
                  <div className="frame-169-1 frame-169-20">
                    <div className="frame-1-6">
                      <img
                        className="frame-169-20"
                        src={images["light-gray-rupee.svg"]}
                        alt="Frame 169"
                      />
                      <div className={`text valign-text-middle ${Theme}_semibold color_eerie-black font23`}>
                        {ViewBy === 0
                          ? +data.FareFamilies.FFList[0].Travellers.Adults[0]
                              .Fare +
                            +data.FareFamilies.FFList[0].Travellers.Adults[0]
                              .Tax
                          : +data.Fare.MegaTotal}
                      </div>
                    </div>
                    <div className={`per-adult-16 ${Theme}_regular color_eerie-black font13`}>
                      {ViewBy === 0 ? "per adult" : "per traveller"}
                    </div>
                  </div>
                </div>
                
                {selectedData.ResultId === data.ResultId ? (
                  <img
                    src={images[Theme == "proxima" ? "radio-data-selector-fill.svg" : "golden-circle-selected.svg"]}
                    alt=""
                    className="selector-img"
                  />
                ) : (
                  <img
                  onClick={() => SelectTheData(mode, data, allAirlines)}
                    src={images[Theme == "proxima" ? "radio-data-selector-non-fill.svg" : "golden-circle-non-selected.svg"]}
                    alt=""
                    className="selector-img"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {form === 1 && (
          <div className="frame-171">
            <div className={`partially-refundable ${Theme}_medium color_eastern-blue font14`}>
              Partially Refundable
            </div>
            <div className="frame-203-1">
              <div className={`flight-details ${Theme}_semibold color_${Theme == "proxima" ? "endeavour" : "optera-galaxy"} font14`} onClick={() => setOpenPopup(true)}>
                FLIGHT DETAILS
              </div>
            </div>
          </div>
        )}

        {hasCoupon === false && TagsData[0] === false ? (
          <div className="frame-171">
            <div
              className={`${
                data.IsRefundable === true
                  ? `partially-refundable ${Theme}_medium color_eastern-blue font14`
                  : `partially-refundable ${Theme}_medium color_crimson font13`
              }`}
            >
              {data.IsRefundable === true
                ? "Partially Refundable"
                : "Non-Refundable"}
            </div>
            <div className="frame-203-1">
              <div className={`flight-details ${Theme}_semibold color_${Theme == "proxima" ? "endeavour" : "optera-galaxy"} font14`} onClick={() => setOpenPopup(true)}>
                FLIGHT DETAILS
              </div>
            </div>
          </div>
        ) : hasCoupon === true && TagsData[0] === false ? (
          <>
            <div className={`coupon-container ${Theme}`}>
              <div className={`frame-230 ${Theme}`}>
                <div className="frame-203-2">
                  <img src={images[Theme == "proxima" ? "coupon-icon.svg" : "golden-coupon.svg"]} alt="" />
                </div>
                <div className="frame">
                  <div className="frame-211">
                    <div className="frame-13">
                      <p className={`deal-text ${Theme}_regular color_${Theme == "proxima" ? "endeavour" : "burning-gold"} font14`}>
                        {data.Fare.DiscountsAndLess.Coupon.Flat.Available ==
                        true
                          ? `Flat Discount of ${
                              data.Fare.DiscountsAndLess.Coupon.Flat
                                .Amount_in_Percentage > 0
                                ? data.Fare.DiscountsAndLess.Coupon.Flat
                                    .Amount_in_Percentage + "%"
                                : "Rs. " +
                                  data.Fare.DiscountsAndLess.Coupon.Flat.Amount
                            } Applied`
                          : `Use Code STFCODE for ${
                              data.Fare.DiscountsAndLess.Coupon.Discount
                                .Amount_in_Percentage > 0
                                ? "Rs. " +
                                  (data.Fare.BaseFare *
                                    data.Fare.DiscountsAndLess.Coupon.Discount
                                      .Amount_in_Percentage) /
                                    100
                                : "Rs. " +
                                  data.Fare.DiscountsAndLess.Coupon.Discount
                                    .Amount
                            } off Per Person `}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="frame-171">
              <div
                className={`${
                  data.IsRefundable === true
                    ? `partially-refundable ${Theme}_medium color_eastern-blue font14`
                    : `partially-refundable ${Theme}_medium color_crimson font13`
                }`}
              >
                {data.IsRefundable === true
                  ? "Partially Refundable"
                  : "Non-Refundable"}
              </div>

              <div className="frame-203-1">
                <div className={`flight-details ${Theme}_semibold color_${Theme == "proxima" ? "endeavour" : "optera-galaxy"} font14`} onClick={() => setOpenPopup(true)}>
                  FLIGHT DETAILS
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="frame-171-5">
              <div
                className={`${
                  data.IsRefundable === true
                    ? `partially-refundable ${Theme}_medium color_eastern-blue font14`
                    : `partially-refundable ${Theme}_medium color_crimson font13`
                }`}
              >
                {data.IsRefundable === true
                  ? "Partially Refundable"
                  : "Non-Refundable"}
              </div>
              <div
                className={`coupon-container ${Theme}`}
                style={{ visibility: hasCoupon === true ? null : "hidden" }}
              >
                <div className={`frame-230 ${Theme}`}>
                  <div className="frame-203-2">
                    <img src={images[Theme == "proxima" ? "coupon-icon.svg" : "golden-coupon.svg"]} alt="" />
                  </div>
                  <div className="frame">
                    <div className="frame-211">
                      <div className="frame-13">
                        <p className={`deal-text ${Theme}_regular color_${Theme == "proxima" ? "endeavour" : "burning-gold"} font14`}>
                
                          {hasCoupon === true &&
                            (data.Fare.DiscountsAndLess.Coupon.Flat
                              .Available === true
                              ? `Flat Discount of ${
                                  data.Fare.DiscountsAndLess.Coupon.Flat
                                    .Amount_in_Percentage > 0
                                    ? data.Fare.DiscountsAndLess.Coupon.Flat
                                        .Amount_in_Percentage + "%"
                                    : "Rs. " +
                                      data.Fare.DiscountsAndLess.Coupon.Flat
                                        .Amount
                                } Applied`
                              : `Use Code STFCODE for ${
                                  data.Fare.DiscountsAndLess.Coupon.Discount
                                    .Amount_in_Percentage > 0
                                    ? "Rs. " +
                                      (data.Fare.BaseFare *
                                        data.Fare.DiscountsAndLess.Coupon
                                          .Discount.Amount_in_Percentage) /
                                        100
                                    : "Rs. " +
                                      data.Fare.DiscountsAndLess.Coupon.Discount
                                        .Amount
                                } off Per Person `)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={`tags-container frame-174-7 ${Theme}`}>
              <div className="frame-207-1">
                {TagsData[1].splice(0, 3).map((tag, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div className="frame-12">
                        <img
                          src={tagMapping[tag]?.imgSrc}
                          alt={tagMapping[tag]?.displayText}
                        />
                        <div className={`self-transfer-baggage-6 ${Theme}_regular color_eerie-black font13`}>
                          {tagMapping[tag]?.displayText}
                        </div>
                      </div>
                      {index < TagsData[1].length - 1 && (
                        <div className="frame-208">
                          <div className="line-straight">
                            |
                          </div>
                        </div>
                      )}
                    </React.Fragment>
                  );
                })}
              </div>
              <div className="frame-203-5">
                <div className={`flight-details-15 ${Theme}_semibold color_${Theme == "proxima" ? "endeavour" : "optera-galaxy"} font14`} onClick={() => setOpenPopup(true)}>
                  FLIGHT DETAILS
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  

    </>
  );
}

export default ResultBar3;
