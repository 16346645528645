
let setLocalStorageJSON = (key , data) => {

    localStorage.setItem(key , JSON.stringify(data));

}
let getLocalStorageJSON = (key) => {

    let dd = localStorage.getItem(key);

    return JSON.parse(dd);
}
let removeLocalStorageJSON = (key) => {

    localStorage.removeItem(key);
}


export {
    setLocalStorageJSON,
    getLocalStorageJSON,
    removeLocalStorageJSON,
};