import React from "react";
import "./index.css";
import images from "../../../../Utils/importImages";
import { useSelector } from "react-redux";
import FiltersSection from "./FilterSection/FiltersSection";
import ResultBarSection from "./ResultBarSection/ResultBarSection";
import OnewayData from "./ResultSectionData/OnewayData";

import RoundwayData_Domestic from "./ResultSectionData/RoundwayData_Domestic";
import RoundInternational_Split from "./ResultSectionData/Round_International/Split";
import RoundInternational_Combined from "./ResultSectionData/Round_International/Combined";
import MultiDomestic_2Row from "./ResultSectionData/MultiDomestic/2Row";
import MultiDomestic_3Row from "./ResultSectionData/MultiDomestic/3Row";
import MultiDomestic_4Row from "./ResultSectionData/MultiDomestic/4Row";
import MultiInternational_2Row_Split from "./ResultSectionData/MultiInternational/2Row/Split";
import MultiInternational_2Row_Combined from "./ResultSectionData/MultiInternational/2Row/Combined";
import MultiInternational_3Row_Combined from "./ResultSectionData/MultiInternational/3Row/Combined";
import MultiInternational_3Row_Split from "./ResultSectionData/MultiInternational/3Row/Split";
import MultiInternational_4Row_Split from "./ResultSectionData/MultiInternational/4Row/Split";
import MultiInternational_4Row_Combined from "./ResultSectionData/MultiInternational/4Row/Combined";

function ResultPageData({Theme }) {
  let resultPageData = useSelector((state) => state.ResultPageFlightsFlight);
  
  return (
    <div
      className={`flights-section-results-page ${Theme}`}
      style={
        resultPageData.SearchResultType === 3 ||
        (resultPageData.SearchResultType === 4 &&
          resultPageData.ResultView === 2) ||
        resultPageData.SearchResultType === 5 ||
        resultPageData.SearchResultType === 7 ||
        resultPageData.SearchResultType === 9 ||
        (resultPageData.SearchResultType === 6 &&
          resultPageData.ResultView === 2) ||
        (resultPageData.SearchResultType === 8 &&
          resultPageData.ResultView === 2) ||
        (resultPageData.SearchResultType === 10 &&
          resultPageData.ResultView === 2)
          ? { marginTop: "90px" }
          : {}
      }
    >
      <div className="result-main-section">
          <div className={`filters-section`} style={(resultPageData.SearchResultType === 3 && resultPageData.FilteredData !== null) == true ? {width : "23%"} : {width : "24%"}}>
            <FiltersSection Theme={Theme}/>
          </div>
          <div className={`results-section`} style={(resultPageData.SearchResultType === 3 && resultPageData.FilteredData !== null) == true ? {width : "75%"} : {width : "74%"}}>
          <div className="result-data-section">
            {(resultPageData.SearchResultType === 1 ||
              resultPageData.SearchResultType === 2) && resultPageData.FilteredData !== null && <OnewayData Theme={Theme}/>}
            {resultPageData.SearchResultType === 3 && resultPageData.FilteredData !== null && <RoundwayData_Domestic Theme={Theme}/>}
            {resultPageData.SearchResultType === 4 && resultPageData.FilteredData !== null &&
              (resultPageData.ResultView === 2 ? (
                <RoundInternational_Split Theme={Theme}/>
              ) : (
                <RoundInternational_Combined Theme={Theme}/>
              ))}
            {resultPageData.SearchResultType === 5 && resultPageData.FilteredData !== null && <MultiDomestic_2Row Theme={Theme}/>}
            {resultPageData.SearchResultType === 7 && resultPageData.FilteredData !== null && <MultiDomestic_3Row Theme={Theme}/>}
            {resultPageData.SearchResultType === 9 && resultPageData.FilteredData !== null && <MultiDomestic_4Row Theme={Theme}/>}
            {resultPageData.SearchResultType === 6 && resultPageData.FilteredData !== null &&
              (resultPageData.ResultView === 2 ? (
                <MultiInternational_2Row_Split Theme={Theme} />
              ) : (
                <MultiInternational_2Row_Combined Theme={Theme} />
              ))}
            {resultPageData.SearchResultType === 8 &&
              (resultPageData.ResultView === 2 ? (
                <MultiInternational_3Row_Split Theme={Theme} />
              ) : (
                <MultiInternational_3Row_Combined Theme={Theme} />
              ))}
            {resultPageData.SearchResultType === 10 &&
              (resultPageData.ResultView === 2 ? (
                <MultiInternational_4Row_Split Theme={Theme} />
              ) : (
                <MultiInternational_4Row_Combined Theme={Theme} />
              ))}
          </div>
        </div>

      </div>
    </div>
  );
}

export default ResultPageData;
