import React from "react";
import images from "../../../../../Utils/importImages";
import { useDispatch, useSelector } from "react-redux";
import {
  convertDateStringResultPage,
  formatDate,
} from "../../../../../Utils/Date_Time_Calendar_Functions";
import SpecialFareOptions from "./SpecialFareOptions";
import FlightsOptionsPopup from "../../../../Components/Popups/FlightsOptionsPopup";
import {
  searchTheFlightUrlCreator,
  setExchangeSearchParas,
  setSearchBarMode,
  setSearchSegmentsParams,
  setTheDates,
  setTravellersAndClasses,
  setTravellersErrors,
} from "../../../../../Redux/Slices/Flight_Slices/ResultPage-Flights-SLICE";
import From_To_Popup from "../../../../Components/Popups/From_To_Popup";
import ErrorMessagePopup from "../../../../Components/Popups/ErrorMessagePopup";
import { getLocalStorageJSON } from "../../../../../Utils/localStorageFunc";
import { getdateformattedrange } from "../../../../../Utils/Date_Time_Calendar_Functions";
import RangeCalender from "../../../../Components/Popups/Calendars/RangeCalender";
import TravellersPopup from "../../../../Components/Popups/TravellersPopup";
import { TravellersFareMessage } from "../../../../../Constants/ConstantsFlights";
function RoundwaySearchBar({
  loadingPorgessBar,
  isSticky,
  openPopup,
  setopenPopup,
  openPopupActual,
  setopenPopupActual,
  Theme: FontTheme,
}) {
  let searchBarData = useSelector((state) => state.ResultPageFlightsFlight);

  let travellersData = useSelector(
    (state) => state.ResultPageFlightsFlight.searchParamsTravellers
  );
  let classData = useSelector(
    (state) => state.ResultPageFlightsFlight.searchParamsFare
  );

  let dispatch = useDispatch();

  const isButtonDisabled = () => {
    return (
      searchBarData.SearchResultsStatus === "Loading" ||
      searchBarData.disableSearch === true ||
      searchBarData.searchParamsTravellers.TravellersAndClassesErrorIn ===
        true ||
      searchBarData.searchParamsTravellers.TravellersAndClassesErrorOut ===
        true ||
      searchBarData.DateError1[0] === true ||
      searchBarData.DateError11[0] === true ||
      searchBarData.DateError2[0] === true ||
      searchBarData.DateError3[0] === true ||
      searchBarData.DateError4[0] === true ||
      searchBarData.AirportsToError1[0] === true ||
      searchBarData.AirportsToError2[0] === true ||
      searchBarData.AirportsToError3[0] === true ||
      searchBarData.AirportsToError4[0] === true ||
      searchBarData.AirportsFromError1[0] === true ||
      searchBarData.AirportsFromError2[0] === true ||
      searchBarData.AirportsFromError3[0] === true ||
      searchBarData.AirportsFromError4[0] === true
    );
  };

  let changeTheDate = (date) => {
    let selectedDate1 = date;
    let selectedDate2 =
      searchBarData?.searchParams[1]?.OriginDate !== null
        ? new Date(searchBarData?.searchParams[1]?.OriginDate)
        : null;
    let selectedDate3 =
      searchBarData?.searchParams[2]?.OriginDate !== null
        ? new Date(searchBarData?.searchParams[2]?.OriginDate)
        : null;
    let selectedDate4 =
      searchBarData?.searchParams[3]?.OriginDate !== null
        ? new Date(searchBarData?.searchParams[3]?.OriginDate)
        : null;
    let ReturnDate = searchBarData?.searchParams[0]?.ReturnDate
      ? new Date(searchBarData?.searchParams[0]?.ReturnDate)
      : null;

    if (ReturnDate != null && selectedDate1 != null) {
      if (selectedDate1 > ReturnDate) {
        const newDate = new Date(selectedDate1);
        newDate.setDate(selectedDate1.getDate() + 1);
        ReturnDate = newDate;
      }
    }

    if (selectedDate1 !== null && selectedDate2 !== null) {
      if (selectedDate1 > selectedDate2) {
        const newDate = new Date(selectedDate1);
        newDate.setDate(selectedDate1.getDate() + 1);
        selectedDate2 = newDate;
      }
    }

    if (selectedDate2 !== null && selectedDate3 !== null) {
      if (selectedDate2 > selectedDate3) {
        const newDate = new Date(selectedDate2);
        newDate.setDate(selectedDate2.getDate() + 1);
        selectedDate3 = newDate;
      }
    }

    if (selectedDate3 !== null && selectedDate4 !== null) {
      if (selectedDate3 > selectedDate4) {
        const newDate = new Date(selectedDate3);
        newDate.setDate(selectedDate3.getDate() + 1);
        selectedDate4 = newDate;
      }
    }

    let Obj = [
      {
        OriginDate: formatDate(selectedDate1),
        ReturnDate: formatDate(ReturnDate),
      },
      {
        OriginDate: formatDate(selectedDate2),
        ReturnDate: null,
      },
      {
        OriginDate: formatDate(selectedDate3),
        ReturnDate: null,
      },
      {
        OriginDate: formatDate(selectedDate4),
        ReturnDate: null,
      },
    ];

    dispatch(setTheDates(Obj));
  };

  let changeTheDateReturn = (date) => {
    let Obj = [
      {
        OriginDate: searchBarData?.searchParams[0]?.OriginDate,
        ReturnDate: formatDate(date),
      },
      {
        OriginDate: searchBarData?.searchParams[1]?.OriginDate,
        ReturnDate: null,
      },
      {
        OriginDate: searchBarData?.searchParams[2]?.OriginDate,
        ReturnDate: null,
      },
      {
        OriginDate: searchBarData?.searchParams[3]?.OriginDate,
        ReturnDate: null,
      },
    ];

    dispatch(setTheDates(Obj));
  };

  let changeTheSearchBarMode = (option) => {
    if (option === "One") {
      dispatch(setSearchBarMode(1));
    } else if (option === "Round") {
      dispatch(setSearchBarMode(2));
    } else if (option === "Multi") {
      dispatch(setSearchBarMode(3));
    }
  };

  let handleClose = (item, PopupType) => {
    // console.log("item is : ", item);
    // console.log("PopupType is : ", PopupType);
    let StructuredAirport = {
      City: item.city_name,
      Code: item.airport_code,
      AirportName: item.airport_name,
      CountryCode: item.country_code,
      CountryName: item.country_name,

      type: PopupType[0],
      PopupType: PopupType[1],

      airport: item,
    };

    dispatch(setSearchSegmentsParams(StructuredAirport));

    setopenPopup(openPopup + 1);
    setopenPopupActual(openPopup + 1);
  };

  const handleSearch = () => {
    dispatch(searchTheFlightUrlCreator());
  };

  return (
    <div
      className={`result-page-flights result-page-flights-search-bar ${
        isSticky ? "sticky" : ""
      }`}
    >
      <div className={`result-page-flights-frame-97 ${FontTheme}`}>
        <div className="result-page-flights-frame-27">
          <div className="result-page-flights-frame-33">
            <div className="result-page-flights-frame-35">
              <div
                className="result-page-flights-frame-101"
                onClick={() => setopenPopup(openPopup === 6 ? 0 : 6)}
              >
                <div
                  className={`result-page-flights-trip-type ${FontTheme}_light color_white font12`}
                >
                  TRIP TYPE
                </div>
                <div className="result-page-flights-frame-168-6">
                  <div
                    className={`result-page-flights-one-way ${FontTheme}_regular color_white font16`}
                  >
                    Round Way
                  </div>
                  <img
                    src={images["arrow.png"]}
                    alt="arrow"
                    style={{
                      position: "relative",

                      transform: openPopup === 2 ? "rotate(180deg)" : null,
                    }}
                  />
                </div>

                {openPopup === 6 && (
                  <FlightsOptionsPopup
                    options={["One", "Multi"]}
                    open={openPopup === 6}
                    onClickClose={changeTheSearchBarMode}
                    position={openPopup}
                    onClose={() => {
                      setopenPopup(0);
                      setopenPopupActual(0);
                    }}
                    Theme={FontTheme}
                  />
                )}
              </div>
              <div className="result-page-flights-frame-99-7">
                <>
                  <div
                    className="result-page-flights-frame-28"
                    onClick={() => {
                      setopenPopup(7);
                      setopenPopupActual(7);
                    }}
                  >
                    <div className="result-page-flights-frame-99-8">
                      <div
                        className={`result-page-flights-place-32 ${FontTheme}_light color_white font12`}
                      >
                        FROM
                      </div>
                    </div>
                    <div
                      className={`result-page-flights-innextText ${FontTheme}_regular color_white font16`}
                    >
                      {searchBarData?.searchParams?.[0]?.OriginCode}{" "}
                      {searchBarData?.searchParams?.[0]?.OriginCode !== "" &&
                        ", "}
                      {searchBarData?.searchParams?.[0]?.OriginCity}{" "}
                      {searchBarData?.searchParams?.[0]?.OriginCity !== "" &&
                        ", "}
                      {searchBarData?.searchParams?.[0]?.OriginCountry}
                    </div>
                  </div>

                  {searchBarData?.AirportsFromError1[0] === true && (
                    <ErrorMessagePopup
                      Theme={FontTheme}
                      style={{
                        display:"flex",
                        alignItems : "end",
                        width: "max-content",
                        zIndex: 1,
                        backgroundColor: "#FFE0DA",
                        position: "absolute",
                        top: "108%",
                        left: "10px",
                        padding: "4px 8px",
                      }}
                      // addClass={searchBarData?.searchClicked}
                      message={searchBarData?.AirportsFromError1[1]}
                    />
                  )}
                  {openPopup === 7 && (
                    <From_To_Popup
                      Theme={FontTheme}
                      segmentsData={searchBarData?.searchParams}
                      popular_cities={searchBarData?.popularAirports}
                      recent_searches={
                        getLocalStorageJSON("fts-hm-fa") === null
                          ? []
                          : getLocalStorageJSON("fts-hm-fa")
                      }
                      open={openPopup === 7}
                      position={openPopup}
                      PopupType={[0, 0]}
                      onClickClose={handleClose}
                      onClose={() => {
                        setopenPopup(0);
                        setopenPopupActual(0);
                      }}
                      left={"0px"}
                      top={"30px"}
                      placeholder={"From"}
                      positionType={"absolute"}
                    />
                  )}
                </>
                <>
                  <div
                    className="result-page-flights-frame-29"
                    onClick={() => {
                      setopenPopup(8);
                      setopenPopupActual(8);
                    }}
                  >
                    <div className="result-page-flights-frame-99-9">
                      <div
                        className={`result-page-flights-to ${FontTheme}_light color_white font12`}
                      >
                        TO
                      </div>
                    </div>

                    <div
                      className={`result-page-flights-innextText ${FontTheme}_regular color_white font16`}
                    >
                      {searchBarData?.searchParams?.[0]?.DestinyCode}{" "}
                      {searchBarData?.searchParams?.[0]?.DestinyCode !== "" &&
                        ", "}
                      {searchBarData?.searchParams?.[0]?.DestinationCity}{" "}
                      {searchBarData?.searchParams?.[0]?.DestinationCity !==
                        "" && ", "}
                      {searchBarData?.searchParams?.[0]?.DestinationCountry}
                    </div>
                  </div>
                  {searchBarData?.AirportsToError1[0] === true && (
                    <ErrorMessagePopup
                      Theme={FontTheme}
                      style={{
                        display:"flex",
                        alignItems : "end",
                        width: "max-content",
                        zIndex: 1,
                        backgroundColor: "#FFE0DA",
                        position: "absolute",
                        top: "108%",
                        left: "200px",
                        padding: "4px 8px",
                      }}
                      // addClass={searchBarData?.searchClicked}
                      message={searchBarData?.AirportsToError1[1]}
                    />
                  )}
                  {openPopup === 8 && openPopupActual === 8 && (
                    <From_To_Popup
                      Theme={FontTheme}
                      segmentsData={searchBarData?.searchParams}
                      popular_cities={searchBarData?.popularAirports}
                      recent_searches={
                        getLocalStorageJSON("fts-hm-ta") === null
                          ? []
                          : getLocalStorageJSON("fts-hm-ta")
                      }
                      open={openPopup === 8}
                      position={openPopup}
                      PopupType={[0, 1]}
                      onClickClose={handleClose}
                      onClose={() => {
                        setopenPopup(0);
                        setopenPopupActual(0);
                      }}
                      left={"210px"}
                      top={"30px"}
                      placeholder={"To"}
                      positionType={"absolute"}
                    />
                  )}
                </>
                <img
                  style={{ position: "absolute", top: "20px" }}
                  src={images["exchange-alt.png"]}
                  alt="exchange-alt"
                  onClick={() => dispatch(setExchangeSearchParas(0))}
                />
              </div>
              <div className="result-page-flights-frame-100-1">
                <div style={{width:"50%"}}>
                  <div
                    className="result-page-flights-frame-30"
                    onClick={() => {
                      setopenPopup(9);
                      setopenPopupActual(9);
                    }}
                  >
                    <div className="result-page-flights-frame-103-6">
                      <div
                        className={`result-page-flights-departure ${FontTheme}_light color_white font12`}
                      >
                        DEPARTURE
                      </div>
                    </div>
                    <div
                      className={`result-page-flights-tue-30-jan-9 ${FontTheme}_regular color_white font16`}
                    >
                      {convertDateStringResultPage(
                        searchBarData?.searchParams?.[0]?.OriginDate
                      )}
                    </div>
                  </div>
                  {searchBarData?.DateError1[0] === true && (
                    <ErrorMessagePopup
                      Theme={FontTheme}
                      style={{
                        display:"flex",
                        alignItems : "end",
                        width: "max-content",
                        zIndex: 1,
                        backgroundColor: "#FFE0DA",
                        position: "absolute",
                        top: "108%",
                        left: "10px",
                        padding: "4px 8px",
                      }}
                      // addClass={searchBarData?.searchClicked}
                      message={searchBarData?.DateError1[1]}
                    />
                  )}
                  {openPopup === 9 && openPopupActual === 9 && (
                    <RangeCalender
                      Theme={FontTheme}
                      // marginTop={"-35px" }
                      // left={"599px"}
                      showTopButtons={false}
                      Buttonelements={false}
                      open={openPopup === 9}
                      position={openPopup}
                      onClickClose={() => {
                        setopenPopup(10);
                        setopenPopupActual(10);
                      }}
                      onClose={() => {
                        setopenPopup(0);
                        setopenPopupActual(0);
                      }}
                      minimumDate={new Date().setDate(new Date().getDate() - 1)}
                      CalenderValues={null}
                      selectedDate={
                        new Date(searchBarData?.searchParams[0]?.OriginDate)
                      }
                      setSelectedDate={changeTheDate}
                      activeHeaderBar={1}
                      showReturnDate={true}
                      returnDateValue={
                        new Date(searchBarData?.searchParams[0]?.ReturnDate)
                      }
                      showNumberOfDaysCount={true}
                      onClickDepart={null}
                      onClickReturn={() => {
                        setopenPopup(10);
                        setopenPopupActual(10);
                      }}
                      isReturnCalender={true}
                      range={[
                        [
                          new Date(searchBarData?.searchParams[0]?.OriginDate),
                          getdateformattedrange(
                            new Date(searchBarData?.searchParams[0]?.OriginDate)
                          ),
                        ],
                        [
                          new Date(searchBarData?.searchParams[0]?.ReturnDate),
                          getdateformattedrange(
                            new Date(searchBarData?.searchParams[0]?.ReturnDate)
                          ),
                        ],
                      ]}
                    />
                  )}
                </div>

                <div style={{width:"50%"}}>
                  <div
                    className="result-page-flights-frame-31-1"
                    onClick={() => {
                      setopenPopup(10);
                      setopenPopupActual(10);
                    }}
                  >
                    <div
                      className={`result-page-flights-return ${FontTheme}_light color_white font12`}
                    >
                      RETURN
                    </div>
                    <img
                      onClick={() => {
                        dispatch(setSearchBarMode(1));
                      }}
                      className="result-page-flights-return-cross"
                      src={images["frame-205.png"]}
                      alt="Frame 205"
                    ></img>
                    <div
                      className={`result-page-flights-tue-30-jan-9 ${FontTheme}_regular color_white font16`}
                    >
                      {searchBarData?.searchParams?.[0]?.ReturnDate === null ? (
                        <span style={{ visibility: "hidden" }}>
                          aaa, aa aaa, aa
                        </span>
                      ) : (
                        convertDateStringResultPage(
                          searchBarData?.searchParams?.[0]?.ReturnDate
                        )
                      )}
                    </div>
                  </div>
                  {searchBarData?.DateError11[0] === true && (
                    <ErrorMessagePopup
                      Theme={FontTheme}
                      style={{
                        display:"flex",
                        alignItems : "end",
                        width: "max-content",
                        zIndex: 1,
                        backgroundColor: "#FFE0DA",
                        position: "absolute",
                        top: "108%",
                        left: "160px",
                        padding: "4px 8px",
                      }}
                      // addClass={searchBarData?.searchClicked}
                      message={searchBarData?.DateError11[1]}
                    />
                  )}
                  {openPopup === 10 && openPopupActual === 10 && (
                    <RangeCalender
                      Theme={FontTheme}
                      // marginTop={"-35px" }
                      left={"0px"}
                      showTopButtons={false}
                      Buttonelements={null}
                      open={openPopup === 10}
                      position={openPopup}
                      onClickClose={() => {
                        setopenPopup(11);
                        setopenPopupActual(11);
                      }}
                      onClose={() => {
                        setopenPopup(0);
                        setopenPopupActual(0);
                      }}
                      minimumDate={
                        searchBarData?.searchParams[0]?.OriginDate === null
                          ? new Date().setDate(new Date().getDate() - 1)
                          : new Date(
                              searchBarData?.searchParams[0]?.OriginDate
                            ).setDate(
                              new Date(
                                searchBarData?.searchParams[0]?.OriginDate
                              ).getDate() - 1
                            )
                      }
                      CalenderValues={null}
                      selectedDate={
                        new Date(searchBarData?.searchParams[0]?.OriginDate)
                      }
                      setSelectedDate={changeTheDateReturn}
                      activeHeaderBar={2}
                      showReturnDate={true}
                      returnDateValue={
                        new Date(searchBarData?.searchParams[0]?.ReturnDate)
                      }
                      showNumberOfDaysCount={true}
                      onClickDepart={() => {
                        setopenPopup(9);
                        setopenPopupActual(9);
                      }}
                      isReturnCalender={true}
                      onClickReturn={null}
                      range={[
                        [
                          new Date(searchBarData?.searchParams[0]?.OriginDate),
                          getdateformattedrange(
                            new Date(searchBarData?.searchParams[0]?.OriginDate)
                          ),
                        ],
                        [
                          new Date(searchBarData?.searchParams[0]?.ReturnDate),
                          getdateformattedrange(
                            new Date(searchBarData?.searchParams[0]?.ReturnDate)
                          ),
                        ],
                      ]}
                    />
                  )}
                </div>
              </div>
              <div style={{width:"20%" , marginLeft:"1px"}}>
                <div
                  className="result-page-flights-frame-32"
                  onClick={() => {
                    setopenPopup(11);
                    setopenPopupActual(11);
                  }}
                >
                  <div
                    className={`result-page-flights-travellers-class ${FontTheme}_light color_white font12`}
                  >
                    TRAVELLERS &amp; CLASS
                  </div>
                  <div className="result-page-flights-frame-1-9">
                    <div
                      className={`result-page-flights-address-18 address-19 ${FontTheme}_regular color_white font16`}
                    >
                      {searchBarData?.searchParamsTravellers?.Adults +
                        searchBarData?.searchParamsTravellers?.Childs +
                        searchBarData?.searchParamsTravellers?.Infrants}{" "}
                      Travellers ,{" "}
                      {searchBarData?.searchParamsFare?.class === "Economy" && (
                        <>
                          {searchBarData?.searchParamsFare?.class}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </>
                      )}
                      {searchBarData?.searchParamsFare?.class ===
                        "Business" && (
                        <>
                          {searchBarData?.searchParamsFare?.class}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </>
                      )}
                      {searchBarData?.searchParamsFare?.class === "First" && (
                        <>
                          {searchBarData?.searchParamsFare?.class}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </>
                      )}
                      {searchBarData?.searchParamsFare?.class ==
                        "Premium Economy" &&
                        searchBarData?.searchParamsFare?.class}
                    </div>
                  </div>
                  {searchBarData?.searchParamsTravellers
                    ?.TravellersAndClassesErrorOut === true && (
                    <ErrorMessagePopup
                      Theme={FontTheme}
                      style={{
                        display:"flex",
                        alignItems : "end",
                        width: "max-content",
                        zIndex: 1,
                        backgroundColor: "#FFE0DA",
                        position: "absolute",
                        top: "108%",
                        left: "-30%",
                        padding: "4px 8px",
                      }}
                      // addClass={searchBarData?.searchClicked}
                      message={TravellersFareMessage(
                        searchBarData?.searchParamsFare?.type
                      )}
                    />
                  )}
                </div>
                {openPopup === 11 && openPopupActual === 11 && (
                  <TravellersPopup
                    Theme={FontTheme}
                    open={openPopup === 11}
                    position={openPopup}
                    onClickClose={() => {
                      setopenPopup(0);
                      setopenPopupActual(0);
                    }}
                    onClose={() => {
                      setopenPopup(0);
                      setopenPopupActual(0);
                    }}
                    travellersData={travellersData}
                    classData={classData}
                    timeOutFunc={setTravellersErrors}
                    setTravellersAndClassesFunc={setTravellersAndClasses}
                  />
                )}
              </div>
              <div
                className={`result-page-flights-frame-20-2 result-page-flights-frame-20-3 ${
                  isButtonDisabled() ? "disabled" : ""
                }  ${FontTheme}`}
                onClick={!isButtonDisabled() ? handleSearch : null}
                style={{width : "10%" , display:"flex"}}
              >
                <div
                  className={`result-page-flights-search-1 ${FontTheme}_semibold color_${
                    FontTheme == "proxima" ? "endeavour" : "optera-galaxy"
                  } font18`}
                >
                  SEARCH
                </div>
              </div>
            </div>
          </div>
        </div>

        {
      ((searchBarData?.SearchResultType == 1 || searchBarData?.SearchResultType == 2 || searchBarData?.SearchResultType == 3 || searchBarData?.SearchResultType == 5 || searchBarData?.SearchResultType == 7 || searchBarData?.SearchResultType == 9) && FontTheme == "averta") ? null : 
          <SpecialFareOptions
          Theme={FontTheme}
            selectedOption={searchBarData?.searchParamsFare?.type}
            showTheToggler={
              (searchBarData?.SearchResultType === 4 ||
                searchBarData?.SearchResultType === 6 ||
                searchBarData?.SearchResultType === 8 ||
                searchBarData?.SearchResultType === 10) &&
              searchBarData?.SearchResultsStatus != "Loading"
            }
            ResultView={searchBarData?.ResultView}
          />
          }
      </div>
      {loadingPorgessBar}
    </div>
  );
}

export default RoundwaySearchBar;
