import React, { useRef, useEffect, useState, useMemo } from "react";
import "./index.css";
import FligthDetails from "./Components/FligthDetails";
import images from "../../../../../../../Utils/importImages";
import FareFamilyContent from "./Components/FareFamilyContent";
import FFTripInfo from "./Components/FFTripInfo";
// import FFTripInfo from "./Components/FFTripInfo";

import TripChoosers from "./Components/TripChooser/TripChoosers";
import TagsContainer from "./Components/TagsContainer";

function MainContent({
  Data,
  airlineLogos,
  ResultType,
  topBarMode,
  topBarSelected,
  settopBarSelected,
  FFSelected,
  setFFSelected,
  isRound,
  Theme,
}) {
  console.log("topBarSelected is : ", topBarSelected);
  const resultsRef = useRef(null);
  const resultsRef2 = useRef(null);

  const [showScrollButtons, setShowScrollButtons] = useState(false);
  const [showScrollButtons2, setShowScrollButtons2] = useState(false);

  const checkScrollButtons = () => {
    if (resultsRef.current) {
      setShowScrollButtons(
        resultsRef.current.scrollHeight > resultsRef.current.clientHeight
      );
    }

    if (resultsRef2.current) {
      setShowScrollButtons2(
        resultsRef2.current.scrollHeight > resultsRef2.current.clientHeight
      );
    }
  };

  const scrollUp = () => {
    if (resultsRef.current) {
      resultsRef.current.scrollBy({ top: -100, behavior: "smooth" });
    }
  };

  const scrollDown = () => {
    if (resultsRef.current) {
      resultsRef.current.scrollBy({ top: 100, behavior: "smooth" });
    }
  };

  const scrollUp2 = () => {
    if (resultsRef2.current) {
      resultsRef2.current.scrollBy({ top: -100, behavior: "smooth" });
    }
  };

  const scrollDown2 = () => {
    if (resultsRef2.current) {
      resultsRef2.current.scrollBy({ top: 100, behavior: "smooth" });
    }
  };

  useEffect(() => {
    checkScrollButtons();
    if (resultsRef.current) {
      resultsRef.current.addEventListener("scroll", checkScrollButtons);
    }
    if (resultsRef2.current) {
      resultsRef2.current.addEventListener("scroll", checkScrollButtons);
    }
    return () => {
      if (resultsRef.current) {
        resultsRef.current.removeEventListener("scroll", checkScrollButtons);
      }
      if (resultsRef2.current) {
        resultsRef2.current.removeEventListener("scroll", checkScrollButtons);
      }
    };
  }, []);

  // let height1 = "450px";
  // let height2 = "400px";

  // let height11 = "495px";
  // let height22 = "465px";

  let SelectTheFF = (index, num) => {
    setFFSelected((prev) => {
      return [...prev, (prev[index] = num)];
    });
  };

  console.log("is round here insdie is  : ", isRound);
  return (
    <div className="main-content-popup">
      <div
        className={`frame-243-3 frame-243-6 ${Theme}`}
        style={
          topBarMode !== 1.5 && topBarMode !== 2.5
            ? { padding: "20px 0px" }
            : { paddingBottom: "20px" }
        }
      >
        {showScrollButtons && (
          <>
            <button onClick={scrollUp} className="scroll-button up-button">
              <img src={images["scroll-btn-img.svg"]} alt="img" />
            </button>
            <button onClick={scrollDown} className="scroll-button down-button">
              <img src={images["scroll-btn-img.svg"]} alt="img" />
            </button>
          </>
        )}

        {topBarMode == 1.5 && (
          <TripChoosers
            Theme={Theme}
            isTrip={isRound == undefined ? true : isRound}
            lengthOfBars={Data.Segments.length}
            Data={Data}
            topBarSelected={topBarSelected}
            settopBarSelected={settopBarSelected}
          />
        )}
        {topBarMode == 2.5 && (
          <TripChoosers
            Theme={Theme}
            isTrip={isRound == undefined ? true : isRound}
            lengthOfBars={Data.Segments.length}
            Data={Data}
            topBarSelected={topBarSelected}
            settopBarSelected={settopBarSelected}
          />
        )}
        <div className={`results ${Theme}`} ref={resultsRef}>
          {topBarMode == 1 &&
            (Data.Segments == undefined
              ? Data.Connections[0].Segments.length == 1
              : Data.Segments.length == 1) && (
              <FligthDetails
                Theme={Theme}
                data={
                  Data.Segments == undefined
                    ? Data.Connections[0].Segments[0]
                    : Data.Segments[0]
                }
                airlineLogos={airlineLogos}
                ResultType={ResultType}
                Tags={
                  <TagsContainer
                    Theme={Theme}
                    data={
                      Data.Segments == undefined
                        ? Data.Connections[0].Segments
                        : Data.Segments
                    }
                    index={0}
                    single={true}
                  />
                }
              />
            )}

          {topBarMode == 1 &&
            (Data.Segments == undefined
              ? Data.Connections[0].Segments.length == 2
              : Data.Segments.length == 2) && (
              <>
                <FligthDetails
                  Theme={Theme}
                  data={
                    Data.Segments == undefined
                      ? Data.Connections[0].Segments[0]
                      : Data.Segments[0]
                  }
                  airlineLogos={airlineLogos}
                  ResultType={ResultType}
                  Tags={
                    <TagsContainer
                      Theme={Theme}
                      data={
                        Data.Segments == undefined
                          ? Data.Connections[0].Segments
                          : Data.Segments
                      }
                      index={[0, 1]}
                      single={false}
                    />
                  }
                />

                <FligthDetails
                  Theme={Theme}
                  data={
                    Data.Segments == undefined
                      ? Data.Connections[0].Segments[1]
                      : Data.Segments[1]
                  }
                  airlineLogos={airlineLogos}
                  ResultType={ResultType}
                  Tags={
                    <TagsContainer
                      Theme={Theme}
                      data={
                        Data.Segments == undefined
                          ? Data.Connections[0].Segments
                          : Data.Segments
                      }
                      index={1}
                      single={true}
                    />
                  }
                />
              </>
            )}

          {topBarMode == 1 &&
            (Data.Segments == undefined
              ? Data.Connections[0].Segments.length == 3
              : Data.Segments.length == 3) && (
              <>
                <FligthDetails
                  Theme={Theme}
                  data={
                    Data.Segments == undefined
                      ? Data.Connections[0].Segments[0]
                      : Data.Segments[0]
                  }
                  airlineLogos={airlineLogos}
                  ResultType={ResultType}
                  Tags={
                    <TagsContainer
                      Theme={Theme}
                      data={
                        Data.Segments == undefined
                          ? Data.Connections[0].Segments
                          : Data.Segments
                      }
                      index={[0, 1]}
                      single={false}
                    />
                  }
                />

                <FligthDetails
                  Theme={Theme}
                  data={
                    Data.Segments == undefined
                      ? Data.Connections[0].Segments[1]
                      : Data.Segments[1]
                  }
                  airlineLogos={airlineLogos}
                  ResultType={ResultType}
                  Tags={
                    <TagsContainer
                      Theme={Theme}
                      data={
                        Data.Segments == undefined
                          ? Data.Connections[0].Segments
                          : Data.Segments
                      }
                      index={[1, 2]}
                      single={false}
                    />
                  }
                />

                <FligthDetails
                  Theme={Theme}
                  data={Data.Segments[2]}
                  airlineLogos={airlineLogos}
                  ResultType={ResultType}
                  Tags={
                    <TagsContainer
                      Theme={Theme}
                      data={
                        Data.Segments == undefined
                          ? Data.Connections[0].Segments
                          : Data.Segments
                      }
                      index={2}
                      single={true}
                    />
                  }
                />
              </>
            )}

          {topBarMode == 1 && Data.Connections != undefined && (
            <div
              className="${Theme}_regular color_eerie-black font13"
              style={{ margin: "auto" }}
            >
              {" "}
              -- Connection --{" "}
            </div>
          )}

          {topBarMode == 1 &&
            Data.Connections != undefined &&
            Data.Connections[1].Segments.length == 1 && (
              <FligthDetails
                Theme={Theme}
                data={Data.Connections[1].Segments[0]}
                airlineLogos={airlineLogos}
                ResultType={ResultType}
                Tags={
                  <TagsContainer
                    Theme={Theme}
                    data={Data.Connections[1].Segments}
                    index={0}
                    single={true}
                  />
                }
              />
            )}

          {topBarMode == 1 &&
            Data.Connections != undefined &&
            Data.Connections[1].Segments.length == 2 && (
              <>
                <FligthDetails
                  Theme={Theme}
                  data={Data.Connections[1].Segments[0]}
                  airlineLogos={airlineLogos}
                  ResultType={ResultType}
                  Tags={
                    <TagsContainer
                      Theme={Theme}
                      data={Data.Connections[1].Segments}
                      index={[0, 1]}
                      single={false}
                    />
                  }
                />

                <FligthDetails
                  Theme={Theme}
                  data={Data.Connections[1].Segments[1]}
                  airlineLogos={airlineLogos}
                  ResultType={ResultType}
                  Tags={
                    <TagsContainer
                      Theme={Theme}
                      data={Data.Connections[1].Segments}
                      index={1}
                      single={true}
                    />
                  }
                />
              </>
            )}

          {topBarMode == 1 &&
            Data.Connections != undefined &&
            Data.Connections[1].Segments.length == 3 && (
              <>
                <FligthDetails
                  Theme={Theme}
                  data={Data.Connections[1].Segments[0]}
                  airlineLogos={airlineLogos}
                  ResultType={ResultType}
                  Tags={
                    <TagsContainer
                      Theme={Theme}
                      data={Data.Connections[1].Segments}
                      index={[0, 1]}
                      single={false}
                    />
                  }
                />

                <FligthDetails
                  Theme={Theme}
                  data={Data.Connections[1].Segments[1]}
                  airlineLogos={airlineLogos}
                  ResultType={ResultType}
                  Tags={
                    <TagsContainer
                      Theme={Theme}
                      data={Data.Connections[1].Segments}
                      index={[1, 2]}
                      single={false}
                    />
                  }
                />

                <FligthDetails
                  Theme={Theme}
                  data={Data.Connections[1].Segments[2]}
                  airlineLogos={airlineLogos}
                  ResultType={ResultType}
                  Tags={
                    <TagsContainer
                      Theme={Theme}
                      data={Data.Connections[1].Segments}
                      index={2}
                      single={true}
                    />
                  }
                />
              </>
            )}

          {(topBarMode == 1.5 || topBarMode == 2.5) &&
            Data.Segments[topBarSelected].length == 1 && (
              <FligthDetails
                Theme={Theme}
                data={Data.Segments[topBarSelected][0]}
                airlineLogos={airlineLogos}
                ResultType={ResultType}
                Tags={
                  <TagsContainer
                    Theme={Theme}
                    data={Data.Segments[topBarSelected]}
                    index={0}
                    single={true}
                  />
                }
              />
            )}

          {(topBarMode == 1.5 || topBarMode == 2.5) &&
            Data.Segments[topBarSelected].length == 2 && (
              <>
                <FligthDetails
                  Theme={Theme}
                  data={Data.Segments[topBarSelected][0]}
                  airlineLogos={airlineLogos}
                  ResultType={ResultType}
                  Tags={
                    <TagsContainer
                      Theme={Theme}
                      data={Data.Segments[topBarSelected]}
                      index={[0, 1]}
                      single={false}
                    />
                  }
                />

                <FligthDetails
                  Theme={Theme}
                  data={Data.Segments[topBarSelected][1]}
                  airlineLogos={airlineLogos}
                  ResultType={ResultType}
                  Tags={
                    <TagsContainer
                      Theme={Theme}
                      data={Data.Segments[topBarSelected]}
                      index={1}
                      single={true}
                    />
                  }
                />
              </>
            )}

          {(topBarMode == 1.5 || topBarMode == 2.5) &&
            Data.Segments[topBarSelected].length == 3 && (
              <>
                <FligthDetails
                  Theme={Theme}
                  data={Data.Segments[topBarSelected][0]}
                  airlineLogos={airlineLogos}
                  ResultType={ResultType}
                  Tags={
                    <TagsContainer
                      Theme={Theme}
                      data={Data.Segments[topBarSelected]}
                      index={[0, 1]}
                      single={false}
                    />
                  }
                />

                <FligthDetails
                  Theme={Theme}
                  data={Data.Segments[topBarSelected][1]}
                  airlineLogos={airlineLogos}
                  ResultType={ResultType}
                  Tags={
                    <TagsContainer
                      Theme={Theme}
                      data={Data.Segments[topBarSelected]}
                      index={[1, 2]}
                      single={false}
                    />
                  }
                />

                <FligthDetails
                  Theme={Theme}
                  data={Data.Segments[topBarSelected][2]}
                  airlineLogos={airlineLogos}
                  ResultType={ResultType}
                  Tags={
                    <TagsContainer
                      Theme={Theme}
                      data={Data.Segments[topBarSelected]}
                      index={2}
                      single={true}
                    />
                  }
                />
              </>
            )}

          {(topBarMode == 2 || topBarMode == 3 || topBarMode == 4) &&
            (Data[topBarSelected] == undefined
              ? Data.Connections[topBarSelected].Segments.length == 1
              : Data[topBarSelected].Segments.length == 1) && (
              <FligthDetails
                Theme={Theme}
                data={
                  Data[topBarSelected] == undefined
                    ? Data.Connections[topBarSelected].Segments[0]
                    : Data[topBarSelected].Segments[0]
                }
                airlineLogos={airlineLogos}
                ResultType={ResultType}
                Tags={
                  <TagsContainer
                    Theme={Theme}
                    data={
                      Data[topBarSelected] == undefined
                        ? Data.Connections[topBarSelected].Segments
                        : Data[topBarSelected].Segments
                    }
                    index={0}
                    single={true}
                  />
                }
              />
            )}

          {(topBarMode == 2 || topBarMode == 3 || topBarMode == 4) &&
            (Data[topBarSelected] == undefined
              ? Data.Connections[topBarSelected].Segments.length == 2
              : Data[topBarSelected].Segments.length == 2) && (
              <>
                <FligthDetails
                  Theme={Theme}
                  data={
                    Data[topBarSelected] == undefined
                      ? Data.Connections[topBarSelected].Segments[0]
                      : Data[topBarSelected].Segments[0]
                  }
                  airlineLogos={airlineLogos}
                  ResultType={ResultType}
                  Tags={
                    <TagsContainer
                      Theme={Theme}
                      data={
                        Data[topBarSelected] == undefined
                          ? Data.Connections[topBarSelected].Segments
                          : Data[topBarSelected].Segments
                      }
                      index={[0, 1]}
                      single={false}
                    />
                  }
                />

                <FligthDetails
                  Theme={Theme}
                  data={
                    Data[topBarSelected] == undefined
                      ? Data.Connections[topBarSelected].Segments[1]
                      : Data[topBarSelected].Segments[1]
                  }
                  airlineLogos={airlineLogos}
                  ResultType={ResultType}
                  Tags={
                    <TagsContainer
                      Theme={Theme}
                      data={
                        Data[topBarSelected] == undefined
                          ? Data.Connections[topBarSelected].Segments
                          : Data[topBarSelected].Segments
                      }
                      index={1}
                      single={true}
                    />
                  }
                />
              </>
            )}

          {(topBarMode == 2 || topBarMode == 3 || topBarMode == 4) &&
            (Data[topBarSelected] == undefined
              ? Data.Connections[topBarSelected].Segments.length == 3
              : Data[topBarSelected].Segments.length == 3) && (
              <>
                <FligthDetails
                  Theme={Theme}
                  data={
                    Data[topBarSelected] == undefined
                      ? Data.Connections[topBarSelected].Segments[0]
                      : Data[topBarSelected].Segments[0]
                  }
                  airlineLogos={airlineLogos}
                  ResultType={ResultType}
                  Tags={
                    <TagsContainer
                      Theme={Theme}
                      data={
                        Data[topBarSelected] == undefined
                          ? Data.Connections[topBarSelected].Segments
                          : Data[topBarSelected].Segments
                      }
                      index={[0, 1]}
                      single={false}
                    />
                  }
                />

                <FligthDetails
                  Theme={Theme}
                  data={
                    Data[topBarSelected] == undefined
                      ? Data.Connections[topBarSelected].Segments[1]
                      : Data[topBarSelected].Segments[1]
                  }
                  airlineLogos={airlineLogos}
                  ResultType={ResultType}
                  Tags={
                    <TagsContainer
                      Theme={Theme}
                      data={
                        Data[topBarSelected] == undefined
                          ? Data.Connections[topBarSelected].Segments
                          : Data[topBarSelected].Segments
                      }
                      index={[1, 2]}
                      single={false}
                    />
                  }
                />

                <FligthDetails
                  Theme={Theme}
                  data={
                    Data[topBarSelected] == undefined
                      ? Data.Connections[topBarSelected].Segments[2]
                      : Data[topBarSelected].Segments[2]
                  }
                  airlineLogos={airlineLogos}
                  ResultType={ResultType}
                  Tags={
                    <TagsContainer
                      Theme={Theme}
                      data={
                        Data[topBarSelected] == undefined
                          ? Data.Connections[topBarSelected].Segments
                          : Data[topBarSelected].Segments
                      }
                      index={2}
                      single={true}
                    />
                  }
                />
              </>
            )}
        </div>
      </div>

      <div className={`frame-221 ${Theme}`}>
        <div className="frame-241-3">
          <div className="frame-2-5 frame-2-10">
            <div className="frame-241-4">
              <div className="frame-242-11">
                <div
                  className={`more-fare-options ${Theme}_semibold color_${
                    Theme == "proxima" ? "endeavour" : "burning-gold"
                  } font16`}
                >
                  MORE FARE OPTIONS
                </div>
                <div
                  className={`available-for-your-trip ${Theme}_semibold color_${
                    Theme == "proxima" ? "eerie-black" : "deep-ocean"
                  } font16`}
                >
                  available for your trip
                </div>
              </div>
            </div>
          </div>
          {topBarMode == 1 && Data.Connections != undefined && (
            <FFTripInfo
              Theme={Theme}
              chooser={topBarMode == 1 && Data.Connections != undefined}
              Data={Data}
              onBarChange={settopBarSelected}
              selectedBar={topBarSelected}
            />
          )}
          {(topBarMode == 2 || topBarMode == 3 || topBarMode == 4) &&
            isRound !== "FromMulSplit" &&
            isRound == false && (
              <FFTripInfo
                Theme={Theme}
                chooser={false}
                Data={Data}
                onBarChange={settopBarSelected}
                selectedBar={topBarSelected}
              />
            )}
          <div className="frame-242-12">
            <div className="frame-241-5">
              {showScrollButtons2 && (
                <>
                  <button
                    onClick={scrollUp2}
                    className="scroll-button up-button-2"
                  >
                    <img src={images["scroll-btn-img.svg"]} alt="img" />
                  </button>
                  <button
                    onClick={scrollDown2}
                    className="scroll-button down-button-2"
                  >
                    <img src={images["scroll-btn-img.svg"]} alt="img" />
                  </button>
                </>
              )}

              <div
                className={`frame-241-6 ${Theme}`}
                style={
                  ((topBarMode == 1 && Data.Connections != undefined) ||
                    ((topBarMode == 2 || topBarMode == 3 || topBarMode == 4) &&
                      isRound !== "FromMulSplit" &&
                      isRound == false)) == true
                    ? { maxHeight: "390px" }
                    : {}
                }
                ref={resultsRef2}
              >
                {topBarMode == 1 &&
                  (Data.Segments == undefined
                    ? Data.Connections[topBarSelected].FareFamilies.FFList
                    : Data.FareFamilies.FFList
                  ).map((item, index) => {
                    return (
                      <>
                        <FareFamilyContent
                          Theme={Theme}
                          topBarSelected={topBarSelected}
                          data={item}
                          No={index}
                          selected={FFSelected[topBarSelected] == index}
                          onSelect={SelectTheFF}
                        />
                      </>
                    );
                  })}

                {(topBarMode == 1.5 || topBarMode == 2.5) &&
                  Data.FareFamilies.FFList.map((item, index) => {
                    return (
                      <>
                        <FareFamilyContent
                          Theme={Theme}
                          topBarSelected={0}
                          data={item}
                          No={index}
                          selected={FFSelected[0] == index}
                          onSelect={SelectTheFF}
                        />
                      </>
                    );
                  })}

                {(topBarMode == 2 || topBarMode == 3 || topBarMode == 4) &&
                  (Data[topBarSelected] == undefined
                    ? Data.Connections[topBarSelected].FareFamilies.FFList
                    : Data[topBarSelected].FareFamilies.FFList
                  ).map((item, index) => {
                    return (
                      <>
                        <FareFamilyContent
                          Theme={Theme}
                          topBarSelected={topBarSelected}
                          data={item}
                          No={index}
                          selected={FFSelected[topBarSelected] == index}
                          onSelect={SelectTheFF}
                        />
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainContent;
