import React, { useEffect, useState, useMemo, useCallback } from "react";
import CalenderBar from "./Components/CalenderBar";
import "./index.css";
import FilterBar from "../Components/FilterBar";
import ResultBar1 from "../Components/ResultBars/ResultBar1";
import { useDispatch, useSelector } from "react-redux";
import { formatDateForCalendarBar } from "../../../../../../Utils/Date_Time_Calendar_Functions";
import { convertToPrices } from "../../../../../../Utils/Flight_results_func";
import {
  SearchTheFligthFromCalendarBar,
  setFiltersApplied,
  setInitialSettings,
  setopenMainPopup,
} from "../../../../../../Redux/Slices/Flight_Slices/ResultPage-Flights-SLICE";
import { useDebounce } from "@uidotdev/usehooks";
import MainPopup from "../../MainPopup/MainPopup";

function OnewayData() {
  const dispatch = useDispatch();

  const searchBarData = useSelector((state) => state.ResultPageFlightsFlight);

  const [FlightAffordance, setFlightAffordance] = useState(0);
  const [ViewBy, setViewBy] = useState(0);
  const [searchText, setSearchText] = useState("");
  const debouncedSearchTerm = useDebounce(searchText, 1000);

  const handleFilterChange = (index, key, value, mode, specialIndex) => {
    dispatch(
      setFiltersApplied({
        index: index,
        key: key,
        value: value,
        mode: mode,
        specialIndex: specialIndex,
      })
    );
  };

  const changeFlightAffordance = useCallback((mode) => {
    setFlightAffordance(mode);

    handleFilterChange(
      searchBarData?.barModeSelected,
      `AFFORD${searchBarData?.barModeSelected}`,
      mode === 0 ? "Price" : "Time",
      "SortBy"
    );
  }, []);

  const changeViewBy = useCallback((mode) => {
    setViewBy(mode);
  }, []);

  const changesearchText = useCallback((value) => {
    setSearchText(value);
  }, []);

  useEffect(() => {
    if (searchText === "") {
      handleFilterChange(
        searchBarData?.barModeSelected,
        `QUERY${searchBarData?.barModeSelected}`,
        searchText,
        "SearchBy",
        1
      );
    } else {
      handleFilterChange(
        searchBarData?.barModeSelected,
        `QUERY${searchBarData?.barModeSelected}`,
        searchText,
        "SearchBy"
      );
    }
  }, [debouncedSearchTerm]);

  const airlineLogos = useMemo(
    () => searchBarData?.SearchResults?.AirlinesLogos,
    [searchBarData]
  );

  const selectedDate = useMemo(
    () => searchBarData?.SearchParameters?.Segments?.[0]?.Date,
    [searchBarData]
  );

  const calendarPrices = useMemo(
    () => convertToPrices(searchBarData?.SearchResults?.CalendarBarData),
    [searchBarData]
  );

  const filteredData = useMemo(
    () => searchBarData?.FilteredData?.slice(0, 5000),
    [searchBarData]
  );

  useMemo(() => {
    dispatch(setInitialSettings());
  }, []);

  const searchTheDataBydate = useCallback(
    (date) => {
      dispatch(SearchTheFligthFromCalendarBar(date));
    },
    [dispatch]
  );

  const FiltersApplied = useMemo(
    () => searchBarData?.FiltersApplied[searchBarData?.barModeSelected] || [],
    [searchBarData?.FiltersApplied, searchBarData?.barModeSelected]
  );

  console.log("FiltersApplied is : ", FiltersApplied);

  let [OpenPopup , setOpenPopup] = useState(false);

  let CloseThePopup = () => {
    setOpenPopup(false);
  };

  let OpenThePopup = () => {
    setOpenPopup(true);
  };

  let FontTheme = searchBarData?.PageTheme == 1 ? "proxima" : "averta";

  return (
    <>
      <div className="frame-257" style={FontTheme == "proxima" ? {marginTop:"10px"} : {}}>
        
        {
         FontTheme == "proxima" &&  
        <CalenderBar
          selectedDate={selectedDate}
          Prices={calendarPrices}
          onChange={searchTheDataBydate}
        />}
        <FilterBar
        Theme={FontTheme}
          FlightAffordance={FlightAffordance}
          ViewBy={ViewBy}
          searchText={searchText}
          onChangeFlightAffordance={changeFlightAffordance}
          onChangeViewBy={changeViewBy}
          onChangesearchText={changesearchText}
        />
      </div>

      {filteredData.length === 0 && FiltersApplied.length > 0 && (
        <div>To Many Filters Appplied ,, No Result Found</div>
      )}
      {filteredData?.map((item, index) => {
        return (
          <ResultBar1
          Theme={FontTheme}
            key={index}
            index={index}
            data={item}
            airlineLogos={airlineLogos}
            ViewBy={ViewBy}
            OpenPopup={OpenPopup}
            OpenThePopup={OpenThePopup}
          />
        );
      })}

      <MainPopup
              Theme={FontTheme}
        Open={OpenPopup}
        onClose={CloseThePopup}
        topBarMode={1}
        topBarSelected={0}
        isRound={false}
      />
    </>
  );
}

export default OnewayData;
