import React, { useState } from 'react';
import './index.css'; // Make sure to import your CSS file
import images from '../../../../../../../Utils/importImages';
import { useDispatch } from 'react-redux';
import { setbarModeSelected } from '../../../../../../../Redux/Slices/Flight_Slices/ResultPage-Flights-SLICE';

export const TripSlider = ({trips , selected , Theme}) => {
  const [currentTripIndex, setCurrentTripIndex] = useState(0);

  let dispatch = useDispatch();

  const handlePrevClick = () => {
    setCurrentTripIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : trips.length - 1));
  };

  const handleNextClick = () => {
    setCurrentTripIndex((prevIndex) => (prevIndex < trips.length - 1 ? prevIndex + 1 : 0));
  };

  return (
    <div className={`frame-slider ${Theme}`}>
      <div className="frame-233-5" style={{ transform: `translateX(${currentTripIndex === 0 ? 33.33 : currentTripIndex === 1 ? 0 : currentTripIndex === 2 ? -33.33 : -66.66}%)` }}>
        {trips.map((trip, index) => (
          <div
          onClick={() => dispatch(setbarModeSelected(trip.toSelect))}
            key={trip.id}
            className={`frame-17 ${index === currentTripIndex ? 'active' : 'inactive'}`}
            style={{width: trips.length === 2 ? "350px" : trips.length === 3 ? "230px" : "175px" , border : trip.toSelect === selected ? `0.5px solid var(${Theme == "proxima" ? "--endeavour" : "--burning-gold"})`: `0.5px solid var(--gray-4)`}}
          >
            <div className="frame-233">
              <div className="frame">
                <div className={trip.toSelect === selected ? `trip-1 trip-5 ${Theme}_regular color_${Theme == "proxima" ? "endeavour" : "optera-galaxy"} font13` : `trip-1 trip-5 ${Theme}_regular color_shady-lady font13`}>TRIP {trip.id}</div>
              </div>
            </div>
            <div className="frame-234">
              <div className="frame"><div className={trip.toSelect === selected ? `place ${Theme}_semibold color_${Theme == "proxima" ? "endeavour" : "burning-gold"} font16` : `place ${Theme}_semibold color_gray-4 font16`}>{trip.from}</div></div>
              <img src={images["city-exchange-arrow.svg"]} alt="exchange-alt" />
              <div className="frame"><div className={trip.toSelect === selected ? `place-1 ${Theme}_semibold color_${Theme == "proxima" ? "endeavour" : "burning-gold"} font16` : `place-1 ${Theme}_semibold color_gray-4 font16`}>{trip.to}</div></div>
            </div>
          </div>
        ))}
      </div>
      <img
        className="frame-233-6"
        src={images[Theme == "proxima" ? "frame-233.svg" : "circle-forward-button-gold.svg"]}
        style={
          Theme == "averta" ? { transform: "rotate(180deg)" } : {}
        }
        alt="Frame 233"
        onClick={handlePrevClick}
      />
      <img
        className="frame-234-4"
        src={images[Theme == "proxima" ? "frame-234.svg" : "circle-forward-button-gold.svg"]}
        alt="Frame 234"
        onClick={handleNextClick}
      />
    </div>
  );
};

export default TripSlider;
