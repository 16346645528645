import React from "react";
import images from "../../../../Utils/importImages";
import {
  getCalendarDateFormatted,
  getNoOfDays,
} from "../../../../Utils/Date_Time_Calendar_Functions";

function CalendarHeader({
  activeHeaderBar,
  showNumberOfDaysCount,
  onClickReturn,
  selectedDate,
  returnDateValue,
  onClickDepart,
  Theme,
}) {
  return (
    <div className="cal-head">
      <div
        className="calendar-frame-111"
     
      >
        <div className="calendar-frame-109-4">
          <div
            className={
              activeHeaderBar === 1
                ? "calendar-frame-109-5"
                : "calendar-frame-110"
            }
            onClick={onClickDepart}
          >
            <img
              className={
                activeHeaderBar === 1 ? "calendar-frame-203" : "calendar-1"
              }
              src={
                activeHeaderBar === 1
                  ? images["frame-203.svg"]
                  : images["calendar.svg"]
              }
              alt="calendar-Frame 203"
            />
            <div
              className={
                activeHeaderBar === 1
                  ? `calendar-address ${Theme}_semibold font18 color_black calendar-valign-text-middle`
                  : `calendar-address-1 ${Theme}_regular font18 color_stack calendar-valign-text-middle`
              }
            >
              {new Date(selectedDate).setHours(0, 0, 0, 0) <
              new Date().getHours(0, 0, 0, 0)
                ? "Select a Date"
                : getCalendarDateFormatted(selectedDate)}
            </div>
          </div>

          <div
            className={
              activeHeaderBar === 2
                ? "calendar-frame-109-5"
                : "calendar-frame-110"
            }
            onClick={onClickReturn}
          >
            {returnDateValue !== null && (
              <img
                className={
                  activeHeaderBar === 2 ? "calendar-frame-203" : "calendar-1"
                }
                src={
                  activeHeaderBar === 2
                    ? images["frame-203.svg"]
                    : images["calendar.svg"]
                }
                alt="calendar"
              />
            )}
            <div
              className={
                activeHeaderBar === 2
                  ? `calendar-address ${Theme}_semibold font18 color_black calendar-valign-text-middle`
                  : `calendar-address-1 ${Theme}_regular font18 color_stack calendar-valign-text-middle`
              }
            >
              {returnDateValue === "Add Return Date To Save More"
                ? "Add Return Date To Save More"
                : returnDateValue === null
                ? ""
                : new Date(returnDateValue).setHours(0, 0, 0, 0) <
                  new Date().getHours(0, 0, 0, 0)
                ? "Select a Date"
                : getCalendarDateFormatted(returnDateValue)}
            </div>
          </div>
        </div>

<div style={{
    display: "flex",
    gap: "10px",
    ...(showNumberOfDaysCount === false ? { visibility: "hidden" } : {}),
  }} >
        <div className="calendar-frame-109-2">
          <div
            className={`calendar-number-of-days calendar-valign-text-middle ${Theme}_regular color_storm-dust font13`}
          >
            Number of days
          </div>
        </div>
        <div className="calendar-frame-109-3">
          <div
            className={`calendar-number calendar-valign-text-middle ${Theme}_regular color_black font16`}
          >
            {showNumberOfDaysCount === true && getNoOfDays(selectedDate , returnDateValue)}
            
          </div>
        </div>
        {/* <img className="calendar-check" src={images["check.svg"]} alt="check" /> */}

</div>
      </div>
    </div>
  );
}

export default CalendarHeader;
