import React, { useRef, useState } from "react";
import images from "../../../../../../../../../../Utils/importImages";
import "./index.css";
import {
  formatDateResultBar,
  formatTime,
  getTotalMinutes,
} from "../../../../../../../../../../Utils/Date_Time_Calendar_Functions";


function Slider({ isTrip, Data, topBarSelected, settopBarSelected, Theme }) {
  // alert("here")
  console.log("Data at cslider is  : ", Data);

  const containerRef = useRef(null);

  let [scrollIndex, setScrollIndex] = useState(0);

  const handleForward = () => {
    if (scrollIndex < Data.Segments.length - 1) {
      setScrollIndex(scrollIndex + 1);
      containerRef.current.scrollBy({
        left: containerRef.current.clientWidth / 2,
        behavior: "smooth",
      });
    }
  };

  const handleBack = () => {
    if (scrollIndex > 0) {
      setScrollIndex(scrollIndex - 1);
      containerRef.current.scrollBy({
        left: -containerRef.current.clientWidth / 2,
        behavior: "smooth",
      });
    }
  };

  return (

    <div className="trip-chooser-slider">
      <img
              src={images[Theme == "proxima" ? "back-circle-arrow-button.svg" : "circle-forward-button-gold.svg"]}
              style={
                Theme == "averta" ? { transform: "rotate(180deg)" } : {}
              }
        alt="arrow-bton"
        className="back-arrow"
        onClick={() => handleBack()}
      />

      <div
        className="trip-chooser-2"
        style={{ display: "flex", overflowX: "hidden" }}
        ref={containerRef}
      >
          {
        Data?.Segments?.map((item, id) => {

          let selectedClass = topBarSelected == id ? "selected" : "non-selected";

          return (
            <div
              className={`frame-244 ${selectedClass} ${Theme}`}
              onClick={() => settopBarSelected(id)}
            >
              <div className="frame-2-1">
                <div className="frame-242-1">
                  <div className="group-261">
                    <div className={`${Theme}_semibold color_${(topBarSelected == id) ? (Theme == "proxima" ? "rhino" : "burning-gold") : "gray-4"} font12`}>
                      {isTrip == false ? `Trip ${id+1}` : id == 0 ? "ONWARD" : "RETURN"}
                    </div>
<div style={{fontSize:"x-small"}}>|</div>
                    {/* <div className="line-straight"></div> */}
                    <div className={`address-5 ${Theme}_regular color_${(topBarSelected == id) ? "gravel" : "stack"} font12`}>
                      {formatDateResultBar(item[0].Origin.DepTime)}
                    </div>
                  </div>

                  <div className="frame-242-13">
                    <div className="frame-202">
                      <img src={images[topBarSelected == id ? "clock-sign-black.svg" : "click-icon-gray.svg"]} alt="Vector 50" />

                      <div className={`x2h-40m-3 ${Theme}_regular color_${(topBarSelected == id) ? "gravel" : "stack"} font14`}>
                        {" "}
                        {formatTime(
                          getTotalMinutes(
                            item[0].Origin.DepTime,
                            item[item.length - 1].Destination
                              .ArrTime
                          )
                        )}
                      </div>
                    </div>
                  </div>

                </div>


                <div className="frame-243-4 frame-243-6">
                  <div className={`${Theme}_regular color_${(topBarSelected == id) ? (Theme == "proxima" ? "rhino" : "optera-galaxy") : "gray-4"} font16`}>
                    {item[0].Origin.Airport.CityName}
                  </div>
                  <div className="frame-170">
                    <div className="line-through"> </div>
                    <div className="frame-202">
                      <div className={`address-5 ${Theme}_regular color_${(topBarSelected == id) ? "gravel" : "stack"} font12`}>
                        {" "}
                        {item.length === 1
                          ? "Non Stop"
                          : item.length - 1 + " Stops"}
                      </div>
                    </div>

                    <div className="line-through"> </div>
                  </div>
                  <div className={`${Theme}_regular color_${(topBarSelected == id) ? (Theme == "proxima" ? "rhino" : "optera-galaxy") : "gray-4"} font16`}>
                    {
                      item[item.length - 1].Destination
                        .Airport.CityName
                    }
                  </div>
                </div>

              </div>
            </div>

          )
        })
      }
      </div>

      <img
              src={images[Theme == "proxima" ? "forward-circle-arrow-button.svg" : "circle-forward-button-gold.svg"]}

        alt="arrow-bton"
        className="forward-arrow"
        onClick={() => handleForward()}
      />
    </div>
  );
}

export default Slider;
