import React from "react";
import "./index2.css";
import images from "../../../../Utils/importImages";
import { generateListToRender } from "../../../../Utils/Flight_results_func";

function StopsSummary({ data, ResultType , index , Theme}) {

  const listToRender = generateListToRender(data, ResultType , index);

  return (
    <div className="tooltip-content">
      <div className="x3-stop-proxima-1">
        <div className={`frame-205-1 frame-1 ${Theme}`}>
          <div className="frame-206-1 frame-1">
            <div className="frame-205-1-1">
              <img src={images["stops-icon.png"]} alt="Vector" />

              <div className={`${Theme}_regular color_white font13`}>Stops</div>
            </div>
            <div className="frame-209-1 frame-1">
              <img src={images["layover.svg"]} alt="Vector 50" />

              <div className={`${Theme}_regular color_white font13`}>Layover</div>
            </div>
          </div>
          <div className={`frame-205-2-1 ${Theme}_regular color_white font13`}>
            {listToRender?.map((item, index) => {
              if (item !== null && item !== false) {
                return (
                  <div className="frame-21-1 frame-1">
                    <div className="tas-tashkent-1">{item.name}</div>
                    <div className="x2h-19m-1">{item.time}</div>
                  </div>
                );
              }
            })}
          </div>
        </div>
    
      </div>
    </div>
  );
}

export default StopsSummary;
