import React from 'react'

function Rules() {
  return (
<div className="frame-242-14-cc">
                <div className="terms-conditions-cc proxima_semibold color_eerie-black font16">
                  Terms &amp; Conditions
                </div>
                <ul style={{ marginTop: "initial" }}>
                  <li className="the-airline-fee-is-i-cc proxima_regular color_gravel font14">
                    The airline fee is indicative. Stfares does not guarantee
                    the accuracy of this information.
                  </li>
                  <li className="the-airline-fee-is-i-cc proxima_regular color_gravel font14">
                    All fees mentioned are per passenger. Date change charges
                    are applicable only on selecting the same airline on a new
                    date. The difference in fares between the old and the new
                    booking will also be payable by the user.
                  </li>
                  <li className="the-airline-fee-is-i-cc proxima_regular color_gravel font14">
                    Please refer to the Date Change Charges section above for
                    details on the number of allowed free date changes, if
                    applicable
                  </li>
                </ul>
              </div>
  )
}

export default Rules
