import React, { useEffect, useState } from "react";
import images from "../../../../Utils/importImages";
import SpecialFareOptions from "./SpecialFareOptions";
import {
  formatDate,
  getDayOfTheDate,
  getFirstTwoLettersOfDate,
  getThreeLetterMonth,
  getTwoLetterYear,
} from "../../../../Utils/Date_Time_Calendar_Functions";
import RangeCalender from "../../../Components/Popups/Calendars/RangeCalender";
import From_To_Popup from "../../../Components/Popups/From_To_Popup";
import TravellersPopup from "../../../Components/Popups/TravellersPopup";
import { getdateformattedrange } from "../../../../Utils/Date_Time_Calendar_Functions";
import {
  setExchangeSearchParas,
  setSearchBarMode,
  setSearchSegmentsParams,
  setTheDates,
  setTravellersAndClasses,
  setTravellersErrors,
} from "../../../../Redux/Slices/Flight_Slices/HomePage-Flights-SLICE";
import { useDispatch, useSelector } from "react-redux";
import ErrorMessagePopup from "../../../Components/Popups/ErrorMessagePopup";
import {
  SearchBarErrorMessages,
  TravellersFareMessage,
} from "../../../../Constants/ConstantsFlights";
import { getLocalStorageJSON } from "../../../../Utils/localStorageFunc";

export default function RoundwaySearchBar({
  openPopup,
  setopenPopup,
  openPopupActual,
  setopenPopupActual,
}) {
  // let [selectedDate1, setSelectedDate1] = useState(new Date());
  // let [selectedDate2, setSelectedDate2] = useState(new Date("2024-06-20"));

  let dispatch = useDispatch();
  let searchBarData = useSelector((state) => state.HomePageSearchBarFlight);

  let travellersData = useSelector(
    (state) => state.HomePageSearchBarFlight.searchParamsTravellers
  );
  let classData = useSelector(
    (state) => state.HomePageSearchBarFlight.searchParamsFare
  );

  let changeTheDate = (date) => {
    let selectedDate1 = date;
    let selectedDate2 = new Date(
      searchBarData?.searchParams?.Segments[1]?.OriginDate
    );

    let selectedDate3 = new Date(
      searchBarData?.searchParams?.Segments[2]?.OriginDate
    );

    let selectedDate4 = new Date(
      searchBarData?.searchParams?.Segments[3]?.OriginDate
    );

    let ReturnDate = searchBarData?.searchParams?.Segments[0]?.ReturnDate;

    if (ReturnDate != null) {
      if (
        selectedDate1 >
        new Date(searchBarData?.searchParams?.Segments[0]?.ReturnDate).setHours(
          0,
          0,
          0,
          0
        )
      ) {
        const newDate = new Date(selectedDate1);
        newDate.setDate(selectedDate1.getDate() + 1);
        ReturnDate = newDate;
      }
    }

    if (selectedDate1 > selectedDate2) {
      const newDate = new Date(selectedDate1);
      newDate.setDate(selectedDate1.getDate() + 1);
      selectedDate2 = newDate;
    }
    if (selectedDate2 > selectedDate3) {
      const newDate = new Date(selectedDate2);
      newDate.setDate(selectedDate2.getDate() + 1);
      selectedDate3 = newDate;
    }
    if (selectedDate3 > selectedDate4) {
      const newDate = new Date(selectedDate3);
      newDate.setDate(selectedDate3.getDate() + 1);
      selectedDate4 = newDate;
    }

    let Obj = [
      {
        OriginDate: formatDate(selectedDate1),
        ReturnDate: formatDate(ReturnDate),
      },
      {
        OriginDate: formatDate(selectedDate2),
        ReturnDate: null,
      },
      {
        OriginDate: formatDate(selectedDate3),
        ReturnDate: null,
      },
      {
        OriginDate: formatDate(selectedDate4),
        ReturnDate: null,
      },
    ];

    dispatch(setTheDates(Obj));
  };

  let changeTheDateReturn = (date) => {
    let Obj = [
      {
        OriginDate: searchBarData?.searchParams?.Segments[0]?.OriginDate,
        ReturnDate: formatDate(date),
      },
      {
        OriginDate: searchBarData?.searchParams?.Segments[1]?.OriginDate,
        ReturnDate: null,
      },
      {
        OriginDate: searchBarData?.searchParams?.Segments[2]?.OriginDate,
        ReturnDate: null,
      },
      {
        OriginDate: searchBarData?.searchParams?.Segments[3]?.OriginDate,
        ReturnDate: null,
      },
    ];

    dispatch(setTheDates(Obj));
  };

  let handleClose = (item, PopupType) => {
    let StructuredAirport = {
      City: item.city_name,
      Code: item.airport_code,
      AirportName: item.airport_name,
      CountryCode: item.country_code,

      type: PopupType[0],
      PopupType: PopupType[1],

      airport: item,
    };

    dispatch(setSearchSegmentsParams(StructuredAirport));

    setopenPopup(openPopup + 1);
    setopenPopupActual(openPopup + 1);
  };

  return (
    <>
      <div className="frame-33">
        <div className="frame">
          <div className="frame-99-5">
            <div className="overlap-group-1">
              <div>
                <div
                  className="frame-28"
                  onClick={() => setopenPopup(6)}
                  style={openPopup === 6 ? { backgroundColor: "#E8F3FF" } : {}}
                >
                  <div className="frame-99-6">
                    <div
                      className="place-1 proxima_regular color_storm-dust font14"
                      style={openPopup === 6 ? { color: "#0057B7" } : {}}
                    >
                      From
                    </div>
                  </div>
                  <div className="place-2 proxima_regular color_eerie-black font24">
                    {searchBarData?.searchParams?.Segments[0]?.OriginCity}
                  </div>
                  <div className="del-indira-gandhi-airport proxima_regular color_storm-dust font16 text-overflow-elipsis-search">
                    {searchBarData?.searchParams?.Segments[0]?.OriginCode}
                    {!searchBarData?.searchParams?.Segments[0]?.OriginCode && (
                      <span style={{ visibility: "hidden" }}>dummy</span>
                    )}
                    {searchBarData?.searchParams?.Segments[0]?.OriginCode &&
                      ","}{" "}
                    {searchBarData?.searchParams?.Segments[0]?.Origin}
                  </div>
                </div>
                {searchBarData?.AirportsFromError1[0] === true && (
                  <ErrorMessagePopup
                    Theme={"proxima"}
                    style={{
                      display:"flex",
                      alignItems : "end",
                      width: "max-content",
                      zIndex: 1,
                      backgroundColor: "#FFE0DA",
                      position: "absolute",
                      top: "108%",
                      left: "10px",
                      padding: "4px 8px",
                    }}
                    addClass={searchBarData?.searchClicked}
                    message={searchBarData?.AirportsFromError1[1]}
                  />
                )}
                {openPopup === 6 && (
                  <From_To_Popup
                    Theme={"proxima"}
                    popular_cities={searchBarData?.popularAirports}
                    recent_searches={
                      getLocalStorageJSON("fts-hm-fa") === null
                        ? []
                        : getLocalStorageJSON("fts-hm-fa")
                    }
                    open={openPopup === 6}
                    position={openPopup}
                    PopupType={[0, 0]}
                    onClickClose={handleClose}
                    onClose={() => {
                      setopenPopup(0);
                      setopenPopupActual(0);
                    }}
                    placeholder={"From"}
                    left={"-1px"}
                    top={"35px"}
                    positionType={"absolute"}
                  />
                )}
              </div>

              <div>
                <div
                  className="frame-29"
                  style={openPopup === 7 ? { backgroundColor: "#E8F3FF" } : {}}
                  onClick={() => {
                    setopenPopup(7);
                    setopenPopupActual(7);
                  }}
                >
                  <div className="frame-99-7">
                    <div
                      className="to proxima_regular color_storm-dust font14"
                      style={
                        openPopup === 7 && openPopupActual === 7
                          ? { color: "#0057B7" }
                          : {}
                      }
                    >
                      To
                    </div>
                    <div className="airplane-landing-arrival-1"></div>
                  </div>
                  <div className="place-3 proxima_regular color_eerie-black font24">
                    {searchBarData?.searchParams?.Segments[0]?.DestinationCity}
                  </div>
                  <div className="atq-amritsar-airport proxima_regular color_storm-dust font16 text-overflow-elipsis-search">
                    {searchBarData?.searchParams?.Segments[0]?.DestinyCode}
                    {!searchBarData?.searchParams?.Segments[0]?.DestinyCode && (
                      <span style={{ visibility: "hidden" }}>dummy</span>
                    )}
                    {searchBarData?.searchParams?.Segments[0]?.DestinyCode &&
                      ","}{" "}
                    {searchBarData?.searchParams?.Segments[0]?.Destiny}
                  </div>
                </div>
                <img
                  className="exchange-alt"
                  src={images["exchange-alt-6.svg"]}
                  alt="exchange-alt"
                  onClick={() => dispatch(setExchangeSearchParas(0))}
                />
                {searchBarData?.AirportsToError1[0] === true && (
                  <ErrorMessagePopup
                    Theme={"proxima"}
                    style={{
                      display:"flex",
                      alignItems : "end",
                      width: "max-content",
                      zIndex: 1,
                      backgroundColor: "#FFE0DA",
                      position: "absolute",
                      top: "120%",
                      left: "300px",
                      padding: "4px 8px",
                    }}
                    addClass={searchBarData?.searchClicked}
                    message={searchBarData?.AirportsToError1[1]}
                  />
                )}
                {openPopup === 7 && openPopupActual === 7 && (
                  <From_To_Popup
                    Theme={"proxima"}
                    popular_cities={searchBarData?.popularAirports}
                    recent_searches={
                      getLocalStorageJSON("fts-hm-ta") === null
                        ? []
                        : getLocalStorageJSON("fts-hm-ta")
                    }
                    open={openPopup === 7}
                    position={openPopup}
                    PopupType={[0, 1]}
                    onClickClose={handleClose}
                    onClose={() => {
                      setopenPopup(0);
                      setopenPopupActual(0);
                    }}
                    placeholder={"To"}
                    left={"301px"}
                    top={"35px"}
                    positionType={"absolute"}
                  />
                )}
              </div>
            </div>
            <div>
              <div
                className="frame-30"
                style={openPopup === 8 ? { backgroundColor: "#E8F3FF" } : {}}
                onClick={() => {
                  setopenPopup(8);
                  setopenPopupActual(8);
                }}
              >
                <div className="frame-103-9">
                  <div
                    className="departure proxima_regular color_storm-dust font14"
                    style={openPopup === 8 ? { color: "#0057B7" } : {}}
                  >
                    Departure
                  </div>
                </div>
                <div className="frame proxima_regular color_eerie-black font24">
                  <div className="address">
                    {getFirstTwoLettersOfDate(
                      searchBarData?.searchParams?.Segments[0]?.OriginDate
                    )}{" "}
                    {getThreeLetterMonth(
                      searchBarData?.searchParams?.Segments[0]?.OriginDate
                    )}
                    `
                  </div>
                  <div className="text-9">
                    {getTwoLetterYear(
                      searchBarData?.searchParams?.Segments[0]?.OriginDate
                    )}
                  </div>
                </div>
                <div className="tuesday proxima_regular color_storm-dust font16">
                  {getDayOfTheDate(
                    searchBarData?.searchParams?.Segments[0]?.OriginDate
                  )}
                </div>
              </div>

              {openPopup === 8 && openPopupActual === 8 && (
                <RangeCalender
                  Theme={"proxima"}
                  marginTop={"0px" }
                  left={"599px"}
                  showTopButtons={false}
                  Buttonelements={
                    <div
                      style={{
                        display: "flex",
                        width: "max-content",
                      }}
                    >
                      <div
                        className="frame-30"
                        style={
                          openPopup === 8 ? { backgroundColor: "#E8F3FF" } : {}
                        }
                        onClick={() => {
                          setopenPopup(8);
                          setopenPopupActual(8);
                        }}
                      >
                        <div className="frame-103-9">
                          <div
                            className="departure proxima_regular color_storm-dust font14"
                            style={openPopup === 8 ? { color: "#0057B7" } : {}}
                          >
                            Departure
                          </div>
                        </div>
                      </div>

                      <div
                        className="frame-31"
                        style={
                          openPopup === 9 ? { backgroundColor: "#E8F3FF" } : {}
                        }
                      >
                        <div
                          className="return proxima_regular color_storm-dust font14"
                          style={openPopup === 9 ? { color: "#0057B7" } : {}}
                        >
                          <span
                            onClick={() => {
                              setopenPopup(9);
                              setopenPopupActual(9);
                            }}
                          >
                            Return
                          </span>
                          <img
                            className="frame-206"
                            style={{
                              // zIndex: "10000 !important",
                              marginLeft: "5px",
                              // position: "absolute",
                              // top: "-14px",
                              // right: "95px",
                            }}
                            src={images["frame-206.svg"]}
                            alt="Frame 206"
                            onClick={() => {
                              if (openPopupActual === 9) {
                                setopenPopup(3);
                                setopenPopupActual(3);
                              }

                              dispatch(setSearchBarMode(1));
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  }
                  open={openPopup === 8}
                  position={openPopup}
                  onClickClose={() => {
                    setopenPopup(9);
                    setopenPopupActual(9);
                  }}
                  onClose={() => {
                    setopenPopup(0);
                    setopenPopupActual(0);
                  }}
                  minimumDate={new Date().setDate(new Date().getDate() - 1)}
                  CalenderValues={null}
                  selectedDate={
                    new Date(
                      searchBarData?.searchParams?.Segments[0]?.OriginDate
                    )
                  }
                  setSelectedDate={changeTheDate}
                  activeHeaderBar={1}
                  showReturnDate={true}
                  returnDateValue={
                    new Date(
                      searchBarData?.searchParams?.Segments[0]?.ReturnDate
                    )
                  }
                  showNumberOfDaysCount={true}
                  onClickDepart={null}
                  onClickReturn={() => {
                    setopenPopup(9);
                    setopenPopupActual(9);
                  }}
                  isReturnCalender={true}
                  range={[
                    [
                      new Date(
                        searchBarData?.searchParams?.Segments[0]?.OriginDate
                      ),
                      getdateformattedrange(
                        new Date(
                          searchBarData?.searchParams?.Segments[0]?.OriginDate
                        )
                      ),
                    ],
                    [
                      new Date(
                        searchBarData?.searchParams?.Segments[0]?.ReturnDate
                      ),
                      getdateformattedrange(
                        new Date(
                          searchBarData?.searchParams?.Segments[0]?.ReturnDate
                        )
                      ),
                    ],
                  ]}
                />
              )}
            </div>

            <div>
              <div
                className="frame-31"
                style={openPopup === 9 ? { backgroundColor: "#E8F3FF" } : {}}
              >
                <div
                  className="return proxima_regular color_storm-dust font14"
                  style={openPopup === 9 ? { color: "#0057B7" } : {}}
                >
                  <span
                    onClick={() => {
                      setopenPopup(9);
                      setopenPopupActual(9);
                    }}
                  >
                    Return
                  </span>
                  <img
                    className="frame-206"
                    style={{
                      marginLeft: "5px",
                    }}
                    src={images["frame-206.svg"]}
                    alt="Frame 206"
                    onClick={() => {
                      if (openPopupActual === 9) {
                        setopenPopup(3);
                        setopenPopupActual(3);
                      }

                      dispatch(setSearchBarMode(1));
                    }}
                  />
                </div>

                <div
                  className="frame proxima_regular color_eerie-black font24"
                  style={{ marginLeft: "-46px" }}
                  onClick={() => {
                    setopenPopup(9);
                    setopenPopupActual(9);
                  }}
                >
                  <div className="address">
                    {getFirstTwoLettersOfDate(
                      searchBarData?.searchParams?.Segments[0]?.ReturnDate
                    )}{" "}
                    {getThreeLetterMonth(
                      searchBarData?.searchParams?.Segments[0]?.ReturnDate
                    )}
                    `
                  </div>
                  <div className="text-9">
                    {getTwoLetterYear(
                      searchBarData?.searchParams?.Segments[0]?.ReturnDate
                    )}
                  </div>
                </div>
                <div className="tuesday proxima_regular color_storm-dust font16">
                  {getDayOfTheDate(
                    searchBarData?.searchParams?.Segments[0]?.ReturnDate
                  )}
                </div>
              </div>

              {openPopup === 9 && openPopupActual === 9 && (
                <RangeCalender
                  Theme={"proxima"}
                  marginTop={"0px" }
                left={"599px"}
                  showTopButtons={false}
                  Buttonelements={
                    <div
                      style={{
                        display: "flex",
                        width: "max-content",
                      }}
                    >
                      <div
                        className="frame-30"
                        style={
                          openPopup === 8 ? { backgroundColor: "#E8F3FF" } : {}
                        }
                        onClick={() => {
                          setopenPopup(8);
                          setopenPopupActual(8);
                        }}
                      >
                        <div className="frame-103-9">
                          <div
                            className="departure proxima_regular color_storm-dust font14"
                            style={openPopup === 8 ? { color: "#0057B7" } : {}}
                          >
                            Departure
                          </div>
                        </div>
                      </div>

                      <div
                        className="frame-31"
                        style={
                          openPopup === 9 ? { backgroundColor: "#E8F3FF" } : {}
                        }
                      >
                        <div
                          className="return proxima_regular color_storm-dust font14"
                          style={openPopup === 9 ? { color: "#0057B7" } : {}}
                        >
                          <span
                            onClick={() => {
                              setopenPopup(9);
                              setopenPopupActual(9);
                            }}
                          >
                            Return
                          </span>
                          <img
                            className="frame-206"
                            style={{
                              // zIndex: "10000 !important",
                              marginLeft: "5px",
                              // position: "absolute",
                              // top: "-14px",
                              // right: "95px",
                            }}
                            src={images["frame-206.svg"]}
                            alt="Frame 206"
                            onClick={() => {
                              if (openPopupActual === 9) {
                                setopenPopup(3);
                                setopenPopupActual(3);
                              }

                              dispatch(setSearchBarMode(1));
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  }
                  open={openPopup === 9}
                  position={openPopup}
                  onClickClose={() => {
                    setopenPopup(10);
                    setopenPopupActual(10);
                  }}
                  onClose={() => {
                    setopenPopup(0);
                    // setopenPopupActual(0);
                  }}
                  minimumDate={new Date(
                    searchBarData?.searchParams?.Segments[0]?.OriginDate
                  ).setDate(
                    new Date(
                      searchBarData?.searchParams?.Segments[0]?.OriginDate
                    ).getDate() - 1
                  )}
                  CalenderValues={null}
                  selectedDate={
                    new Date(
                      searchBarData?.searchParams?.Segments[0]?.OriginDate
                    )
                  }
                  setSelectedDate={changeTheDateReturn}
                  activeHeaderBar={2}
                  showReturnDate={true}
                  returnDateValue={
                    new Date(
                      searchBarData?.searchParams?.Segments[0]?.ReturnDate
                    )
                  }
                  showNumberOfDaysCount={true}
                  onClickDepart={() => {
                    setopenPopup(8);
                    setopenPopupActual(8);
                  }}
                  isReturnCalender={true}
                  onClickReturn={null}
                  range={[
                    [
                      new Date(
                        searchBarData?.searchParams?.Segments[0]?.OriginDate
                      ),
                      getdateformattedrange(
                        new Date(
                          searchBarData?.searchParams?.Segments[0]?.OriginDate
                        )
                      ),
                    ],
                    [
                      new Date(
                        searchBarData?.searchParams?.Segments[0]?.ReturnDate
                      ),
                      getdateformattedrange(
                        new Date(
                          searchBarData?.searchParams?.Segments[0]?.ReturnDate
                        )
                      ),
                    ],
                  ]}
                />
              )}
            </div>

          <div>
            <div
              className="frame-32"
              onClick={() => {
                setopenPopup(10);
                setopenPopupActual(10);
              }}
            >
              <div
                className="travellers-and-class proxima_regular color_storm-dust font14"
                style={openPopup === 10 ? { color: "#0057B7" } : {}}
              >
                Travellers and Class
              </div>
              <div className="address-1 proxima_regular color_eerie-black font24">
                {searchBarData?.searchParamsTravellers?.Adults +
                  searchBarData?.searchParamsTravellers?.Childs +
                  searchBarData?.searchParamsTravellers?.Infrants}{" "}
                Traveller
              </div>
              <div className="place-4 proxima_regular color_storm-dust font16">
                {searchBarData?.searchParamsFare?.class}
              </div>
              {searchBarData?.searchParamsTravellers
                ?.TravellersAndClassesErrorOut === true && (
                <ErrorMessagePopup
                  Theme={"proxima"}
                  style={{
                    display:"flex",
                    alignItems : "end",
                    width: "max-content",
                    zIndex: 1,
                    backgroundColor: "#FFE0DA",
                    position: "absolute",
                    top: "108%",
                    left: "-30%",
                    padding: "4px 8px",
                  }}
                  addClass={searchBarData?.searchClicked}
                  message={TravellersFareMessage(
                    searchBarData?.searchParamsFare?.type
                  )}
                />
              )}
            </div>

            {openPopup === 10 && openPopupActual === 10 && (
              <TravellersPopup
                Theme={"proxima"}
                open={openPopup === 10}
                position={openPopup}
                onClickClose={() => {
                  setopenPopup(0);
                  setopenPopupActual(0);
                }}
                onClose={() => {
                  setopenPopup(0);
                  setopenPopupActual(0);
                }}
                travellersData={travellersData}
                classData={classData}
                timeOutFunc={setTravellersErrors}
                setTravellersAndClassesFunc={setTravellersAndClasses}
              />
            )}
          </div>
          </div>
     

     

        </div>
      </div>
    </>
  );
}
