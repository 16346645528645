import React, { useEffect, useMemo, useState } from "react";

import FlightPageSearchBar from "./Flights/HomePageSearchBar"
import HotelPageSarchBar from "./Hotels/HomePageSearchBar"
import PackagePageSarchBar from "./Packages/HomePageSearchBar"
import "./HomePage.css";

import { useDispatch, useSelector } from "react-redux";
import { PopularAirprotsFetcher } from "../../Redux/Slices/Flight_Slices/HomePage-Flights-SLICE";
import { UserLocationFetcher } from "../../Redux/Slices/User_Slices/User-SLICE";
import Header from "../Components/Headers/Header";
import Footer from "../Components/Footers/Footer";
import OffersSection from "./HomePageSections/OffersSection/OffersSection";
import PopularDestinations from "./HomePageSections/PopularDestinations/PopularDestinations";

export default function HomePage({ type }) {

  let dispatch = useDispatch();

  useMemo(() => {

    // These are the fucntions which will ne loaded when user will visit-revisit the site related to airlines 

    dispatch(PopularAirprotsFetcher());

  }, []);

  return (

    <div className="homepage screen">
      <div className="frame-101">
        {/* <Header /> */}

        <div className="frame-203">
          <p className="book-international-and-domestic-flights valign-text-bottom proxima_semibold color_white font25">
            Book International And Domestic Flights
          </p>
        </div>

        {(type === "flights" || type === undefined) && <FlightPageSearchBar />}
        {type === "hotels" && <HotelPageSarchBar />}
        {type === "packages" && <PackagePageSarchBar />}

        {/* <OffersSection header={1} />

        <OffersSection header={2} />

        <PopularDestinations /> */}

        {/* <Footer /> */}
      </div>
    </div>




  );
}
