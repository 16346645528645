import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const useOffline = () => {

    let isOffline1 = useSelector((state) => state.user.isOffline);
    let isOffline2 = useSelector((state) => state.HomePageSearchBarFlight.isOffline);
    let isOffline3 = useSelector((state) => state.ResultPageFlightsFlight.isOffline);

    return isOffline1 || isOffline2 || isOffline3;
 
};

export default useOffline;
