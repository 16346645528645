import React, { useEffect, useRef, useState } from "react";
import "./From_To_Popup.css";
import images from "../../../Utils/importImages";
import AirportContent from "./From_To_Component/AirportContent";
import { DefaultPopularCities } from "../../../Constants/ConstantsFlights";
import { debouncedSearch } from "../../../Redux/Apis/Flight-Apis";
import { useDebounce } from "@uidotdev/usehooks";

const From_To_Popup = ({
  open,
  onClose,
  position,
  onClickClose,
  popular_cities,
  recent_searches,
  segmentsData,
  PopupType,
  left,
  top,
  placeholder,
  positionType,
  Theme
}) => {
  const popupRef = useRef(null);

  console.log("thmee here is  :  " , Theme);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchStarted, setSearchStarted] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [SearchedAirports, setSearchedAirports] = useState([]);

  const handleInputChange = (event) => {
    const { value } = event.target;
    setSearchInput(value);
    setSearchLoading(true);
    setSearchStarted(true);
  };

  const debouncedSearchTerm = useDebounce(searchInput, 500);

  useEffect(() => {
      debouncedSearch(searchInput, setSearchLoading, setSearchedAirports);
   
  }, [debouncedSearchTerm]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open, onClose]);

  console.log("the theme is : " , Theme);

  let [mainTheme , setMainTheme] = useState("");

  useEffect(() => {
    setMainTheme(Theme)
  } , [Theme])
  if (!open) {
    return null;
  }

  return (
    <div className="custom-popup fromToPopup" ref={popupRef}>
      <div
        style={{
          width: "350.5px",
          minHeight: "384px",
          position: positionType,
          zIndex: 10000,
          top: top,
          left: left,
          backgroundColor: "#fff",
          border: "1.2px solid #e6e6e6",
          borderRadius: "1px",
        }}
      >
        <div className="searchWrapper">
          <div className="searchwrapper-item">
            <img className="search" src={images["search-icon.svg"]} alt="Search" />
        
            <input
              onChange={handleInputChange}
              value={searchInput}
              autoFocus={true}
              className={`search-input place-1 ${mainTheme}_regular color_black font14`}
              type="text"
              placeholder={placeholder}
              style={{ border: "none"}}
            />
          </div>
        </div>
        <div className="thin-line"></div>

        {searchStarted ? (
          <div
            className="mainPopup"
          >
            <div style={{width : "338px"}}>
            {SearchedAirports?.length > 0 && (
             
                SearchedAirports.map((item, index) => (
                  <AirportContent
                  Theme={Theme}
                    segmentsData={segmentsData}
                    PopupType={PopupType}
                    item={item}
                    onClickClose={onClickClose}
                    key={index}
                  />
                ))
           
            )}



            {SearchedAirports?.length === 0 && (
              <>
                {!searchLoading && (
                  <>
                    <div className={`recent-searches-2 ${mainTheme}_bold font14 color_graish-white`} >
                      NO AIRPORT FOUND
                    </div>
                    <div class="thin-line"></div>
                  </>
                )}
                {(popular_cities?.length > 0 || DefaultPopularCities?.length > 0) && (
                  <div className={`recent-searches-2 ${mainTheme}_bold font14 color_graish-white`} >
                    POPULAR CITIES
                  </div>
                )}
             
                  {(popular_cities?.length > 0 ? popular_cities : DefaultPopularCities).map(
                    (item, index) => (
                      <AirportContent
                      Theme={Theme}
                        segmentsData={segmentsData}
                        PopupType={PopupType}
                        item={item}
                        onClickClose={onClickClose}
                        key={index}
                      />
                    )
                  )}
             
              </>
            )}

              </div>
          </div>
        ) : (
          <div
            className="mainPopup"
          >
            {recent_searches?.length > 0 && (
              <>
                <div className={`recent-searches-2 ${mainTheme}_bold font14 color_graish-white`}>RECENT SEARCHES</div>
              
                  {recent_searches.slice(0, 5).map((item, index) => (
                    <AirportContent
                    Theme={Theme}
                      segmentsData={segmentsData}
                      PopupType={PopupType}
                      item={item}
                      onClickClose={onClickClose}
                      key={index}
                    />
                  ))}
            
              </>
            )}

            {(popular_cities?.length > 0 || DefaultPopularCities?.length > 0) && (
              <div className={`recent-searches-2 ${mainTheme}_bold font14 color_graish-white`} >
                POPULAR CITIES
              </div>
            )}

        
              {(popular_cities?.length > 0 ? popular_cities : DefaultPopularCities).map(
                (item, index) => (
                  <AirportContent
                  Theme={Theme}
                    segmentsData={segmentsData}
                    PopupType={PopupType}
                    item={item}
                    onClickClose={onClickClose}
                    key={index}
                  />
                )
              )}
           
          </div>
        )}
      </div>
    </div>
  );
};

export default From_To_Popup;




