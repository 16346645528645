import React, { useState } from 'react'
import images from "../../../../Utils/importImages";
import { fareOptions } from '../../../../Constants/ConstantsFlights';
import { useDispatch } from 'react-redux';
import { searchTheFlightUrlCreator, setTheFareType } from "../../../../Redux/Slices/Flight_Slices/HomePage-Flights-SLICE"
import { useNavigate } from 'react-router-dom';

function SpecialFareOptions({ selectedFareOption, style }) {

  let dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSearch = () => {
    dispatch(searchTheFlightUrlCreator({ navigate }));
  };

  return (
    <div className="frame-37" style={style}>
      <div className="frame-43">
        <div className="fare-type proxima_regular color_light-gray-black font15">
          Fare Type
        </div>
        <div className="frame-205">
          <div className="frame-47">
            <div className="frame-205-1 frame-205-44">
              {
                fareOptions.map((item, index) => {
                  return (
                    <div className="frame-42" key={index}
                      onClick={() => dispatch(setTheFareType(item))}
                    >
                      <div className="frame-103-10">
                        <div className={`special-fares ${selectedFareOption === item ? "proxima_regular color_endeavour font14" : "proxima_regular color_eerie-black font14"}`}>
                          {item}
                        </div>
                        <div className={`fares ${selectedFareOption === item ? "proxima_regular color_endeavour font12" : "proxima_regular color_eerie-black font12"}`}>
                          Fares
                        </div>
                      </div>
                      {selectedFareOption === item && <img className="check" src={images['check-4.svg']} alt="check" />}
                    </div>

                  )
                })
              }

            </div>
          </div>
        </div>
      </div>
      <div className="frame-44" onClick={handleSearch}>
        <div className="search">SEARCH</div>
      </div>
    </div>
  )
}

export default SpecialFareOptions
