import React, { useState } from "react";
import images from "../../../../../../../Utils/importImages";
import "./CalendarBar.css";
import { generateDates } from "../../../../../../../Utils/Date_Time_Calendar_Functions";

function CalenderBar({ selectedDate, Prices, onChange }) {
  // console.log("selectedIndex is: " , selectedIndex);
  // console.log("dates is  : " , dates);
  // console.log("selectedDate is : " , selectedDate);

  // console.log("Prices is : " , Prices);
  const dates = generateDates(360);
  const selectedIndex = dates?.findIndex((item) => item[1] === selectedDate);

  const [startIndex, setStartIndex] = useState(selectedIndex);
  const [EndIndex, setEndIndex] = useState(0);

  const endIndex = startIndex + 8;

  const handleNext = () => {
    if (endIndex < dates.length) {
      setStartIndex(startIndex + 8);
    }
  };

  const handlePrevious = () => {
    if (startIndex - 8 < 0) {
      setStartIndex(0);
    } else if (startIndex > 0) {
      setStartIndex(startIndex - 8);
    }
  };

  return (
    <div className="calendar-Bar frame-169-20">
      <img
        className="frame-110"
        src={images["frame-110.svg"]}
        alt="Frame 110"
        onClick={endIndex >= dates.length ? null : handleNext}
        style={{ cursor: endIndex >= dates.length ? "not-allowed" : "pointer" , opacity : endIndex >= dates.length ? 0.5: 1}}
      />
      <img
        className="frame-111"
        src={images["frame-111.svg"]}
        alt="Frame 111"
        onClick={startIndex === 0 ? null : handlePrevious}
        style={{ cursor: startIndex === 0 ? "not-allowed" : "pointer" , opacity : startIndex === 0 ? 0.5: 1}}
      />

      <div className="frame-103-4">
        {dates?.slice(startIndex, endIndex).map((item, index) => {
          return selectedDate != item[1] ? (
            <div className="frame-1-4" onClick={() => onChange(item[1])}>
            {/* <div className="frame-1-4" > */}
              <div className="tue-30-jan proxima_regular color_eerie-black font16">
                {item[0]}
              </div>
              <div className="frame-4">
                <img
                  className="vector-2"
                  src={images["vector-16.svg"]}
                  alt="Vector"
                />
                <div className="number-20 number-29 proxima_regular color_storm-dust font14">
                  {Prices[item[1]] === undefined ? "---" : Prices[item[1]]}
                </div>
              </div>
            </div>
          ) : (
            <div className="frame-1-4 bg-blue-grow" onClick={() => onChange(item[1])}>
              <div className="tue-30-jan-3 tue-30-jan-9 proxima_regular color_endeavour font18">
                {" "}
                {item[0]}
              </div>

              <div className="frame-4">
                <img
                  className="vector-2"
                  src={images["vector-19.svg"]}
                  alt="Vector"
                />
                <div className="number-20 number-29 proxima_regular color_endeavour font16">
                  {" "}
                  {Prices[item[1]] === undefined ? "---" : Prices[item[1]]}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default CalenderBar;

{
  /* <div className="frame-1-4">
<div className="tue-30-jan proxima_regular color_eerie-black font16">Tue, 30 Jan</div>
<div className="frame-4">
  <img className="vector-2" src={images["vector-16.svg"]} alt="Vector" />
  <div className="number-20 number-29 proxima_regular color_storm-dust font14">0000</div>
</div>
</div>

<div className="frame-1-4 bg-blue-grow">
<div className="tue-30-jan-3 tue-30-jan-9 proxima_regular color_endeavour font18">Tue, 30 Jan</div>

<div className="frame-4">
<img className="vector-2" src={images["vector-19.svg"]} alt="Vector" />
<div className="number-20 number-29 proxima_regular color_endeavour font16">0000</div>
</div>

</div> */
}
