import React, { useCallback, useEffect, useMemo, useState } from 'react'
import ResultBar6 from '../../../Components/ResultBars/ResultBar6'
import FilterBar from '../../../Components/FilterBar'
import "./index.css"
import { useDispatch, useSelector } from 'react-redux';
import { setFiltersApplied, setInitialSettings } from '../../../../../../../../Redux/Slices/Flight_Slices/ResultPage-Flights-SLICE';
import { useDebounce } from '@uidotdev/usehooks';
import MainPopup from '../../../../MainPopup/MainPopup';
export default function MultiInternational_4Row_Combined({Theme}) {

   
  const dispatch = useDispatch();

  const searchBarData = useSelector((state) => state.ResultPageFlightsFlight);

  useMemo(() => {
    dispatch(setInitialSettings());
  }, []);

  const [FlightAffordance, setFlightAffordance] = useState(0);
  const [ViewBy, setViewBy] = useState(0);
  const [searchText, setSearchText] = useState("");
  const debouncedSearchTerm = useDebounce(searchText, 1000);


  const handleFilterChange = (index, key, value, mode, specialIndex) => {
    dispatch(
      setFiltersApplied({
        index: index,
        key: key,
        value: value,
        mode: mode,
        specialIndex: specialIndex,
      })
    );
  };

  const changeFlightAffordance = useCallback((mode) => {
    setFlightAffordance(mode);

    handleFilterChange(
      searchBarData?.barModeSelected,
      `AFFORD${searchBarData?.barModeSelected}`,
      mode === 0 ? "Price" : "Time",
      "SortBy"
    );

  }, []);

  const changeViewBy = useCallback((mode) => {
    setViewBy(mode);
  }, []);

  const changesearchText = useCallback((value) => {
    setSearchText(value);
  }, []);

  useEffect(() => {
    if (searchText === "") {
      handleFilterChange(
        searchBarData?.barModeSelected,
        `QUERY${searchBarData?.barModeSelected}`,
        searchText,
        "SearchBy",
        1
      );
    } else {
      handleFilterChange(
        searchBarData?.barModeSelected,
        `QUERY${searchBarData?.barModeSelected}`,
        searchText,
        "SearchBy"
      );
    }
  }, [debouncedSearchTerm]);

  const airlineLogos = useMemo(
    () => searchBarData?.SearchResults?.AirlinesLogos,
    [searchBarData]
  );

  const filteredData = useMemo(
    () => searchBarData?.FilteredData?.Combined?.slice(0, 500),
    [searchBarData]
  );

  const FiltersApplied = useMemo(
    () => searchBarData?.FiltersApplied[searchBarData?.barModeSelected] || [],
    [searchBarData?.FiltersApplied, searchBarData?.barModeSelected]
  );

  let [TopBarMode , setTopBarMode] = useState(2.5)

  let [OpenPopup , setOpenPopup] = useState(false);

  let CloseThePopup = () => {
    setOpenPopup(false)
  }

  let OpenThePopup = () => {
    setOpenPopup(true)
  }

  return (
<>

<div className="frame-257">

<FilterBar
Theme={Theme}
          FlightAffordance={FlightAffordance}
          ViewBy={ViewBy}
          searchText={searchText}
          onChangeFlightAffordance={changeFlightAffordance}
          onChangeViewBy={changeViewBy}
          onChangesearchText={changesearchText}
        />

</div>

{filteredData?.length === 0 && FiltersApplied.length > 0 && (
        <div>To Many Filters Appplied ,, No Result Found</div>
      )}


{filteredData.slice(0, 500).map((item, index) => {
        return (
          <ResultBar6
          Theme={Theme}
            showTripBar={true}
            key={index}
            data={item}
            airlineLogos={airlineLogos}
            ViewBy={ViewBy}
            OpenPopup={OpenPopup}
            OpenThePopup={OpenThePopup}
            setTopBarMode={setTopBarMode}
          />
        );
      })}

<MainPopup
Theme={Theme}
Open={OpenPopup} 
onClose={CloseThePopup} 
topBarMode={TopBarMode} 
topBarSelected={0}
isRound={false}

/>
</>

  )
}
