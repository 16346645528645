import moment from "moment";
import { formatTime, getTotalMinutes } from "./Date_Time_Calendar_Functions";

export function convertToPrices(data) {
  if (data?.length === 0) {
    return [];
  }

  const transformedData = data.reduce((acc, item) => {
    const date = new Date(item.date);
    const formattedDate = `${date.getDate().toString().padStart(2, "0")}-${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${date.getFullYear()}`;
    acc[formattedDate] = parseFloat(item.price);
    return acc;
  }, {});

  return transformedData;
}

export function getAllAirlinesWithindex1(data, ResultType, logos, index) {
  const dataRoModify = data;

  let airlinesWithData = {};

  if (ResultType === 1) {
    dataRoModify?.Segments?.forEach((element) => {
      airlinesWithData[element.Airline.AirlineCode] = {
        name: element.Airline.AirlineName,
        code: element.Airline.AirlineCode,
        logo: logos[element.Airline.AirlineCode],
        Number: element.Airline.FlightNumber,
      };
    });
  } else if (ResultType === 2) {
    dataRoModify?.Connections[0]?.Segments?.forEach((element) => {
      airlinesWithData[element.Airline.AirlineCode] = {
        name: element.Airline.AirlineName,
        code: element.Airline.AirlineCode,
        logo: logos[element.Airline.AirlineCode],
        Number: element.Airline.FlightNumber,
      };
    });

    dataRoModify?.Connections[1]?.Segments?.forEach((element) => {
      airlinesWithData[element.Airline.AirlineCode] = {
        name: element.Airline.AirlineName,
        code: element.Airline.AirlineCode,
        logo: logos[element.Airline.AirlineCode],
        Number: element.Airline.FlightNumber,
      };
    });
  } else if (ResultType === 3) {
    (index < 0
      ? dataRoModify?.Segments
      : dataRoModify?.Segments[index]
    )?.forEach((element) => {
      airlinesWithData[element.Airline.AirlineCode] = {
        name: element.Airline.AirlineName,
        code: element.Airline.AirlineCode,
        logo: logos[element.Airline.AirlineCode],
        Number: element.Airline.FlightNumber,
      };
    });
  }

  return Object.values(airlinesWithData);
}


export const getTags = (data, ResultType, index) => {
  let Tags = new Set();

  if (ResultType === 1) {
    data.Segments.forEach((element) => {
      Object.keys(element.ItenaryDetails.Tags).forEach((key) => {
        if (element.ItenaryDetails.Tags[key].Required === true) {
          Tags.add(key);
        }
      });
    });
  } else if (ResultType === 2) {
    data.Connections.forEach((connection) => {
      connection.Segments.forEach((segment) => {
        Object.keys(segment.ItenaryDetails.Tags).forEach((key) => {
          if (segment.ItenaryDetails.Tags[key].Required === true) {
            Tags.add(key);
          }
        });
      });
    });
  } else if (ResultType === 3) {
    index < 0
      ? data.Connections.forEach((element) => {
          element.Segments.forEach((element) => {
            Object.keys(element.ItenaryDetails.Tags).forEach((key) => {
              if (element.ItenaryDetails.Tags[key].Required === true) {
                Tags.add(key);
              }
            });
          });
        })
      : data.Segments.forEach((element) => {
          element.forEach((element) => {
            Object.keys(element.ItenaryDetails.Tags).forEach((key) => {
              if (element.ItenaryDetails.Tags[key].Required === true) {
                Tags.add(key);
              }
            });
          });
        });
  }

  const tagsArray = Array.from(Tags);
  const hasTags = tagsArray.length > 0;
  return [hasTags, tagsArray];
};

export const compareFlightTimes = (departureTime, arrivalTime , mode) => {

  console.log("departureTime, arrivalTime , mode : " , departureTime, arrivalTime , mode);
  const depTime1 = new Date(departureTime);
  const arrTime2 = new Date(arrivalTime);
  return depTime1 > arrTime2;
};

export const generateListToRender = (data, ResultType, indexx) => {
  if (data === undefined && ResultType === undefined) {
    return;
  }
  let listToRender = [];
  if (ResultType === 1) {
    listToRender = data.Segments.map((item, index) => {
      if (index < data.Segments.length - 1) {
        return {
          name: item.Destination.Airport.CityName,
          time: formatTime(
            getTotalMinutes(
              item.Destination.ArrTime,
              data.Segments[index + 1].Origin.DepTime
            )
          ),
        };
      }
      return null;
    }).filter((item) => item !== null);
  } else if (ResultType === 2) {
    if (data.Connections.length === 2) {
      let listToRender1 = data.Connections[0].Segments.map((item, index) => {
        
        console.log("before error the item is  : " , item);
        
        return {
          name: item.Destination.Airport.CityName,
          time: formatTime(
            getTotalMinutes(
              item.Destination.ArrTime,
              index < data.Connections[1].Segments.length - 1
                ? data.Connections[0].Segments[index + 1].Origin.DepTime
                : data.Connections[1].Segments[0].Origin.DepTime
            )
          ),
        };
   
      }).filter((item) => item !== null);

      let listToRender2 = data.Connections[1].Segments.map((item, index) => {
        if (index < data.Connections[1].Segments.length - 1) {
          return {
            name: item.Destination.Airport.CityName,
            time: formatTime(
              getTotalMinutes(
                item.Destination.ArrTime,
                data.Connections[1].Segments[index + 1].Origin.DepTime
              )
            ),
          };
        }
        return null;
      }).filter((item) => item !== null);

      listToRender = [...listToRender1, ...listToRender2];
    }
  } else if (ResultType === 3) {
    listToRender =
      indexx < 0
        ? data.Segments.map((item, index) => {
            if (index < data.Segments.length - 1) {
              return {
                name: item.Destination.Airport.CityName,
                time: formatTime(
                  getTotalMinutes(
                    item.Destination.ArrTime,
                    data.Segments[index + 1].Origin.DepTime
                  )
                ),
              };
            }
            return null;
          }).filter((item) => item !== null)
        : data.Segments[indexx]
            .map((item, index) => {
              if (index < data.Segments[indexx].length - 1) {
                return {
                  name: item.Destination.Airport.CityName,
                  time: formatTime(
                    getTotalMinutes(
                      item.Destination.ArrTime,
                      data.Segments[indexx][index + 1].Origin.DepTime
                    )
                  ),
                };
              }
              return null;
            })
            .filter((item) => item !== null);
  }
  return listToRender;
};

export async function gerateFiltersParams(
  AllFilters,
  Fulldata,
  Logos,
  indexToPass,
  Journey
) {
  // Generate Popular Filters

  let currentMinprice1 = Infinity;
  let currentMinprice2 = Infinity;
  let currentMinprice3 = Infinity;

  let refundableMinPrice = Infinity;

  let minPrice = Infinity;
  let maxPrice = -Infinity;

  let allAirlines = [];

  let MinimumMinutes = Infinity;
  let MaximumMinutes = -Infinity;

  let MinimumMinutesLayOver = Infinity;
  let MaximumMinutesLayOver = -Infinity;

  let layOverAirports = [];
  let ArrivalAirports = [];

  Fulldata.forEach((element) => {
    let durationParasInside = [];

    if (
      element.DataFormat === "DOM-OW" ||
      element.DataFormat === "DOM-OW-NR" ||
      element.DataFormat === "INT-OW" ||
      element.DataFormat === "INT-OW-NR" ||
      element.DataFormat === "INT-OW-VIA" ||
      element.DataFormat === "DOM-RD" ||
      element.DataFormat === "DOM-RD-NR" ||
      element.DataFormat === "INT-RD-OWOW" ||
      element.DataFormat === "INT-RD-OWOW-NR" ||
      element.DataFormat === "DOM-ML-2" ||
      element.DataFormat === "DOM-ML-NR-2" ||
      element.DataFormat === "DOM-ML-3" ||
      element.DataFormat === "DOM-ML-NR-3" ||
      element.DataFormat === "DOM-ML-4" ||
      element.DataFormat === "DOM-ML-NR-4" ||
      element.DataFormat === "INT-ML-OWOW-2" ||
      element.DataFormat === "INT-ML-OWOW-3" ||
      element.DataFormat === "INT-ML-OWOW-4" ||
      element.DataFormat === "HYB-ML-OWOW-2" ||
      element.DataFormat === "HYB-ML-OWOW-3" ||
      element.DataFormat === "HYB-ML-OWOW-4"
    ) {
      element.Segments.forEach((airlineEleemnt) => {
        if (allAirlines[airlineEleemnt.Airline.AirlineName] === undefined) {
          allAirlines[airlineEleemnt.Airline.AirlineName] = {
            count: 1,
            Price: element.Fare.MegaTotal,
            code: airlineEleemnt.Airline.AirlineCode,
          };
        } else {
          let price =
            element.Fare.MegaTotal <
            allAirlines[airlineEleemnt.Airline.AirlineName].Price
              ? element.Fare.MegaTotal
              : allAirlines[airlineEleemnt.Airline.AirlineName].Price;
          allAirlines[airlineEleemnt.Airline.AirlineName] = {
            count: allAirlines[airlineEleemnt.Airline.AirlineName].count + 1,
            Price: price,
            code: airlineEleemnt.Airline.AirlineCode,
          };
        }

        durationParasInside.push([
          airlineEleemnt.Origin.DepTime,
          airlineEleemnt.Destination.ArrTime,
        ]);
      });

      if (element.Segments.length === 2) {
        layOverAirports.push({
          Code: element.Segments[0].Destination.Airport.AirportCode,
          Name: element.Segments[0].Destination.Airport.AirportName,
          CityName: element.Segments[0].Destination.Airport.CityName,
          Price: element.Fare.MegaTotal,
        });
      } else if (element.Segments.length === 3) {
        layOverAirports.push({
          Code: element.Segments[0].Destination.Airport.AirportCode,
          Name: element.Segments[0].Destination.Airport.AirportName,
          CityName: element.Segments[0].Destination.Airport.CityName,
          Price: element.Fare.MegaTotal,
        });
        layOverAirports.push({
          Code: element.Segments[1].Destination.Airport.AirportCode,
          Name: element.Segments[1].Destination.Airport.AirportName,
          CityName: element.Segments[1].Destination.Airport.CityName,
          Price: element.Fare.MegaTotal,
        });
      }

      if (element.IsRefundable === true) {
        refundableMinPrice =
          element.Fare.MegaTotal < refundableMinPrice
            ? element.Fare.MegaTotal
            : refundableMinPrice;
        AllFilters[indexToPass].PopularFilters["Refundable"] = {
          code: "RF",
          name: "Refundable Flights",
          price: refundableMinPrice,
          hasLogo: false,
        };
      }

      if (element.Segments.length === 1) {
        currentMinprice1 =
          element.Fare.MegaTotal < currentMinprice1
            ? element.Fare.MegaTotal
            : currentMinprice1;
        AllFilters[indexToPass].PopularFilters["Non_Stop"] = {
          code: "NSF",
          name: "Non Stop Flights",
          price: currentMinprice1,
          hasLogo: false,
        };
      } else if (element.Segments.length === 2) {
        currentMinprice2 =
          element.Fare.MegaTotal < currentMinprice2
            ? element.Fare.MegaTotal
            : currentMinprice2;
        AllFilters[indexToPass].PopularFilters["One_Stop"] = {
          code: "OSF",
          name: "1 Stop Flights",
          price: currentMinprice2,
          hasLogo: false,
        };
      } else {
        currentMinprice3 =
          element.Fare.MegaTotal < currentMinprice3
            ? element.Fare.MegaTotal
            : currentMinprice3;
        AllFilters[indexToPass].PopularFilters["One_Plus_Stop"] = {
          code: "OPSF",
          name: "1+ Stop Flights",
          price: currentMinprice3,
          hasLogo: false,
        };
      }

      if (element.Fare.MegaTotal < minPrice) {
        minPrice = element.Fare.MegaTotal;
      }
      if (element.Fare.MegaTotal > maxPrice) {
        maxPrice = element.Fare.MegaTotal;
      }

      if (
        element.Segments[element.Segments.length - 1].Destination.Airport
          .AirportCode !== Journey[1]
      ) {
        ArrivalAirports.push({
          Code: element.Segments[element.Segments.length - 1].Destination
            .Airport.AirportCode,
          Name: element.Segments[element.Segments.length - 1].Destination
            .Airport.AirportName,
          CityName:
            element.Segments[element.Segments.length - 1].Destination.Airport
              .CityName,
          Price: element.Fare.MegaTotal,
        });
      }
    } else {
      element.Connections[0].Segments.forEach((airlineEleemnt) => {
        if (allAirlines[airlineEleemnt.Airline.AirlineName] === undefined) {
          allAirlines[airlineEleemnt.Airline.AirlineName] = {
            count: 1,
            Price: element.Price,
            code: airlineEleemnt.Airline.AirlineCode,
          };
        } else {
          let price =
            element.Price <
            allAirlines[airlineEleemnt.Airline.AirlineName].Price
              ? element.Price
              : allAirlines[airlineEleemnt.Airline.AirlineName].Price;
          allAirlines[airlineEleemnt.Airline.AirlineName] = {
            count: allAirlines[airlineEleemnt.Airline.AirlineName].count + 1,
            Price: price,
            code: airlineEleemnt.Airline.AirlineCode,
          };
        }

        durationParasInside.push([
          airlineEleemnt.Origin.DepTime,
          airlineEleemnt.Destination.ArrTime,
        ]);
      });

      element.Connections[1].Segments.forEach((airlineEleemnt) => {
        if (allAirlines[airlineEleemnt.Airline.AirlineName] === undefined) {
          allAirlines[airlineEleemnt.Airline.AirlineName] = {
            count: 1,
            Price: element.Price,
            code: airlineEleemnt.Airline.AirlineCode,
          };
        } else {
          let price =
            element.Price <
            allAirlines[airlineEleemnt.Airline.AirlineName].Price
              ? element.Price
              : allAirlines[airlineEleemnt.Airline.AirlineName].Price;
          allAirlines[airlineEleemnt.Airline.AirlineName] = {
            count: allAirlines[airlineEleemnt.Airline.AirlineName].count + 1,
            Price: price,
            code: airlineEleemnt.Airline.AirlineCode,
          };
        }

        durationParasInside.push([
          airlineEleemnt.Origin.DepTime,
          airlineEleemnt.Destination.ArrTime,
        ]);
      });

      if (
        element.Connections[1].Segments[
          element.Connections[1].Segments.length - 1
        ].Destination.Airport.AirportCode !== Journey[1]
      ) {
        ArrivalAirports.push({
          Code: element.Connections[1].Segments[
            element.Connections[1].Segments.length - 1
          ].Destination.Airport.AirportCode,
          Name: element.Connections[1].Segments[
            element.Connections[1].Segments.length - 1
          ].Destination.Airport.AirportName,
          CityName:
            element.Connections[1].Segments[
              element.Connections[1].Segments.length - 1
            ].Destination.Airport.CityName,
          Price: element.Price,
        });
      }

      if (element.Connections[0].Segments.length === 2) {
        layOverAirports.push({
          Code: element.Connections[0].Segments[0].Destination.Airport
            .AirportCode,
          Name: element.Connections[0].Segments[0].Destination.Airport
            .AirportName,
          CityName:
            element.Connections[0].Segments[0].Destination.Airport.CityName,
          Price: element.Price,
        });
      } else if (element.Connections[0].Segments.length === 3) {
        layOverAirports.push({
          Code: element.Connections[0].Segments[0].Destination.Airport
            .AirportCode,
          Name: element.Connections[0].Segments[0].Destination.Airport
            .AirportName,
          CityName:
            element.Connections[0].Segments[0].Destination.Airport.CityName,
          Price: element.Price,
        });
        layOverAirports.push({
          Code: element.Connections[0].Segments[1].Destination.Airport
            .AirportCode,
          Name: element.Connections[0].Segments[1].Destination.Airport
            .AirportName,
          CityName:
            element.Connections[0].Segments[1].Destination.Airport.CityName,
          Price: element.Price,
        });
      }

      if (element.Connections[1].Segments.length === 2) {
        layOverAirports.push({
          Code: element.Connections[1].Segments[0].Destination.Airport
            .AirportCode,
          Name: element.Connections[1].Segments[0].Destination.Airport
            .AirportName,
          CityName:
            element.Connections[1].Segments[0].Destination.Airport.CityName,
          Price: element.Price,
        });
      } else if (element.Connections[1].Segments.length === 3) {
        layOverAirports.push({
          Code: element.Connections[1].Segments[0].Destination.Airport
            .AirportCode,
          Name: element.Connections[1].Segments[0].Destination.Airport
            .AirportName,
          CityName:
            element.Connections[1].Segments[0].Destination.Airport.CityName,
          Price: element.Price,
        });
        layOverAirports.push({
          Code: element.Connections[1].Segments[1].Destination.Airport
            .AirportCode,
          Name: element.Connections[1].Segments[1].Destination.Airport
            .AirportName,
          CityName:
            element.Connections[1].Segments[1].Destination.Airport.CityName,
          Price: element.Price,
        });
      }

      if (
        element.Connections[0].IsRefundable === true &&
        element.Connections[1].IsRefundable === true
      ) {
        refundableMinPrice =
          element.Price < refundableMinPrice
            ? element.Price
            : refundableMinPrice;
        AllFilters[indexToPass].PopularFilters["Refundable"] = {
          code: "RF",
          name: "Refundable Flights",
          price: refundableMinPrice,
          hasLogo: false,
        };
      }
      if (
        element.Connections[0].Segments.length +
          element.Connections[1].Segments.length ===
        2
      ) {
        currentMinprice2 =
          element.Price < currentMinprice2 ? element.Price : currentMinprice2;
        AllFilters[indexToPass].PopularFilters["One_Stop"] = {
          code: "OSF",
          name: "1 Stop Flights",
          price: currentMinprice2,
          hasLogo: false,
        };
      } else {
        currentMinprice3 =
          element.Price < currentMinprice3 ? element.Price : currentMinprice3;
        AllFilters[indexToPass].PopularFilters["One_Plus_Stop"] = {
          code: "OPSF",
          name: "1+ Stop Flights",
          price: currentMinprice3,
          hasLogo: false,
        };
      }

      if (element.Price < minPrice) {
        minPrice = element.Price;
      }
      if (element.Price > maxPrice) {
        maxPrice = element.Price;
      }
    }

    if (
      element.DataFormat === "DOM-OW" ||
      element.DataFormat === "DOM-OW-NR" ||
      element.DataFormat === "INT-OW" ||
      element.DataFormat === "INT-OW-NR" ||
      element.DataFormat === "INT-OW-VIA" ||
      element.DataFormat === "DOM-RD" ||
      element.DataFormat === "DOM-RD-NR" ||
      element.DataFormat === "INT-RD-OWOW" ||
      element.DataFormat === "INT-RD-OWOW-NR" ||
      element.DataFormat === "DOM-ML-2" ||
      element.DataFormat === "DOM-ML-NR-2" ||
      element.DataFormat === "DOM-ML-3" ||
      element.DataFormat === "DOM-ML-NR-3" ||
      element.DataFormat === "DOM-ML-4" ||
      element.DataFormat === "DOM-ML-NR-4" ||
      element.DataFormat === "INT-ML-OWOW-2" ||
      element.DataFormat === "INT-ML-OWOW-3" ||
      element.DataFormat === "INT-ML-OWOW-4" ||
      element.DataFormat === "HYB-ML-OWOW-2" ||
      element.DataFormat === "HYB-ML-OWOW-3" ||
      element.DataFormat === "HYB-ML-OWOW-4"
    ) {
      if (element.Segments.length === 2) {
        let minutes = getTotalMinutes(
          durationParasInside[0][1],
          durationParasInside[1][0]
        );

        // get Min And Max_Duration
        if (minutes < MinimumMinutesLayOver) {
          MinimumMinutesLayOver = minutes;
        }
        if (minutes > MaximumMinutesLayOver) {
          MaximumMinutesLayOver = minutes;
        }
      } else if (element.Segments.length === 3) {
        let minutes1 = getTotalMinutes(
          durationParasInside[0][1],
          durationParasInside[1][0]
        );
        let minutes2 = getTotalMinutes(
          durationParasInside[1][1],
          durationParasInside[2][0]
        );

        let minutes = minutes1 + minutes2;

        if (minutes < MinimumMinutesLayOver) {
          MinimumMinutesLayOver = minutes;
        }
        if (minutes > MaximumMinutesLayOver) {
          MaximumMinutesLayOver = minutes;
        }
      }
    } else {
      let minutes = 0;

      let endDurations = [];

      if (element.Connections[0].Segments.length === 1) {
        endDurations.push(
          element.Connections[0].Segments[0].Destination.ArrTime
        );
      } else if (element.Connections[0].Segments.length === 2) {
        minutes += getTotalMinutes(
          durationParasInside[0][1],
          durationParasInside[1][0]
        );
        endDurations.push(durationParasInside[1][0]);
      } else if (element.Connections[0].Segments.length === 3) {
        minutes += getTotalMinutes(
          durationParasInside[0][1],
          durationParasInside[1][0]
        );
        minutes += getTotalMinutes(
          durationParasInside[1][1],
          durationParasInside[2][0]
        );

        endDurations.push(durationParasInside[2][1]);
      }

      if (element.Connections[1].Segments.length === 1) {
        endDurations.push(
          element.Connections[1].Segments[0].Destination.ArrTime
        );
      } else if (element.Connections[1].Segments.length === 2) {
        minutes += getTotalMinutes(
          durationParasInside[element.Connections[0].Segments.length][1],
          durationParasInside[element.Connections[0].Segments.length + 1][0]
        );

        endDurations.push(
          durationParasInside[element.Connections[0].Segments.length + 1][1]
        );

        // get Min And Max_Duration
      } else if (element.Connections[1].Segments.length === 3) {
        minutes += getTotalMinutes(
          durationParasInside[element.Connections[0].Segments.length][1],
          durationParasInside[element.Connections[0].Segments.length + 1][0]
        );
        minutes += getTotalMinutes(
          durationParasInside[element.Connections[0].Segments.length + 1][1],
          durationParasInside[element.Connections[0].Segments.length + 2][0]
        );

        endDurations.push(
          durationParasInside[element.Connections[0].Segments.length + 2][1]
        );
      }

      minutes += getTotalMinutes(endDurations[0], endDurations[1]);

      if (minutes < MinimumMinutesLayOver) {
        MinimumMinutesLayOver = minutes;
      }
      if (minutes > MaximumMinutesLayOver) {
        MaximumMinutesLayOver = minutes;
      }
    }

    let minutes = getTotalMinutes(
      durationParasInside[0][0],
      durationParasInside[durationParasInside.length - 1][1]
    );

    // get Min And Max_Duration
    if (minutes < MinimumMinutes) {
      MinimumMinutes = minutes;
    }
    if (minutes > MaximumMinutes) {
      MaximumMinutes = minutes;
    }
  });

  let airlineEntries = Object.entries(allAirlines);
  airlineEntries.sort((a, b) => b[1].count - a[1].count); // Sort by count in descending order

  for (let i = 0; i < airlineEntries.length; i++) {
    let airlineName = airlineEntries[i][0];
    let airlineData = airlineEntries[i][1];

    if (
      airlineName !=="" &&
      Logos[airlineData.code] !==undefined &&
      airlineData.code !==""
    ) {
      if (i < 3) {
        AllFilters[indexToPass].PopularFilters[airlineName] = {
          name: airlineName,
          price: airlineData.Price,
          code: airlineData.code,
          logo: Logos[airlineData.code],
          hasLogo: true,
        };
      }

      AllFilters[indexToPass].Airlines[airlineName] = {
        name: airlineName,
        price: airlineData.Price,
        code: airlineData.code,
        logo: Logos[airlineData.code],
      };
    }
  }

  // Price Dragger
  AllFilters[indexToPass].PriceDragger = {
    minPrice: minPrice,
    maxPrice: maxPrice,
  };

  // Flight duration dragger
  AllFilters[indexToPass].DurationDragger = {
    MinimumMinutes: MinimumMinutes,
    MaximumMinutes: MaximumMinutes,
  };

  // Flight layover duration dragger
  AllFilters[indexToPass].LayoverDurationDragger = {
    MinimumMinutes: MinimumMinutesLayOver,
    MaximumMinutes: MaximumMinutesLayOver,
  };

  AllFilters[indexToPass].PopularFilters = Object.values(
    AllFilters[indexToPass].PopularFilters
  );

  AllFilters[indexToPass].Airlines = Object.values(
    AllFilters[indexToPass].Airlines
  );

  // add non dublicates from the layOverAirports ....

  let uniqueAirports = [];
  let airportPrices = {};

  layOverAirports.forEach((airport) => {
    if (
      !airportPrices[airport.Code] ||
      airportPrices[airport.Code] > airport.Price
    ) {
      airportPrices[airport.Code] = airport.Price;
      uniqueAirports = uniqueAirports.filter((a) => a.Code !== airport.Code); // Remove old entry if exists
      uniqueAirports.push(airport);
    }
  });

  AllFilters[indexToPass].LayoverAirports = uniqueAirports;

  let uniqueAirports2 = [];
  let airportPrices2 = {};

  ArrivalAirports.forEach((airport) => {
    if (
      !airportPrices2[airport.Code] ||
      airportPrices2[airport.Code] > airport.Price
    ) {
      airportPrices2[airport.Code] = airport.Price;
      uniqueAirports2 = uniqueAirports2.filter((a) => a.Code !== airport.Code); // Remove old entry if exists
      uniqueAirports2.push(airport);
    }
  });

  AllFilters[indexToPass].nearbyArrivalAirports = uniqueAirports2;

  return AllFilters;
}

export async function gerateFiltersParamsCombined(
  AllFilters,
  CombinedData,
  Logos,
  Journey,
  numOfContent
) {
  // Generate Popular Filters

  let currentMinprice1 = [Infinity, Infinity, Infinity, Infinity];
  let currentMinprice2 = [Infinity, Infinity, Infinity, Infinity];
  let currentMinprice3 = [Infinity, Infinity, Infinity, Infinity];

  let refundableMinPrice = [Infinity, Infinity, Infinity, Infinity];

  let minPrice = [Infinity, Infinity, Infinity, Infinity];
  let maxPrice = [-Infinity, -Infinity, -Infinity, -Infinity];

  let allAirlines = [[], [], [], []];

  let MinimumMinutes = [Infinity, Infinity, Infinity, Infinity];
  let MaximumMinutes = [-Infinity, -Infinity, -Infinity, -Infinity];

  let MinimumMinutesLayOver = [Infinity, Infinity, Infinity, Infinity];
  let MaximumMinutesLayOver = [-Infinity, -Infinity, -Infinity, -Infinity];

  let layOverAirports = [[], [], [], []];
  let ArrivalAirports = [[], [], [], []];

  for (let element of CombinedData) {
    let durationParasInside = [[], [], [], []];

    if (
      element.DataFormat === "INT-RD" ||
      element.DataFormat === "INT-RD-NR" ||
      element.DataFormat === "INT-ML-2" ||
      element.DataFormat === "INT-ML-3" ||
      element.DataFormat === "INT-ML-4" ||
      element.DataFormat === "HYB-ML-2" ||
      element.DataFormat === "HYB-ML-3" ||
      element.DataFormat === "HYB-ML-4"
    ) {
      for (let i = 0; i < Journey.length; i++) {
        element.Segments[i].forEach((airlineEleemnt) => {
          if (
            allAirlines[i][airlineEleemnt.Airline.AirlineName] === undefined
          ) {
            allAirlines[i][airlineEleemnt.Airline.AirlineName] = {
              count: 1,
              Price: element.Fare.MegaTotal,
              code: airlineEleemnt.Airline.AirlineCode,
            };
          } else {
            let price =
              element.Fare.MegaTotal <
              allAirlines[i][airlineEleemnt.Airline.AirlineName].Price
                ? element.Fare.MegaTotal
                : allAirlines[i][airlineEleemnt.Airline.AirlineName].Price;
            allAirlines[i][airlineEleemnt.Airline.AirlineName] = {
              count:
                allAirlines[i][airlineEleemnt.Airline.AirlineName].count + 1,
              Price: price,
              code: airlineEleemnt.Airline.AirlineCode,
            };
          }

          durationParasInside[i].push([
            airlineEleemnt.Origin.DepTime,
            airlineEleemnt.Destination.ArrTime,
          ]);
        });

        if (element.Segments[i].length === 2) {
          layOverAirports[i].push({
            Code: element.Segments[i][0].Destination.Airport.AirportCode,
            Name: element.Segments[i][0].Destination.Airport.AirportName,
            CityName: element.Segments[i][0].Destination.Airport.CityName,
            Price: element.Fare.MegaTotal,
          });
        } else if (element.Segments[i].length === 3) {
          layOverAirports[i].push({
            Code: element.Segments[i][0].Destination.Airport.AirportCode,
            Name: element.Segments[i][0].Destination.Airport.AirportName,
            CityName: element.Segments[i][0].Destination.Airport.CityName,
            Price: element.Fare.MegaTotal,
          });
          layOverAirports[i].push({
            Code: element.Segments[i][1].Destination.Airport.AirportCode,
            Name: element.Segments[i][1].Destination.Airport.AirportName,
            CityName: element.Segments[i][1].Destination.Airport.CityName,
            Price: element.Fare.MegaTotal,
          });
        }

        if (element.IsRefundable === true) {
          refundableMinPrice[i] =
            element.Fare.MegaTotal < refundableMinPrice[i]
              ? element.Fare.MegaTotal
              : refundableMinPrice[i];
          AllFilters[Journey[i].SeqNo].PopularFilters["Refundable"] = {
            code: "RF",
            name: "Refundable Flights",
            price: refundableMinPrice[i],
            hasLogo: false,
          };
        }

        if (element.Segments[i].length === 1) {
          currentMinprice1[i] =
            element.Fare.MegaTotal < currentMinprice1[i]
              ? element.Fare.MegaTotal
              : currentMinprice1[i];
          AllFilters[Journey[i].SeqNo].PopularFilters["Non_Stop"] = {
            code: "NSF",
            name: "Non Stop Flights",
            price: currentMinprice1[i],
            hasLogo: false,
          };
        } else if (element.Segments[i].length === 2) {
          currentMinprice2[i] =
            element.Fare.MegaTotal < currentMinprice2[i]
              ? element.Fare.MegaTotal
              : currentMinprice2[i];
          AllFilters[Journey[i].SeqNo].PopularFilters["One_Stop"] = {
            code: "OSF",
            name: "1 Stop Flights",
            price: currentMinprice2[i],
            hasLogo: false,
          };
        } else {
          currentMinprice3[i] =
            element.Fare.MegaTotal < currentMinprice3[i]
              ? element.Fare.MegaTotal
              : currentMinprice3[i];
          AllFilters[Journey[i].SeqNo].PopularFilters["One_Plus_Stop"] = {
            code: "OPSF",
            name: "1+ Stop Flights",
            price: currentMinprice3[i],
            hasLogo: false,
          };
        }

        if (element.Fare.MegaTotal < minPrice[i]) {
          minPrice[i] = element.Fare.MegaTotal;
        }
        if (element.Fare.MegaTotal > maxPrice[i]) {
          maxPrice[i] = element.Fare.MegaTotal;
        }

        if (
          element.Segments[i][element.Segments[i].length - 1].Destination
            .Airport.AirportCode !== Journey[1]
        ) {
          ArrivalAirports[i].push({
            Code: element.Segments[i][element.Segments[i].length - 1]
              .Destination.Airport.AirportCode,
            Name: element.Segments[i][element.Segments[i].length - 1]
              .Destination.Airport.AirportName,
            CityName:
              element.Segments[i][element.Segments[i].length - 1].Destination
                .Airport.CityName,
            Price: element.Fare.MegaTotal,
          });
        }
      }
    } else {
      for (let i = 0; i < Journey.length; i++) {
        element.Connections[i].Segments.forEach((airlineEleemnt) => {
          if (
            allAirlines[i][airlineEleemnt.Airline.AirlineName] === undefined
          ) {
            allAirlines[i][airlineEleemnt.Airline.AirlineName] = {
              count: 1,
              Price: element.Price,
              code: airlineEleemnt.Airline.AirlineCode,
            };
          } else {
            let price =
              element.Price <
              allAirlines[i][airlineEleemnt.Airline.AirlineName].Price
                ? element.Price
                : allAirlines[i][airlineEleemnt.Airline.AirlineName].Price;
            allAirlines[i][airlineEleemnt.Airline.AirlineName] = {
              count:
                allAirlines[i][airlineEleemnt.Airline.AirlineName].count + 1,
              Price: price,
              code: airlineEleemnt.Airline.AirlineCode,
            };
          }

          durationParasInside[i].push([
            airlineEleemnt.Origin.DepTime,
            airlineEleemnt.Destination.ArrTime,
          ]);
        });
      }

      if (
        element.Connections[Journey.length - 1].Segments[
          element.Connections[Journey.length - 1].Segments.length - 1
        ].Destination.Airport.AirportCode !==
        Journey[Journey.length - 1].Destination
      ) {
        ArrivalAirports[Journey.length - 1].push({
          Code: element.Connections[Journey.length - 1].Segments[
            element.Connections[Journey.length - 1].Segments.length - 1
          ].Destination.Airport.AirportCode,
          Name: element.Connections[Journey.length - 1].Segments[
            element.Connections[Journey.length - 1].Segments.length - 1
          ].Destination.Airport.AirportName,
          CityName:
            element.Connections[Journey.length - 1].Segments[
              element.Connections[Journey.length - 1].Segments.length - 1
            ].Destination.Airport.CityName,
          Price: element.Price,
        });
      }

      for (let i = 0; i < Journey.length; i++) {
        if (element.Connections[i].Segments.length === 2) {
          layOverAirports[i].push({
            Code: element.Connections[i].Segments[0].Destination.Airport
              .AirportCode,
            Name: element.Connections[i].Segments[0].Destination.Airport
              .AirportName,
            CityName:
              element.Connections[i].Segments[0].Destination.Airport.CityName,
            Price: element.Price,
          });
        } else if (element.Connections[i].Segments.length === 3) {
          layOverAirports[i].push({
            Code: element.Connections[i].Segments[0].Destination.Airport
              .AirportCode,
            Name: element.Connections[i].Segments[0].Destination.Airport
              .AirportName,
            CityName:
              element.Connections[i].Segments[0].Destination.Airport.CityName,
            Price: element.Price,
          });
          layOverAirports[i].push({
            Code: element.Connections[i].Segments[1].Destination.Airport
              .AirportCode,
            Name: element.Connections[i].Segments[1].Destination.Airport
              .AirportName,
            CityName:
              element.Connections[i].Segments[1].Destination.Airport.CityName,
            Price: element.Price,
          });
        }

        if (element.Connections[i].IsRefundable === true) {
          refundableMinPrice[i] =
            element.Price < refundableMinPrice[i]
              ? element.Price
              : refundableMinPrice[i];

          AllFilters[Journey[i].SeqNo].PopularFilters["Refundable"] = {
            code: "RF",
            name: "Refundable Flights",
            price: refundableMinPrice[i],
            hasLogo: false,
          };
        }

        if (element.Connections[i].Segments.length === 1) {
          currentMinprice1[i] =
            element.Price < currentMinprice1[i]
              ? element.Price
              : currentMinprice1[i];
          AllFilters[Journey[i].SeqNo].PopularFilters["Non_Stop"] = {
            code: "NSF",
            name: "Non Stop Flights",
            price: currentMinprice1[i],
            hasLogo: false,
          };
        } else if (element.Connections[i].Segments.length === 2) {
          currentMinprice2[i] =
            element.Price < currentMinprice2[i]
              ? element.Price
              : currentMinprice2[i];
          AllFilters[Journey[i].SeqNo].PopularFilters["One_Stop"] = {
            code: "OSF",
            name: "1 Stop Flights",
            price: currentMinprice2[i],
            hasLogo: false,
          };
        } else {
          currentMinprice3[i] =
            element.Price < currentMinprice3[i]
              ? element.Price
              : currentMinprice3[i];
          AllFilters[Journey[i].SeqNo].PopularFilters["One_Plus_Stop"] = {
            code: "OPSF",
            name: "1+ Stop Flights",
            price: currentMinprice3[i],
            hasLogo: false,
          };
        }

        if (element.Price < minPrice[i]) {
          minPrice[i] = element.Price;
        }
        if (element.Price > maxPrice[i]) {
          maxPrice[i] = element.Price;
        }
      }
    }

    if (
      element.DataFormat === "INT-RD" ||
      element.DataFormat === "INT-RD-NR" ||
      element.DataFormat === "INT-ML-2" ||
      element.DataFormat === "INT-ML-3" ||
      element.DataFormat === "INT-ML-4" ||
      element.DataFormat === "HYB-ML-2" ||
      element.DataFormat === "HYB-ML-3" ||
      element.DataFormat === "HYB-ML-4"
    ) {
      for (let i = 0; i < Journey.length; i++) {
        if (element.Segments[i].length === 2) {
          let minutes = getTotalMinutes(
            durationParasInside[i][0][1],
            durationParasInside[i][1][0]
          );

          // get Min And Max_Duration
          if (minutes < MinimumMinutesLayOver[i]) {
            MinimumMinutesLayOver[i] = minutes;
          }
          if (minutes > MaximumMinutesLayOver[i]) {
            MaximumMinutesLayOver[i] = minutes;
          }
        } else if (element.Segments[i].length === 3) {
          let minutes1 = getTotalMinutes(
            durationParasInside[i][0][1],
            durationParasInside[i][1][0]
          );
          let minutes2 = getTotalMinutes(
            durationParasInside[i][1][1],
            durationParasInside[i][2][0]
          );

          let minutes = minutes1 + minutes2;

          if (minutes < MinimumMinutesLayOver[i]) {
            MinimumMinutesLayOver[i] = minutes;
          }
          if (minutes > MaximumMinutesLayOver[i]) {
            MaximumMinutesLayOver[i] = minutes;
          }
        }
      }
    } else {
      for (let i = 0; i < Journey.length; i++) {
        let minutes = 0;

        if (element.Connections[i].Segments.length === 2) {
          minutes += getTotalMinutes(
            durationParasInside[i][0][1],
            durationParasInside[i][1][0]
          );
        } else if (element.Connections[i].Segments.length === 3) {
          minutes += getTotalMinutes(
            durationParasInside[i][0][1],
            durationParasInside[i][1][0]
          );
          minutes += getTotalMinutes(
            durationParasInside[i][1][1],
            durationParasInside[i][2][0]
          );
        }

        if (minutes < MinimumMinutesLayOver[i]) {
          MinimumMinutesLayOver[i] = minutes;
        }
        if (minutes > MaximumMinutesLayOver[i]) {
          MaximumMinutesLayOver[i] = minutes;
        }
      }
    }

    for (let i = 0; i < Journey.length; i++) {
      let minutes = getTotalMinutes(
        durationParasInside[i][0][0],
        durationParasInside[i][durationParasInside[i].length - 1][1]
      );

      // get Min And Max_Duration
      if (minutes < MinimumMinutes[i]) {
        MinimumMinutes[i] = minutes;
      }
      if (minutes > MaximumMinutes[i]) {
        MaximumMinutes[i] = minutes;
      }

      let airlineEntries = Object.entries(allAirlines[i]);
      airlineEntries.sort((a, b) => b[1].count - a[1].count); // Sort by count in descending order

      for (let ii = 0; ii < airlineEntries.length; ii++) {
        let airlineName = airlineEntries[ii][0];
        let airlineData = airlineEntries[ii][1];

        if (
          airlineName !== "" &&
          Logos[airlineData.code] !== undefined &&
          airlineData.code !== ""
        ) {
          if (ii < 3) {
            AllFilters[Journey[i].SeqNo].PopularFilters[airlineName] = {
              name: airlineName,
              price: airlineData.Price,
              code: airlineData.code,
              logo: Logos[airlineData.code],
              hasLogo: true,
            };
          }

          AllFilters[Journey[i].SeqNo].Airlines[airlineName] = {
            name: airlineName,
            price: airlineData.Price,
            code: airlineData.code,
            logo: Logos[airlineData.code],
          };
        }
      }

      // Price Dragger
      AllFilters[Journey[i].SeqNo].PriceDragger = {
        minPrice: minPrice[i],
        maxPrice: maxPrice[i],
      };
      // Flight duration dragger
      AllFilters[Journey[i].SeqNo].DurationDragger = {
        MinimumMinutes: MinimumMinutes[i],
        MaximumMinutes: MaximumMinutes[i],
      };
      // Flight layover duration dragger
      AllFilters[Journey[i].SeqNo].LayoverDurationDragger = {
        MinimumMinutes: MinimumMinutesLayOver[i],
        MaximumMinutes: MaximumMinutesLayOver[i],
      };

      let uniqueAirports = [];
      let airportPrices = {};

      layOverAirports[i].forEach((airport) => {
        if (
          !airportPrices[airport.Code] ||
          airportPrices[airport.Code] > airport.Price
        ) {
          airportPrices[airport.Code] = airport.Price;
          uniqueAirports = uniqueAirports.filter(
            (a) => a.Code !== airport.Code
          ); // Remove old entry if exists
          uniqueAirports.push(airport);
        }
      });

      AllFilters[Journey[i].SeqNo].LayoverAirports = uniqueAirports;

      let uniqueAirports2 = [];
      let airportPrices2 = {};

      ArrivalAirports[i].forEach((airport) => {
        if (
          !airportPrices2[airport.Code] ||
          airportPrices2[airport.Code] > airport.Price
        ) {
          airportPrices2[airport.Code] = airport.Price;
          uniqueAirports2 = uniqueAirports2.filter(
            (a) => a.Code !== airport.Code
          ); // Remove old entry if exists
          uniqueAirports2.push(airport);
        }
      });

      AllFilters[Journey[i].SeqNo].nearbyArrivalAirports = uniqueAirports2;
    }
  }

  let AllPopular = [];
  for (let i = 0; i < Journey.length; i++) {
    console.log("PopularFilters a sof her eis : " , AllFilters[Journey[i].SeqNo].PopularFilters );
    AllPopular.push(Object.values(AllFilters[Journey[i].SeqNo].PopularFilters));

    AllFilters[Journey[i].SeqNo].Airlines = Object.values(
      AllFilters[Journey[i].SeqNo].Airlines
    );
  }

  let PopularFiltersFinal = removeDuplicates2(AllPopular.flat());
  console.log("PopularFiltersFinal here is : " , PopularFiltersFinal);
  AllFilters[0].PopularFilters = PopularFiltersFinal;

  for (let i = 0; i < Journey.length; i++) {
    AllFilters[Journey[i].SeqNo].Airlines = AllFilters[
      Journey[i].SeqNo
    ].Airlines.map((item) => {
      let indexOfAirline = PopularFiltersFinal.findIndex(
        (filter) => item.code === filter?.code
      );

      if (indexOfAirline !== -1) {
        return {
          ...item,
          isOfPopular: true,
        };
      } else {
        return item;
      }
    });
  }

  console.log("AllFilters is : ", AllFilters);
  return AllFilters;
}

// Filters Helper Functions

export const sortByPrice = (filteredData, mode) => {
  // Create a copy of the array to avoid mutating the original state
  const dataToSort = [...filteredData];

  console.log("in the dataToSort : ", dataToSort);

  if (mode === 1) {
    return dataToSort.sort((a, b) => {
      const priceA = a.Segments !== undefined ? a.Fare.MegaTotal : a.Price;
      const priceB = b.Segments !== undefined ? b.Fare.MegaTotal : b.Price;

      return priceA - priceB;
    });
  } else {
    return dataToSort.sort((a, b) => {
      const priceA = a.Segments !== undefined ? a.Fare.MegaTotal : a.Price;
      const priceB = b.Segments !== undefined ? b.Fare.MegaTotal : b.Price;

      return priceA - priceB;
    });
  }
};

const getTimeDurations = (data, mode) => {
  let time1, time2;
  if (mode === 1) {
    time1 = data.Segments
      ? data.Segments[0].Origin.DepTime
      : data.Connections[0].Segments[0].Origin.DepTime;
    time2 = data.Segments
      ? data.Segments[data.Segments.length - 1].Destination.ArrTime
      : data.Connections[data.Connections.length - 1].Segments[
          data.Connections[data.Connections.length - 1].Segments.length - 1
        ].Destination.ArrTime;
    return getTotalMinutes(time1, time2);
  } else {
    let totalMinutes = 0;
    let lengthOfLoop = data.Segments
      ? data.Segments.length
      : data.Connections.length;
    for (let i = 0; i < lengthOfLoop; i++) {
      const segments = data.Segments
        ? data.Segments[i]
        : data.Connections[i].Segments;
      const depTime = segments[0].Origin.DepTime;
      const arrTime = segments[segments.length - 1].Destination.ArrTime;

      totalMinutes += getTotalMinutes(depTime, arrTime);
    }

    return totalMinutes;
  }
};

export const sortByTime = (filteredData, mode) => {
  return [...filteredData].sort((a, b) => {
    const durationA = getTimeDurations(a, mode);
    const durationB = getTimeDurations(b, mode);
    return durationA - durationB;
  });
};

export const filterByStops = (data, mode, value) => {
  // Create a copy of the array to avoid mutating the original state
  const datatoFilter = [...data];

  if (mode === 1) {
    return datatoFilter.filter((item) => {
      if (item.Segments !== undefined) {
        if (value === "NSF") {
          return item.Segments.length === 1;
        } else if (value === "OSF") {
          return item.Segments.length === 2;
        } else if (value === "OPSF") {
          return item.Segments.length > 2;
        }

        return false;
      } else {
        if (value === "NSF") {
          return (
            item.Connections[0].Segments.length +
              item.Connections[1].Segments.length ===
            1
          );
        } else if (value === "OSF") {
          return (
            item.Connections[0].Segments.length +
              item.Connections[1].Segments.length ===
            2
          );
        } else if (value === "OPSF") {
          return (
            item.Connections[0].Segments.length +
              item.Connections[1].Segments.length >
            2
          );
        }
      }
    });
  } else {
    let dataFiltered = datatoFilter.filter((item) => {
      let SegmentDataLength = item.Segments
        ? item.Segments.length
        : item.Connections.length;

      for (let i = 0; i < SegmentDataLength; i++) {
        let SegmentData = item.Segments
          ? item.Segments[i]
          : item.Connections[i].Segments;

        if (value === "NSF" && SegmentData.length === 1) {
          return true;
        } else if (value === "OSF" && SegmentData.length === 2) {
          return true;
        } else if (value === "OPSF" && SegmentData.length > 2) {
          return true;
        }
      }

      return false;
    });

    return dataFiltered;
  }
};

export const filterByDepartureTime = (data, mode, value , index) => {
  // Create a copy of the array to avoid mutating the original state
  const datatoFilter = [...data];

  if (mode === 1) {
    return datatoFilter.filter((item) => {
      if (item.Segments !== undefined) {
        const depTime = moment(item.Segments[0].Origin.DepTime);

        switch (value) {
          case 0: // Before 6 AM
            return depTime.isBefore(
              moment(depTime).set({ hour: 6, minute: 0 })
            );
          case 1: // Between 6 AM and 12 PM
            return (
              depTime.isSameOrAfter(
                moment(depTime).set({ hour: 6, minute: 0 })
              ) &&
              depTime.isBefore(moment(depTime).set({ hour: 12, minute: 0 }))
            );
          case 2: // Between 12 PM and 6 PM
            return (
              depTime.isSameOrAfter(
                moment(depTime).set({ hour: 12, minute: 0 })
              ) &&
              depTime.isBefore(moment(depTime).set({ hour: 18, minute: 0 }))
            );
          case 3: // After 6 PM
            return depTime.isSameOrAfter(
              moment(depTime).set({ hour: 18, minute: 0 })
            );
          default:
            return false;
        }
      } else {
        const depTime = moment(item.Connections[0].Segments[0].Origin.DepTime);

        switch (value) {
          case 0: // Before 6 AM
            return depTime.isBefore(
              moment(depTime).set({ hour: 6, minute: 0 })
            );
          case 1: // Between 6 AM and 12 PM
            return (
              depTime.isSameOrAfter(
                moment(depTime).set({ hour: 6, minute: 0 })
              ) &&
              depTime.isBefore(moment(depTime).set({ hour: 12, minute: 0 }))
            );
          case 2: // Between 12 PM and 6 PM
            return (
              depTime.isSameOrAfter(
                moment(depTime).set({ hour: 12, minute: 0 })
              ) &&
              depTime.isBefore(moment(depTime).set({ hour: 18, minute: 0 }))
            );
          case 3: // After 6 PM
            return depTime.isSameOrAfter(
              moment(depTime).set({ hour: 18, minute: 0 })
            );
          default:
            return false;
        }
      }
    });
  } else {

    return datatoFilter.filter((item) => {

      let SegmentData = item.Segments ? item.Segments[index] : item.Connections[index].Segments;

      const depTime = moment(SegmentData[0].Origin.DepTime);

      switch (value) {
        case 0: // Before 6 AM
          return depTime.isBefore(moment(depTime).set({ hour: 6, minute: 0 }));
        case 1: // Between 6 AM and 12 PM
          return (
            depTime.isSameOrAfter(
              moment(depTime).set({ hour: 6, minute: 0 })
            ) && depTime.isBefore(moment(depTime).set({ hour: 12, minute: 0 }))
          );
        case 2: // Between 12 PM and 6 PM
          return (
            depTime.isSameOrAfter(
              moment(depTime).set({ hour: 12, minute: 0 })
            ) && depTime.isBefore(moment(depTime).set({ hour: 18, minute: 0 }))
          );
        case 3: // After 6 PM
          return depTime.isSameOrAfter(
            moment(depTime).set({ hour: 18, minute: 0 })
          );
        default:
          return false;
      }
    });

  }
};

export const filterByArrivalTime = (data, mode, value , index) => {
  // Create a copy of the array to avoid mutating the original state
  const datatoFilter = [...data];

  if (mode === 1) {
    return datatoFilter.filter((item) => {
      if (item.Segments !== undefined) {
        const arrTime = moment(
          item.Segments[item.Segments.length - 1].Destination.ArrTime
        );

        switch (value) {
          case 0: // Before 6 AM
            return arrTime.isBefore(
              moment(arrTime).set({ hour: 6, minute: 0 })
            );
          case 1: // Between 6 AM and 12 PM
            return (
              arrTime.isSameOrAfter(
                moment(arrTime).set({ hour: 6, minute: 0 })
              ) &&
              arrTime.isBefore(moment(arrTime).set({ hour: 12, minute: 0 }))
            );
          case 2: // Between 12 PM and 6 PM
            return (
              arrTime.isSameOrAfter(
                moment(arrTime).set({ hour: 12, minute: 0 })
              ) &&
              arrTime.isBefore(moment(arrTime).set({ hour: 18, minute: 0 }))
            );
          case 3: // After 6 PM
            return arrTime.isSameOrAfter(
              moment(arrTime).set({ hour: 18, minute: 0 })
            );
          default:
            return false;
        }
      } else {
        const arrTime = moment(
          item.Connections[item.Connections.length - 1].Segments[
            item.Connections[item.Connections.length - 1].Segments.length - 1
          ].Destination.ArrTime
        );

        switch (value) {
          case 0: // Before 6 AM
            return arrTime.isBefore(
              moment(arrTime).set({ hour: 6, minute: 0 })
            );
          case 1: // Between 6 AM and 12 PM
            return (
              arrTime.isSameOrAfter(
                moment(arrTime).set({ hour: 6, minute: 0 })
              ) &&
              arrTime.isBefore(moment(arrTime).set({ hour: 12, minute: 0 }))
            );
          case 2: // Between 12 PM and 6 PM
            return (
              arrTime.isSameOrAfter(
                moment(arrTime).set({ hour: 12, minute: 0 })
              ) &&
              arrTime.isBefore(moment(arrTime).set({ hour: 18, minute: 0 }))
            );
          case 3: // After 6 PM
            return arrTime.isSameOrAfter(
              moment(arrTime).set({ hour: 18, minute: 0 })
            );
          default:
            return false;
        }
      }
    });
  } else {


    return datatoFilter.filter((item) => {

      let SegmentData = item.Segments ? item.Segments[index] : item.Connections[index].Segments;

      const arrTime = moment(
        SegmentData[SegmentData.length - 1].Destination.ArrTime
      );

      switch (value) {
        case 0: // Before 6 AM
          return arrTime.isBefore(moment(arrTime).set({ hour: 6, minute: 0 }));
        case 1: // Between 6 AM and 12 PM
          return (
            arrTime.isSameOrAfter(
              moment(arrTime).set({ hour: 6, minute: 0 })
            ) && arrTime.isBefore(moment(arrTime).set({ hour: 12, minute: 0 }))
          );
        case 2: // Between 12 PM and 6 PM
          return (
            arrTime.isSameOrAfter(
              moment(arrTime).set({ hour: 12, minute: 0 })
            ) && arrTime.isBefore(moment(arrTime).set({ hour: 18, minute: 0 }))
          );
        case 3: // After 6 PM
          return arrTime.isSameOrAfter(
            moment(arrTime).set({ hour: 18, minute: 0 })
          );
        default:
          return false;
      }
    });
  }
};

export const filterByLayover = (data, mode, value, index) => {
  // Create a copy of the array to avoid mutating the original state
  const datatoFilter = [...data];

  if (mode === 1) {
    return datatoFilter.filter((item) => {
      if (item.Segments !== undefined) {
        if (item.Segments.length === 2) {
          return item.Segments[0].Destination.Airport.AirportCode === value;
        }
        if (item.Segments.length === 3) {
          return (
            item.Segments[0].Destination.Airport.AirportCode === value ||
            item.Segments[1].Destination.Airport.AirportCode === value
          );
        }

        return false;
      } else {
        if (item.Connections[0].Segments.length === 2) {
          return (
            item.Connections[0].Segments[0].Destination.Airport.AirportCode ===
            value
          );
        }
        if (item.Connections[0].Segments.length === 3) {
          return (
            item.Connections[0].Segments[0].Destination.Airport.AirportCode ===
              value ||
            item.Connections[0].Segments[1].Destination.Airport.AirportCode ===
              value
          );
        }
        if (item.Connections[1].Segments.length === 2) {
          return (
            item.Connections[1].Segments[0].Destination.Airport.AirportCode ===
            value
          );
        }
        if (item.Connections[1].Segments.length === 3) {
          return (
            item.Connections[1].Segments[0].Destination.Airport.AirportCode ===
              value ||
            item.Connections[1].Segments[1].Destination.Airport.AirportCode ===
              value
          );
        }

        return false;
      }
    });
  } else {
    let dataFiltered = datatoFilter.filter((item) => {
      let SegmentData = item.Segments
        ? item.Segments[index]
        : item.Connections[index].Segments;

      // Check if any segment matches the airline code

      if (SegmentData.length === 2) {
        return SegmentData[0].Destination.Airport.AirportCode === value;
      }
      if (SegmentData.length === 3) {
        return (
          SegmentData[0].Destination.Airport.AirportCode === value ||
          SegmentData[1].Destination.Airport.AirportCode === value
        );
      }
    });

    console.log("the total data here is 2:", dataFiltered);
    return dataFiltered;
  }
};

export const filterByNearbyAirport = (data, mode, value , index) => {
  // Create a copy of the array to avoid mutating the original state
  const datatoFilter = [...data];

  if (mode === 1) {
    return datatoFilter.filter((item) => {
      if (item.Segments !== undefined) {
        return (
          item.Segments[item.Segments.length - 1].Destination.Airport
            .AirportCode === value
        );
      } else {
        return (
          item.Connections[item.Connections.length - 1].Segments[
            item.Connections[item.Connections.length - 1].length - 1
          ].Destination.Airport.AirportCode === value
        );
      }
    });
  } else {

    let dataFiltered = datatoFilter.filter((item) => {

      let SegmentData = item.Segments ? item.Segments[index] : item.Connections[index].Segments;

      // console.log("SegmentData ix : " , SegmentData , " , length si  :   " , );
      // Check if any segment matches the airline code
      return SegmentData[SegmentData.length - 1].Destination.Airport.AirportCode === value

    });

    console.log("the total data here is 2:", dataFiltered);
    return dataFiltered;

  }
};

export const filterByAirline = (data, mode, value, index) => {
  // Create a copy of the array to avoid mutating the original state
  const datatoFilter = [...data];

  if (mode === 1) {
    return datatoFilter.filter((item) => {
      if (item.Segments !== undefined) {
        return item.Segments.some(
          (segment) => segment.Airline.AirlineCode === value
        );
      } else {
        return item.Connections.some((connection) =>
          connection.Segments.some(
            (segment) => segment.Airline.AirlineCode === value
          )
        );
      }
    });
  } else if (mode === 2) {
    if (value.endsWith("_Pop") === true) {
      let valuee = value.slice(0, -4);

      let dataFiltered = datatoFilter.filter((item) => {
        let SegmentDataLength = item.Segments
          ? item.Segments.length
          : item.Connections.length;

        // Initialize a variable to track if any segment matches
        let hasMatchingSegment = false;

        for (let i = 0; i < SegmentDataLength; i++) {
          let SegmentData = item.Segments
            ? item.Segments[i]
            : item.Connections[i].Segments;

          if (
            SegmentData.some(
              (segment) => segment.Airline.AirlineCode === valuee
            )
          ) {
            hasMatchingSegment = true;
            break; // Exit the loop early if a match is found
          }
        }

        return hasMatchingSegment;
      });

      console.log("the total data here is : ", dataFiltered);
      return dataFiltered;
    } else {
      let dataFiltered = datatoFilter.filter((item) => {
        let SegmentData = item.Segments
          ? item.Segments[index]
          : item.Connections[index].Segments;

        // Check if any segment matches the airline code
        return SegmentData.some(
          (segment) => segment.Airline.AirlineCode === value
        );
      });

      console.log("the total data here is 2:", dataFiltered);
      return dataFiltered;
    }
  }
};

export const filterByPrice = (data, mode, value) => {
  // Create a copy of the array to avoid mutating the original state
  const datatoFilter = [...data];

  if (mode === 1) {
    return datatoFilter.filter((item) => {
      if (item.Segments !== undefined) {
        return (
          item.Fare.MegaTotal >= value.min && item.Fare.MegaTotal <= value.max
        );
      } else {
        return (
          item.Connections[0].Fare.MegaTotal >= value.min &&
          item.Connections[item.Connections.length - 1].Fare.MegaTotal <=
            value.max
        );
      }
    });
  } else if (mode === 2) {
    return datatoFilter.filter((item) => {
      let Price = item.Price ? item.Price : item.Fare.MegaTotal;

      return Price >= value.min && Price <= value.max;
    });
  }
};

export const filterByDuration = (data, mode, value , index) => {
  // Create a copy of the array to avoid mutating the original state
  const datatoFilter = [...data];

  if (mode === 1) {
    return datatoFilter.filter((item) => {
      if (item.Segments !== undefined) {
        let time = getTotalMinutes(
          item.Segments[0].Origin.DepTime,
          item.Segments[item.Segments.length - 1].Destination.ArrTime
        );
        return time >= value.min && time <= value.max;
      } else {
        let time = getTotalMinutes(
          item.Connections[0].Segments[0].Origin.DepTime,
          item.Connections[item.Connections.length - 1].Segments[
            item.Connections[item.Connections.length - 1].Segments.length - 1
          ].Destination.ArrTime
        );
        return time >= value.min && time <= value.max;
      }
    });
  } else {

    let dataFiltered = datatoFilter.filter((item) => {

      let SegmentData = item.Segments
        ? item.Segments[index]
        : item.Connections[index].Segments;

        let time = getTotalMinutes(
          SegmentData[0].Origin.DepTime,
          SegmentData[SegmentData.length - 1].Destination.ArrTime
        );
        return time >= value.min && time <= value.max;
    });

    return dataFiltered;
  }
};

export const filterByLayoverDuration = (data, mode, value , index) => {
  // Create a copy of the array to avoid mutating the original state
  const datatoFilter = [...data];

  if (mode === 1) {
    return datatoFilter.filter((item) => {
      if (item.Segments !== undefined) {
        if (item.Segments.length === 2) {
          let time = getTotalMinutes(
            item.Segments[0].Destination.ArrTime,
            item.Segments[1].Origin.DepTime
          );

          return time >= value.min && time <= value.max;
        }
        if (item.Segments.length === 3) {
          let time = 0;
          time += getTotalMinutes(
            item.Segments[0].Destination.ArrTime,
            item.Segments[1].Origin.DepTime
          );
          time += getTotalMinutes(
            item.Segments[1].Destination.ArrTime,
            item.Segments[2].Origin.DepTime
          );

          return time >= value.min && time <= value.max;
        }

        return false;
      } else {
        let time = 0;

        item.Connections.forEach((element) => {
          if (element.Segments.length === 2) {
            time += getTotalMinutes(
              element.Segments[0].Destination.ArrTime,
              element.Segments[1].Origin.DepTime
            );
          }
          if (element.Segments.length === 3) {
            time += getTotalMinutes(
              element.Segments[0].Destination.ArrTime,
              element.Segments[1].Origin.DepTime
            );
            time += getTotalMinutes(
              element.Segments[1].Destination.ArrTime,
              element.Segments[2].Origin.DepTime
            );
          }
        });

        for (let i = 0; i < item.Connections.length - 2; i++) {
          time += getTotalMinutes(
            item.Connections[i].Segments[
              item.Connections[i].Segments.length - 1
            ].Destination.ArrTime,
            item.Connections[i + 1].Segments[0].Origin.DepTime
          );
        }

        return time >= value.min && time <= value.max;
      }
    });
  } else {
    return datatoFilter.filter((item) => {

      let SegmentData = item.Segments
      ? item.Segments[index]
      : item.Connections[index].Segments;

      if (SegmentData.length === 2) {
        let time = getTotalMinutes(
          SegmentData[0].Destination.ArrTime,
          SegmentData[1].Origin.DepTime
        );

        return time >= value.min && time <= value.max;
      }
      if (SegmentData.length === 3) {
        let time = 0;
        time += getTotalMinutes(
          SegmentData[0].Destination.ArrTime,
          SegmentData[1].Origin.DepTime
        );
        time += getTotalMinutes(
          SegmentData[1].Destination.ArrTime,
          SegmentData[2].Origin.DepTime
        );

        return time >= value.min && time <= value.max;
      }

      return false;
    });
  }
};

export const filterByRefundability = (data, mode) => {
  // Create a copy of the array to avoid mutating the original state
  const datatoFilter = [...data];

  if (mode === 1) {
    return datatoFilter.filter((item) => {
      if (item.Segments !== undefined) {
        return item.IsRefundable === true;
      } else {
        return (
          item.Connections[0].IsRefundable === true &&
          item.Connections[1].IsRefundable === true
        );
      }
    });
  } else {

    return datatoFilter.filter((item) => {
      if (item.Segments !== undefined) {
        return item.IsRefundable === true;
      }
    
      if (item.Connections !== undefined) {
        return item.Connections.every(element => element.IsRefundable === true);
      }
    
      return false; // Default return false if neither Segments nor Connections are defined
    });
    

  }
};

export const filterBySearch = (data, mode, text) => {
  text = text.trim().replace(/\s+/g, " ");
  // Create a copy of the array to avoid mutating the original state
  const datatoFilter = [...data];

  // collect all the data where airline number  ,  coe or name matches or contains the text
  if (mode === 1) {
    return datatoFilter.filter((item) => {
      if (item.Segments !== undefined) {
        return item.Segments.some((segment) => {
          const { AirlineName, FlightNumber, AirlineCode } = segment.Airline;

          if (
            AirlineName !== null &&
            FlightNumber !== null &&
            AirlineCode !== null
          ) {
            return (
              AirlineName.toLowerCase().includes(text.toLowerCase()) ||
              FlightNumber.toLowerCase().includes(text.toLowerCase()) ||
              AirlineCode.toLowerCase().includes(text.toLowerCase()) ||
              AirlineName.toLowerCase() === text.toLowerCase() ||
              FlightNumber.toLowerCase() === text.toLowerCase() ||
              AirlineCode.toLowerCase() === text.toLowerCase()
            );
          }
        });
      } else {
        return item.Connections.forEach((element) => {
          return element.Segments.some((segment) => {
            const { AirlineName, FlightNumber, AirlineCode } = segment.Airline;

            if (
              AirlineName !== null &&
              FlightNumber !== null &&
              AirlineCode !== null
            ) {
              return (
                AirlineName.toLowerCase().includes(text.toLowerCase()) ||
                FlightNumber.toLowerCase().includes(text.toLowerCase()) ||
                AirlineCode.toLowerCase().includes(text.toLowerCase()) ||
                AirlineName.toLowerCase() === text.toLowerCase() ||
                FlightNumber.toLowerCase() === text.toLowerCase() ||
                AirlineCode.toLowerCase() === text.toLowerCase()
              );
            }
          });
        });
      }
    });
  } else {
    let dataFiltered = datatoFilter.filter((item) => {
      let SegmentDataLength = item.Segments
        ? item.Segments.length
        : item.Connections.length;

      for (let i = 0; i < SegmentDataLength; i++) {
        let SegmentData = item.Segments
          ? item.Segments[i]
          : item.Connections[i].Segments;

        if (
          SegmentData.some((segment) => {
            const { AirlineName, FlightNumber, AirlineCode } = segment.Airline;

            if (AirlineName && FlightNumber && AirlineCode) {
              return (
                AirlineName.toLowerCase().includes(text.toLowerCase()) ||
                FlightNumber.toLowerCase().includes(text.toLowerCase()) ||
                AirlineCode.toLowerCase().includes(text.toLowerCase()) ||
                AirlineName.toLowerCase() === text.toLowerCase() ||
                FlightNumber.toLowerCase() === text.toLowerCase() ||
                AirlineCode.toLowerCase() === text.toLowerCase()
              );
            }

            return false;
          })
        ) {
          return true;
        }
      }

      return false;
    });

    return dataFiltered;
  }
};

const FiltersFiltering1 = (FinalFilteredData, FiltersApplied, index) => {
  let mergedData = FinalFilteredData;

  let AirlinesData = [];

  // AirlinesData
  FiltersApplied[index].forEach((element) => {
    let mode = element.Mode;

    switch (mode) {
      case "Airline":
        AirlinesData.push(filterByAirline(FinalFilteredData, 1, element.Value));
        break;
    }
  });

  if (AirlinesData.length > 0) {
    mergedData = AirlinesData.flat();
  }

  let StopsData = [];

  // StopsData
  FiltersApplied[index].forEach((element) => {
    let mode = element.Mode;

    switch (mode) {
      case "Stops":
        StopsData.push(filterByStops(mergedData, 1, element.Value));
        break;
    }
  });

  if (StopsData.length > 0) {
    mergedData = StopsData.flat();
  }

  let LayoverAirport = [];

  //LayoverAirport
  FiltersApplied[index].forEach((element) => {
    let mode = element.Mode;

    switch (mode) {
      case "LayoverAirport":
        LayoverAirport.push(filterByLayover(mergedData, 1, element.Value));
        break;
    }
  });

  if (LayoverAirport.length > 0) {
    mergedData = LayoverAirport.flat();
  }

  let nearbyArrivalAirports = [];

  //nearbyArrivalAirports
  FiltersApplied[index].forEach((element) => {
    let mode = element.Mode;

    switch (mode) {
      case "NearbyAirport":
        nearbyArrivalAirports.push(
          filterByNearbyAirport(mergedData, 1, element.Value)
        );
        break;
    }
  });

  if (nearbyArrivalAirports.length > 0) {
    mergedData = nearbyArrivalAirports.flat();
  }

  let RefundableData = [];

  // RefundableData
  FiltersApplied[index].forEach((element) => {
    let mode = element.Mode;

    switch (mode) {
      case "Refundable":
        RefundableData.push(filterByRefundability(mergedData, 1));
        break;
    }
  });

  if (RefundableData.length > 0) {
    mergedData = RefundableData.flat();
  }

  let DepartureTimeData = [];

  // DepartureTimeData
  FiltersApplied[index].forEach((element) => {
    let mode = element.Mode;

    switch (mode) {
      case "DepartureTime":
        DepartureTimeData.push(
          filterByDepartureTime(mergedData, 1, element.Value)
        );
        break;
    }
  });

  if (DepartureTimeData.length > 0) {
    mergedData = removeDuplicates(DepartureTimeData.flat());
  }

  let ArrivalTimeData = [];

  // ArrivalTimeData
  FiltersApplied[index].forEach((element) => {
    let mode = element.Mode;

    switch (mode) {
      case "ArrivalTime":
        ArrivalTimeData.push(filterByArrivalTime(mergedData, 1, element.Value));
        break;
    }
  });

  if (ArrivalTimeData.length > 0) {
    mergedData = removeDuplicates(ArrivalTimeData.flat());
  }

  // The Last Filters
  let PriceData = [];

  FiltersApplied[index].forEach((element) => {
    let mode = element.Mode;

    switch (mode) {
      case "Price":
        PriceData.push(filterByPrice(mergedData, 1, element.Value));
        break;
    }
  });

  if (PriceData.length > 0) {
    mergedData = PriceData.flat();
  }

  // The Last Filters
  let DurationData = [];

  FiltersApplied[index].forEach((element) => {
    let mode = element.Mode;

    switch (mode) {
      case "Duration":
        DurationData.push(filterByDuration(mergedData, 1, element.Value));
        break;
    }
  });

  if (DurationData.length > 0) {
    mergedData = DurationData.flat();
  }

  // Layover Duration
  let LayoverDurationData = [];

  FiltersApplied[index].forEach((element) => {
    let mode = element.Mode;

    switch (mode) {
      case "DurationLayover":
        LayoverDurationData.push(
          filterByLayoverDuration(mergedData, 1, element.Value)
        );
        break;
    }
  });

  if (LayoverDurationData.length > 0) {
    mergedData = LayoverDurationData.flat();
  }

  // Searched Data
  let SearchedData = [];

  FiltersApplied[index].forEach((element) => {
    let mode = element.Mode;

    switch (mode) {
      case "SearchBy":
        element.Value === ""
          ? (SearchedData = [])
          : SearchedData.push(filterBySearch(mergedData, 1, element.Value));
        break;
    }
  });

  if (SearchedData.length > 0) {
    mergedData = SearchedData.flat();
  }

  // Sort the filtered Data

  let SortedAndFilteredData = mergedData;
  FiltersApplied[index].forEach((element) => {
    let mode = element.Mode;

    if (mode === "SortBy") {
      if (element.Value === "Time") {
        SortedAndFilteredData = sortByTime(mergedData, 1);
      } else if (element.Value === "Price") {
        SortedAndFilteredData = sortByPrice(mergedData, 1);
      }
    }
  });

  console.log("SortedAndFilteredData is : ", SortedAndFilteredData);

  return SortedAndFilteredData;
};

const FiltersFiltering2 = (
  FinalFilteredData,
  FiltersApplied,
  index,
  turns,
  start
) => {
  let mergedData = FinalFilteredData;

  let AirlinesData = [];

  // AirlinesData  -> done
  for (let i = 0; i < turns; i++) {
    let airlinedataMini = [];
    FiltersApplied[i + start].forEach((element) => {
      let mode = element.Mode;

      switch (mode) {
        case "Airline":
          airlinedataMini.push(
            filterByAirline(FinalFilteredData, 2, element.Value, i)
          );
          break;
      }
    });

    AirlinesData.push(airlinedataMini.flat());
  }

  if (AirlinesData.some((innerArray) => innerArray.length > 0)) {
    mergedData = removeDuplicates(AirlinesData.flat());
  }

  let StopsData = [];

  // StopsData  -> done
  FiltersApplied[index].forEach((element) => {
    let mode = element.Mode;

    switch (mode) {
      case "Stops":
        StopsData.push(filterByStops(mergedData, 2, element.Value));
        break;
    }
  });

  if (StopsData.length > 0) {
    mergedData = StopsData.flat();
  }

  let LayoverAirport = [];

  //LayoverAirport  -> done
  for (let i = 0; i < turns; i++) {
    let dataMini = [];
    FiltersApplied[i + start].forEach((element) => {
      let mode = element.Mode;

      switch (mode) {
        case "LayoverAirport":
          dataMini.push(filterByLayover(mergedData, 2, element.Value, i));
          break;
      }
    });
    LayoverAirport.push(dataMini.flat());

  }
  
  console.log("LayoverAirport is : " , LayoverAirport);
  if (LayoverAirport.some((innerArray) => innerArray.length > 0)) {
    mergedData = removeDuplicates(LayoverAirport.flat());
  }

  let nearbyArrivalAirports = [];


  // NearbyAirport -> done

  for (let i = 0; i < turns; i++) {
    let dataMini = [];

    FiltersApplied[i + start].forEach((element) => {
      
      let mode = element.Mode;

        switch (mode) {
          case "NearbyAirport":
            dataMini.push(
              filterByNearbyAirport(mergedData, 2, element.Value , i)
            );
            break;
        }
    });

    nearbyArrivalAirports.push(dataMini.flat());
  }

  if (nearbyArrivalAirports.some((innerArray) => innerArray.length > 0)) {
    mergedData = removeDuplicates(nearbyArrivalAirports.flat());
  }

  let RefundableData = [];

  // RefundableData
  FiltersApplied[index].forEach((element) => {
    let mode = element.Mode;

    switch (mode) {
      case "Refundable":
        RefundableData.push(filterByRefundability(mergedData, 2));
        break;
    }
  });

  if (RefundableData.length > 0) {
    mergedData = RefundableData.flat();
  }

  let DepartureTimeData = [];

  // DepartureTimeData

  for (let i = 0; i < turns; i++) {
    let airlinedataMini = [];
    FiltersApplied[i + start].forEach((element) => {
      let mode = element.Mode;

      switch (mode) {
        case "DepartureTime":
          airlinedataMini.push(
            filterByDepartureTime(mergedData, 2, element.Value , i)
          );
          break;
      }
    });

    DepartureTimeData.push(airlinedataMini.flat());
  }

console.log("DepartureTimeData is : " , DepartureTimeData);

  if (DepartureTimeData.some((innerArray) => innerArray.length > 0)) {
    mergedData = removeDuplicates(DepartureTimeData.flat());
  }

  console.log("DepartureTimeData is : " , mergedData);

  let ArrivalTimeData = [];

  // ArrivalTimeData
  for (let i = 0; i < turns; i++) {
    let airlinedataMini = [];
    FiltersApplied[i + start].forEach((element) => {
      let mode = element.Mode;

      switch (mode) {
        case "ArrivalTime":
          airlinedataMini.push(filterByArrivalTime(mergedData, 2, element.Value , i));
          break;
      }
    });

    ArrivalTimeData.push(airlinedataMini.flat());
  }

  console.log("ArrivalTimeData is : " , ArrivalTimeData);

  if (ArrivalTimeData.some((innerArray) => innerArray.length > 0)) {
    mergedData = removeDuplicates(ArrivalTimeData.flat());
  }

  // The Last Filters
  let PriceData = [];

  FiltersApplied[index].forEach((element) => {
    let mode = element.Mode;

    switch (mode) {
      case "Price":
        PriceData.push(filterByPrice(mergedData, 2, element.Value));
        break;
    }
  });

  if (PriceData.length > 0) {
    mergedData = PriceData.flat();
  }

  // The Last Filters
  let DurationData = [];

  for (let i = 0; i < turns; i++) {
    let dataMini = [];

    FiltersApplied[i + start].forEach((element) => {
      let mode = element.Mode;

      switch (mode) {
        case "Duration":
          dataMini.push(filterByDuration(mergedData, 2, element.Value , i));
          break;
      }
    });

    DurationData.push(dataMini.flat());
  }

  if (DurationData.some((innerArray) => innerArray.length > 0)) {
    mergedData = removeDuplicates(DurationData.flat());
  }

  // Layover Duration
  let LayoverDurationData = [];

  for (let i = 0; i < turns; i++) {
    let dataMini = [];

  FiltersApplied[i + start].forEach((element) => {
    let mode = element.Mode;

    switch (mode) {
      case "DurationLayover":
        dataMini.push(
          filterByLayoverDuration(mergedData, 2, element.Value , i)
        );
        break;
    }
  });

  LayoverDurationData.push(dataMini.flat());
}

if (LayoverDurationData.some((innerArray) => innerArray.length > 0)) {
  mergedData = removeDuplicates(LayoverDurationData.flat());
}


  // Searched Data
  let SearchedData = [];

  FiltersApplied[index].forEach((element) => {
    let mode = element.Mode;

    switch (mode) {
      case "SearchBy":
        element.Value === ""
          ? (SearchedData = [])
          : SearchedData.push(filterBySearch(mergedData, 2, element.Value));
        break;
    }
  });

  if (SearchedData.length > 0) {
    mergedData = SearchedData.flat();
  }

  // Sort the filtered Data

  let SortedAndFilteredData = mergedData;
  FiltersApplied[index].forEach((element) => {
    let mode = element.Mode;

    if (mode === "SortBy") {
      if (element.Value === "Time") {
        SortedAndFilteredData = sortByTime(mergedData, 2);
      } else if (element.Value === "Price") {
        SortedAndFilteredData = sortByPrice(mergedData, 2);
      }
    }
  });

  console.log("SortedAndFilteredData is : ", SortedAndFilteredData);

  return SortedAndFilteredData;
};

export const filterFlights = (
  DatatoFilter,
  FiltersApplied,
  index,
  SearchResultType,
  isAdded
) => {
  if (
    SearchResultType === 1 ||
    SearchResultType === 2 ||
    SearchResultType === 3 ||
    SearchResultType === 5 ||
    SearchResultType === 7 ||
    SearchResultType === 9 ||
    (SearchResultType === 4 && index > 1) ||
    (SearchResultType === 6 && index > 1) ||
    (SearchResultType === 8 && index > 2) ||
    (SearchResultType === 10 && index > 3)
  ) {
    let FinalFilteredData = [...DatatoFilter];

    return FiltersFiltering1(FinalFilteredData, FiltersApplied, index);
  } else if ((SearchResultType === 4 && index < 2) || (SearchResultType === 6 && index < 2)) {
    let FinalFilteredData = [...DatatoFilter];

    return FiltersFiltering2(FinalFilteredData, FiltersApplied, index, 2, 0);
  } else if (SearchResultType === 8 && index < 3) {
    let FinalFilteredData = [...DatatoFilter];

    return FiltersFiltering2(FinalFilteredData, FiltersApplied, index, 3, 0);
  } else if (SearchResultType === 10 && index < 3) {
    let FinalFilteredData = [...DatatoFilter];

    return FiltersFiltering2(FinalFilteredData, FiltersApplied, index, 4, 0);
  }
};

const removeDuplicates = (data) => {
  if (!Array.isArray(data)) {
    console.error("Expected an array but got:", data);
    return [];
  }

  const uniqueItems = new Set();

  return data.filter((item) => {
    const itemString = JSON.stringify(item);
    if (!uniqueItems.has(itemString)) {
      uniqueItems.add(itemString);
      return true;
    }
    return false;
  });
};

const removeDuplicates2 = (data) => {
  const uniqueItems = data.reduce((acc, current) => {
    const existingItem = acc.find((item) => item.name === current.name);
    if (existingItem) {
      // If an item with the same name exists, keep the one with the lower price
      if (current.price < existingItem.price) {
        acc = acc.filter((item) => item.name !== current.name); // Remove the existing item
        acc.push(current); // Add the current item with the lower price
      }
    } else {
      acc.push(current); // If no item with the same name exists, add the current item
    }
    return acc;
  }, []);

  // Get the top 3 lowest priced airlines
  const top3Airlines = getTop3LowestPricedAirlines(uniqueItems);

  console.log("uniqueItems are : ", uniqueItems);
  // Remove the top 3 airlines from the unique data
  const remainingData = uniqueItems.filter((item) => {
    return !(item.hasLogo === true);
  });

  console.log("remainingData is : ", remainingData);
  console.log("top3Airlines is : ", top3Airlines);

  // Return the combination of top 3 airlines and the remaining data
  return [...top3Airlines, ...remainingData];

  // return uniqueItems;
};

const getTop3LowestPricedAirlines = (data) => {
  // Filter out only the airlines from the data (those with a 'name' and 'code')
  const airlines = data.filter((item) => item.hasLogo === true);

  // Sort the airlines by price in ascending order
  const sortedAirlines = airlines.sort((a, b) => a.price - b.price);

  // Return the top 3 lowest priced airlines
  return sortedAirlines.slice(0, 3);
};

const findDuplicates = (data) => {
  const itemCounts = new Map();

  // Count occurrences of each item
  data.forEach((item) => {
    const itemString = JSON.stringify(item);
    if (itemCounts.has(itemString)) {
      itemCounts.set(itemString, itemCounts.get(itemString) + 1);
    } else {
      itemCounts.set(itemString, 1);
    }
  });

  console.log("the data final is : ", data);

  // Filter to get only repeated items
  return data.filter((item) => itemCounts.get(JSON.stringify(item)) > 2);
};
