import TripSlider from "./TripSlider";
import TripChooser from "./TripChooser";
 
function index({ lengthOfBars, isTrip , Data , topBarSelected , settopBarSelected , Theme}) {
  if (lengthOfBars == 2) {
    return <TripChooser isTrip={isTrip} Data={Data} topBarSelected={topBarSelected} settopBarSelected={settopBarSelected}  Theme={Theme}/>;
  } else {
    return <TripSlider isTrip={isTrip} Data={Data} topBarSelected={topBarSelected} settopBarSelected={settopBarSelected} Theme={Theme}/>;
  }
}

export default index;
