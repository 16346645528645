import { configureStore } from '@reduxjs/toolkit';
import userReducer from "../Slices/User_Slices/User-SLICE"
import HomePageSearchBarFligthReducer from "../Slices/Flight_Slices/HomePage-Flights-SLICE"
import ResultPageFlightsFlightSlice from "../Slices/Flight_Slices/ResultPage-Flights-SLICE"

export const store = configureStore({
  reducer: {
    user: userReducer,
    HomePageSearchBarFlight : HomePageSearchBarFligthReducer,
    ResultPageFlightsFlight : ResultPageFlightsFlightSlice
  }
});
