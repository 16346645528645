import React from 'react'
import images from '../../../../Utils/importImages'
import "./index.css";

export default function FareSummary({base , tax}) {

  console.log("base is : " , base);
  console.log("tax is : " , tax);
  return (
    <div className="fare-summary-popup">

    <div className="frame-242"><div className="fare-summary">FARE SUMMARY</div></div>
    <div className="frame-244">
      <div className="base-fare">Base Fare</div>
      <div className="frame-173">
        <img className="frame-169" src={images["white-rupee-icon.svg"]} alt="Frame 169" />
        <div className="text valign-text-middle">{base}</div>
      </div>
    </div>
    <div className="frame-243">
      <div className="taxes">Taxes</div>
      <div className="frame-173">
        <img className="frame-169" src={images["white-rupee-icon.svg"]} alt="Frame 169" />
        <div className="text valign-text-middle">{tax}</div>
      </div>
    </div>
    <div className="frame-241">
      <div className="total">TOTAL</div>
      <div className="frame-173">
        <img className="frame-169" src={images["white-rupee-icon.svg"]} alt="Frame 169" />
        <div className="text-3 valign-text-middle">{base+tax}</div>
      </div>
    </div>
  </div>
  )
}
