import React from 'react';
import images from '../../../Utils/importImages';
import "./index.css"

function Footer() {
  return (
    <footer className="main-footer">
      <div className="footer-columns">
        <div className="frame-66">
          <div className="frame-65">
            <p className="get-latest-updates-offers segoe_ui_semibold color_deep-sapphire font20">Get latest updates & offers</p>
            <div className="group-70">
              <div className="overlap-group-2">
                <div className="enter-email-address valign-text-middle segoe_ui_regular color_eerie-black font16">
                  Enter email address
                </div>
              </div>
            </div>
            <div className="frame-71"><div className="subscribe segoe_ui_semibold color_white font16">Subscribe</div></div>
            <div className="group-79">
              <div className="group-container">
                <div className="group-76"><img className="vector-1" src={images['vector-5.svg']} alt="Vector" /></div>
                <div className="group-74"><img className="vector-5" src={images['vector-1.svg']} alt="Vector" /></div>
                <div className="group-77"><img className="vector-6" src={images['vector-17.svg']} alt="Vector" /></div>
                <div className="group-78"><img className="gle" src={images['google-plus-g-3.svg']} alt="google-plus-g" /></div>
              </div>
            </div>
            <div className="group-72">
              <img className="img2" src={images['img2.png']} alt="img2" />
              <img className="img1" src={images['img1.png']} alt="img1" />
            </div>
          </div>
        </div>
        <div className="frame-67">
          <div className="group-65">
            <div className="our-services segoe_ui_semibold color_deep-sapphire font20">Our Services</div>
            <div className="group-64">
              <div className="group-61">
                <img className="gle" src={images['angle-right-42.svg']} alt="angle-right" />
                <div className="international valign-text-middle segoe_ui_regular color_eerie-black font16">
                  <span><span className="segoe_ui_regular color_eerie-black font16">International Flights</span> </span>
                </div>
              </div>
              <div className="group-62">
                <img className="gle" src={images['angle-right-42.svg']} alt="angle-right" />
                <div className="domestic-flights valign-text-middle segoe_ui_regular color_eerie-black font16">
                  <span><span className="segoe_ui_regular color_eerie-black font16">Domestic Flights</span> </span>
                </div>
              </div>
              <div className="group-63">
                <img className="gle" src={images['angle-right-42.svg']} alt="angle-right" />
                <div className="hotels-in-india valign-text-middle segoe_ui_regular color_eerie-black font16">
                  Hotels in India
                </div>
              </div>
              <div className="group-64-1">
                <img className="gle" src={images['angle-right.svg']} alt="angle-right" />
                <div className="international valign-text-middle segoe_ui_regular color_eerie-black font16">
                  International Hotels
                </div>
              </div>
              <div className="group-65-1">
                <img className="gle" src={images['angle-right.svg']} alt="angle-right" />
                <div className="tour-packages valign-text-middle segoe_ui_regular color_eerie-black font16">
                  Tour Packages
                </div>
              </div>
              <div className="group-66">
                <img className="gle" src={images['angle-right.svg']} alt="angle-right" />
                <div className="special-offers valign-text-middle segoe_ui_regular color_eerie-black font16">
                  Special Offers
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="frame-68">
          <div className="group-65-2">
            <div className="quick-links segoe_ui_semibold color_deep-sapphire font20">Quick Links</div>
            <div className="group-64-2">
              <div className="group-61-1 group-61-3">
                <img className="gle" src={images['angle-right-42.svg']} alt="angle-right" />
                <div className="place-7 valign-text-middle segoe_ui_regular color_eerie-black font16">Home</div>
              </div>
              <div className="group-62-1 group-62-3">
                <img className="gle" src={images['angle-right-42.svg']} alt="angle-right" />
                <div className="about valign-text-middle segoe_ui_regular color_eerie-black font16">About</div>
              </div>
              <div className="group-63-1 group-63-3">
                <img className="gle" src={images['angle-right-42.svg']} alt="angle-right" />
                <div className="x247-support valign-text-middle segoe_ui_regular color_eerie-black font16">
                  24*7 Support
                </div>
              </div>
              <div className="group-64-3">
                <img className="gle" src={images['angle-right.svg']} alt="angle-right" />
                <div className="place-8 valign-text-middle segoe_ui_regular color_eerie-black font16">Contact</div>
              </div>
            </div>
          </div>
        </div>
        <div className="frame-69">
          <div className="group-65-3">
            <div className="we-accept segoe_ui_semibold color_deep-sapphire font20">We Accept</div>
            <div className="group-80">
              <img className="card" src={images['card4.png']} alt="card4" />
              <img className="card" src={images['card3.png']} alt="card3" />
              <img className="card" src={images['card2.png']} alt="card2" />
              <img className="card" src={images['card1.png']} alt="card1" />
            </div>
          </div>
          <div className="group-66-1">
            <div className="get-to-know-us segoe_ui_semibold color_deep-sapphire font20">Get to Know Us</div>
            <div className="group-64-4">
              <div className="group-61-2 group-61-3">
                <img className="angle-right" src={images['angle-right-52.svg']} alt="angle-right" />
                <div className="login valign-text-middle segoe_ui_regular color_eerie-black font16">Login</div>
              </div>
              <div className="group-62-2 group-62-3">
                <img className="angle-right" src={images['angle-right-53.svg']} alt="angle-right" />
                <div className="enquiry valign-text-middle segoe_ui_regular color_eerie-black font16">Enquiry</div>
              </div>
              <div className="group-63-2 group-63-3">
                <img className="angle-right" src={images['angle-right-54.svg']} alt="angle-right" />
                <div className="offers-1 valign-text-middle segoe_ui_regular color_eerie-black font16">Offers</div>
              </div>
              <div className="group-64-5">
                <img className="angle-right" src={images['angle-right-55.svg']} alt="angle-right" />
                <div className="blog valign-text-middle segoe_ui_regular color_eerie-black font16">Blog</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="frame-74">
        <div className="frame-73">
          <p className="copyright-2020-2024 segoe_ui_regular color_eerie-black font16">
            Copyright 2020-{new Date().getFullYear()}. STFARES | All Rights Reserved
          </p>
          <div className="designed-by-nspl segoe_ui_regular color_white font16">
            <span className="segoe_ui_regular color_eerie-black font16">Designed & Developed by </span><span className="segoe_ui_regular color_deep-sapphire font16"> NSPL</span>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
