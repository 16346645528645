const isDateExpired = (dateString) => {
    const now = new Date();
    const date = new Date(dateString);

    // Normalize both dates to the start of the day (midnight) to compare only the date part
    const nowNormalized = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const dateNormalized = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    
    console.log(dateNormalized < nowNormalized);

    return dateNormalized < nowNormalized;
  };


export const cleanUpExpiredSearches = (searches) => {
    let dd = searches.filter((search) => {
      if (search.type === "ow") {
        return !isDateExpired(search.formattedDate);
      } else {
        return !isDateExpired(search.formattedDate[0]);
      }
    });

    return dd;
  };