import React from 'react'

function AppliedFilters({FiltersApplied , isSecondFormOfFilter , OriginalFiltersApplied , Theme}) {

    const filteredFilters = FiltersApplied?.filter(
        (item) => item.Mode !== "SearchBy" && item.Mode !== "SortBy"
      );


    if(isSecondFormOfFilter[0] === false){
        return (
        filteredFilters && filteredFilters.length > 0  && (
            <div className="frame-260">
                <div className="frame-104-3 frame-104-5">
                <div className="frame-175">
                    <div className="frame-201">
                    <div className={`popular-filters ${Theme}_regular color_magic-potion font18`}>
                        Applied Filters
                    </div>
                    </div>
                </div>
    
                <div className="frame-1" style={{ display: "flex" }}>
                    
                    
                {filteredFilters
                        .filter((item) => {
                        return item.Mode !== "SearchBy" && item.Mode != "SortBy";
                        })
                        .map((item, index) => {
                        return (
                            <div className={`air-india ${Theme}_regular color_gravel font16`}>
                            {item.key} ,{" "}
                            </div>
                        );
                        })
                    }
                </div>
                </div>
            </div>
            )
        )
    } else {

    let Data = isSecondFormOfFilter[1] === 2 ? 
        [...OriginalFiltersApplied[isSecondFormOfFilter[1]] , 
         ...OriginalFiltersApplied[isSecondFormOfFilter[1] + 1]] :
         isSecondFormOfFilter[1] === 3 ? 
         [...OriginalFiltersApplied[isSecondFormOfFilter[1]] , 
         ...OriginalFiltersApplied[isSecondFormOfFilter[1] + 1] , 
         ...OriginalFiltersApplied[isSecondFormOfFilter[1] + 2]] : 
         [...OriginalFiltersApplied[isSecondFormOfFilter[1]] , 
         ...OriginalFiltersApplied[isSecondFormOfFilter[1] + 1] , 
         ...OriginalFiltersApplied[isSecondFormOfFilter[1] + 2] , 
         ...OriginalFiltersApplied[isSecondFormOfFilter[1] + 3]]
   
    let uniqueData = [...new Map(Data.map(item => [item.key, item])).values()];

    const filteredFilters = uniqueData?.filter(
        (item) => item.Mode !== "SearchBy" && item.Mode !== "SortBy"
    );  

    return (
        filteredFilters && filteredFilters.length > 0  && (
            <div className="frame-260">
              <div className="frame-104-3 frame-104-5">
                <div className="frame-175">
                  <div className="frame-201">
                    <div className="popular-filters proxima_regular color_magic-potion font18">
                      Applied Filters
                    </div>
                  </div>
                </div>
    
                <div className="frame-1" style={{ display: "flex" }}>
                  
                  
                {Data
                      .filter((item) => {
                        return item.Mode !== "SearchBy" && item.Mode != "SortBy";
                      })
                      .map((item, index) => {
                        return (
                          <div className="air-india proxima_regular color_gravel font16">
                            {item.key} ,{" "}
                          </div>
                        );
                      })
                  }
                </div>
              </div>
            </div>
          )
      )
}
}

export default AppliedFilters
