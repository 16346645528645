import React from "react";
import { generateDays } from "../../../../Utils/Date_Time_Calendar_Functions";
import { DaysOfWeek } from "../../../../Constants/ConstantsFlights";

export default function CalendarGridGenerator({
  month,
  minimumDate,
  maximumDate,
  selectedDate,
  onChange,
  onClickClose,

  todaysDate,
  CalenderValues,
  returnDateValue,

  isReturnCalender,
  range,
  Theme
}) {

  let getTheDateNumberClasses = (day) => {
    if (
      (day[2]?.getFullYear() === selectedDate.getFullYear() &&
        day[2]?.getMonth() === selectedDate.getMonth() &&
        day[2]?.getDate() === selectedDate.getDate()) ||
      (isReturnCalender === true &&
        day[2]?.getFullYear() === returnDateValue?.getFullYear() &&
        day[2]?.getMonth() === returnDateValue?.getMonth() &&
        day[2]?.getDate() === returnDateValue?.getDate())
    ) {
      return [
        `calendar-text-2 ${Theme}_regular color_white font14_8 calendar-valign-text-middle calendar-text-4`,
        `calendar-text-3 ${Theme}_regular color_white font11_1 calendar-valign-text-middle calendar-text-4`,
      ];
    }
    if (day[2] < minimumDate || day[2] > maximumDate) {
      return [
        `calendar-text-1 calendar-valign-text-middle calendar-text-4 ${Theme}_regular color_pumice font14_8`,
        `calendar-text calendar-valign-text-middle ${Theme}_regular color_pumice font11_1`,
      ];
    }

    return [
      `calendar-text-1 calendar-valign-text-middle calendar-text-4 ${Theme}_regular color_eerie-black font14_8`,
      `calendar-text calendar-valign-text-middle ${Theme}_regular color_oslo-gray font11_1 ${Theme}`,
    ];
  };
  //   console.log("minimumDate is : ", minimumDate);

  let getValueElement = (value, currency) => {
    if (value === null) {
      return <span style={{ fontSize: "100%", width: "25px" , visibility:"hidden"}}>000</span>;
    } else if (value >= 0 && value < 100) {
      return <span style={{ fontSize: "100%", width: "25px" }}>{value}</span>;
    } else if (value >= 100 && value < 1000) {
      return <span style={{ fontSize: "100%", width: "25px" }}>{value}</span>;
    } else if (value >= 1000 && value < 10000) {
      return <span style={{ fontSize: "100%", width: "25px" }}>{value}</span>;
    } else if (value >= 10000 && value < 100000) {
      return <span style={{ fontSize: "85%", width: "25px" }}>{value}</span>;
    } else if (value >= 100000 && value < 1000000) {
      return <span style={{ fontSize: "65%", width: "25px" }}>{value}</span>;
    } else if (value >= 1000000 && value < 10000000) {
      return <span style={{ fontSize: "55%", width: "25px" }}>{value}</span>;
    }
  };

  const hasCalendarValues = (obj) => {
    return obj && Object.keys(obj)?.length > 0;
  };

  return (
    <div className="calendar-grid">
      <div className="calendar-line">
        {DaysOfWeek.map((item, index) => (
          <div className="calendar-atoms-week-dey" key={index}>
            <div className={`calendar-text calendar-valign-text-middle ${Theme}_regular color_eerie-black font16_2`}>
              {item}
            </div>
          </div>
        ))}
      </div>

      <div className="calendar-line-1">
        {generateDays(month).map((day, index) => {
          let classes = getTheDateNumberClasses(day);

          return (
            <div className={`calendar-date-box " ${day[0] !== null ? `caledner-active-dates ${Theme}` : "null"}`}>
              <div
                onClick={() => {
                  if (day[2] >= minimumDate && day[2] <= maximumDate) {
                    onChange(day[2]);
                    onClickClose();
                  }
                }}
                key={index}
                className={
                  (day[2]?.getFullYear() === selectedDate.getFullYear() &&
                    day[2]?.getMonth() === selectedDate.getMonth() &&
                    day[2]?.getDate() === selectedDate.getDate()) ||
                  (isReturnCalender === true &&
                    day[2]?.getFullYear() === returnDateValue?.getFullYear() &&
                    day[2]?.getMonth() === returnDateValue?.getMonth() &&
                    day[2]?.getDate() === returnDateValue?.getDate())
                    ? ` calendar-atoms-dey-number-4 ${Theme}`
                    : " calendar-atoms-dey-number-1"
                }
                style={
                  day[0] === null
                    ? {
                        visibility: "hidden",
                      }
                    : isReturnCalender === true
                    ? {
                        backgroundColor:
                          day[2] < range[1][0] &&
                          day[2] > range[0][0] &&
                          day[1] != range[0][1] &&
                          day[1] != range[1][1] &&
                          "#e7f2ff",
                      }
                    : {}
                }
              >
                <div
                  className={classes[0]}
                  style={todaysDate === day[1] ? { fontWeight: "bold" } : {}}
                >
                  {day[0]}
                </div>
                <div
                  className={classes[1]}
                  style={todaysDate === day[1] ? { fontWeight: "bold" } : {}}
                >
                  {day[2] >= minimumDate &&
                  day[2] <= maximumDate &&
                  CalenderValues !== null &&
                  hasCalendarValues(CalenderValues)
                    ? getValueElement(
                        CalenderValues[day[1]] === undefined
                          ? null
                          : CalenderValues[day[1]],
                        "cr"
                      )
                    : getValueElement(null)}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
