import React from "react";
import {
  setResultView,
  setbarModeSelected,
} from "../../../../../Redux/Slices/Flight_Slices/ResultPage-Flights-SLICE";
import { useDispatch, useSelector } from "react-redux";

function ResultToggler({ showTheToggler, ResultView  , Theme}) {
  let dispatch = useDispatch();

  let searchBarData = useSelector((state) => state?.ResultPageFlightsFlight);

  let toggleResultView = (num) => {
    if (num === 1) {
      dispatch(setResultView(num));
      dispatch(setbarModeSelected(0));
    } else {
      if (searchBarData?.SearchResultType === 4 || searchBarData?.SearchResultType === 6) {
        dispatch(setbarModeSelected(2));
        dispatch(setResultView(num));
      } else if (searchBarData?.SearchResultType === 8) {
        dispatch(setbarModeSelected(3));
        dispatch(setResultView(num));
      } else if (searchBarData?.SearchResultType === 10) {
        dispatch(setbarModeSelected(4));
        dispatch(setResultView(num));
      }
    }
  };

  return (
    <div
      className={`result-page-flights-frame-2-1 result-page-flights-frame-2-3 ${Theme}`}
      style={showTheToggler === false ? { visibility: "hidden" } : {}}
    >
      <div
        className={
          ResultView === 1
            ? `result-page-flights-frame-234 ${Theme}`
            : `result-page-flights-frame-233-1 ${Theme}`
        }
        onClick={() => toggleResultView(1)}
      >
        <div
          className={
            ResultView === 1
              ? `flight-combinations result-page-flights-flight ${Theme}_regular color_white font14`
              : `result-page-flights-select-your-own-flights ${Theme}_regular color_${Theme == "proxima" ? "black" : "burning-gold"} font14`
          }
        >
          Flight Combinations
        </div>
      </div>
      <div
        className={
          ResultView === 2
            ? `result-page-flights-frame-234 ${Theme}`
            : `result-page-flights-frame-233-1 ${Theme}`
        }
        onClick={() => toggleResultView(2)}
      >
        <div
          className={
            ResultView === 2
              ? `flight-combinations result-page-flights-flight ${Theme}_regular color_white font14`
              : `result-page-flights-select-your-own-flights ${Theme}_regular color_${Theme == "proxima" ? "black" : "burning-gold"} font14`
          }
        >
          Select Your Own Flights
          
        </div>
      </div>
    </div>
  );
}

export default ResultToggler;
