import React from 'react'
import images from '../../../../Utils/importImages'
import "./index3.css"
function FareSummaryDetailed({base , tax , totalTravellers , Theme}) {
  return (
    <div className={`fare-summary-popup-detailed ${Theme}`}>

    <div className="frame-242"><div className="fare-summary color_white font15 proxima_semibold">FARE SUMMARY</div></div>
    <div className={`frame-244 ${Theme}`}>
      <div className="base-fare proxima_medium color_white font16">Base Fare <small>( x {totalTravellers} )</small></div>
      <div className="frame-173">
        <img className="frame-169" src={images["white-rupee-icon.svg"]} alt="Frame 169" />
        <div className="text color_white font16 proxima_regular valign-text-middle">{base} </div>
      </div>
    </div>
    <div className={`frame-243 ${Theme}`}>
      <div className="taxes">Taxes <small>( x {totalTravellers} )</small></div>
      <div className="frame-173">
        <img className="frame-169" src={images["white-rupee-icon.svg"]} alt="Frame 169" />
        <div className="text color_white font16 proxima_regular valign-text-middle">{tax}</div>
      </div>
    </div>
    <div className={`frame-241 ${Theme}`}>
      <div className="total color_white font16 proxima_semibold">TOTAL</div>
      <div className="frame-173">
        <img className="frame-169" src={images["white-rupee-icon.svg"]} alt="Frame 169" />
        <div className="text-3 color_white font18 proxima_semibold valign-text-middle">{Math.ceil(base+tax)}</div>
      </div>
    </div>
  </div>
  )
}

export default FareSummaryDetailed
