import React, { useEffect, useRef, useState } from "react";
import "./Common.css";
import AirlinesLogContainer from "../../../Components/AirlinesLogContainer";
import images from "../../../../../../../Utils/importImages";
import Header from "./Components/Header";
import DataBar from "./Components/DataBar";
import Rules from "./Components/Rules";
import { getAllAirlinesWithindex1 } from "../../../../../../../Utils/Flight_results_func";
import TableContainer from "./Components/TableContainer";

function CancellationContent({
  topBarMode,
  Data,
  airlineLogos,
  ResultType,
  FFSelected,
  topBarSelected,
  Theme
}) {
  // let allAirlines = [{ logo: "", name: "airindia" }];

  // console.log("before thr ailrine the data is  :"   , Data,
  // airlineLogos,
  // ResultType);

  // console.log("allAirlines here is : " , allAirlines);
  const resultsRef = useRef(null);

  const [showScrollButtons, setShowScrollButtons] = useState(false);

  const checkScrollButtons = () => {
    if (resultsRef.current) {
      setShowScrollButtons(
        resultsRef.current.scrollHeight > resultsRef.current.clientHeight
      );
    }
  };

  const scrollUp = () => {
    if (resultsRef.current) {
      resultsRef.current.scrollBy({ top: -100, behavior: "smooth" });
    }
  };

  const scrollDown = () => {
    if (resultsRef.current) {
      resultsRef.current.scrollBy({ top: 100, behavior: "smooth" });
    }
  };

  useEffect(() => {
    checkScrollButtons();
    if (resultsRef.current) {
      resultsRef.current.addEventListener("scroll", checkScrollButtons);
    }
    return () => {
      if (resultsRef.current) {
        resultsRef.current.removeEventListener("scroll", checkScrollButtons);
      }
    };
  }, []);


  console.log("data ehr eta the parnet is  : " , Data);

  return (
    <div className="content">
      <div className="frame-242-3-cc">
        {showScrollButtons && (
          <>
            <button onClick={scrollUp} className="scroll-button up-button">
              <img src={images["scroll-btn-img.svg"]} alt="img" />
            </button>
            <button onClick={scrollDown} className="scroll-button down-button">
              <img src={images["scroll-btn-img.svg"]} alt="img" />
            </button>
          </>
        )}

        <div className={`results-cc ${Theme}`} ref={resultsRef}>
          <div className="frame-238-3-cc">
            <div className="frame-242-4-cc">
              <div className="results-1-cc">
                {topBarMode == 1 && Data.Segments != undefined && (
                  <TableContainer
                    Data={Data}
                    ResultType={ResultType}
                    airlineLogos={airlineLogos}
                    ffIndex={FFSelected[0]}
                  />
                )}
                {topBarMode == 1 && Data.Segments == undefined && (
                  <>
                    <TableContainer
                      Data={Data.Connections[0]}
                      ResultType={1}
                      airlineLogos={airlineLogos}
                      ffIndex={FFSelected[0]}
                    />
                    <br />
                    <TableContainer
                      Data={Data.Connections[1]}
                      ResultType={1}
                      airlineLogos={airlineLogos}
                      ffIndex={FFSelected[1]}
                    />
                  </>
                )}
                {topBarMode == 2 && (
                  <>
                    <TableContainer
                      Data={
                        Data[0] == undefined
                          ? Data.Connections[0]
                          : Data[0]
                      }
                      ResultType={1}
                      airlineLogos={airlineLogos}
                      ffIndex={FFSelected[0]}
                    />
                    <br />
                    <TableContainer
                      Data={
                        Data[1] == undefined
                          ? Data.Connections[1]
                          : Data[1]
                      }
                      ResultType={1}
                      airlineLogos={airlineLogos}
                      ffIndex={FFSelected[1]}
                    />
                  </>
                )}
                {topBarMode == 3 && (
                  <>
                    <TableContainer
                      Data={
                        Data[0] == undefined
                          ? Data.Connections[0]
                          : Data[0]
                      }
                      ResultType={1}
                      airlineLogos={airlineLogos}
                      ffIndex={FFSelected[0]}
                    />
                    <br />
                    <TableContainer
                      Data={
                        Data[1] == undefined
                          ? Data.Connections[1]
                          : Data[1]
                      }
                      ResultType={1}
                      airlineLogos={airlineLogos}
                      ffIndex={FFSelected[1]}
                    />
                    <br />
                    <TableContainer
                      Data={
                        Data[2] == undefined
                          ? Data.Connections[2]
                          : Data[2]
                      }
                      ResultType={1}
                      airlineLogos={airlineLogos}
                      ffIndex={FFSelected[2]}
                    />
                  </>
                )}
                {topBarMode == 4 && (
                  <>
                    <TableContainer
                      Data={
                        Data[0] == undefined
                          ? Data.Connections[0]
                          : Data[0]
                      }
                      ResultType={1}
                      airlineLogos={airlineLogos}
                      ffIndex={FFSelected[0]}
                    />
                    <br />
                    <TableContainer
                      Data={
                        Data[1] == undefined
                          ? Data.Connections[1]
                          : Data[1]
                      }
                      ResultType={1}
                      airlineLogos={airlineLogos}
                      ffIndex={FFSelected[1]}
                    />
                    <br />
                    <TableContainer
                      Data={
                        Data[2] == undefined
                          ? Data.Connections[2]
                          : Data[2]
                      }
                      ResultType={1}
                      airlineLogos={airlineLogos}
                      ffIndex={FFSelected[2]}
                    />
                    <br />
                    <TableContainer
                      Data={
                        Data[3] == undefined
                          ? Data.Connections[3]
                          : Data[3]
                      }
                      ResultType={1}
                      airlineLogos={airlineLogos}
                      ffIndex={FFSelected[3]}
                    />
                  </>
                )}

                {/* // get the rednering  bar's logic for round all all combined more than 2 row ..... also i tehre single rules for full journey ? */}
                {(topBarMode == 1.5 || topBarMode == 2.5) && (

                  <TableContainer
                    Data={Data}
                    topBarMode={topBarMode}
                    ResultType={3}
                    index={1}
                    airlineLogos={airlineLogos}
                    ffIndex={FFSelected[0]}
                  />

                )}
              </div>

              <Rules />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CancellationContent;
