import React, { useState } from "react";
import "./Monthpopup.css";
function MonthsPopup({ left, minimumMonth, maxMonth, onSelect , Theme}) {
  const generateDropDownMonths = () => {
    const months = [];
    let current = new Date(minimumMonth);
    while (current <= maxMonth) {
      months.push(
        current.toLocaleString("default", { month: "long", year: "numeric" })
      );
      current.setMonth(current.getMonth() + 1);
    }
    return months;
  };

  const months = generateDropDownMonths();


  return (
    <div
      className="month-popup"
      style={{
        marginTop: "35px",
        position: "absolute",
        zIndex: 10000,
        top: "0px",
        left: left,
        // width:"200px",
        borderRadius: "1px",
        background: "white",
        maxHeight: "200px",
        overflowY: "scroll",
        border: "1.2px solid",
        borderColor: "#e6e6e6",
        backgroundColor: "rgb(255, 255, 255)",
      }}
    >
      <div className="month-popup-calendar-months-3-proxima screen">
        <div className="month-popup-months">
          <div className="month-popup-frame-147 month-popup-frame">
            {months.map((month, index) => (
              <div
                key={index}
                className={`month-popup-frame month-popup-frame-1 ${Theme} ${Theme}_regular color_eerie-black font16`}
                onClick={() => onSelect(month)}
              >
                <div className={`month-popup-uary-2024 month-popup-valign-text-middle ${Theme}_regular color_eerie-black font16`}>
                  {month}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MonthsPopup;
