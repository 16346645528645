export async function fetchUserCurrentLocationData() {
  try {
    const response = await fetch("https://ipapi.co/json/");
    const data = await response.json();
    return data;
  } catch (error) {

    console.log("the main error is  : " , error);
    throw error;
  }
}
