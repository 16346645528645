
import React from 'react'
import "./index.css"
import images from '../../../Utils/importImages';
import { useNavigate } from 'react-router-dom'

export default function ErrorResultPage({ mainmessage, descriptionmessage , Theme}) {

  let navigate = useNavigate();

  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <div className="error-page-section">
      <div className="error-page-background"></div>
      <div className="error-page-content">
        <div className="error-page-icon-container">
          <img className="error-page-icon" src={images['result-error-icon.svg']} alt="Error Icon" />
          <div className="error-page-message-container">
            <div className={`error-page-main-message ${Theme}_semibold font20 color_eerie-black`}>{mainmessage}</div>
            <p className={`error-page-description ${Theme}_semibold font20 color_eerie-black`}>
              {descriptionmessage?.[0]} <br /> {descriptionmessage?.[1]}
            </p>
          </div>
          <div className="error-page-actions">
            <div className={`error-page-home-button ${Theme}`} onClick={() => navigate("/")}>
              <div className={`error-page-home-text ${Theme}_semibold font18 color_${Theme == "proxima" ? "endeavour" : "optera-galaxy"}`}>HOME PAGE</div>
            </div>
            <div className={`error-page-refresh-button ${Theme}`} onClick={handleRefresh}>
              <div className={`error-page-refresh-text ${Theme}_semibold font18 color_white`}>REFRESH</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


