import TripChooser from "./TripChooser/TripChooser"
import TripInformation from "./TripInformer/TripInformation"

function index({Data , chooser , onBarChange , selectedBar , Theme}) {

    if(chooser == true){
        return <TripChooser Data={Data} onBarChange={onBarChange} selectedBar={selectedBar} Theme={Theme}/>
    } else {
       return <TripInformation Data={Data} onBarChange={onBarChange} selectedBar={selectedBar} Theme={Theme}/>
    }
 
  
}

export default index
