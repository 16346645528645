import React, { useCallback, useEffect, useMemo, useState } from 'react'
import "./index.css";
import FilterBar from '../../Components/FilterBar';
import ResultBar3 from '../../Components/ResultBars/ResultBar3';
import { useDispatch, useSelector } from 'react-redux';
import { setbarModeData, setFiltersApplied, setInitialSettings } from '../../../../../../../Redux/Slices/Flight_Slices/ResultPage-Flights-SLICE';
import { useDebounce } from '@uidotdev/usehooks';
import MainPopup from '../../../MainPopup/MainPopup';

export default function MultiDomestic_3Row({Theme}) {

  const dispatch = useDispatch();

  const searchBarData = useSelector((state) => state.ResultPageFlightsFlight);

  let [OpenPopup , setOpenPopup] = useState(false);

  let CloseThePopup = () => {
    setOpenPopup(false);
  };

  const [FlightAffordance, setFlightAffordance] =  useState([0, 0 , 0]);
  const [ViewBy, setViewBy] = useState([0, 0 , 0]);
  const [searchText, setSearchText] = useState(["", "" , ""]);

  const debouncedSearchTerm = useDebounce(searchText, 1000);

  const handleFilterChange = (index, key, value, mode, specialIndex) => {
    dispatch(
      setFiltersApplied({
        index: index,
        key: key,
        value: value,
        mode: mode,
        specialIndex: specialIndex,
      })
    );
  };



  const changeFlightAffordance = useCallback((mode) => {
    setFlightAffordance((val) => [
      ...val.slice(0, searchBarData?.barModeSelected),
      mode,
      ...val.slice((searchBarData?.barModeSelected) + 1),
    ]);
  
  handleFilterChange(
      searchBarData?.barModeSelected,
      `AFFORD${searchBarData?.barModeSelected}`,
      mode === 0 ? "Price" : "Time",
      "SortBy"
    );
  }, [searchBarData?.barModeSelected]);

  const changeViewBy = useCallback((mode) => {
    setViewBy((val) => [
      ...val.slice(0, searchBarData?.barModeSelected),
      mode,
      ...val.slice((searchBarData?.barModeSelected) + 1),
    ]);
  }, [searchBarData?.barModeSelected]);
  
  const changesearchText = useCallback((value) => {
    setSearchText((val) => [
      ...val.slice(0, searchBarData?.barModeSelected),
      value,
      ...val.slice((searchBarData?.barModeSelected) + 1),
    ]);
  }, [searchBarData?.barModeSelected]);

  useEffect(() => {
    if (searchText[searchBarData?.barModeSelected] === "") {
      handleFilterChange(
        searchBarData?.barModeSelected,
        `QUERY${searchBarData?.barModeSelected}`,
        searchText[searchBarData?.barModeSelected],
        "SearchBy",
        1
      );
    } else {
      handleFilterChange(
        searchBarData?.barModeSelected,
        `QUERY${searchBarData?.barModeSelected}`,
        searchText[searchBarData?.barModeSelected],
        "SearchBy"
      );
    }
  }, [debouncedSearchTerm , searchBarData?.barModeSelected]);


  let ChangeTheSelectedData = (mode, item, allAirlines) => {
    let Data = { ...item, AirlinesDetails: allAirlines };
    dispatch(setbarModeData({ mode: mode, Data: Data }));
    CloseThePopup()
  };

  const airlineLogos = useMemo(
    () => searchBarData?.SearchResults?.AirlinesLogos,
    [searchBarData]
  );

  const Row1 = useMemo(
    () => searchBarData?.FilteredData?.Row1,
    [searchBarData]
  );
  const Row2 = useMemo(
    () => searchBarData?.FilteredData?.Row2,
    [searchBarData]
  );
  const Row3 = useMemo(
    () => searchBarData?.FilteredData?.Row3,
    [searchBarData]
  );

  const FiltersApplied1 = useMemo(
    () => searchBarData?.FiltersApplied[0] || [],
    [searchBarData?.FiltersApplied, searchBarData?.barModeSelected]
  );

  const FiltersApplied2 = useMemo(
    () => searchBarData?.FiltersApplied[1] || [],
    [searchBarData?.FiltersApplied, searchBarData?.barModeSelected]
  );

  const FiltersApplied3 = useMemo(
    () => searchBarData?.FiltersApplied[2] || [],
    [searchBarData?.FiltersApplied, searchBarData?.barModeSelected]
  );

  const SelectedDatas = useMemo(
    () => searchBarData?.barModeData,
    [searchBarData]
  );

  
  useMemo(() => {
    dispatch(setInitialSettings());
  }, []);


  return (
    <>
    <div className="frame-257">
  
    <FilterBar
           Theme={Theme}
          FlightAffordance={FlightAffordance[searchBarData?.barModeSelected]}
          ViewBy={ViewBy[searchBarData?.barModeSelected]}
          searchText={searchText[searchBarData?.barModeSelected]}
          onChangeFlightAffordance={changeFlightAffordance}
          onChangeViewBy={changeViewBy}
          onChangesearchText={changesearchText}
        />

    </div>
    {searchBarData?.barModeSelected === 0 &&

<>

{Row1?.length === 0 && FiltersApplied1?.length > 0 && (
            <div>To Many Filters Appplied ,, No Result Found</div>
          )}

        {Row1?.slice(0, 500).map((item, index) => {
          return (
            <ResultBar3
            Theme={Theme}
              key={index}
              index={index}
              data={item}
              airlineLogos={airlineLogos}
              ViewBy={ViewBy[searchBarData?.barModeSelected]}
              selectedData={SelectedDatas[0]}
              SelectTheData={ChangeTheSelectedData}
              mode={0}
              setopenMainPopup={setOpenPopup}
            />
          );
        })}

        </>

      }


      {searchBarData?.barModeSelected === 1 &&

<>
{Row2?.length === 0 && FiltersApplied2?.length > 0 && (
  <div>To Many Filters Appplied ,, No Result Found</div>
)}



       { Row2?.slice(0, 500).map((item, index) => {
          return (
            <ResultBar3
            Theme={Theme}
            setopenMainPopup={setOpenPopup}
              key={index}
              index={index}
              data={item}
              airlineLogos={airlineLogos}
              ViewBy={ViewBy[searchBarData?.barModeSelected]}
              selectedData={SelectedDatas[1]}
              SelectTheData={ChangeTheSelectedData}
              mode={1}
            />
          );
        })}

        </>

      }


      {searchBarData?.barModeSelected === 2 &&

      
<>
{Row3?.length === 0 && FiltersApplied3?.length > 0 && (
  <div>To Many Filters Appplied ,, No Result Found</div>
)}


        {Row3?.slice(0, 500).map((item, index) => {
          return (
            <ResultBar3
            Theme={Theme}
              key={index}
              index={index}
              data={item}
              airlineLogos={airlineLogos}
              ViewBy={ViewBy[searchBarData?.barModeSelected]}
              selectedData={SelectedDatas[2]}
              SelectTheData={ChangeTheSelectedData}
              mode={2}
              setopenMainPopup={setOpenPopup}
            />
          );
        })}
        
        </>
        }

<MainPopup
           Theme={Theme}
    Open={OpenPopup} 
    onClose={CloseThePopup}
    showSelect={true} 

    mode={searchBarData?.barModeSelected}
    onSelect={ChangeTheSelectedData}
    topBarMode={1} 
    topBarSelected={0} 
    isRound={false} 
 
    
    />
    </>
  )
}
