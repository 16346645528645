import React from "react";
import images from "../../../../../../../Utils/importImages";
import "./ResultBar2.css";
import StopsSummary from "../../../../../../Components/Popups/Hoverers/StopsSummary";
import {
  getAllAirlinesWithindex1,
  getTags,
} from "../../../../../../../Utils/Flight_results_func";
import {
  formatTime,
  getTotalDays,
  getTotalMinutes,
} from "../../../../../../../Utils/Date_Time_Calendar_Functions";
import AirlinesLogContainer from "../../../Components/AirlinesLogContainer";
import { useDispatch } from "react-redux";
import { setdataMainPopup, setopenMainPopup, setResultTypeMainPopup } from "../../../../../../../Redux/Slices/Flight_Slices/ResultPage-Flights-SLICE";

function ResultBar2({ data, airlineLogos, selectedData , SelectTheData , mode , Theme}) {

  let dispatch = useDispatch();

  let allAirlines = getAllAirlinesWithindex1(data, 1, airlineLogos);

  let hasCoupon = data?.Fare?.DiscountsAndLess?.Coupon?.Available;

  const TagsData = getTags(data, 1);

  const tagMapping = {
    TerminalChange: {
      imgSrc: images["terminal-change.svg"],
      displayText: "Terminal Change",
    },
    ChangeOfAirport: {
      imgSrc: images["terminal-change.svg"],
      displayText: "Airport Changed",
    },
    ChangeOfPlanes: {
      imgSrc: images["terminal-change.svg"],
      displayText: "Planes Changed",
    },
    RecheckInbaggage: {
      imgSrc: images["recheckin-icon.svg"],
      displayText: "Recheck-in Baggage",
    },
    SelfTransferBaggage: {
      imgSrc: images["self-transfter-icon.svg"],
      displayText: "Self Transfer Baggage",
    },
    BagSame: {
      imgSrc: images["self-transfter-icon.svg"],
      displayText: "Bags Quantity Changed",
    },
    TransitVisa: {
      imgSrc: images["transit-visa-required.svg"],
      displayText: "Transit Visa Required",
    },
    TechnicalStopage: {
      imgSrc: images["transit-visa-required.svg"],
      displayText: "Technical Stopage",
    },
  };


  return (
    // <div className="result-bar-2">
    <div className={`result-bar-2 ${selectedData.ResultId === data.ResultId ? "bordered-bar" : "non-bordered-bar"}`} onClick={() => SelectTheData(mode,data ,allAirlines)}>
      <div className="frame-17-1 frame-17-4">
        <div className="frame-232">
          <div className="frame-104">
            <div className="frame-9">
              <AirlinesLogContainer allAirlines={allAirlines} Theme={Theme}/>

              <div className="frame-174">
                <div className="frame-173-2">
                  <div className="indigo-spi proxima_medium color_eerie-black font16">
                    {allAirlines?.map((item, index) => (
                      <span key={index}>
                        {item.name}
                        {index < allAirlines?.length - 1 ? ", " : ""}
                      </span>
                    ))}
                  </div>
                </div>
                <div className="frame-4">
                  <div className="in-123-sj-123 proxima_light color_gravel font13">
                    {allAirlines?.map((item, index) => (
                      <span key={index}>
                        {item.Number}
                        {index < allAirlines?.length - 1 ? ", " : ""}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-172-1">
              <div className="frame-169">
                <div className="frame-10">
                  <img
                    className="frame-169-20"
                    src={images["light-gray-rupee.svg"]}
                    alt="Frame 169"
                  />
                  <div className="text valign-text-middle proxima_semibold color_eerie-black font22">
                    {+data.FareFamilies.FFList[0].Travellers.Adults[0].Fare + +data.FareFamilies.FFList[0].Travellers.Adults[0].Tax}
                  </div>
                </div>
                <div className="per-adult-1 per-adult-16 proxima_regular color_gravel font13">
                  per adult
                </div>
              </div>
            </div>
            <div className="frame-11">
              {
                selectedData.ResultId === data.ResultId ? 
                 <img src={images["selected-round-domestic.svg"]} alt="" /> :
              <img src={images["non-selected-round-domestic.svg"]} alt="" />
              }
            </div>
          </div>
          <div className="frame-232-1">
            <div style={{ display: "flex", gap: "12px" }}>
              <div className="frame-2-2">
                <div className="frame-20">
                  <div className="frame-1-4">
                    <div className="frame-12">
                      <div className="text-397 text-1 proxima_semibold color_eerie-black font22">
                        {data.Segments[0].Origin.DepTime.substring(11, 16)}
                      </div>
                    </div>
                    <div className="frame-13">
                      <div className="bom proxima_regular color_eerie-black font14">
                        {data.Segments[0].Origin.Airport.AirportCode},
                      </div>
                      <div className="place proxima_regular color_gravel font14">
                        {data.Segments[0].Origin.Airport.CityName}
                      </div>
                    </div>
                  </div>
                  <div className="frame-17-3 frame-17-4">
                    <div className="frame-168-2 frame-168-6">
                      <div className="frame-170-1">
                        <div className="line"></div>
                        <div className="frame-202">
                          <div className="group-206">
                            <img
                              src={images["result-bar-clock-icon.svg"]}
                              alt="Vector 50"
                            />
                          </div>
                          <div className="x28h-40m proxima_regular color_gravel font13">
                            {formatTime(
                              getTotalMinutes(
                                data.Segments[0].Origin.DepTime,

                                data.Segments[data.Segments.length - 1]
                                  .Destination.ArrTime
                              )
                            )}
                          </div>
                        </div>
                        <div className="line"></div>
                      </div>
                    </div>
                    <div className={`frame-172-8 ${data.Segments.length > 1
                              ? "tooltip-container"
                              : ""}`}>
                      <div className="frame-14">
                        <div className="frame-175-2">
                          <div className="non-stop non proxima_regular color_endeavour font14">
                            {data.Segments.length > 1
                              ? `${data.Segments.length - 1} Stops`
                              : "Non Stop"}
                          </div>
                        </div>
                   
                        {data.Segments.length > 1 && (
                        
                            <div className="frame-203-15">|</div>
                         
                        )}
                      </div>

                      <div className="frame-202-22">
                        <div className="frame-99">
                          <div>
                            <div className="tashkent-2 proxima_regular color_eerie-black font13">
                              {data.Segments.length > 0 &&
                                data?.Segments?.map((item, index) => {
                                  return index < data?.Segments.length - 1 ? (
                                    <span key={index}>
                                      {item.Destination.Airport.CityName}
                                      {index < data?.Segments.length - 2
                                        ? ", "
                                        : ""}
                                    </span>
                                  ) : (
                                    ""
                                  );
                                })}
                            </div>
                            <StopsSummary data={data} ResultType={1} Theme={Theme}/>
                          </div>
                        </div>
                        <div className="frame-1777"></div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-1-5">
                    <div className="text-398 text-1 proxima_semibold color_eerie-black font22">
                      {data.Segments[
                        data.Segments.length - 1
                      ].Destination.ArrTime.substring(11, 16)}
                    </div>

                    <div className="frame-13">
                      <div className="atq proxima_regular color_eerie-black font14">
                        {
                          data.Segments[data.Segments.length - 1].Destination
                            .Airport.AirportCode
                        }{" "}
                        ,
                      </div>
                      <div className="place-1 proxima_regular color_gravel font14">
                        {
                          data.Segments[data.Segments.length - 1].Destination
                            .Airport.CityName
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame-201-3">
                <div className="proxima_regular color_crimson font12">
                  {getTotalDays(
                    data.Segments[0].Origin.DepTime,
                    data.Segments[data.Segments.length - 1].Destination.ArrTime
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="frame-177">
          <div
            className={`${
              data.IsRefundable === true
                ? "partially-refundable proxima_medium color_eastern-blue font14"
                : "partially-refundable proxima_medium color_crimson font13"
            }`}
          >
            {data.IsRefundable === true
              ? "Partially Refundable"
              : "Non-Refundable"}
          </div>

          {hasCoupon === false && <div className="frame-203"></div>}
          {hasCoupon === true && (
            <div className="coupon-container">
              <div className="frame-230">
                <div className="frame-203-2">
                  <img src={images["coupon-icon.svg"]} alt="" />
                </div>
                <div className="frame">
                  <div className="frame-211">
                    <div className="frame-13">
                      <p className="deal-text proxima_regular color_endeavour font14">
                        {data.Fare.DiscountsAndLess.Coupon.Flat.Available ==
                        true
                          ? `Flat Discount of ${
                              data.Fare.DiscountsAndLess.Coupon.Flat
                                .Amount_in_Percentage > 0
                                ? data.Fare.DiscountsAndLess.Coupon.Flat
                                    .Amount_in_Percentage + "%"
                                : "Rs. " +
                                  data.Fare.DiscountsAndLess.Coupon.Flat.Amount
                            } Applied`
                          : `Use Code STFCODE for ${
                              data.Fare.DiscountsAndLess.Coupon.Discount
                                .Amount_in_Percentage > 0
                                ? "Rs. " +
                                  (data.Fare.BaseFare *
                                    data.Fare.DiscountsAndLess.Coupon.Discount
                                      .Amount_in_Percentage) /
                                    100
                                : "Rs. " +
                                  data.Fare.DiscountsAndLess.Coupon.Discount
                                    .Amount
                            } off Per Person `}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {TagsData[0] === true && (
          <div className="tags-container frame-174-7">
            <div className="frame-207-1">
              {TagsData[1].splice(0, 1).map((tag, index) => {
                return (
                  <React.Fragment key={index}>
                    <div className="frame-12">
                      <img
                        src={tagMapping[tag]?.imgSrc}
                        alt={tagMapping[tag]?.displayText}
                      />
                      <div className="self-transfer-baggage-6 proxima_regular color_eerie-black font13">
                        {tagMapping[tag]?.displayText}
                      </div>
                    </div>
                    {index < TagsData[1].length - 1 && (
                      <div className="frame-208">
                        <div className="line-straight">
                          |
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ResultBar2;
