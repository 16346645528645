import React, { useEffect, useState } from "react";

import "./ResultBar1.css";
import images from "../../../../../../../Utils/importImages";
import StopsSummary from "../../../../../../Components/Popups/Hoverers/StopsSummary";
import {
  getAllAirlinesWithindex1,
  getTags,
} from "../../../../../../../Utils/Flight_results_func";
import {
  formatTime,
  getTotalDays,
  getTotalMinutes,
} from "../../../../../../../Utils/Date_Time_Calendar_Functions";
import AirlinesLogContainer from "../../../Components/AirlinesLogContainer";
import MainPopup from "../../../MainPopup/MainPopup";
import { useDispatch } from "react-redux";
import { setdataMainPopup, setopenMainPopup, setResultTypeMainPopup } from "../../../../../../../Redux/Slices/Flight_Slices/ResultPage-Flights-SLICE";

// result bar without stconnect or tags

function ResultBar1({ data, airlineLogos, ViewBy , OpenPopup , OpenThePopup , Theme}) {

  let dispatch = useDispatch();

  let ResultType =
  data?.DataFormat === "DOM-OW" ||
  data?.DataFormat === "DOM-OW-NR" ||
  data?.DataFormat === "INT-OW" ||
  data?.DataFormat === "INT-OW-NR" ||
  data?.DataFormat === "INT-OW-CA"
  ? 1
  : 2;
  
  let allAirlines = getAllAirlinesWithindex1(data, ResultType, airlineLogos);
  
  let hasCoupon =
  ResultType === 1
  ? data.Fare.DiscountsAndLess.Coupon.Available
  : data.Connections[0].Fare.DiscountsAndLess.Coupon.Available ||
  data.Connections[1].Fare.DiscountsAndLess.Coupon.Available;
  
  const TagsData = getTags(data, ResultType);
  
  const tagMapping = {
    TerminalChange: {
      imgSrc: images["terminal-change.svg"],
      displayText: "Terminal Change",
    },
    ChangeOfAirport: {
      imgSrc: images["terminal-change.svg"],
      displayText: "Airport Changed",
    },
    ChangeOfPlanes: {
      imgSrc: images["terminal-change.svg"],
      displayText: "Planes Changed",
    },
    RecheckInbaggage: {
      imgSrc: images["recheckin-icon.svg"],
      displayText: "Recheck-in Baggage",
    },
    SelfTransferBaggage: {
      imgSrc: images["self-transfter-icon.svg"],
      displayText: "Self Transfer Baggage",
    },
    BagSame: {
      imgSrc: images["self-transfter-icon.svg"],
      displayText: "Bags Quantity Changed",
    },
    TransitVisa: {
      imgSrc: images["transit-visa-required.svg"],
      displayText: "Transit Visa Required",
    },
    TechnicalStopage: {
      imgSrc: images["transit-visa-required.svg"],
      displayText: "Technical Stopage",
    },
  };
  
  let setOpenPopup = (info) => {

    dispatch(setdataMainPopup(data));
    dispatch(setResultTypeMainPopup(ResultType));
    OpenThePopup();

  }
  return (

    <>
    <div className={`result-bar-1 ${Theme}`}>
      <div className="frame-201-3">
        <div className="frame-17-2 frame-17-3">
          <div className="frame-104">
            <div className="frame-104-1 frame-104-5">
              <div className="frame-103">
                <AirlinesLogContainer allAirlines={allAirlines} />

                <div className="frame-168-2 frame-168-5">
                  <div className="frame-173-2">
                    <div className={`indigo-spi ${Theme}_medium color_eerie-black font16`}>
                      {allAirlines?.map((item, index) => (
                        <span key={index}>
                          {item.name}
                          {index < allAirlines?.length - 1 ? ", " : ""}
                        </span>
                      ))}
                    </div>
                  </div>
                  <div className="frame-4">
                    <div className={`in-123-sj-123 ${Theme}_light color_gravel font13`}>
                      {allAirlines?.map((item, index) => (
                        <span key={index}>
                          {item.Number}
                          {index < allAirlines?.length - 1 ? ", " : ""}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`frame-214 ${Theme}`}>
            <div className="frame-202">
              <div className="frame-170-1 frame-170-4">
                <div className={`text-561 text-4 ${Theme == "proxima" ? "proxima_semibold" : "averta_bold"} color_eerie-black font22`}>
                  {ResultType === 1
                    ? data.Segments[0].Origin.DepTime.substring(11, 16)
                    : data.Connections[0].Segments[0].Origin.DepTime.substring(
                        11,
                        16
                      )}
                </div>
                <div className="frame-9">
                  <div className={`del ${Theme}_regular color_${Theme == "proxima" ? "eerie-black" : "light-gray-black"} font14`}>
                    {ResultType === 1
                      ? data.Segments[0].Origin.Airport.AirportCode
                      : data.Connections[0].Segments[0].Origin.Airport
                          .AirportCode}{" "}
                    ,
                  </div>
                  <div className={`place ${Theme}_regular color_${Theme == "proxima" ? "eerie-black" : "gravel"} font14`}>
                    {ResultType === 1
                      ? data.Segments[0].Origin.Airport.CityName
                      : data.Connections[0].Segments[0].Origin.Airport.CityName}
                  </div>
                </div>
              </div>

              <div className="frame-1-5">
                <div className="frame-10">
                  <div className="frame-8">
                    <div className={`line ${Theme}`}></div>
                    <div className="frame-202-1">
                      <div className="group-206">
                        <img
                          src={images["result-bar-clock-icon.svg"]}
                          alt="Vector 50"
                        />
                      </div>
                      <div className={`x28h-40m ${Theme}_regular color_${Theme == "proxima" ? "eerie-black" : "light-gray-black"} font13`}>
                        {formatTime(
                          getTotalMinutes(
                            ResultType === 1
                              ? data.Segments[0].Origin.DepTime
                              : data.Connections[0].Segments[0].Origin.DepTime,
                            ResultType === 1
                              ? data.Segments[data.Segments.length - 1]
                                  .Destination.ArrTime
                              : data.Connections[1].Segments[
                                  data.Connections[1].Segments.length - 1
                                ].Destination.ArrTime
                          )
                        )}
                      </div>
                    </div>
                    <div className={`line ${Theme}`}></div>
                  </div>
                </div>
                <div className={`frame-11 ${ResultType === 1
                          ? data.Segments.length > 1
                            ? "tooltip-container"
                            : ""
                          : data.Connections[0].Segments.length > 0 &&
                          "tooltip-container"}`}>
                  <div className="frame-11">
                    <div className="frame-175-2">
                      <div className={`address-2 address-17 ${Theme}_regular color_${Theme == "proxima" ? "endeavour" : "optera-galaxy"} font14`}>
                        {ResultType === 1
                          ? data.Segments.length > 1
                            ? `${data.Segments.length - 1} Stops`
                            : "Non Stop"
                          : data.Connections[0].Segments.length > 0 &&
                            `${
                              data.Connections[0].Segments.length +
                              data.Connections[1].Segments.length -
                              1
                            } Stops`}
                      </div>
                    </div>
                    {ResultType === 1
                      ? data.Segments.length > 1 && (
                          <div className="frame-214-1 frame-214-3">
                            <div className="frame-203"></div>
                          </div>
                        )
                      : data.Connections[0].Segments.length > 0 && (
                          <div className="frame-214-1 frame-214-3">
                            <div className="frame-203"></div>
                          </div>
                        )}
                  </div>
                  <div className="frame-202-2">
                    <div className="frame-9">
                      <div>
                        <div className={`tashkent-2 ${Theme}_regular color_${Theme == "proxima" ? "eerie-black" : "light-gray-black"} font13`}>
                          {ResultType === 1
                            ? data.Segments.length > 0 &&
                              data?.Segments?.map((item, index) => {
                                return index < data?.Segments.length - 1 ? (
                                  <span key={index}>
                                    {item.Destination.Airport.CityName}
                                    {index < data?.Segments.length - 2
                                      ? ", "
                                      : ""}
                                  </span>
                                ) : (
                                  ""
                                );
                              })
                            : data.Connections.map(
                                (connection, connectionIndex) => {
                                  return connection.Segments.map(
                                    (segment, segmentIndex) => {
                                      return segmentIndex <
                                        connection?.Segments.length - 1 ? (
                                        <span key={segmentIndex}>
                                          {segment.Destination.Airport.CityName}
                                          {segmentIndex <
                                          connection.Segments.length - 1
                                            ? ", "
                                            : ""}
                                        </span>
                                      ) : (
                                        ""
                                      );
                                    }
                                  );
                                }
                              )}
                        </div>

                        {ResultType === 1 ? (
                          data.Segments.length > 0 && (
                            <StopsSummary Theme={Theme} data={data} ResultType={ResultType} />
                          )
                        ) : (
                          <StopsSummary Theme={Theme} data={data} ResultType={ResultType} />
                        )}
                      </div>
                    </div>
                    <div className="frame-177"></div>
                  </div>
                </div>
              </div>

              <div className="frame-169">
                <div className="frame-12">
                  <div className={`text-562 text-4 ${Theme == "proxima" ? "proxima_semibold" : "averta_bold"} color_eerie-black font22`}>
                    {ResultType === 1
                      ? data.Segments[
                          data.Segments.length - 1
                        ].Destination.ArrTime.substring(11, 16)
                      : data.Connections[1].Segments[
                          data.Connections[1].Segments.length - 1
                        ].Destination.ArrTime.substring(11, 16)}
                  </div>
                </div>
                <div className="frame-9">
                  <div className={`atq ${Theme}_regular color_${Theme == "proxima" ? "eerie-black" : "light-gray-black"} font14`}>
                    {ResultType === 1
                      ? data.Segments[data.Segments.length - 1].Destination
                          .Airport.AirportCode
                      : data.Connections[1].Segments[
                          data.Connections[1].Segments.length - 1
                        ].Destination.Airport.AirportCode}{" "}
                    ,
                  </div>
                  <div className={`place-1 ${Theme}_regular color_${Theme == "proxima" ? "gravel" : "light-gray-black"} font14`}>
                    {ResultType === 1
                      ? data.Segments[data.Segments.length - 1].Destination
                          .Airport.CityName
                      : data.Connections[1].Segments[
                          data.Connections[1].Segments.length - 1
                        ].Destination.Airport.CityName}
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-202-3">
              <div className={`x1-day ${Theme}_regular color_crimson font12`}>
                {getTotalDays(
                  ResultType === 1
                    ? data.Segments[0].Origin.DepTime
                    : data.Connections[0].Segments[0].Origin.DepTime,
                  ResultType === 1
                    ? data.Segments[data.Segments.length - 1].Destination
                        .ArrTime
                    : data.Connections[1].Segments[
                        data.Connections[1].Segments.length - 1
                      ].Destination.ArrTime
                )}
              </div>
            </div>
          </div>
          <div className="frame-104-2 frame-104-5">
            <div className="frame-202-4">
              <div className="frame-103-1">
                <div className="frame-172-1">
                  <div className="frame-169-1 frame-169-20">
                    <div className="frame-1-6">
                      <img
                        className="frame-169-20"
                        src={images["light-gray-rupee.svg"]}
                        alt="Frame 169"
                      />
                      <div className={`text valign-text-middle ${Theme == "proxima" ? "proxima_semibold" : "averta_bold"} color_eerie-black font23`}>
                        {ResultType === 1
                          ? ViewBy === 0
                            ? +data.FareFamilies.FFList[0].Travellers.Adults[0]
                                .Fare + +data.FareFamilies.FFList[0].Travellers.Adults[0]
                                .Tax
                            : +data.Fare.MegaTotal
                          : ViewBy === 0
                          ? +data.Connections[0].FareFamilies.FFList[0]
                              .Travellers.Adults[0].Fare +
                            (+data.Connections[1].FareFamilies.FFList[0]
                              .Travellers.Adults[0].Fare + 
                              +data.Connections[0].FareFamilies.FFList[0].Travellers.Adults[0]
                              .Tax + +data.Connections[1].FareFamilies.FFList[0].Travellers.Adults[0]
                              .Tax)
                          : +data.Price}
                      </div>
                    </div>
                    <div className={`per-adult-16 ${Theme}_regular color_${Theme == "proxima" ? "eerie-black" : "light-gray-black"} font13`}>
                      {ViewBy === 0 ? "per adult" : "per traveller"}
                    </div>
                  </div>
                </div>
                <div className={`frame-103-2 ${Theme}`} onClick={() => {setOpenPopup(!OpenPopup)}}>
                  <div className={`book valign-text-middle ${Theme}_semibold font14`}>
                    BOOK
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <>
          {ResultType === 1 &&
            (hasCoupon === false && TagsData[0] === false ? (
              <div className="frame-171">
                <div
                  className={`${
                    data.IsRefundable === true
                      ? `partially-refundable ${Theme}_medium color_eastern-blue font14`
                      : `partially-refundable ${Theme}_medium color_crimson font13`
                  }`}
                >
                  {data.IsRefundable === true
                    ? "Partially Refundable"
                    : "Non-Refundable"}
                </div>
                <div className="frame-203-1" onClick={() => setOpenPopup(!OpenPopup)}>
                  <div className={`flight-details ${Theme}_semibold  color_${Theme == "proxima" ? "endeavour" : "optera-galaxy"} font14`}>
                    FLIGHT DETAILS
                  </div>
                </div>
              </div>
            ) : hasCoupon === true && TagsData[0] === false ? (
              <>
                <div className={`coupon-container ${Theme}`}>
                  <div className={`frame-230 ${Theme}`}>
                    <div className="frame-203-2">
                      <img src={images[Theme == "proxima" ? "coupon-icon.svg" : "golden-coupon.svg"]} alt="" />
                    </div>
                    <div className="frame">
                      <div className="frame-211">
                        <div className="frame-13">
                          <p className={`deal-text ${Theme}_regular color_${Theme == "proxima" ? "endeavour" : "burning-gold"} font14`}>
                            {data.Fare.DiscountsAndLess.Coupon.Flat.Available ==
                            true
                              ? `Flat Discount of ${
                                  data.Fare.DiscountsAndLess.Coupon.Flat
                                    .Amount_in_Percentage > 0
                                    ? data.Fare.DiscountsAndLess.Coupon.Flat
                                        .Amount_in_Percentage + "%"
                                    : "Rs. " +
                                      data.Fare.DiscountsAndLess.Coupon.Flat
                                        .Amount
                                } Applied`
                              : `Use Code STFCODE for ${
                                  data.Fare.DiscountsAndLess.Coupon.Discount
                                    .Amount_in_Percentage > 0
                                    ? "Rs. " +
                                      (data.Fare.BaseFare *
                                        data.Fare.DiscountsAndLess.Coupon
                                          .Discount.Amount_in_Percentage) /
                                        100
                                    : "Rs. " +
                                      data.Fare.DiscountsAndLess.Coupon.Discount
                                        .Amount
                                } off Per Person `}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="frame-171">
                  <div
                    className={`${
                      data.IsRefundable === true
                        ? `partially-refundable ${Theme}_medium color_eastern-blue font14`
                        : `partially-refundable ${Theme}_medium color_crimson font13`
                    }`}
                  >
                    {data.IsRefundable === true
                      ? "Partially Refundable"
                      : "Non-Refundable"}
                  </div>

                  <div className="frame-203-1" onClick={() => setOpenPopup(!OpenPopup)}>
                    <div className={`flight-details ${Theme}_semibold color_${Theme == "proxima" ? "endeavour" : "optera-galaxy"} font14`}>
                      FLIGHT DETAILS
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="frame-171-5">
                  <div
                    className={`${
                      data.IsRefundable === true
                        ? `partially-refundable ${Theme}_medium color_eastern-blue font14`
                        : `partially-refundable ${Theme}_medium color_crimson font13`
                    }`}
                  >
                    {data.IsRefundable === true
                      ? "Partially Refundable"
                      : "Non-Refundable"}
                  </div>
                  <div
                    className={`coupon-container ${Theme}`}
                    style={{ visibility: hasCoupon === true ? null : "hidden" }}
                  >
                    <div className={`frame-230 ${Theme}`}>
                      <div className="frame-203-2">
                        <img src={images[Theme == "proxima" ? "coupon-icon.svg" : "golden-coupon.svg"]} alt="" />
                      </div>
                      <div className="frame">
                        <div className="frame-211">
                          <div className="frame-13">
                            <p className={`deal-text ${Theme}_regular color_${Theme == "proxima" ? "endeavour" : "burning-gold"} font14`}>
                     
                              {hasCoupon === true &&
                                (data.Fare.DiscountsAndLess.Coupon.Flat
                                  .Available === true
                                  ? `Flat Discount of ${
                                      data.Fare.DiscountsAndLess.Coupon.Flat
                                        .Amount_in_Percentage > 0
                                        ? data.Fare.DiscountsAndLess.Coupon.Flat
                                            .Amount_in_Percentage + "%"
                                        : "Rs. " +
                                          data.Fare.DiscountsAndLess.Coupon.Flat
                                            .Amount
                                    } Applied`
                                  : `Use Code STFCODE for ${
                                      data.Fare.DiscountsAndLess.Coupon.Discount
                                        .Amount_in_Percentage > 0
                                        ? "Rs. " +
                                          (data.Fare.BaseFare *
                                            data.Fare.DiscountsAndLess.Coupon
                                              .Discount.Amount_in_Percentage) /
                                            100
                                        : "Rs. " +
                                          data.Fare.DiscountsAndLess.Coupon
                                            .Discount.Amount
                                    } off Per Person `)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={`tags-container frame-174-7 ${Theme}`}>
                  <div className="frame-207-1">
                    {TagsData[1].splice(0, 3).map((tag, index) => {
                      return (
                        <React.Fragment key={index}>
                          <div className="frame-12">
                            <img
                              src={tagMapping[tag]?.imgSrc}
                              alt={tagMapping[tag]?.displayText}
                            />
                            <div className={`self-transfer-baggage-6 ${Theme}_regular color_eerie-black font13`}>
                              {tagMapping[tag]?.displayText}
                            </div>
                          </div>
                          {index < TagsData[1].length - 1 && (
                            <div className="frame-208">
                              <div className="line-straight">
                                |
                              </div>
                            </div>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </div>
                  <div className="frame-203-5" onClick={() => setOpenPopup(!OpenPopup)}>
                    <div className={`flight-details-15 ${Theme}_semibold color_${Theme == "proxima" ? "endeavour" : "optera-galaxy"} font14`}>
                      FLIGHT DETAILS
                    </div>
                  </div>
                </div>
              </>
            ))}

          {/* // if it is isconnect then this below   */}
          {ResultType === 2 && (
            <>
              <div className="frame-171-5">
                <div
                  className={`${
                    data.Connections[0].IsRefundable === true &&
                    data.Connections[1].IsRefundable === true
                      ? `partially-refundable ${Theme}_medium color_eastern-blue font14`
                      : `partially-refundable ${Theme}_medium color_crimson font13`
                  }`}
                >
                  {data.Connections[0].IsRefundable === true &&
                  data.Connections[1].IsRefundable === true
                    ? "Partially Refundable"
                    : "Non-Refundable"}
                </div>

                <div
                  className={`coupon-container ${Theme}`}
                  style={{ visibility: hasCoupon === true ? null : "hidden" }}
                >
                  <div className={`frame-230 ${Theme}`}>
                    <div className="frame-203-2">
                      <img src={images[Theme == "proxima" ? "coupon-icon.svg" : "golden-coupon.svg"]} alt="" />
                    </div>
                    <div className="frame">
                      <div className="frame-211">
                        <div className="frame-13">
                          <p className={`deal-text ${Theme}_regular color_${Theme == "proxima" ? "endeavour" : "burning-gold"} font14`}>
                            {hasCoupon === true &&
                              (data.Connections[0].Fare.DiscountsAndLess.Coupon
                                .Flat.Available === true
                                ? `Flat Discount of ${
                                    data.Connections[0].Fare.DiscountsAndLess
                                      .Coupon.Flat.Amount_in_Percentage > 0
                                      ? data.Connections[0].Fare
                                          .DiscountsAndLess.Coupon.Flat
                                          .Amount_in_Percentage + "%"
                                      : "Rs. " +
                                        data.Connections[0].Fare
                                          .DiscountsAndLess.Coupon.Flat.Amount
                                  } Applied`
                                : `Use Code STFCODE for ${
                                    data.Connections[0].Fare.DiscountsAndLess
                                      .Coupon.Discount.Amount_in_Percentage > 0
                                      ? "Rs. " +
                                        (data.Connections[0].Fare.BaseFare *
                                          data.Connections[0].Fare
                                            .DiscountsAndLess.Coupon.Discount
                                            .Amount_in_Percentage) /
                                          100
                                      : "Rs. " +
                                        data.Connections[0].Fare
                                          .DiscountsAndLess.Coupon.Discount
                                          .Amount
                                  } off Per Person `)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={`tags-container frame-174-7 ${Theme}`}>
                <div className="frame-207-1">
                  <div className="frame-223-2">
                    <div className="frame-228">
                      <div className={`st-4 ${Theme}_semibold color_${Theme == "proxima" ? "smalt" : "burning-gold"} font14`}>
                        STCONNECT
                      </div>
                    </div>
                    <img
                      className="frame-228-1"
                      src={images[Theme == "proxima" ? "st-connect-arrow.svg" : "st-connect-arrow-gold.svg"]}
                      alt="Frame 228"
                    />
                  </div>

                  {TagsData[1].splice(0, 3).map((tag, index) => (
                    <React.Fragment key={index}>
                      <div className="frame-12">
                        <img
                          src={tagMapping[tag]?.imgSrc}
                          alt={tagMapping[tag]?.displayText}
                        />
                        <div className={`self-transfer-baggage-6 ${Theme}_regular color_eerie-black font13`}>
                          {tagMapping[tag]?.displayText}
                        </div>
                      </div>
                      {index < TagsData[1].length - 1 && (
                        <div className="frame-208">
                          <div className="line-straight">
                            |
                          </div>
                        </div>
                      )}
                    </React.Fragment>
                  ))}
                </div>
                <div className="frame-203-5" onClick={() => setOpenPopup(!OpenPopup)}>
                  <div className={`flight-details-15 ${Theme}_semibold color_${Theme == "proxima" ? "endeavour" : "optera-galaxy"} font14`}>
                    FLIGHT DETAILS
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      </div>
    </div>

    </>
  );
}

export default ResultBar1;
