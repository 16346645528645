import React, { useEffect, useState } from "react";

import images from "../../../../Utils/importImages";
import SpecialFareOptions from "./SpecialFareOptions";

import {
  formatDate,
  getDayOfTheDate,
  getFirstTwoLettersOfDate,
  getThreeLetterMonth,
  getTwoLetterYear,
} from "../../../../Utils/Date_Time_Calendar_Functions";
import From_To_Popup from "../../../Components/Popups/From_To_Popup";
import RangeCalender from "../../../Components/Popups/Calendars/RangeCalender";
import TravellersPopup from "../../../Components/Popups/TravellersPopup";
import { useDispatch, useSelector } from "react-redux";
import ErrorMessagePopup from "../../../Components/Popups/ErrorMessagePopup";
import {
  SearchBarErrorMessages,
  TravellersFareMessage,
} from "../../../../Constants/ConstantsFlights";
import {
  setExchangeSearchParas,
  setMultiRowNumber,
  setSearchSegmentsParams,
  setTheDates,
  setTravellersAndClasses,
  setTravellersErrors,
} from "../../../../Redux/Slices/Flight_Slices/HomePage-Flights-SLICE";
import { getLocalStorageJSON } from "../../../../Utils/localStorageFunc";

export default function MultiwaySearchBar({
  openPopup,
  setopenPopup,
  openPopupActual,
  setopenPopupActual,
}) {
  let searchBarData = useSelector((state) => state.HomePageSearchBarFlight);

  let dispatch = useDispatch();

  let travellersData = useSelector(
    (state) => state.HomePageSearchBarFlight.searchParamsTravellers
  );
  let classData = useSelector(
    (state) => state.HomePageSearchBarFlight.searchParamsFare
  );

  // let [selectedDate1, setSelectedDate1] = useState(new Date());
  // let [selectedDate2, setSelectedDate2] = useState(new Date("2024-06-15"));
  // let [selectedDate3, setSelectedDate3] = useState(new Date("2024-06-18"));
  // let [selectedDate4, setSelectedDate4] = useState(new Date("2024-06-22"));

  // useEffect(() => {
  //   // Check if selectedDate1 is greater than selectedDate2
  //   if (selectedDate1 > selectedDate2) {
  //     // Set selectedDate2 to be one day after selectedDate1
  //     const newDate2 = new Date(selectedDate1);
  //     newDate2.setDate(selectedDate1.getDate() + 1);
  //     setSelectedDate2(newDate2);
  //   }
  // }, [selectedDate1, selectedDate2]);

  // useEffect(() => {
  //   // Check if selectedDate2 is greater than selectedDate3
  //   if (selectedDate2 > selectedDate3) {
  //     // Set selectedDate3 to be one day after selectedDate2
  //     const newDate3 = new Date(selectedDate2);
  //     newDate3.setDate(selectedDate2.getDate() + 1);
  //     setSelectedDate3(newDate3);
  //   }
  // }, [selectedDate2, selectedDate3]);

  // useEffect(() => {
  //   // Check if selectedDate3 is greater than selectedDate4
  //   if (selectedDate3 > selectedDate4) {
  //     // Set selectedDate4 to be one day after selectedDate3
  //     const newDate4 = new Date(selectedDate3);
  //     newDate4.setDate(selectedDate3.getDate() + 1);
  //     setSelectedDate4(newDate4);
  //   }
  // }, [selectedDate3, selectedDate4]);

  let changeTheDate1 = (date) => {
    let selectedDate1 = date;
    let selectedDate2 = new Date(
      searchBarData?.searchParams?.Segments[1]?.OriginDate
    );
    let selectedDate3 = new Date(
      searchBarData?.searchParams?.Segments[2]?.OriginDate
    );
    let selectedDate4 = new Date(
      searchBarData?.searchParams?.Segments[3]?.OriginDate
    );

    let ReturnDate = new Date(
      searchBarData?.searchParams?.Segments[0]?.ReturnDate
    );

    if (ReturnDate != null) {
      if (
        selectedDate1 >
        new Date(searchBarData?.searchParams?.Segments[0]?.ReturnDate)
      ) {
        const newDate = new Date(selectedDate1);
        newDate.setDate(selectedDate1.getDate() + 1);
        ReturnDate = newDate;
      }
    }

    if (selectedDate1 > selectedDate2) {
      const newDate = new Date(selectedDate1);
      newDate.setDate(selectedDate1.getDate() + 1);
      selectedDate2 = newDate;
    }
    if (selectedDate2 > selectedDate3) {
      const newDate = new Date(selectedDate2);
      newDate.setDate(selectedDate2.getDate() + 1);
      selectedDate3 = newDate;
    }
    if (selectedDate3 > selectedDate4) {
      const newDate = new Date(selectedDate3);
      newDate.setDate(selectedDate3.getDate() + 1);
      selectedDate4 = newDate;
    }

    let Obj = [
      {
        OriginDate: formatDate(selectedDate1),
        ReturnDate: formatDate(ReturnDate),
      },
      {
        OriginDate: formatDate(selectedDate2),
        ReturnDate: null,
      },
      {
        OriginDate: formatDate(selectedDate3),
        ReturnDate: null,
      },
      {
        OriginDate: formatDate(selectedDate4),
        ReturnDate: null,
      },
    ];

    dispatch(setTheDates(Obj));
  };

  let changeTheDate2 = (date) => {
    let selectedDate1 = new Date(
      searchBarData?.searchParams?.Segments[0]?.OriginDate
    );
    let selectedDate2 = date;
    let selectedDate3 = new Date(
      searchBarData?.searchParams?.Segments[2]?.OriginDate
    );
    let selectedDate4 = new Date(
      searchBarData?.searchParams?.Segments[3]?.OriginDate
    );

    let ReturnDate = new Date(
      searchBarData?.searchParams?.Segments[0]?.ReturnDate
    );

    if (selectedDate1 > selectedDate2) {
      const newDate = new Date(selectedDate1);
      newDate.setDate(selectedDate1.getDate() + 1);
      selectedDate2 = newDate;
    }
    if (selectedDate2 > selectedDate3) {
      const newDate = new Date(selectedDate2);
      newDate.setDate(selectedDate2.getDate() + 1);
      selectedDate3 = newDate;
    }
    if (selectedDate3 > selectedDate4) {
      const newDate = new Date(selectedDate3);
      newDate.setDate(selectedDate3.getDate() + 1);
      selectedDate4 = newDate;
    }

    let Obj = [
      {
        OriginDate: formatDate(selectedDate1),
        ReturnDate: formatDate(ReturnDate),
      },
      {
        OriginDate: formatDate(selectedDate2),
        ReturnDate: null,
      },
      {
        OriginDate: formatDate(selectedDate3),
        ReturnDate: null,
      },
      {
        OriginDate: formatDate(selectedDate4),
        ReturnDate: null,
      },
    ];

    dispatch(setTheDates(Obj));
  };

  let changeTheDate3 = (date) => {
    let selectedDate1 = new Date(
      searchBarData?.searchParams?.Segments[0]?.OriginDate
    );
    let selectedDate2 = new Date(
      searchBarData?.searchParams?.Segments[1]?.OriginDate
    );
    let selectedDate3 = date;
    let selectedDate4 = new Date(
      searchBarData?.searchParams?.Segments[3]?.OriginDate
    );

    let ReturnDate = new Date(
      searchBarData?.searchParams?.Segments[0]?.ReturnDate
    );

    if (selectedDate3 > selectedDate4) {
      const newDate = new Date(selectedDate3);
      newDate.setDate(selectedDate3.getDate() + 1);
      selectedDate4 = newDate;
    }

    let Obj = [
      {
        OriginDate: formatDate(selectedDate1),
        ReturnDate: formatDate(ReturnDate),
      },
      {
        OriginDate: formatDate(selectedDate2),
        ReturnDate: null,
      },
      {
        OriginDate: formatDate(selectedDate3),
        ReturnDate: null,
      },
      {
        OriginDate: formatDate(selectedDate4),
        ReturnDate: null,
      },
    ];

    dispatch(setTheDates(Obj));
  };

  let changeTheDate4 = (date) => {
    let selectedDate1 = new Date(
      searchBarData?.searchParams?.Segments[0]?.OriginDate
    );
    let selectedDate2 = new Date(
      searchBarData?.searchParams?.Segments[1]?.OriginDate
    );
    let selectedDate3 = new Date(
      searchBarData?.searchParams?.Segments[2]?.OriginDate
    );
    let selectedDate4 = date;

    let ReturnDate = new Date(
      searchBarData?.searchParams?.Segments[0]?.ReturnDate
    );

    let Obj = [
      {
        OriginDate: formatDate(selectedDate1),
        ReturnDate: formatDate(ReturnDate),
      },
      {
        OriginDate: formatDate(selectedDate2),
        ReturnDate: null,
      },
      {
        OriginDate: formatDate(selectedDate3),
        ReturnDate: null,
      },
      {
        OriginDate: formatDate(selectedDate4),
        ReturnDate: null,
      },
    ];

    dispatch(setTheDates(Obj));
  };

  let handleClose = (item, PopupType) => {
    let StructuredAirport = {
      City: item.city_name,
      Code: item.airport_code,
      AirportName: item.airport_name,
      CountryCode: item.country_code,

      type: PopupType[0],
      PopupType: PopupType[1],

      airport: item,
    };

    dispatch(setSearchSegmentsParams(StructuredAirport));

    setopenPopup(openPopup + 1);
    setopenPopupActual(openPopup + 1);
  };

  return (
    <>
      <div className="frame-33">
        <div className="frame">
          <div className="frame-99-5">
            <div className="overlap-group-1">
              <div>
                <div
                  className="frame-28"
                  onClick={() => setopenPopup(11)}
                  style={openPopup === 11 ? { backgroundColor: "#E8F3FF" } : {}}
                >
                  <div className="frame-99-6">
                    <div
                      className="place-1 proxima_regular color_storm-dust font14"
                      style={openPopup === 11 ? { color: "#0057B7" } : {}}
                    >
                      From
                    </div>
                  </div>
                  <div className="place-2 proxima_regular color_eerie-black font24">
                    {searchBarData?.searchParams?.Segments[0]?.OriginCity}
                  </div>
                  <div className="del-indira-gandhi-airport proxima_regular color_storm-dust font16 text-overflow-elipsis-search">
                    {searchBarData?.searchParams?.Segments[0]?.OriginCode}
                    {!searchBarData?.searchParams?.Segments[0]?.OriginCode && (
                      <span style={{ visibility: "hidden" }}>dummy</span>
                    )}
                    {searchBarData?.searchParams?.Segments[0]?.OriginCode &&
                      ","}{" "}
                    {searchBarData?.searchParams?.Segments[0]?.Origin}
                  </div>
                </div>
                {searchBarData?.AirportsFromError1[0] === true && (
                  <ErrorMessagePopup
                    Theme={"proxima"}
                    style={{
                      display:"flex",
                      alignItems : "end",
                      width: "max-content",
                      zIndex: 1,
                      backgroundColor: "#FFE0DA",
                      position: "absolute",
                      top: "108%",
                      left: "10px",
                      padding: "4px 8px",
                    }}
                    addClass={searchBarData?.searchClicked}
                    message={searchBarData?.AirportsFromError1[1]}
                  />
                )}
                {openPopup === 11 && (
                  <From_To_Popup
                    Theme={"proxima"}
                    popular_cities={searchBarData?.popularAirports}
                    recent_searches={
                      getLocalStorageJSON("fts-hm-fa") === null
                        ? []
                        : getLocalStorageJSON("fts-hm-fa")
                    }
                    open={openPopup === 11}
                    position={openPopup}
                    PopupType={[0, 0]}
                    onClickClose={handleClose}
                    onClose={() => {
                      setopenPopup(0);
                      setopenPopupActual(0);
                    }}
                    placeholder={"From"}
                    left={"-1px"}
                    top={"34px"}
                    positionType={"absolute"}
                  />
                )}
              </div>

              <div>
                <div
                  className="frame-29"
                  style={openPopup === 12 ? { backgroundColor: "#E8F3FF" } : {}}
                  onClick={() => {
                    setopenPopup(12);
                    setopenPopupActual(12);
                  }}
                >
                  <div className="frame-99-7">
                    <div
                      className="to proxima_regular color_storm-dust font14"
                      style={
                        openPopup === 12 && openPopupActual === 12
                          ? { color: "#0057B7" }
                          : {}
                      }
                    >
                      To
                    </div>
                    <div className="airplane-landing-arrival-1"></div>
                  </div>
                  <div className="place-3 proxima_regular color_eerie-black font24">
                    {searchBarData?.searchParams?.Segments[0]?.DestinationCity}
                  </div>
                  <div className="atq-amritsar-airport proxima_regular color_storm-dust font16 text-overflow-elipsis-search">
                    {searchBarData?.searchParams?.Segments[0]?.DestinyCode}
                    {!searchBarData?.searchParams?.Segments[0]?.DestinyCode && (
                      <span style={{ visibility: "hidden" }}>dummy</span>
                    )}
                    {searchBarData?.searchParams?.Segments[0]?.DestinyCode &&
                      ","}{" "}
                    {searchBarData?.searchParams?.Segments[0]?.Destiny}{" "}
                  </div>
                </div>
                <img
                  className="exchange-alt"
                  src={images["exchange-alt-6.svg"]}
                  alt="exchange-alt"
                  onClick={() => dispatch(setExchangeSearchParas(0))}
                />
                {searchBarData?.AirportsToError1[0] === true && (
                  <ErrorMessagePopup
                    Theme={"proxima"}
                    style={{
                      display:"flex",
                      alignItems : "end",
                      width: "max-content",
                      zIndex: 1,
                      backgroundColor: "#FFE0DA",
                      position: "absolute",
                      top: "108%",
                      left: "300px",
                      padding: "4px 8px",
                    }}
                    addClass={searchBarData?.searchClicked}
                    message={searchBarData?.AirportsToError1[1]}
                  />
                )}
                {openPopup === 12 && openPopupActual === 12 && (
                  <From_To_Popup
                    Theme={"proxima"}
                    popular_cities={searchBarData?.popularAirports}
                    recent_searches={
                      getLocalStorageJSON("fts-hm-ta") === null
                        ? []
                        : getLocalStorageJSON("fts-hm-ta")
                    }
                    open={openPopup === 12}
                    position={openPopup}
                    PopupType={[0, 1]}
                    onClickClose={handleClose}
                    onClose={() => {
                      setopenPopup(0);
                      setopenPopupActual(0);
                    }}
                    placeholder={"To"}
                    left={"298px"}
                    top={"35px"}
                    positionType={"absolute"}
                  />
                )}
              </div>
            </div>
            <div>
              <div
                className="frame-30"
                style={openPopup === 13 ? { backgroundColor: "#E8F3FF" } : {}}
                onClick={() => {
                  setopenPopup(13);
                  setopenPopupActual(13);
                }}
              >
                <div className="frame-103-9">
                  <div
                    className="departure proxima_regular color_storm-dust font14"
                    style={openPopup === 13 ? { color: "#0057B7" } : {}}
                  >
                    Departure
                  </div>
                </div>
                <div className="frame proxima_regular color_eerie-black font24">
                  <div className="address">
                    {getFirstTwoLettersOfDate(
                      searchBarData?.searchParams?.Segments[0]?.OriginDate
                    )}{" "}
                    {getThreeLetterMonth(
                      searchBarData?.searchParams?.Segments[0]?.OriginDate
                    )}
                    `
                  </div>
                  <div className="text-9">
                    {getTwoLetterYear(
                      searchBarData?.searchParams?.Segments[0]?.OriginDate
                    )}
                  </div>
                </div>
                <div className="tuesday proxima_regular color_storm-dust font16">
                  {getDayOfTheDate(
                    searchBarData?.searchParams?.Segments[0]?.OriginDate
                  )}
                </div>
              </div>

              {openPopup === 13 && openPopupActual === 13 && (
                <RangeCalender
                  Theme={"proxima"}
                  marginTop={"0px"}
                  left={"599px"}
                  showTopButtons={false}
                  open={openPopup === 13}
                  position={openPopup}
                  onClickClose={() => {
                    setopenPopup(14);
                    setopenPopupActual(14);
                  }}
                  onClose={() => {
                    setopenPopup(0);
                    setopenPopupActual(0);
                  }}
                  minimumDate={new Date().setDate(new Date().getDate() - 1)}
                  CalenderValues={null}
                  selectedDate={
                    new Date(
                      searchBarData?.searchParams?.Segments[0]?.OriginDate
                    )
                  }
                  setSelectedDate={changeTheDate1}
                  showReturnDate={false}
                  showNumberOfDaysCount={false}
                  onClickReturn={null}
                  activeHeaderBar={1}
                  returnDateValue={null}
                  onClickDepart={null}
                  isReturnCalender={false}
                  range={[null, null]}
                />
              )}
            </div>
            <div>
              <div
                className="frame-32-2"
                onClick={() => {
                  setopenPopup(14);
                  setopenPopupActual(14);
                }}
              >
                <div
                  className="travellers-and-class proxima_regular color_storm-dust font14"
                  style={openPopup === 14 ? { color: "#0057B7" } : {}}
                >
                  Travellers and Class
                </div>
                <div className="address-1 proxima_regular color_eerie-black font24">
                  {searchBarData?.searchParamsTravellers?.Adults +
                    searchBarData?.searchParamsTravellers?.Childs +
                    searchBarData?.searchParamsTravellers?.Infrants}{" "}
                  Traveller
                </div>
                <div className="place-4 proxima_regular color_storm-dust font16">
                  {searchBarData?.searchParamsFare?.class}
                </div>

                {searchBarData?.searchParamsTravellers
                  ?.TravellersAndClassesErrorOut === true && (
                  <ErrorMessagePopup
                    Theme={"proxima"}
                    style={{
                      display:"flex",
                      alignItems : "end",
                      width: "max-content",
                      zIndex: 1,
                      backgroundColor: "#FFE0DA",
                      position: "absolute",
                      top: "108%",
                      left: "1%",
                      padding: "4px 8px",
                    }}
                    addClass={searchBarData?.searchClicked}
                    message={TravellersFareMessage(
                      searchBarData?.searchParamsFare?.type
                    )}
                  />
                )}
              </div>
              {openPopup === 14 && openPopupActual === 14 && (
                <TravellersPopup
                  Theme={"proxima"}
                  open={openPopup === 14}
                  position={openPopup}
                  onClickClose={() => {
                    setopenPopup(0);
                    setopenPopupActual(0);
                  }}
                  onClose={() => {
                    setopenPopup(0);
                    setopenPopupActual(0);
                  }}
                  travellersData={travellersData}
                  classData={classData}
                  timeOutFunc={setTravellersErrors}
                  setTravellersAndClassesFunc={setTravellersAndClasses}
                />
              )}
            </div>
          </div>
        </div>

        <div className="frame" style={{ marginTop: "-10px" }}>
          <div className="frame-99-5" style={{ borderTop: "none" }}>
            <div className="overlap-group-1">
              <div>
                <div
                  className="frame-28"
                  onClick={() => {
                    setopenPopup(15);
                    setopenPopupActual(15);
                  }}
                  style={
                    openPopup === 15 && openPopupActual === 15
                      ? { backgroundColor: "#E8F3FF" }
                      : {}
                  }
                >
                  <div className="frame-99-6">
                    <div
                      className="place-1 proxima_regular color_storm-dust font14"
                      style={
                        openPopup === 15 && openPopupActual === 15
                          ? { color: "#0057B7" }
                          : {}
                      }
                    >
                      From
                    </div>
                  </div>
                  <div className="place-2 proxima_regular color_eerie-black font24">
                    {searchBarData?.searchParams?.Segments[1]?.OriginCity}
                  </div>
                  <div className="del-indira-gandhi-airport proxima_regular color_storm-dust font16 text-overflow-elipsis-search">
                    {searchBarData?.searchParams?.Segments[1]?.OriginCode}
                    {!searchBarData?.searchParams?.Segments[1]?.OriginCode && (
                      <span style={{ visibility: "hidden" }}>dummy</span>
                    )}
                    {searchBarData?.searchParams?.Segments[1]?.OriginCode &&
                      ","}{" "}
                    {searchBarData?.searchParams?.Segments[1]?.Origin}
                  </div>
                </div>

                {searchBarData?.AirportsFromError2[0] === true && (
                  <ErrorMessagePopup
                    Theme={"proxima"}
                    style={{
                      display:"flex",
                      alignItems : "end",
                      width: "max-content",
                      zIndex: 1,
                      backgroundColor: "#FFE0DA",
                      position: "absolute",
                      top: "108%",
                      left: "10px",
                      padding: "4px 8px",
                    }}
                    addClass={searchBarData?.searchClicked}
                    message={searchBarData?.AirportsFromError2[1]}
                  />
                )}

                {openPopup === 15 && openPopupActual === 15 && (
                  <From_To_Popup
                    Theme={"proxima"}
                    popular_cities={searchBarData?.popularAirports}
                    recent_searches={
                      getLocalStorageJSON("fts-hm-fa") === null
                        ? []
                        : getLocalStorageJSON("fts-hm-fa")
                    }
                    open={openPopup === 15}
                    position={openPopup}
                    PopupType={[1, 0]}
                    onClickClose={handleClose}
                    onClose={() => {
                      setopenPopup(0);
                      setopenPopupActual(0);
                    }}
                    placeholder={"From"}
                    left={"-1px"}
                    top={"34px"}
                    positionType={"absolute"}
                  />
                )}
              </div>

              <div>
                <div
                  className="frame-29"
                  style={openPopup === 16 ? { backgroundColor: "#E8F3FF" } : {}}
                  onClick={() => {
                    setopenPopup(16);
                    setopenPopupActual(16);
                  }}
                >
                  <div className="frame-99-7">
                    <div
                      className="to proxima_regular color_storm-dust font14"
                      style={
                        openPopup === 16 && openPopupActual === 16
                          ? { color: "#0057B7" }
                          : {}
                      }
                    >
                      To
                    </div>
                    <div className="airplane-landing-arrival-1"></div>
                  </div>
                  <div className="place-3 proxima_regular color_eerie-black font24">
                    {searchBarData?.searchParams?.Segments[1]?.DestinationCity}
                  </div>
                  <div className="atq-amritsar-airport proxima_regular color_storm-dust font16 text-overflow-elipsis-search">
                    {searchBarData?.searchParams?.Segments[1]?.DestinyCode}
                    {!searchBarData?.searchParams?.Segments[1]?.DestinyCode && (
                      <span style={{ visibility: "hidden" }}>dummy</span>
                    )}
                    {searchBarData?.searchParams?.Segments[1]?.DestinyCode &&
                      ","}{" "}
                    {searchBarData?.searchParams?.Segments[1]?.Destiny}
                  </div>
                </div>
                <img
                  className="exchange-alt"
                  src={images["exchange-alt-6.svg"]}
                  alt="exchange-alt"
                  onClick={() => dispatch(setExchangeSearchParas(1))}
                />
                {searchBarData?.AirportsToError2[0] === true && (
                  <ErrorMessagePopup
                    Theme={"proxima"}
                    style={{
                      display:"flex",
                      alignItems : "end",
                      width: "max-content",
                      zIndex: 1,
                      backgroundColor: "#FFE0DA",
                      position: "absolute",
                      top: "108%",
                      left: "300px",
                      padding: "4px 8px",
                    }}
                    addClass={searchBarData?.searchClicked}
                    message={searchBarData?.AirportsToError2[1]}
                  />
                )}
                {openPopup === 16 && openPopupActual === 16 && (
                  <From_To_Popup
                    Theme={"proxima"}
                    popular_cities={searchBarData?.popularAirports}
                    recent_searches={
                      getLocalStorageJSON("fts-hm-ta") === null
                        ? []
                        : getLocalStorageJSON("fts-hm-ta")
                    }
                    open={openPopup === 16}
                    position={openPopup}
                    PopupType={[1, 1]}
                    onClickClose={handleClose}
                    onClose={() => {
                      setopenPopup(0);
                      setopenPopupActual(0);
                    }}
                    placeholder={"To"}
                    left={"298px"}
                    top={"34px"}
                    positionType={"absolute"}
                  />
                )}
              </div>
            </div>

            <div>
              <div
                className="frame-30"
                style={openPopup === 17 ? { backgroundColor: "#E8F3FF" } : {}}
                onClick={() => {
                  setopenPopup(17);
                  setopenPopupActual(17);
                }}
              >
                <div className="frame-103-9">
                  <div
                    className="departure proxima_regular color_storm-dust font14"
                    style={openPopup === 17 ? { color: "#0057B7" } : {}}
                  >
                    Departure
                  </div>
                </div>
                <div className="frame proxima_regular color_eerie-black font24">
                  <div className="address">
                    {getFirstTwoLettersOfDate(
                      searchBarData?.searchParams?.Segments[1]?.OriginDate
                    )}{" "}
                    {getThreeLetterMonth(
                      searchBarData?.searchParams?.Segments[1]?.OriginDate
                    )}
                    `
                  </div>
                  <div className="text-9">
                    {getTwoLetterYear(
                      searchBarData?.searchParams?.Segments[1]?.OriginDate
                    )}
                  </div>
                </div>
                <div className="tuesday proxima_regular color_storm-dust font16">
                  {getDayOfTheDate(
                    searchBarData?.searchParams?.Segments[1]?.OriginDate
                  )}
                </div>
              </div>

              {openPopup === 17 && openPopupActual === 17 && (
                <RangeCalender
                  Theme={"proxima"}
                  marginTop={"0px"}
                  left={"599px"}
                  showTopButtons={false}
                  open={openPopup === 17}
                  position={openPopup}
                  onClickClose={() => {
                    setopenPopup(0);
                    setopenPopupActual(0);
                  }}
                  onClose={() => {
                    setopenPopup(0);
                    setopenPopupActual(0);
                  }}
                  minimumDate={new Date(
                    searchBarData?.searchParams?.Segments[0]?.OriginDate
                  ).setDate(
                    new Date(
                      searchBarData?.searchParams?.Segments[0]?.OriginDate
                    ).getDate() - 1
                  )}
                  selectedDate={
                    new Date(
                      searchBarData?.searchParams?.Segments[1]?.OriginDate
                    )
                  }
                  CalenderValues={null}
                  setSelectedDate={changeTheDate2}
                  showReturnDate={false}
                  showNumberOfDaysCount={false}
                  onClickReturn={null}
                  activeHeaderBar={1}
                  returnDateValue={null}
                  onClickDepart={null}
                  isReturnCalender={false}
                  range={[null, null]}
                />
              )}
            </div>

            <div className="frame-102">
              {/* <a href="macbook-multi-city2.html"> */}
              {searchBarData?.multiRowNumber < 3 ? (
                <div
                  className="frame-44-5"
                  onClick={() => dispatch(setMultiRowNumber(3))}
                >
                  <img className="plus" src={images["plus.svg"]} alt="Plus" />
                  <div className="add-city">ADD&nbsp;&nbsp;CITY</div>
                </div>
              ) : (
                <div className="frame-44-5" style={{ visibility: "hidden" }}>
                  <img className="plus" src={images["plus.svg"]} alt="Plus" />
                  <div className="add-city">ADD&nbsp;&nbsp;CITY</div>
                </div>
              )}
              {/* </a> */}
            </div>
          </div>
        </div>

        {(searchBarData?.multiRowNumber === 3 ||
          searchBarData?.multiRowNumber === 4) && (
          <div className="frame" style={{ marginTop: "-10px" }}>
            <div className="frame-99-5" style={{ borderTop: "none" }}>
              <div className="overlap-group-1">
                <div>
                  <div
                    className="frame-28"
                    onClick={() => {
                      setopenPopup(18);
                      setopenPopupActual(18);
                    }}
                    style={
                      openPopup === 18 && openPopupActual === 18
                        ? { backgroundColor: "#E8F3FF" }
                        : {}
                    }
                  >
                    <div className="frame-99-6">
                      <div
                        className="place-1 proxima_regular color_storm-dust font14"
                        style={
                          openPopup === 18 && openPopupActual === 18
                            ? { color: "#0057B7" }
                            : {}
                        }
                      >
                        From
                      </div>
                    </div>
                    <div className="place-2 proxima_regular color_eerie-black font24">
                      {searchBarData?.searchParams?.Segments[2]?.OriginCity}
                    </div>
                    <div className="del-indira-gandhi-airport proxima_regular color_storm-dust font16 text-overflow-elipsis-search">
                      {searchBarData?.searchParams?.Segments[2]?.OriginCode}
                      {!searchBarData?.searchParams?.Segments[2]
                        ?.OriginCode && (
                        <span style={{ visibility: "hidden" }}>dummy</span>
                      )}
                      {searchBarData?.searchParams?.Segments[2]?.OriginCode &&
                        ","}{" "}
                      {searchBarData?.searchParams?.Segments[2]?.Origin}
                    </div>
                  </div>
                  {searchBarData?.AirportsFromError3[0] === true && (
                    <ErrorMessagePopup
                      Theme={"proxima"}
                      style={{
                        display:"flex",
                        alignItems : "end",
                        width: "max-content",
                        zIndex: 1,
                        backgroundColor: "#FFE0DA",
                        position: "absolute",
                        top: "108%",
                        left: "10px",
                        padding: "4px 8px",
                      }}
                      addClass={searchBarData?.searchClicked}
                      message={searchBarData?.AirportsFromError3[1]}
                    />
                  )}
                  {openPopup === 18 && openPopupActual === 18 && (
                    <From_To_Popup
                      Theme={"proxima"}
                      popular_cities={searchBarData?.popularAirports}
                      recent_searches={
                        getLocalStorageJSON("fts-hm-fa") === null
                          ? []
                          : getLocalStorageJSON("fts-hm-fa")
                      }
                      open={openPopup === 18}
                      PopupType={[2, 0]}
                      position={openPopup}
                      onClickClose={handleClose}
                      onClose={() => {
                        setopenPopup(0);
                        setopenPopupActual(0);
                      }}
                      placeholder={"From"}
                      left={"-1px"}
                      top={"34px"}
                      positionType={"absolute"}
                    />
                  )}
                </div>

                <div>
                  <div
                    className="frame-29"
                    style={
                      openPopup === 19 ? { backgroundColor: "#E8F3FF" } : {}
                    }
                    onClick={() => {
                      setopenPopup(19);
                      setopenPopupActual(19);
                    }}
                  >
                    <div className="frame-99-7">
                      <div
                        className="to proxima_regular color_storm-dust font14"
                        style={
                          openPopup === 19 && openPopupActual === 19
                            ? { color: "#0057B7" }
                            : {}
                        }
                      >
                        To
                      </div>
                      <div className="airplane-landing-arrival-1"></div>
                    </div>
                    <div className="place-3 proxima_regular color_eerie-black font24">
                      {
                        searchBarData?.searchParams?.Segments[2]
                          ?.DestinationCity
                      }
                    </div>
                    <div className="atq-amritsar-airport proxima_regular color_storm-dust font16 text-overflow-elipsis-search">
                      {searchBarData?.searchParams?.Segments[2]?.DestinyCode}
                      {!searchBarData?.searchParams?.Segments[2]
                        ?.DestinyCode && (
                        <span style={{ visibility: "hidden" }}>dummy</span>
                      )}
                      {searchBarData?.searchParams?.Segments[2]?.DestinyCode &&
                        ","}{" "}
                      {searchBarData?.searchParams?.Segments[2]?.Destiny}
                    </div>
                  </div>
                  <img
                    className="exchange-alt"
                    src={images["exchange-alt-6.svg"]}
                    alt="exchange-alt"
                    onClick={() => dispatch(setExchangeSearchParas(2))}
                  />
                  {searchBarData?.AirportsToError3[0] === true && (
                    <ErrorMessagePopup
                      Theme={"proxima"}
                      style={{
                        display:"flex",
                        alignItems : "end",
                        width: "max-content",
                        zIndex: 1,
                        backgroundColor: "#FFE0DA",
                        position: "absolute",
                        top: "108%",
                        left: "300px",
                        padding: "4px 8px",
                      }}
                      addClass={searchBarData?.searchClicked}
                      message={searchBarData?.AirportsToError3[1]}
                    />
                  )}
                  {openPopup === 19 && openPopupActual === 19 && (
                    <From_To_Popup
                      Theme={"proxima"}
                      popular_cities={searchBarData?.popularAirports}
                      recent_searches={
                        getLocalStorageJSON("fts-hm-ta") === null
                          ? []
                          : getLocalStorageJSON("fts-hm-ta")
                      }
                      open={openPopup === 19}
                      PopupType={[2, 1]}
                      position={openPopup}
                      onClickClose={handleClose}
                      onClose={() => {
                        setopenPopup(0);
                        setopenPopupActual(0);
                      }}
                      placeholder={"To"}
                      left={"298px"}
                      top={"34px"}
                      positionType={"absolute"}
                    />
                  )}
                </div>
              </div>
              <div>
                <div
                  className="frame-30"
                  style={openPopup === 20 ? { backgroundColor: "#E8F3FF" } : {}}
                  onClick={() => {
                    setopenPopup(20);
                    setopenPopupActual(20);
                  }}
                >
                  <div className="frame-103-9">
                    <div
                      className="departure proxima_regular color_storm-dust font14"
                      style={openPopup === 20 ? { color: "#0057B7" } : {}}
                    >
                      Departure
                    </div>
                  </div>
                  <div className="frame proxima_regular color_eerie-black font24">
                    <div className="address">
                      {getFirstTwoLettersOfDate(
                        searchBarData?.searchParams?.Segments[2]?.OriginDate
                      )}{" "}
                      {getThreeLetterMonth(
                        searchBarData?.searchParams?.Segments[2]?.OriginDate
                      )}
                      `
                    </div>
                    <div className="text-9">
                      {getTwoLetterYear(
                        searchBarData?.searchParams?.Segments[2]?.OriginDate
                      )}
                    </div>
                  </div>
                  <div className="tuesday proxima_regular color_storm-dust font16">
                    {getDayOfTheDate(
                      searchBarData?.searchParams?.Segments[2]?.OriginDate
                    )}
                  </div>
                </div>

                {openPopup === 20 && openPopupActual === 20 && (
                  <RangeCalender
                    Theme={"proxima"}
                    marginTop={"0px"}
                    left={"599px"}
                    showTopButtons={false}
                    open={openPopup === 20}
                    position={openPopup}
                    onClickClose={() => {
                      setopenPopup(0);
                      setopenPopupActual(0);
                    }}
                    onClose={() => {
                      setopenPopup(0);
                      setopenPopupActual(0);
                    }}
                    minimumDate={new Date(
                      searchBarData?.searchParams?.Segments[1]?.OriginDate
                    ).setDate(
                      new Date(
                        searchBarData?.searchParams?.Segments[1]?.OriginDate
                      ).getDate() - 1
                    )}
                    selectedDate={
                      new Date(
                        searchBarData?.searchParams?.Segments[2]?.OriginDate
                      )
                    }
                    CalenderValues={null}
                    setSelectedDate={changeTheDate3}
                    activeHeaderBar={1}
                    returnDateValue={null}
                    onClickDepart={null}
                    showReturnDate={false}
                    showNumberOfDaysCount={false}
                    onClickReturn={null}
                    isReturnCalender={false}
                    range={[null, null]}
                  />
                )}
              </div>

              <div
                className="frame-102"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                {searchBarData?.multiRowNumber < 4 && (
                  <div
                    className="frame-44-5"
                    onClick={() => dispatch(setMultiRowNumber(4))}
                  >
                    <img className="plus" src={images["plus.svg"]} alt="Plus" />
                    <div className="add-city">ADD&nbsp;&nbsp;CITY</div>
                  </div>
                )}

                {searchBarData?.multiRowNumber < 4 && (
                  <img
                    className="frame-205"
                    src={images["frame-205-3.svg"]}
                    alt="Frame 205"
                    onClick={() => dispatch(setMultiRowNumber(2))}
                  />
                )}

                {/* <div className="frame-44-6" onClick={() => dispatch(setMultiRowNumber(2))}>
                <img className="minus" src={images["rectangle-51.svg"]} alt="minus" />
                <div className="remove-city">REMOVE&nbsp;&nbsp;CITY</div>
              </div> */}
              </div>
            </div>
          </div>
        )}

        {searchBarData?.multiRowNumber === 4 && (
          <div className="frame" style={{ marginTop: "-10px" }}>
            <div className="frame-99-5" style={{ borderTop: "none" }}>
              <div className="overlap-group-1">
                <div>
                  <div
                    className="frame-28"
                    onClick={() => {
                      setopenPopup(21);
                      setopenPopupActual(21);
                    }}
                    style={
                      openPopup === 21 && openPopupActual === 21
                        ? { backgroundColor: "#E8F3FF" }
                        : {}
                    }
                  >
                    <div className="frame-99-6">
                      <div
                        className="place-1 proxima_regular color_storm-dust font14"
                        style={
                          openPopup === 21 && openPopupActual === 21
                            ? { color: "#0057B7" }
                            : {}
                        }
                      >
                        From
                      </div>
                    </div>
                    <div className="place-2 proxima_regular color_eerie-black font24">
                      {searchBarData?.searchParams?.Segments[3]?.OriginCity}
                    </div>
                    <div className="del-indira-gandhi-airport proxima_regular color_storm-dust font16 text-overflow-elipsis-search">
                      {searchBarData?.searchParams?.Segments[3]?.OriginCode}
                      {!searchBarData?.searchParams?.Segments[3]
                        ?.OriginCode && (
                        <span style={{ visibility: "hidden" }}>dummy</span>
                      )}
                      {searchBarData?.searchParams?.Segments[3]?.OriginCode &&
                        ","}{" "}
                      {searchBarData?.searchParams?.Segments[3]?.Origin}
                    </div>
                  </div>

                  {searchBarData?.AirportsFromError4[0] === true && (
                    <ErrorMessagePopup
                      Theme={"proxima"}
                      style={{
                        display:"flex",
                        alignItems : "end",
                        width: "max-content",
                        zIndex: 1,
                        backgroundColor: "#FFE0DA",
                        position: "absolute",
                        top: "108%",
                        left: "10px",
                        padding: "4px 8px",
                      }}
                      addClass={searchBarData?.searchClicked}
                      message={searchBarData?.AirportsFromError4[1]}
                    />
                  )}

                  {openPopup === 21 && openPopupActual === 21 && (
                    <From_To_Popup
                      Theme={"proxima"}
                      popular_cities={searchBarData?.popularAirports}
                      recent_searches={
                        getLocalStorageJSON("fts-hm-fa") === null
                          ? []
                          : getLocalStorageJSON("fts-hm-fa")
                      }
                      open={openPopup === 21}
                      PopupType={[3, 0]}
                      position={openPopup}
                      onClickClose={handleClose}
                      onClose={() => {
                        setopenPopup(0);
                        setopenPopupActual(0);
                      }}
                      placeholder={"From"}
                      left={"-1px"}
                      top={"34px"}
                      positionType={"absolute"}
                    />
                  )}
                </div>

                <div>
                  <div
                    className="frame-29"
                    style={
                      openPopup === 22 ? { backgroundColor: "#E8F3FF" } : {}
                    }
                    onClick={() => {
                      setopenPopup(22);
                      setopenPopupActual(22);
                    }}
                  >
                    <div className="frame-99-7">
                      <div
                        className="to proxima_regular color_storm-dust font14"
                        style={
                          openPopup === 22 && openPopupActual === 22
                            ? { color: "#0057B7" }
                            : {}
                        }
                      >
                        To
                      </div>
                      <div className="airplane-landing-arrival-1"></div>
                    </div>
                    <div className="place-3 proxima_regular color_eerie-black font24">
                      {
                        searchBarData?.searchParams?.Segments[3]
                          ?.DestinationCity
                      }
                    </div>
                    <div className="atq-amritsar-airport proxima_regular color_storm-dust font16 text-overflow-elipsis-search">
                      {searchBarData?.searchParams?.Segments[3]?.DestinyCode}
                      {!searchBarData?.searchParams?.Segments[3]
                        ?.DestinyCode && (
                        <span style={{ visibility: "hidden" }}>dummy</span>
                      )}
                      {searchBarData?.searchParams?.Segments[3]?.DestinyCode &&
                        ","}{" "}
                      {searchBarData?.searchParams?.Segments[3]?.Destiny}
                    </div>
                  </div>
                  <img
                    className="exchange-alt"
                    src={images["exchange-alt-6.svg"]}
                    alt="exchange-alt"
                    onClick={() => dispatch(setExchangeSearchParas(3))}
                  />

                  {searchBarData?.AirportsToError4[0] === true && (
                    <ErrorMessagePopup
                      Theme={"proxima"}
                      style={{
                        display:"flex",
                        alignItems : "end",
                        width: "max-content",
                        zIndex: 1,
                        backgroundColor: "#FFE0DA",
                        position: "absolute",
                        top: "108%",
                        left: "300px",
                        padding: "4px 8px",
                      }}
                      addClass={searchBarData?.searchClicked}
                      message={searchBarData?.AirportsToError4[1]}
                    />
                  )}
                  {openPopup === 22 && openPopupActual === 22 && (
                    <From_To_Popup
                      Theme={"proxima"}
                      popular_cities={searchBarData?.popularAirports}
                      recent_searches={
                        getLocalStorageJSON("fts-hm-ta") === null
                          ? []
                          : getLocalStorageJSON("fts-hm-ta")
                      }
                      open={openPopup === 22}
                      PopupType={[3, 1]}
                      position={openPopup}
                      onClickClose={handleClose}
                      onClose={() => {
                        setopenPopup(0);
                        setopenPopupActual(0);
                      }}
                      placeholder={"To"}
                      left={"298px"}
                      top={"34px"}
                      positionType={"absolute"}
                    />
                  )}
                </div>
              </div>
              <div>
                <div
                  className="frame-30"
                  style={openPopup === 23 ? { backgroundColor: "#E8F3FF" } : {}}
                  onClick={() => {
                    setopenPopup(23);
                    setopenPopupActual(23);
                  }}
                >
                  <div className="frame-103-9">
                    <div
                      className="departure proxima_regular color_storm-dust font14"
                      style={openPopup === 23 ? { color: "#0057B7" } : {}}
                    >
                      Departure
                    </div>
                  </div>
                  <div className="frame proxima_regular color_eerie-black font24">
                    <div className="address">
                      {getFirstTwoLettersOfDate(
                        searchBarData?.searchParams?.Segments[3]?.OriginDate
                      )}{" "}
                      {getThreeLetterMonth(
                        searchBarData?.searchParams?.Segments[3]?.OriginDate
                      )}
                      `
                    </div>
                    <div className="text-9">
                      {getTwoLetterYear(
                        searchBarData?.searchParams?.Segments[3]?.OriginDate
                      )}
                    </div>
                  </div>
                  <div className="tuesday proxima_regular color_storm-dust font16">
                    {getDayOfTheDate(
                      searchBarData?.searchParams?.Segments[3]?.OriginDate
                    )}
                  </div>
                </div>

                {openPopup === 23 && openPopupActual === 23 && (
                  <RangeCalender
                    Theme={"proxima"}
                    marginTop={"0px"}
                    left={"599px"}
                    showTopButtons={false}
                    open={openPopup === 23}
                    position={openPopup}
                    onClickClose={() => {
                      setopenPopup(0);
                      setopenPopupActual(0);
                    }}
                    onClose={() => {
                      setopenPopup(0);
                      setopenPopupActual(0);
                    }}
                    minimumDate={new Date(
                      searchBarData?.searchParams?.Segments[2]?.OriginDate
                    ).setDate(
                      new Date(
                        searchBarData?.searchParams?.Segments[2]?.OriginDate
                      ).getDate() - 1
                    )}
                    selectedDate={
                      new Date(
                        searchBarData?.searchParams?.Segments[3]?.OriginDate
                      )
                    }
                    CalenderValues={null}
                    returnDateValue={null}
                    isReturnCalender={false}
                    setSelectedDate={changeTheDate4}
                    showReturnDate={false}
                    showNumberOfDaysCount={false}
                    onClickReturn={null}
                    activeHeaderBar={1}
                    onClickDepart={null}
                    range={[null, null]}
                  />
                )}
              </div>
              <div
                className="frame-102"
                // style={{
                //   display: "flex",
                //   flexDirection: "row",
                //   alignItems: "center",
                //   justifyContent: "flex-start",
                // }}
              >
                <img
                  className="frame-205"
                  src={images["frame-205-3.svg"]}
                  alt="Frame 205"
                  onClick={() => dispatch(setMultiRowNumber(3))}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
