import React from "react";
import "./tripinfo.css";
import images from "../../../../../../../../../../Utils/importImages";

function TripInformation({Data,
  onBarChange,
  selectedBar , 
Theme}) {

  return (
    <div className={`tripInfo ${Theme}`}>
      <div className="frame-238-5">
        <div className="frame">
          <div className="frame-2">
            <div className={`flight-details ${Theme}_regular color_white font14`}>
              Trip {selectedBar + 1}
            </div>
          </div>
        </div>
      </div>
      <div className="frame-239">
        <div className="frame-238-6">
          <div className={`frame-2 ${Theme}_regular color_white font16`}>
            <div className="place">{Data.Connections[selectedBar].Segments[0].Origin.Airport.CityName}</div>
            <div className="frame-170-1 frame-170-4">
              <img src={images["forward-arrow-white.svg"]} />
            </div>
            <div className="place-1">
              {Data.Connections[selectedBar].Segments[
                      Data.Connections[selectedBar].Segments.length - 1
                    ].Destination.Airport.CityName}
                    </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TripInformation;
