export const fareOptions = [
    "Regular",
    "Armed Forces",
    "Student",
    "Senior Citizen"
]

export const Travellers = {
    "adults" : [1,2,3,4,5,6,7,8,9],
    "childs" : [0,1,2,3,4,5,6],
    "infants" : [0,1,2,3,4,5,6],
}

export const TravellerClasses = [
    "Economy", "Premium Economy", "Business", "First"
]

export const DaysOfWeek = 
    ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];


export const DefaultPopularCities = [
    {
        "airport_name": "Indira Gandhi International",
        "airport_code": "DEL",
        "city_name": "Delhi",
        "city_code": "DEL",
        "country_code": "IN",
        "country_name": "India",
        "active": 1,
        "has_nearest_airport": 0,
        "nearest_airport": [],
        "has_connecting_airport": 0,
        "connecting_airport": [],
        "has_via_airport": 0,
        "via_airport": []
    },
    {
        "airport_name": "Chhatrapati Shivaji International airport",
        "airport_code": "BOM",
        "city_name": "Mumbai",
        "city_code": "BOM",
        "country_code": "IN",
        "country_name": "India",
        "active": 1,
        "has_nearest_airport": 0,
        "nearest_airport": [],
        "has_connecting_airport": 0,
        "connecting_airport": [],
        "has_via_airport": 0,
        "via_airport": []
    },
    {
        "airport_name": "Netaji Subhas Chandra",
        "airport_code": "CCU",
        "city_name": "Kolkata",
        "city_code": "CCU",
        "country_code": "IN",
        "country_name": "India",
        "active": 1,
        "has_nearest_airport": 0,
        "nearest_airport": [],
        "has_connecting_airport": 0,
        "connecting_airport": [],
        "has_via_airport": 0,
        "via_airport": []
    },
    {
        "airport_name": "Sardar Vallabhbhai Patel International Airport",
        "airport_code": "AMD",
        "city_name": "Ahmedabad",
        "city_code": "AMD",
        "country_code": "IN",
        "country_name": "India",
        "active": 1,
        "has_nearest_airport": 0,
        "nearest_airport": [],
        "has_connecting_airport": 0,
        "connecting_airport": [],
        "has_via_airport": 0,
        "via_airport": []
    },
    {
        "airport_name": "Chennai",
        "airport_code": "MAA",
        "city_name": "Chennai",
        "city_code": "MAA",
        "country_code": "IN",
        "country_name": "India",
        "active": 1,
        "has_nearest_airport": 0,
        "nearest_airport": [],
        "has_connecting_airport": 0,
        "connecting_airport": [],
        "has_via_airport": 0,
        "via_airport": []
    },
    {
        "airport_name": "Amausi",
        "airport_code": "LKO",
        "city_name": "Lucknow",
        "city_code": "LKO",
        "country_code": "IN",
        "country_name": "India",
        "active": 1,
        "has_nearest_airport": 0,
        "nearest_airport": [],
        "has_connecting_airport": 0,
        "connecting_airport": [],
        "has_via_airport": 0,
        "via_airport": []
    }
]


export const SearchBarErrorMessages = {
    "From_To" : "From & To airports cannot be the same",
    "From" : "Please Select Origin Airport",
    "To" : "Please Select Destination Airport",
    "TravellersPopup" : [
        "Adults + Childs Can't be More Than 9",
        "Infants Can't be More Than Adults"
    ]
}

export const TravellersFareMessage = (type) => {
    return `Only adult passengers can avail of ${type} fares`;
}

export const FlightsResultPageErrorMain = {
    "DDNF" : "Sorry, Flights Not Found",
    "IVD" : "Invalid Date",
    "PEFD" : "INVALID DATES",
    "TE" : "TECHNICAL ERROR",
    "IC" : "INTERNET IS SLOW",
    "FNA" : "FLIGHT NOT AVAILABLE",
    "SRD" : "SERVER DOWN",
    "IVC" : "INVALID COMBINATIONS"
}

export const FlightsResultPageErrorMainDescription = {
    "DDNFD" : ["We could not find flights for this search" ,  "Please go back to make a different selection"],
    "PEFDD" : ["We could not find flights for this search for these dates" ,  "Please go back to make a different selection with Today/upcoming dates"],
    "TED" : ["We are currently experiencing a technical problem." , "Please try again after a while"],
    "ICD" : ["Your Internet connection speed is low" ,  "Please try searching with a different connection"],
    "FNAD" : ["No Flight Available for this Route" ,  "Please try another Route"],
    "SRDD" : ["Sorry , Our Servers are Down as of Now" ,  "Please try after SomeTime"],
    "IVCD" : ["The selected flight timings are not compatible. Kindly try a different time option"]
}