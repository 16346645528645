import React, { useEffect, useState } from "react";
import SearchBarMode from "./SearchBarHomePage/SearchBarMode";
import RecentSearches from "./SearchBarHomePage/RecentSearches";
import OnewaySearchBar from "./SearchBarHomePage/OnewaySearchBar";
import RoundwaySearchBar from "./SearchBarHomePage/RoundwaySearchBar";
import MultiwaySearchBar from "./SearchBarHomePage/MultiwaySearchBar";
import { useDispatch, useSelector } from "react-redux";
import SpecialFareOptions from "./SearchBarHomePage/SpecialFareOptions";
import { setSearchClicked } from "../../../Redux/Slices/Flight_Slices/HomePage-Flights-SLICE"
import "./index.css"
import { useNavigate } from "react-router-dom";
export default function HomePageSearchBar() {

  let dispatch = useDispatch();

  let searchBarMode = useSelector(
    (state) => state.HomePageSearchBarFlight.searchBarMode
  );
  let searchBarData = useSelector((state) => state.HomePageSearchBarFlight);
  let [openPopup, setopenPopup] = useState(0);

  let [openPopupActual, setopenPopupActual] = useState(0);

  let navigate = useNavigate()

  useEffect(() => {
    
    if (searchBarData?.searchClicked === true) {
      const timer = setTimeout(() => {
        dispatch(setSearchClicked());
      }, 800);

      // Cleanup function to clear the timeout if the component unmounts or the dependency changes
      return () => clearTimeout(timer);
    }
  }, [searchBarData?.searchClicked]);

  useEffect(() => {
    if (searchBarData.redirectUrl !== "") {
        navigate(`/flights/results?${searchBarData.redirectUrl}`);
    }
}, [searchBarData.redirectUrl]);
  
  return (
    <div className="search-bar">
      <div className="frame-97">
        <div className="frame-27-1">
          <div className="frame-35">

            <SearchBarMode
              openPopup={openPopup}
              setopenPopup={setopenPopup}
              openPopupActual={openPopupActual}
              setopenPopupActual={setopenPopupActual}
            />
          </div>

          {searchBarMode === 1 && (
            <OnewaySearchBar
              openPopup={openPopup}
              setopenPopup={setopenPopup}
              openPopupActual={openPopupActual}
              setopenPopupActual={setopenPopupActual}
            />
          )}

          {searchBarMode === 2 && (
            <RoundwaySearchBar
              openPopup={openPopup}
              setopenPopup={setopenPopup}
              openPopupActual={openPopupActual}
              setopenPopupActual={setopenPopupActual}
            />
          )}

          {searchBarMode === 3 && (
            <MultiwaySearchBar
              openPopup={openPopup}
              setopenPopup={setopenPopup}
              openPopupActual={openPopupActual}
              setopenPopupActual={setopenPopupActual}
            />
          )}

          {/* // provide margin top  */}

          {(searchBarMode === 1 || searchBarMode === 2) && (
            <SpecialFareOptions
              style={
                searchBarData.AirportsToError1[0] === true ||
                searchBarData.searchParamsTravellers
                  .TravellersAndClassesErrorOut === true
                  ? { marginTop: "20px" }
                  : {marginTop:"1px"}
              }
              selectedFareOption={searchBarData?.searchParamsFare?.type}
            />
          )}

          {searchBarMode === 3 && (
            <SpecialFareOptions
              style={
                searchBarData.AirportsToError2[0] === true || searchBarData.AirportsToError3[0] === true || searchBarData.AirportsToError4[0] === true
                  ? { marginTop: "20px" }
                  : {marginTop:"1px"}
              }
              selectedFareOption={searchBarData?.searchParamsFare?.type}
            />
          )}


        </div>
      </div>
      {/* <RecentSearches /> */}
      {searchBarData?.recentSearches?.length > 0 && <RecentSearches data={searchBarData?.recentSearches}/>}
    </div>
  );
}
