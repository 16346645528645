import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import OnewaySearchBar from './SearchBarResultPage/OnewaySearchBar';
import RoundwaySearchBar from './SearchBarResultPage/RoundwaySearchBar';
import MultiwaySearchBar from './SearchBarResultPage/MultiwaySearchBar';
import "./Searchbar.css"
import { resetTheErrors, setSearchClicked } from "../../../../Redux/Slices/Flight_Slices/ResultPage-Flights-SLICE"

export default function ResultPageSearchBar({loadingPorgessBar , isSticky , Theme}) {

    let dispatch = useDispatch();

    let resultPageData = useSelector((state) => state.ResultPageFlightsFlight);

    console.log("resultPageData is : " , resultPageData);
    let [openPopup, setopenPopup] = useState(0);
  
    let [openPopupActual, setopenPopupActual] = useState(0);



    // useEffect(() => {
    
    //   if (resultPageData?.searchClicked === true) {
    //     const timer = setTimeout(() => {
    //       dispatch(setSearchClicked());
    //     }, 800);
  
    //     // Cleanup function to clear the timeout if the component unmounts or the dependency changes
    //     return () => clearTimeout(timer);
    //   }
    // }, [resultPageData?.searchClicked]);

    useEffect(() => {

      if (resultPageData.searchParamsTravellers.TravellersAndClassesErrorIn === true ||
        resultPageData.searchParamsTravellers.TravellersAndClassesErrorOut === true ||
        resultPageData.DateError1[0] === true ||
        resultPageData.DateError11[0] === true ||
        resultPageData.DateError2[0] === true ||
        resultPageData.DateError3[0] === true ||
        resultPageData.DateError4[0] === true ||
        resultPageData.AirportsToError1[0] === true ||
        resultPageData.AirportsToError2[0] === true ||
        resultPageData.AirportsToError3[0] === true ||
        resultPageData.AirportsToError4[0] === true ||
        resultPageData.AirportsFromError1[0] === true ||
        resultPageData.AirportsFromError2[0] === true ||
        resultPageData.AirportsFromError3[0] === true ||
        resultPageData.AirportsFromError4[0] === true) {
        const timer = setTimeout(() => {
          dispatch(resetTheErrors());
        }, 3000);
  
        // Cleanup function to clear the timeout if the component unmounts or the dependency changes
        return () => clearTimeout(timer);
      }


    } , [resultPageData.searchParamsTravellers.TravellersAndClassesErrorIn,
      resultPageData.searchParamsTravellers.TravellersAndClassesErrorOut,

      resultPageData.DateError1,
      resultPageData.DateError11,
      resultPageData.DateError2,
      resultPageData.DateError3,
      resultPageData.DateError4,

      resultPageData.AirportsToError1,
      resultPageData.AirportsToError2,
      resultPageData.AirportsToError3,
      resultPageData.AirportsToError4,
    
      resultPageData.AirportsFromError1,
      resultPageData.AirportsFromError2,
      resultPageData.AirportsFromError3,
      resultPageData.AirportsFromError4])

  return (
    <>
         {resultPageData?.searchBarMode === 1 && (
            <OnewaySearchBar
            Theme={Theme}
            loadingPorgessBar={loadingPorgessBar}
            isSticky={isSticky}
              openPopup={openPopup}
              setopenPopup={setopenPopup}
              openPopupActual={openPopupActual}
              setopenPopupActual={setopenPopupActual}
            />
          )}

          {resultPageData?.searchBarMode === 2 && (
            <RoundwaySearchBar
            Theme={Theme}
            loadingPorgessBar={loadingPorgessBar}
            isSticky={isSticky}
            openPopup={openPopup}
            setopenPopup={setopenPopup}
            openPopupActual={openPopupActual}
            setopenPopupActual={setopenPopupActual}
            />
          )}

          {resultPageData?.searchBarMode === 3 && (
            <MultiwaySearchBar
            Theme={Theme}
            loadingPorgessBar={loadingPorgessBar}
            isSticky={isSticky}
              openPopup={openPopup}
              setopenPopup={setopenPopup}
              openPopupActual={openPopupActual}
              setopenPopupActual={setopenPopupActual}
            />
          )}
      
    
    </>
    
  )
}
