import React from "react";
import images from "../../../../../../Utils/importImages";

import "./filterbar.css";

function FilterBar({
  FlightAffordance,
  ViewBy,
  searchText,

  onChangeFlightAffordance,
  onChangeViewBy,
  onChangesearchText,

  Theme
}) {
  return (
    <>
      <div className={`filter-bar ${Theme}`}>
        <div className="frame-5">
          <div className={`search-bar-hover ${Theme}`}>
            <img
  
              src={images["search-icon-dark.svg"]}
              alt="Search"
            />
            <input
              className={`search-input ${Theme}_regular color_storm-dust font14 ${Theme}`}
              placeholder="Search by Name, Code , Number..."
              value={searchText}
              onChange={(e) => onChangesearchText(e.target.value)}
            ></input>
          </div>
          {
            Theme == "proxima" && 
          <div className="frame-259">
            <div className="frame-8">
              <div className="frame-257-1 frame-257-4">
                
                <div className={FlightAffordance === 0 ? "frame-261" : "frame-262"} onClick={() => onChangeFlightAffordance(0)}>
                  <div className="frame">
                    <img src={images[FlightAffordance === 0 ? "cheapest-svg-blue.svg" : "cheapest-svg-light.svg"]} alt="Vector" />

                    <div className={FlightAffordance === 0 ? `cheapest ${Theme}_semibold color_gravel font15` : `fastest ${Theme}_semibold color_eerie-black font15`}>
                      CHEAPEST
                    </div>
                  </div>
                </div>
                <div className={FlightAffordance === 1 ? "frame-261" : "frame-262"} onClick={() => onChangeFlightAffordance(1)}>
                  <div className="frame">
                    {/* <img  src={images["fastest-svg-light.svg"]} alt="Vector" /> */}
                    <img src={images[FlightAffordance === 1 ? "fastest-svg-blue.svg" : "fastest-svg-light.svg"]} alt="Vector" />
                    <div className={FlightAffordance === 1 ? `cheapest ${Theme}_semibold color_gravel font15` : `fastest ${Theme}_semibold color_eerie-black font15`}>
                      FASTEST
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          }
          <div className="frame-258">
            <div className="frame-257-2 frame-257-4-4">
              <div className="frame-103-5">
                <div className="frame-233-1">
                  <div className={`sort-price-by ${Theme}_regular color_eerie-black font16`}>
                    View Price By :
                  </div>
                </div>
              </div>
              <div className="frame-257-4-4-4">
                <div className={`${ViewBy === 0 ? "frame-258-1" : "frame-262-1"} ${Theme}`} onClick={() => onChangeViewBy(0)}>
                  <div className="frame">
                    <div className={ViewBy === 0 ? `${Theme}_semibold color_${Theme == "proxima" ? "endeavour" : "optera-galaxy"} font15` :`${Theme}_semibold color_storm-dust font15`}>
                      Per Adult
                    </div>
                  </div>
                </div>
                <div className={`${ViewBy === 1 ? "frame-258-1" : "frame-262-1"} ${Theme}`}  onClick={() => onChangeViewBy(1)}>
                  <div className="group-233">
                  <div className={ViewBy === 1 ? `${Theme}_semibold color_${Theme == "proxima" ? "endeavour" : "optera-galaxy"} font14` : `${Theme}_semibold color_storm-dust font14`}>
                      All Travellers
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
   
    </>
  );
}

export default FilterBar;
