import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import {store} from "../src/Redux/MainStore/Store"
import App from './App';

import "../src/Assets/Styles/Globals.css";
import "../src/Assets/Styles/StyleGuide.css";

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <Provider store={store}>
      <App />
    </Provider>
);

