import React, { useState } from "react";
import "./fligthdetails.css";
import AirlinesLogContainer from "../../../../Components/AirlinesLogContainer";
import images from "../../../../../../../../Utils/importImages";
import { getAllAirlinesWithindex1 } from "../../../../../../../../Utils/Flight_results_func";
import {
  formatDateResultBar,
  formatTime,
  getTotalMinutes,
} from "../../../../../../../../Utils/Date_Time_Calendar_Functions";

function FligthDetails({
  data,
  airlineLogos,
  ResultType,
  Tags,
  Theme
}) {

  let [showBagDetails, setshowBagDetails] = useState(false);

  return (
    <div className="flight-detail-content">
      <div className="flight-content">
        <div className="frame-242-1">
          <div className="frame-238">
            <div className="frame-238-1">
              <div className="frame-104">
                <div className="frame-103">
                  <AirlinesLogContainer
                    allAirlines={[
                      {
                        name: data.Airline.AirlineName,
                        code: data.Airline.AirlineCode,
                        logo: airlineLogos[data.Airline.AirlineCode],
                        Number: data.Airline.FlightNumber,
                      },
                    ]}
                  />
                  <div className="frame-168">
                    <div className="frame-245">
                      <div className="frame">
                        <div className={`air-india ${Theme}_medium color_eerie-black font16`}>
                          {data.Airline.AirlineName}
                        </div>
                      </div>
                      <div className="frame-1">
                        <div className={`ai-123 ${Theme}_light color_eerie-black font13`}>
                          {data.Airline.AirlineCode} {data.Airline.FlightNumber}
                        </div>
                        <div className="frame-245-1">
                          <div className="frame-176"></div>
                          <div className="frame">
                            <div className={`airbus-a321 ${Theme}_regular color_eerie-black font13`}>
                              {data.ItenaryDetails.Craft}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-174-1">
                        <div className={`operated-by-air-canada-4 ${Theme}_regular color_eerie-black font13`}>
                          {(data.Airline.AirlineCode ==
                            data.Airline.OperatingCarrier) ==
                          true
                            ? "Operated By " + data.Airline.AirlineName
                            : ""}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame-237">
                <div className="frame-242-2">
                  <div className="frame-2-2 frame-2-10">
                    <div className="frame-2-2 frame-2-10">
                      <div className={`text-311 text ${Theme}_semibold color_eerie-black font20`}>
                        {data.Origin.DepTime.substring(11, 16)}
                      </div>
                    </div>
                    <div className={`tue-30-jan-2024-8 valign-text-middle ${Theme}_regular color_light-gray-black font13`}>
                      {formatDateResultBar(data.Origin.DepTime)}
                    </div>
                  </div>
                  <div className={`frame-2-3 frame-2-10 ${Theme}_regular color_gravel font15`}>
                    <div className="del-4 color_deep-ocean">
                      {data.Origin.Airport.AirportCode},
                    </div>
                    <div className="new-delhi-india-4">
                      {data.Origin.Airport.CityName},{" "}
                      {data.Origin.Airport.CountryName}
                    </div>
                  </div>
                  <div className={`indira-gandhi-international-terminal-2-8 ${Theme}_regular color_gravel font14`}>
                    {data.Origin.Airport.AirportName}
                  </div>

                  {data.Origin.Airport.Terminal !== "" &&
                    data.Origin.Airport.Terminal !== null && (
                      <div className={`indira-gandhi-international-terminal-2-8 ${Theme}_regular color_gravel font14`}>
                        {"Terminal " + data.Origin.Airport.Terminal}
                      </div>
                    )}
                </div>
                <div className="frame-237-1">
                  <div className="frame-170">
                    <div className="frame-202">
                      <div className="line-through"></div>

                      <img
                        src={images["clock-sign-black.svg"]}
                        alt="Rectangle 72"
                      />
                      <div className={`x2h-40m ${Theme}_regular color_eerie-black font13`}>
                        {formatTime(
                          getTotalMinutes(
                            data.Origin.DepTime,
                            data.Destination.ArrTime
                          )
                        )}
                      </div>
                      <div className="line-through"></div>
                    </div>
                  </div>
                </div>
                <div className="frame-243">
                  <div className="frame-2-2 frame-2-10">
                    <div className="frame-2-2 frame-2-10">
                      <div className={`text-312 text ${Theme}_semibold color_eerie-black font20`}>
                        {data.Destination.ArrTime.substring(11, 16)}
                      </div>
                    </div>
                    <div className={`tue-30-jan-2024-8 valign-text-middle ${Theme}_regular color_light-gray-black font13`}>
                      {formatDateResultBar(data.Destination.ArrTime)}
                    </div>
                  </div>
                  <div className={`frame-2-3 frame-2-10 ${Theme}_regular color_gravel font15`}>
                    <div className="tvr-4 color_deep-ocean">
                      {data.Destination.Airport.AirportCode},
                    </div>
                    <div className="thiruvanathapuram-india-4">
                      {data.Destination.Airport.CityName},{" "}
                      {data.Destination.Airport.CountryName}
                    </div>
                  </div>
                  <div className={`indira-gandhi-international-terminal-2-8 ${Theme}_regular color_gravel font14`}>
                    {data.Destination.Airport.AirportName}
                  </div>
                  {data.Destination.Airport.Terminal !== "" &&
                    data.Destination.Airport.Terminal !== null && (
                      <div
                        className={`indira-gandhi-international-terminal-2-8 ${Theme}_regular color_gravel font14`}
                        style={{ textAlign: "end" }}
                      >
                        {"Terminal " + data.Destination.Airport.Terminal}
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="frame-2-4 frame-2-10">
        <div className={`frame-248 ${Theme}`}>
          <div className={`frame-249 ${Theme}_semibold color_eerie-black font12`}>
            {data.Baggage.Childs != undefined ||
            data.Baggage.Infrants != undefined ? (
              <div
                className="frame-249-1"
                onClick={() => setshowBagDetails(!showBagDetails)}
              >
                <img src={images[Theme == "proxima" ? "adult-bags.svg" : "adult-bags-gold.svg"]} alt="Vector" />
                <div className="adult-4">ADULT</div>
                <img
                  src={images["adult-down-arrow.svg"]}
                  style={
                    showBagDetails == true
                      ? { transform: "rotate(180deg)", cursor: "pointer" }
                      : { cursor: "pointer" }
                  }
                  alt="Vector"
                />
              </div>
            ) : (
              <div className="frame-249-1">
                <img src={images[Theme == "proxima" ? "adult-bags.svg" : "adult-bags-gold.svg"]} alt="Vector" />
                <div className="adult-4">ADULT</div>

                <img
                  style={{ visibility: "hidden" }}
                  src={images["adult-down-arrow.svg"]}
                  alt="Vector"
                />
              </div>
            )}
            <div className="frame-246-3">
              <div className="frame-2">
                <div className="frame-24">
                  <div className={`check-in-baggage-4 ${Theme}_semibold font12 color_deep-ocean`}>CHECK-IN BAGGAGE :</div>
                </div>
                <div className={`frame-2-3 frame-2-10 ${Theme}_regular color_deep-ocean font13`}>
                  <div className="address-9">
                    {data.Baggage.Adults[0].CheckinBag}
                  </div>
                  {/* <div className="x2-pcs-x-23-kg-8">(2 pcs x 23 kg)</div> */}
                </div>
              </div>
              <div className="frame-2">
                <div className="frame-24">
                  <div className="cabin-baggage-4">CABIN BAGGAGE :</div>
                </div>
                <div className={`frame-2-3 frame-2-10 ${Theme}_regular color_deep-ocean font13`}>
                  <div className="address-9">
                    {data.Baggage.Adults[0].CabinBaggage}
                  </div>
                  {/* <div className="x2-pcs-x-23-kg-8">(2 pcs x 23 kg)</div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showBagDetails == true && (
        <div className="frame-2-4 frame-2-10">
          {data.Baggage.Childs !== undefined && (
            <div className="frame-248-8">
              <div className={`frame-249 ${Theme}_semibold color_eerie-black font12`}>
                <div className="frame-249-1">
                  <img
                    src={images[Theme == "proxima" ? "adult-bags.svg" : "adult-bags-gold.svg"]}
                    alt="Vector"
                    style={{ visibility: "hidden" }}
                  />
                  <div className="adult-4">CHILD</div>
                  <img
                    src={images["adult-down-arrow.svg"]}
                    alt="Vector"
                    style={{ visibility: "hidden" }}
                  />
                </div>
                <div className="frame-246-3">
                  <div className="frame-2">
                    <div className="frame-24">
                      <div className={`check-in-baggage-4 ${Theme}_semibold font12 color_deep-ocean`}>
                        CHECK-IN BAGGAGE :
                      </div>
                    </div>
                    <div className={`frame-2-3 frame-2-10 ${Theme}_regular color_deep-ocean font13`}>
                      <div className="address-9">
                        {data.Baggage.Childs[0].CheckinBag}
                      </div>
                      {/* <div className="x2-pcs-x-23-kg-8">(2 pcs x 23 kg)</div> */}
                    </div>
                  </div>
                  <div className="frame-2">
                    <div className="frame-24">
                      <div className="cabin-baggage-4">CABIN BAGGAGE :</div>
                    </div>
                    <div className={`frame-2-3 frame-2-10 ${Theme}_regular color_deep-ocean font13`}>
                      <div className="address-9">
                        {data.Baggage.Childs[0].CabinBaggage}
                      </div>
                      {/* <div className="x2-pcs-x-23-kg-8">(2 pcs x 23 kg)</div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {data.Baggage.Infrants !== undefined && (
            <div className="frame-248-8">
              <div className={`frame-249 ${Theme}_semibold color_eerie-black font12`}>
                <div className="frame-249-1">
                  <img
                    src={images[Theme == "proxima" ? "adult-bags.svg" : "adult-bags-gold.svg"]}
                    alt="Vector"
                    style={{ visibility: "hidden" }}
                  />
                  <div className="adult-4">INFANT</div>
                  <img
                    src={images["adult-down-arrow.svg"]}
                    alt="Vector"
                    style={{ visibility: "hidden" }}
                  />
                </div>
                <div className="frame-246-3">
                  <div className="frame-2">
                    <div className="frame-24">
                      <div className={`check-in-baggage-4 ${Theme}_semibold font12 color_deep-ocean`}>
                        CHECK-IN BAGGAGE :
                      </div>
                    </div>
                    <div className={`frame-2-3 frame-2-10 ${Theme}_regular color_deep-ocean font13`}>
                      <div className="address-9">
                        {data.Baggage.Infrants[0].CheckinBag}
                      </div>
                      {/* <div className="x2-pcs-x-23-kg-8">(2 pcs x 23 kg)</div> */}
                    </div>
                  </div>
                  <div className="frame-2">
                    <div className="frame-24">
                      <div className={`cabin-baggage-4 ${Theme}_semibold font12 color_deep-ocean`}>CABIN BAGGAGE :</div>
                    </div>
                    <div className={`frame-2-3 frame-2-10 ${Theme}_regular color_deep-ocean font13`}>
                      <div className="address-9">
                        {data.Baggage.Infrants[0].CabinBaggage}
                      </div>
                      {/* <div className="x2-pcs-x-23-kg-8">(2 pcs x 23 kg)</div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

  {Tags}
    </div>
  );
}

export default FligthDetails;
