import React, { useEffect } from "react";
import images from "../../../../../Utils/importImages";
import { fareOptions } from "../../../../../Constants/ConstantsFlights"
import { useDispatch } from "react-redux";
import { setResultView, setTheFareType, setbarModeSelected } from "../../../../../Redux/Slices/Flight_Slices/ResultPage-Flights-SLICE"
import ResultToggler from "./ResultToggler";

export default function SpecialFareOptions({ selectedOption, showTheToggler , ResultView , Theme}) {
  // create options geenrator
  // console.log("showTheToggler is  :  ", showTheToggler);

  let dispatch = useDispatch();

  return (
    <div className="result-page-flights-frame-444">
      
      <div className="result-page-flights-frame-205" style={Theme == "averta" ? {visibility : "hidden"} : {}}>
        <div className="result-page-flights-fare-type proxima_light color_white font12">
          FARE TYPE
        </div>
        <div className="result-page-flights-frame-47">
          <div className="result-page-flights-frame-205-1">
            {fareOptions.map((item, index) => {
              // console.log("item : " , item , " , selectediption : " , selectedOption);
              return (
                <div
                  onClick={() => dispatch(setTheFareType(item))}
                  key={index}
                  className={
                    item === selectedOption
                      ? "result-page-flights-frame-42"
                      : "result-page-flights-frame-4-1"
                  }
                >
                  <div className="result-page-flights-frame-103-7">
                    <div className="result-page-flights-regular proxima_regular color_white font14">
                      {item}
                    </div>
                    {item === selectedOption && (
                      <img src={images["check-fareOption.svg"]} alt="check" />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      
      {/* ResultView */}
     <ResultToggler showTheToggler={showTheToggler} ResultView={ResultView} Theme={Theme}/>
    </div>
  );
}
