import React, { useState } from "react";

import "./TopBar3.css";
import images from "../../../../../../../Utils/importImages";
import { formatTime, getTotalMinutes } from "../../../../../../../Utils/Date_Time_Calendar_Functions";
function TopBar3({setselectedSection , selectedSection ,isTrip ,data , ResultType , settopBarSelectedd , Theme}) {

  return (
    <div className={`topbar-wrapper ${Theme}`}>
      <div className="topBar3 frame-243-6">
        <div className="frame-237-3">

          <div
            className={`frame-179 ${
              selectedSection == 0 ? `bg-dark-purple ${Theme}` : "bg-white"
            }`}
            onClick={() => {setselectedSection(0); settopBarSelectedd(0)}}
          >
            <div className="frame-238-5">
              <div className="frame">
                <div className="frame-2">
                  <div
                    className={`flight-details ${
                      selectedSection == 0
                        ? `${Theme}_regular color_white font11`
                        : `${Theme}_regular color_black font11`
                    }`}
                  >
                  Trip 1

      
                  </div>
                </div>
              </div>
              <div className="frame-202">
                <img
                  src={
                    images[
                      selectedSection == 0
                        ? "clock-sign-white.svg"
                        : "clock-sign-black.svg"
                    ]
                  }
                  alt="Rectangle 72"
                />
                <div
                  className={`x2h-40m-1 ${
                    selectedSection == 0
                      ? `${Theme}_regular color_white font15`
                      : `${Theme}_regular color_eerie-black font15`
                  }`}
                >
                   {formatTime(
                      getTotalMinutes(
                        ResultType == 2 ? data.Connections[0].Segments[0].Origin.DepTime : data[0].Segments[0].Origin.DepTime,
                        ResultType == 2 ? data.Connections[0].Segments[data.Connections[0].Segments.length - 1].Origin.DepTime : data[0].Segments[data[0].Segments.length - 1].Destination.ArrTime,
                      )
                    )}
                </div>
              </div>
            </div>
            <div className="frame-239">
              <div className="frame-238-6">
       
                <div
                  className={`frame-2 ${
                    selectedSection == 0
                      ? `${Theme}_regular color_white font16`
                      : `${Theme}_regular color_eerie-black font16`
                  }`}
                >
                  <div className="place">{ResultType == 2 ? data.Connections[0].Segments[0].Origin.Airport.CityName : data[0].Segments[0].Origin.Airport.CityName}</div>
                  <div className="frame-170-1 frame-170-4">
                    <div
                      className={
                        selectedSection == 0
                          ? "line-between-white"
                          : "line-between-eerie-black"
                      }
                    ></div>
                    <div className="frame-202">
                      <div
                        className={
                          selectedSection == 0
                            ? `address ${Theme}_regular color_white font12`
                            : "address ${Theme}_regular color_eerie-black font12"
                        }
                      >
                       {ResultType == 2 ? (data.Connections[0].Segments.length === 1 ? "Non Stop" : data.Connections[0].Segments.length - 1 + " Stops") : (data[0].Segments.length === 1 ? "Non Stop" : data[0].Segments.length - 1 + " Stops")}
                      </div>
                    </div>
                    <div
                      className={
                        selectedSection == 0
                          ? "line-between-white"
                          : "line-between-eerie-black"
                      }
                    ></div>
                  </div>
                  <div className="place-1">{ResultType == 2 ? data.Connections[0].Segments[data.Connections[0].Segments.length - 1].Destination.Airport.CityName : data[0].Segments[data[0].Segments.length - 1].Destination.Airport.CityName}</div>
                </div>

              </div>
            </div>
          </div>

          <div
            className={`frame-179 ${
              selectedSection == 1 ? `bg-dark-purple ${Theme}` : "bg-white"
            }`}
            onClick={() => {setselectedSection(1); settopBarSelectedd(1)}}
          >
            <div className="frame-238-5">
              <div className="frame">
                <div className="frame-2">
                  <div
                    className={`flight-details ${
                      selectedSection == 1
                        ? `${Theme}_regular color_white font11`
                        : `${Theme}_regular color_black font11`
                    }`}
                  >
                   Trip 2

                  </div>
                </div>
              </div>
              <div className="frame-202">
                <img
                  src={
                    images[
                      selectedSection == 1
                        ? "clock-sign-white.svg"
                        : "clock-sign-black.svg"
                    ]
                  }
                  alt="Rectangle 72"
                />
                <div
                  className={`x2h-40m-1 ${
                    selectedSection == 1
                      ? `${Theme}_regular color_white font15`
                      : `${Theme}_regular color_eerie-black font15`
                  }`}
                >
          {formatTime(
                      getTotalMinutes(
                        ResultType == 2 ? data.Connections[1].Segments[0].Origin.DepTime : data[1].Segments[0].Origin.DepTime,
                        ResultType == 2 ?  data.Connections[1].Segments[data.Connections[1].Segments.length - 1].Destination.ArrTime : data[1].Segments[data[1].Segments.length - 1].Destination.ArrTime,
                      )
                    )}
                </div>
              </div>
            </div>
            <div className="frame-239">
              <div className="frame-238-6">
       
                <div
                  className={`frame-2 ${
                    selectedSection == 1
                      ? `${Theme}_regular color_white font16`
                      : `${Theme}_regular color_eerie-black font16`
                  }`}
                >
                  <div className="place">{ResultType == 2 ? data.Connections[1].Segments[0].Origin.Airport.CityName : data[1].Segments[0].Origin.Airport.CityName}</div>
                  <div className="frame-170-1 frame-170-4">
                    <div
                      className={
                        selectedSection == 1
                          ? "line-between-white"
                          : "line-between-eerie-black"
                      }
                    ></div>
                    <div className="frame-202">
                      <div
                        className={
                          selectedSection == 1
                            ? `address ${Theme}_regular color_white font12`
                            : "address ${Theme}_regular color_eerie-black font12"
                        }
                      >
                       {ResultType == 2 ? (data.Connections[1].Segments.length === 1 ? "Non Stop" : data.Connections[1].Segments.length - 1 + " Stops") : (data[1].Segments.length === 1 ? "Non Stop" : data[1].Segments.length - 1 + " Stops") }
                       </div>
                    </div>
                    <div
                      className={
                        selectedSection == 1
                          ? "line-between-white"
                          : "line-between-eerie-black"
                      }
                    ></div>
                  </div>
                  <div className="place-1">{ResultType == 2 ? data.Connections[1].Segments[data.Connections[1].Segments.length - 1].Destination.Airport.CityName : data[1].Segments[data[1].Segments.length - 1].Destination.Airport.CityName}</div>
                </div>

              </div>
            </div>
          </div>

          <div
            className={`frame-179 ${
              selectedSection == 2 ? `bg-dark-purple ${Theme}` : "bg-white"
            }`}
            onClick={() => {setselectedSection(2); settopBarSelectedd(2)}}
          >
            <div className="frame-238-5">
              <div className="frame">
                <div className="frame-2">
                  <div
                    className={`flight-details ${
                      selectedSection == 2
                        ? `${Theme}_regular color_white font11`
                        : `${Theme}_regular color_black font11`
                    }`}
                  >
                       Trip 3

                  </div>
                </div>
              </div>
              <div className="frame-202">
                <img
                  src={
                    images[
                      selectedSection == 2
                        ? "clock-sign-white.svg"
                        : "clock-sign-black.svg"
                    ]
                  }
                  alt="Rectangle 72"
                />
                <div
                  className={`x2h-40m-1 ${
                    selectedSection == 2
                      ? `${Theme}_regular color_white font15`
                      : `${Theme}_regular color_eerie-black font15`
                  }`}
                >
             {formatTime(
                      getTotalMinutes(

                        ResultType == 2 ? data.Connections[2].Segments[0].Origin.DepTime : data[2].Segments[0].Origin.DepTime,
                        ResultType == 2 ?  data.Connections[2].Segments[data.Connections[2].Segments.length - 1].Destination.ArrTime : data[2].Segments[data[2].Segments.length - 1].Destination.ArrTime,

                      )
                    )}
                </div>
              </div>
            </div>
            <div className="frame-239">
              <div className="frame-238-6">
       
                <div
                  className={`frame-2 ${
                    selectedSection == 2
                      ? `${Theme}_regular color_white font16`
                      : `${Theme}_regular color_eerie-black font16`
                  }`}
                >
                  <div className="place">{ResultType == 2 ? data.Connections[2].Segments[0].Origin.Airport.CityName : data[2].Segments[0].Origin.Airport.CityName}</div>
                  <div className="frame-170-1 frame-170-4">
                    <div
                      className={
                        selectedSection == 2
                          ? "line-between-white"
                          : "line-between-eerie-black"
                      }
                    ></div>
                    <div className="frame-202">
                      <div
                        className={
                          selectedSection == 2
                            ? `address ${Theme}_regular color_white font12`
                            : "address ${Theme}_regular color_eerie-black font12"
                        }
                      >
                       {ResultType == 2 ? (data.Connections[2].Segments.length === 1 ? "Non Stop" : data.Connections[2].Segments.length - 1 + " Stops") : (data[2].Segments.length === 1 ? "Non Stop" : data[2].Segments.length - 1 + " Stops") }
                       </div>
                    </div>
                    <div
                      className={
                        selectedSection == 2
                          ? "line-between-white"
                          : "line-between-eerie-black"
                      }
                    ></div>
                  </div>
                  <div className="place-1">{ResultType == 2 ? data.Connections[2].Segments[data.Connections[2].Segments.length - 1].Destination.Airport.CityName : data[2].Segments[data[2].Segments.length - 1].Destination.Airport.CityName}</div>
                </div>

              </div>
            </div>
          </div>

          <div
            className={`frame-176-1 ${
              selectedSection == 3 ? `bg-dark-purple ${Theme}` : "bg-white"
            }`}
            onClick={() => setselectedSection(3)}
          >
            <div className="frame-170-2 frame-170-4">
              <div
                className={`view ${
                  selectedSection == 3
                    ? `${Theme}_regular color_white font16`
                    : `${Theme}_regular color_eerie-black font16`
                }`}
              >
                View Cancellation Rules
              </div>
            </div>
          </div>

          <div
            className={`frame-177 ${
              selectedSection == 4 ? `bg-dark-purple ${Theme}` : "bg-white"
            }`}
            onClick={() => setselectedSection(4)}
          >
            <div className="frame-170-3 frame-170-4">
              <div
                className={`view ${
                  selectedSection == 4
                    ? `${Theme}_regular color_white font16`
                    : `${Theme}_regular color_eerie-black font16`
                }`}
              >
                View Rescheduling Rules
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="topBar3-1">
        <div className="frame-237-33">
          <div style={{minWidth:"287px"}}>
            {selectedSection == 0 && <div className="down-arrow"></div> }
          </div>
          <div style={{minWidth:"287px"}}>
            {selectedSection == 1 && <div className="down-arrow"></div> }
          </div>
          <div style={{minWidth:"287px"}}>
            {selectedSection == 2 && <div className="down-arrow"></div> }
          </div>
          <div>
            {selectedSection == 3 && <div className="down-arrow"></div> }
          </div>
          <div>
            {selectedSection == 4 && <div className="down-arrow"></div> }
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopBar3;
