import axios from "axios";
import { MAIN_URL } from "../../Configs/Urls";

export const debouncedSearch = async (
  value,
  setSearchLoading,
  setSearchedAirports
) => {
  if(value.length === 0){
    setSearchedAirports([]);
    return
  }
  try {
    setSearchLoading(true);
    const response = await axios.get(
      `${MAIN_URL}masters/flights/search-airports?search=${value}`
    );
    const data = response.data;
  
    if (data?.length > 0) {
      setSearchedAirports(data);
    } else {
      setSearchedAirports([]);
    }
    setSearchLoading(false);
  } catch (error) {
    console.error("Error fetching search results:", error);
    setSearchLoading(false);
  }


}

export async function fetchPopularAirports() {
  try {
    const response = await fetch(MAIN_URL + "masters/flights/popular-cities");
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
}

export const fetchCalendarValues = async (origin, destination) => {
  try {
    const response = await axios.get(
      `${MAIN_URL}masters/flights/search-calendar-fares`,
      {
        params: {
          origin: origin,
          destination: destination,
        },
      }
    );

    const transformedData = response.data.reduce((acc, item) => {
      const date = new Date(item.date);
      const formattedDate = `${date.getDate().toString().padStart(2, "0")}-${(
        date.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${date.getFullYear()}`;
      acc[formattedDate] = parseFloat(item.price);
      return acc;
    }, {});

    return transformedData;
  } catch (error) {
    throw error;
    return [];
  }
};

export const fetchTheFlights = async (params) => {
  try {
    const response = await axios.post(
      `${MAIN_URL}flights/STFARES/search`,
      params
    );

    console.log(response);
    return response;
  } catch (error) {
    console.log("the api throw error is  : ", error);
    throw error;
  }
};

export const fetchTheAirline = async (params) => {
  console.log(params);
  try {
    const response = await axios.get(
      `${MAIN_URL}masters/flights/search-airlines?search=${params}`
    );

    console.log("AirlineResponse is : ", response);

    return response?.data;
  } catch (error) {
    console.log("the api throw error is  : ", error);
    throw error;
  }
};
