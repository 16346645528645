import React from 'react'

function Header() {
  return (
    <div className="frame-24-1-cc frame-24-3-cc">
    <div className="frame-242-8-cc">
      <div className="time-frame-cc proxima_semibold color_eerie-black font16">
        Time Frame
      </div>
      <div className="from-scheduled-flight-departure-cc proxima_regular color_gravel font13">
        ( From scheduled flight departure )
      </div>
    </div>
    <div className="frame-242-9-cc">
      <div className="airline-charges-st-fare-charges-cc proxima_semibold color_eerie-black font16">
        Airline Charges + Stfare Charges
      </div>
      <div className="per-cc proxima_regular color_gravel font13">
        per traveller
      </div>
    </div>
  </div>
  )
}

export default Header
