import React, { useState } from "react";

// import images from "../../../../../Utils/importImages";
import images from "../../../../Utils/importImages";
import { useDispatch } from "react-redux";

function AirportContent({
  onClickClose,
  item,
  PopupType,
  segmentsData,
  Theme,
}) {
  let [mousehovered, setmousehovered] = useState(false);

  let dispatch = useDispatch();

  let handleClose = (item) => {
    onClickClose(item, PopupType);
  };

  return (
    <div className={`airportContentContainer ${Theme}`}>
      {mousehovered ? (
        <img
          className="bar-line"
          src={images[Theme == "proxima" ? "light-bar-search.svg" : "dark-bar-search.svg"]}
          alt="Line 49"
        />) : (<img
          className="bar-line"
          style={{visibility:"hidden"}}
          src={images[Theme == "proxima" ? "light-bar-search.svg" : "dark-bar-search.svg"]}
          alt="Line 49"
        />)} 
    <div
      className={`airprot-content ${Theme}`}
      onMouseOver={() => setmousehovered(true)}
      onMouseOut={() => setmousehovered(false)}
      onClick={() => handleClose(item)}
    >
      <div className="airline-content-main">
 
        <div className="main-content" style={{ display: "flex" }}>
       
          <div className={`${Theme}_regular color_dark-salt font18`}>
            <div className="main-head">
              {item?.city_name},{" "}
              {item?.country_name === "United States of America"
                ? "USA"
                : item?.country_name}
            </div>
            <div
              className={`${Theme}_light color_black font13`}
              style={{
                whiteSpace: "pre",
                overflow: "hidden",
                maxWidth: "270px",
                textOverflow: "ellipsis",
              }}
            >
              {item?.airport_name}
            </div>
          </div>
        </div>
        <div style={{ width: "30px" }}>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            <div
              className={`del recent-searches ${Theme}_semibold font14 color_graish-white`}
            >
              {item?.airport_code}
            </div>
          </div>
        </div>

        
      </div>
    </div>
    
    </div>
  );
}

export default AirportContent;
