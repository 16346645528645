import React from "react";
import "./Loadings.css";
import images from "../../../../Utils/importImages";

export default function ResultPageLoading({Theme}) {
  return (
    <>

      <div className="loading-container">
      <div className={`loading-message ${Theme}_regular font18`}>
        <p>Hold on, we are fetching your flight results...</p>
      </div>
      <div className="loading-message">
      </div>
      <div className="loading-message">
      </div>
      </div>

    </>
  );
}
