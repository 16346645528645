import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {fetchUserCurrentLocationData } from "../../Apis/User-Apis";
import { setLocalStorageJSON } from "../../../Utils/localStorageFunc";


let searchParamsUser = {
    name: "India",
    code: "IN",
    currency: "INR",
    dial_code: "+91",
};

const initialState = {
  status: "loading",
  userData: null,
  UserCurrentLocationData: searchParamsUser,

  isOffline : false
};

// Async thunk to fetch user current location data
export const UserLocationFetcher = createAsyncThunk(
  "user/fetchUserLocationData",
  async () => {

    try {
    const response = await fetchUserCurrentLocationData();
    // The value we return becomes the `fulfilled` action payload
    return response;
    }
    catch(error){
      console.log("the rror : " , error);
      throw error;
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // Reducer Functions in Future
  },
  extraReducers: (builder) => {
    builder
      .addCase(UserLocationFetcher.pending, (state) => {
        state.status = "loading";
      })
      .addCase(UserLocationFetcher.fulfilled, (state, action) => {
        state.status = "idle";
        state.UserCurrentLocationData = {
          name: action.payload.country_name,
          code: action.payload.country,
          currency: action.payload.currency,
          dial_code: action.payload.country_calling_code,
        };
      })
      .addCase(UserLocationFetcher.rejected, (state , action) => {
        console.log("the erorr here is : " , action);
        state.status = "idle";

        if(action.error.message === "Failed to fetch"){
          state.isOffline = true
        }
      });
  },
});

export default userSlice.reducer;
