import React, { useEffect, useState } from "react";
import images from "../../../../../Utils/importImages";
import { useDispatch, useSelector } from "react-redux";
import {
  convertDateStringResultPage,
  formatDate,
} from "../../../../../Utils/Date_Time_Calendar_Functions";
import SpecialFareOptions from "./SpecialFareOptions";
import { fetchCalendarValues } from "../../../../../Redux/Apis/Flight-Apis"
import FlightsOptionsPopup from "../../../../Components/Popups/FlightsOptionsPopup";
import {
  searchTheFlightUrlCreator,
  setExchangeSearchParas,
  setSearchBarMode,
  setSearchSegmentsParams,
  setTheDates,
  setTravellersAndClasses,
  setTravellersErrors,
} from "../../../../../Redux/Slices/Flight_Slices/ResultPage-Flights-SLICE"
import ErrorMessagePopup from "../../../../Components/Popups/ErrorMessagePopup";
import From_To_Popup from "../../../../Components/Popups/From_To_Popup";
import { getLocalStorageJSON } from "../../../../../Utils/localStorageFunc";
import RangeCalender from "../../../../Components/Popups/Calendars/RangeCalender";
import TravellersPopup from "../../../../Components/Popups/TravellersPopup";
import { TravellersFareMessage } from "../../../../../Constants/ConstantsFlights";
import { useNavigate } from "react-router-dom";

function OnewaySearchBar({
  loadingPorgessBar,
  isSticky,
  openPopup,
  setopenPopup,
  openPopupActual,
  setopenPopupActual,
  Theme : FontTheme
}) {

  // let navigate = useNavigate();

  let searchBarData = useSelector((state) => state.ResultPageFlightsFlight);

  let travellersData = useSelector(
    (state) => state.ResultPageFlightsFlight.searchParamsTravellers
  );
  let classData = useSelector(
    (state) => state.ResultPageFlightsFlight.searchParamsFare
  );

  let dispatch = useDispatch();

  const isButtonDisabled = () => {
    return (
      searchBarData.SearchResultsStatus === "Loading" ||
      searchBarData.disableSearch === true ||
      searchBarData.searchParamsTravellers.TravellersAndClassesErrorIn === true ||
      searchBarData.searchParamsTravellers.TravellersAndClassesErrorOut === true ||
      searchBarData.AirportsToError1[0] === true ||
      searchBarData.AirportsToError2[0] === true ||
      searchBarData.AirportsToError3[0] === true ||
      searchBarData.AirportsToError4[0] === true ||
      searchBarData.AirportsFromError1[0] === true ||
      searchBarData.AirportsFromError2[0] === true ||
      searchBarData.AirportsFromError3[0] === true ||
      searchBarData.AirportsFromError4[0] === true
    );
  };

  let changeTheDate = (date) => {

    let selectedDate1 = date;
    let selectedDate2 = searchBarData?.searchParams[1]?.OriginDate !== null ? new Date(searchBarData?.searchParams[1]?.OriginDate) : null;
    let selectedDate3 = searchBarData?.searchParams[2]?.OriginDate !== null ? new Date(searchBarData?.searchParams[2]?.OriginDate) : null;
    let selectedDate4 = searchBarData?.searchParams[3]?.OriginDate !== null ? new Date(searchBarData?.searchParams[3]?.OriginDate) : null;
    let ReturnDate = searchBarData?.searchParams[0]?.ReturnDate ? new Date(searchBarData?.searchParams[0]?.ReturnDate) : null;
  
    if (ReturnDate != null && selectedDate1 != null) {
      if (selectedDate1 > ReturnDate) {
        const newDate = new Date(selectedDate1);
        newDate.setDate(selectedDate1.getDate() + 1);
        ReturnDate = newDate;
      }
    }
  
    if (selectedDate1 !== null && selectedDate2 !== null) {
      if (selectedDate1 > selectedDate2) {
        const newDate = new Date(selectedDate1);
        newDate.setDate(selectedDate1.getDate() + 1);
        selectedDate2 = newDate;
      }
    }
  
    if (selectedDate2 !== null && selectedDate3 !== null) {
      if (selectedDate2 > selectedDate3) {
        const newDate = new Date(selectedDate2);
        newDate.setDate(selectedDate2.getDate() + 1);
        selectedDate3 = newDate;
      }
    }
  
    if (selectedDate3 !== null && selectedDate4 !== null) {
      if (selectedDate3 > selectedDate4) {
        const newDate = new Date(selectedDate3);
        newDate.setDate(selectedDate3.getDate() + 1);
        selectedDate4 = newDate;
      }
    }

    let Obj = [
      {
        OriginDate: formatDate(selectedDate1),
        ReturnDate: formatDate(ReturnDate),
      },
      {
        OriginDate: formatDate(selectedDate2),
        ReturnDate: null,
      },
      {
        OriginDate: formatDate(selectedDate3),
        ReturnDate: null,
      },
      {
        OriginDate: formatDate(selectedDate4),
        ReturnDate: null,
      },
    ];

    dispatch(setTheDates(Obj));
  };

  let [calendarValues, setCalendarValues] = useState(null);

  useEffect(() => {

    if (openPopup === 4) {
      
      if (
        searchBarData?.AirportsFromError1[0] === true ||
        searchBarData?.AirportsToError1[0] === true ||
        searchBarData?.searchParams?.[0]?.OriginCode === "" ||
        searchBarData?.searchParams?.[0]?.DestinyCode === ""
      ) {
        setCalendarValues(null);
      } else {
        // Call the calendar values API
        const origin = searchBarData?.searchParams?.[0]?.OriginCode;
        const destination = searchBarData?.searchParams?.[0]?.DestinyCode;

        fetchCalendarValues(origin, destination)
          .then((data) => {
            setCalendarValues(data);
          })
          .catch((error) => {
            setCalendarValues(null);
          });
      }
    }
  }, [openPopup]);

  let changeTheSearchBarMode = (option) => {
    if (option === "One") {
      dispatch(setSearchBarMode(1));
    } else if (option === "Round") {
      dispatch(setSearchBarMode(2));
    } else if (option === "Multi") {
      dispatch(setSearchBarMode(3));
    }
  };

  let handleClose = (item, PopupType) => {
    // console.log("item is : ", item);
    // console.log("PopupType is : ", PopupType);
    let StructuredAirport = {
      City: item.city_name,
      Code: item.airport_code,
      AirportName: item.airport_name,
      CountryCode: item.country_code,
      CountryName: item.country_name,

      type: PopupType[0],
      PopupType: PopupType[1],

      airport: item,
    };

    dispatch(setSearchSegmentsParams(StructuredAirport));

    setopenPopup(openPopup + 1);
    setopenPopupActual(openPopup + 1);
  };

  const handleSearch = () => {
    dispatch(searchTheFlightUrlCreator());
  };


  // console.log("the date 0 is  : " , searchBarData?.searchParams[0]?.OriginDate);
  // console.log("the date 01 is  : " , searchBarData?.searchParams[0]?.ReturnDate);
  // console.log("the date 1 is  : " , searchBarData?.searchParams[1]?.OriginDate);
  // console.log("the date 2 is  : " , searchBarData?.searchParams[2]?.OriginDate);
  // console.log("the date 3 is  : " , searchBarData?.searchParams[3]?.OriginDate);


  return (
    <>
    <div
      className={`result-page-flights result-page-flights-search-bar ${
        isSticky ? "sticky" : ""
      }`}
    >
      <div className={`result-page-flights-frame-97 ${FontTheme}`}>
        <div className="result-page-flights-frame-27">
          <div className="result-page-flights-frame-33">
            <div className="result-page-flights-frame-35">
              <div className="result-page-flights-frame-101" onClick={() => setopenPopup(1)}>
             
                  <div className={`result-page-flights-trip-type ${FontTheme}_light color_white font12`}>
                    TRIP TYPE
                  </div>
                  <div className="result-page-flights-frame-168-6">
                    <div className={`result-page-flights-one-way ${FontTheme}_regular color_white font16`}>
                      One Way
                    </div>
                    <img
                      src={images["arrow.png"]}
                      alt="arrow"
                      style={{
                        position: "relative",
                       
                        transform : openPopup === 1 ? "rotate(180deg)" : null
                      }}
                    />
                  </div>
              
                {openPopup === 1 && (
                  <FlightsOptionsPopup
                    options={["Round", "Multi"]}
                    open={openPopup === 1}
                    onClickClose={changeTheSearchBarMode}
                    position={openPopup}
                    onClose={() => {
                      setopenPopup(0);
                      setopenPopupActual(0);
                    }}
                    Theme={FontTheme}
                  />
                )}
              </div>
              <div className="result-page-flights-frame-99-7">
                <>
                  <div
                    className="result-page-flights-frame-28"
                    onClick={() => {
                      setopenPopup(2);
                      setopenPopupActual(2);
                    }}
                  >
                    <div className="result-page-flights-frame-99-8">
                      <div className={`result-page-flights-place-32 ${FontTheme}_light color_white font12`}>
                        FROM
                      </div>
                    </div>
                    <div className={`result-page-flights-innextText ${FontTheme}_regular color_white font16`}>
                      {searchBarData?.searchParams?.[0]?.OriginCode} {searchBarData?.searchParams?.[0]?.OriginCode !== "" && ", "}
                      {searchBarData?.searchParams?.[0]?.OriginCity} {searchBarData?.searchParams?.[0]?.OriginCity !== "" && ", "}
                      {searchBarData?.searchParams?.[0]?.OriginCountry}
                    </div>
                   
                  </div>
                  {searchBarData?.AirportsFromError1[0] === true && (
                    <ErrorMessagePopup
                    Theme={FontTheme}
                      style={{
                        display:"flex",
                        alignItems : "end",
                        width: "max-content",
                        zIndex: 1,
                        backgroundColor: "#FFE0DA",
                        position: "absolute",
                        top: "108%",
                        left: "-50px",
                        padding: "4px 8px",
                      }}
                      // addClass={searchBarData?.searchClicked}
                      message={searchBarData?.AirportsFromError1[1]}
                    />
                  )}
                  {openPopup === 2 && (
                    <From_To_Popup
                  
                      segmentsData={searchBarData?.searchParams}
                      popular_cities={searchBarData?.popularAirports}
                      recent_searches={
                        getLocalStorageJSON("fts-hm-fa") === null
                          ? []
                          : getLocalStorageJSON("fts-hm-fa")
                      }
                      open={openPopup === 2}
                      position={openPopup}
                      PopupType={[0, 0]}
                      onClickClose={handleClose}
                      onClose={() => {
                        setopenPopup(0);
                        setopenPopupActual(0);
                      }}
                      left={"0px"}
                      top={"30px"}
                      placeholder={"From"}
                      positionType={"absolute"}
                      Theme={FontTheme}
                    />
                  )}
                </>
                <>
                  <div
                    className="result-page-flights-frame-29"
                    onClick={() => {
                      setopenPopup(3);
                      setopenPopupActual(3);
                    }}
                  >
                    <div className="result-page-flights-frame-99-9">
                      <div className={`result-page-flights-to ${FontTheme}_light color_white font12`}>
                        TO
                      </div>
                    </div>

                    <div className={`result-page-flights-innextText ${FontTheme}_regular color_white font16`}>
                      {searchBarData?.searchParams?.[0]?.DestinyCode} {searchBarData?.searchParams?.[0]?.DestinyCode !== "" && ", "}
                      {searchBarData?.searchParams?.[0]?.DestinationCity} {searchBarData?.searchParams?.[0]?.DestinationCity !== "" && ", "}
                      {searchBarData?.searchParams?.[0]?.DestinationCountry}
                    </div>
                  </div>
                  {searchBarData?.AirportsToError1[0] === true && (
                    <ErrorMessagePopup
                    Theme={FontTheme}
                      style={{
                        display:"flex",
                        alignItems : "end",
                        width: "max-content",
                        zIndex: 1,
                        backgroundColor: "#FFE0DA",
                        position: "absolute",
                        top: "108%",
                        left: "200px",
                        padding: "4px 8px",
                      }}
                      // addClass={searchBarData?.searchClicked}
                      message={searchBarData?.AirportsToError1[1]}
                    />
                  )}
                  {openPopup === 3 && openPopupActual === 3 && (
                    <From_To_Popup
                    Theme={FontTheme}
                      segmentsData={searchBarData?.searchParams}
                      popular_cities={searchBarData?.popularAirports}
                      recent_searches={
                        getLocalStorageJSON("fts-hm-ta") === null
                          ? []
                          : getLocalStorageJSON("fts-hm-ta")
                      }
                      open={openPopup === 3}
                      position={openPopup}
                      PopupType={[0, 1]}
                      onClickClose={handleClose}
                      onClose={() => {
                        setopenPopup(0);
                        setopenPopupActual(0);
                      }}
                      left={"210px"}
                      top={"30px"}
                      placeholder={"To"}
                      positionType={"absolute"}
                    />
                  )}
                </>
                <img
                  onClick={() => dispatch(setExchangeSearchParas(0))}
                      style={{
                        position: "absolute",
                        top: "20px"
                      }}
                      src={images["exchange-alt.png"]}
                      alt="exchange-alt"
                    />
              </div>
              <div className="result-page-flights-frame-100-1">
                <div style={{width:"50%"}}>
                  <div
                    className="result-page-flights-frame-30"
                    onClick={() => {
                      setopenPopup(4);
                      setopenPopupActual(4);
                    }}
                  >
                    <div className="result-page-flights-frame-103-6">
                      <div className={`result-page-flights-departure ${FontTheme}_light color_white font12`}>
                        DEPARTURE
                      </div>
                    </div>
                    <div className={`result-page-flights-tue-30-jan-9 ${FontTheme}_regular color_white font16`}>
                      {convertDateStringResultPage(
                        searchBarData?.searchParams?.[0]?.OriginDate
                      )}
                    </div>
                  </div>
                  {searchBarData?.DateError1[0] === true && (
                    <ErrorMessagePopup
                    Theme={FontTheme}
                      style={{
                        display:"flex",
                        alignItems : "end",
                        width: "max-content",
                        zIndex: 1,
                        backgroundColor: "#FFE0DA",
                        position: "absolute",
                        top: "108%",
                        left: "10px",
                        padding: "4px 8px",
                      }}
                      // addClass={searchBarData?.searchClicked}
                      message={searchBarData?.DateError1[1]}
                    />
                  )}

                  {openPopup === 4 && openPopupActual === 4 && (
                    <RangeCalender
                    Theme={FontTheme}
                      showTopButtons={false}
                      Buttonelements={null}
                      open={openPopup === 4}
                      position={openPopup}
                      onClickClose={() => {
                        setopenPopup(5);
                        setopenPopupActual(5);
                      }}
                      onClose={() => {
                        setopenPopup(0);
                        setopenPopupActual(0);
                      }}
                      minimumDate={new Date().setDate(new Date().getDate() - 1)}
                      CalenderValues={calendarValues}
                      selectedDate={
                        new Date(searchBarData?.searchParams[0]?.OriginDate)
                      } // Currently selected date
                      setSelectedDate={changeTheDate}
                      showReturnDate={true}
                      returnDateValue={"Add Return Date To Save More"}
                      showNumberOfDaysCount={false}
                      activeHeaderBar={1}
                      onClickDepart={null}
                      onClickReturn={() => {
                        dispatch(setSearchBarMode(2));
                        setopenPopup(11);
                        setopenPopupActual(11);
                        // alert("here");
                      }}
                      isReturnCalender={false}
                      range={[null, null]}
                    />
                  )}
                </div>
                <div className="result-page-flights-frame-31" onClick={() => {
                      setopenPopup(10);
                      setopenPopupActual(10);
                      dispatch(setSearchBarMode(2));
                  }}>
                  <div className={`result-page-flights-return ${FontTheme}_light color_white font12`} >
                    RETURN
                  </div>
                  <div className={`result-page-flights-add-return ${FontTheme}_regular ${FontTheme == "proxima" ? "color_robins-egg-blue" : "color_text-pale-bg"} font16`} >
                    Add Return
                  </div>
                </div>
              </div>

              <div style={{width:"20%" , marginLeft:"1px"}}>
                <div
                  className="result-page-flights-frame-32"
                  onClick={() => {
                    setopenPopup(5);
                    setopenPopupActual(5);
                  }}
                >
                  <div className={`result-page-flights-travellers-class ${FontTheme}_light color_white font12`}>
                    TRAVELLERS &amp; CLASS
                  </div>
                  <div className="result-page-flights-frame-1-9">
                    <div className={`result-page-flights-address-18 address-19 ${FontTheme}_regular color_white font16`}>
                      {searchBarData?.searchParamsTravellers?.Adults +
                        searchBarData?.searchParamsTravellers?.Childs +
                        searchBarData?.searchParamsTravellers?.Infrants}{" "}
                      Travellers ,{" "}
                      {searchBarData?.searchParamsFare?.class === "Economy" && (
                        <>
                          {searchBarData?.searchParamsFare?.class}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </>
                      )}
                      {searchBarData?.searchParamsFare?.class === "Business" && (
                        <>
                          {searchBarData?.searchParamsFare?.class}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </>
                      )}
                      {searchBarData?.searchParamsFare?.class === "First" && (
                        <>
                          {searchBarData?.searchParamsFare?.class}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </>
                      )}
                      {searchBarData?.searchParamsFare?.class ==
                        "Premium Economy" &&
                        searchBarData?.searchParamsFare?.class}
                    </div>
                  </div>
                  {searchBarData?.searchParamsTravellers
                    ?.TravellersAndClassesErrorOut === true && (
                    <ErrorMessagePopup
                    Theme={FontTheme}
                      style={{
                        display:"flex",
                        alignItems : "end",
                        width: " max-content",
                        zIndex: 1,
                        backgroundColor: "#FFE0DA",
                        position: "absolute",
                        top: "108%",
                        left: "-30%",
                        padding: "4px 8px",
                      }}
                      // addClass={searchBarData?.searchClicked}
                      message={TravellersFareMessage(
                        searchBarData?.searchParamsFare?.type
                      )}
                    />
                  )}
                </div>
                {openPopup === 5 && openPopupActual === 5 && (
                  <TravellersPopup
                  Theme={FontTheme}
                    open={openPopup === 5}
                    position={openPopup}
                    onClickClose={() => {
                      setopenPopup(0);
                      setopenPopupActual(0);
                    }}
                    onClose={() => {
                      setopenPopup(0);
                      setopenPopupActual(0);
                    }}
                    travellersData={travellersData}
                    classData={classData}
                    timeOutFunc={setTravellersErrors}
                    setTravellersAndClassesFunc={setTravellersAndClasses}
                  />
                )}
              </div>

              <div   className={`result-page-flights-frame-20-2 result-page-flights-frame-20-3 ${isButtonDisabled() ? 'disabled' : ''} ${FontTheme}`}
      onClick={!isButtonDisabled() ? handleSearch : null} style={{width : "10%" , display:"flex"}}>
                <div className={`result-page-flights-search-1 ${FontTheme}_semibold color_${FontTheme == "proxima" ? "endeavour" : "optera-galaxy"} font18`}>SEARCH</div>
              </div>
            </div>
          </div>
        </div>
    
    {
      ((searchBarData?.SearchResultType == 1 || searchBarData?.SearchResultType == 2 || searchBarData?.SearchResultType == 3 || searchBarData?.SearchResultType == 5 || searchBarData?.SearchResultType == 7 || searchBarData?.SearchResultType == 9) && FontTheme == "averta") ? null : 
        <SpecialFareOptions
        Theme={FontTheme}
          selectedOption={searchBarData?.searchParamsFare?.type}
          showTheToggler={
        
            ( searchBarData?.SearchResultType === 4 ||
              searchBarData?.SearchResultType === 6 ||
              searchBarData?.SearchResultType === 8 ||
              searchBarData?.SearchResultType === 10) &&
              searchBarData?.SearchResultsStatus != "Loading"
          }
          ResultView={searchBarData?.ResultView}
        />
    }
      
      </div>
    {loadingPorgessBar}
    </div>
    
    </>
  );
}

export default OnewaySearchBar;
