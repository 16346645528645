import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSearchBarMode } from "../../../../Redux/Slices/Flight_Slices/HomePage-Flights-SLICE";

function SearchBarMode({ setopenPopup, setopenPopupActual }) {
  let mode = useSelector(
    (state) => state.HomePageSearchBarFlight.searchBarMode
  );

  let dispatch = useDispatch();

  return (
    <div className="frame-9">
      <div
        style={{ cursor: "pointer" }}
        className={`${
          mode === 1
            ? "proxima_bold color_endeavour font18"
            : "proxima_regular color_eerie-black font18"
        }`}
        onClick={() => {
          dispatch(setSearchBarMode(1));
          setopenPopup(0);
          setopenPopupActual(0);
        }}
      >One Way</div>
      <div
        style={{ cursor: "pointer" }}
        className={`${
          mode === 2
            ? "proxima_bold color_endeavour font18"
            : "proxima_regular color_eerie-black font18"
        }`}
        onClick={() => {
          dispatch(setSearchBarMode(2));
          setopenPopup(0);
          setopenPopupActual(0);
        }}
      >
        Round Trip
      </div>

      <div
        className={` ${
          mode === 3
            ? "proxima_bold color_endeavour font18"
            : "proxima_regular color_eerie-black font18"
        }`}
        onClick={() => {
          dispatch(setSearchBarMode(3));
          setopenPopup(0);
          setopenPopupActual(0);
        }}
        style={{ cursor: "pointer" }}
      >
        Multi City
      </div>
    </div>
  );
}

export default SearchBarMode;
