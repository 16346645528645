import React, { useEffect, useMemo } from "react";
import images from "../../../../../../Utils/importImages";
import "./index.css";
import ResultBar2 from "../Components/ResultBars/ResultBar2";
import { useDispatch, useSelector } from "react-redux";
import {
  setbarModeData,
  setInitialSettings,
} from "../../../../../../Redux/Slices/Flight_Slices/ResultPage-Flights-SLICE";
function RoundwayData_Domestic({Theme}) {
  const dispatch = useDispatch();

  const searchBarData = useSelector((state) => state.ResultPageFlightsFlight);

  const airlineLogos = useMemo(
    () => searchBarData?.SearchResults?.AirlinesLogos,
    [searchBarData]
  );

  const GoingData = useMemo(
    () => searchBarData?.FilteredData?.Going,
    [searchBarData]
  );
  const ReturningData = useMemo(
    () => searchBarData?.FilteredData?.Returning,
    [searchBarData]
  );

  const SelectedDatas = useMemo(
    () => searchBarData?.barModeData,
    [searchBarData]
  );

  useMemo(() => {
    dispatch(setInitialSettings());
  }, []);

  let ChangeTheSelectedData = (mode, item, allAirlines) => {
    let Data = { ...item, AirlinesDetails: allAirlines };
    dispatch(setbarModeData({ mode: mode, Data: Data }));
  };

  const FiltersApplied1 = useMemo(
    () => searchBarData?.FiltersApplied[0] || [],
    [searchBarData?.FiltersApplied, searchBarData?.barModeSelected]
  );

  const FiltersApplied2 = useMemo(
    () => searchBarData?.FiltersApplied[1] || [],
    [searchBarData?.FiltersApplied, searchBarData?.barModeSelected]
  );

  return (
    <div className="frame-257-1">
      <div className="frame-168-4 frame-168-6">
        <div className="frame-232-12">
          <div className="frame-232-13">
            {GoingData.length === 0 && FiltersApplied1.length > 0 && (
              <div>To Many Filters Appplied ,, No Result Found</div>
            )}

            {GoingData?.slice(0, 500).map((item, index) => {
              return (
                <ResultBar2
                Theme={Theme}
                  key={index}
                  index={index}
                  data={item}
                  airlineLogos={airlineLogos}
                  selectedData={SelectedDatas[0]}
                  SelectTheData={ChangeTheSelectedData}
                  mode={0}
                />
              );
            })}
          </div>
          <div className="frame-232-13">
            {ReturningData.length === 0 && FiltersApplied2.length > 0 && (
              <div>To Many Filters Appplied ,, No Result Found</div>
            )}

            {ReturningData?.slice(0, 500).map((item, index) => {
              return (
                <ResultBar2
                Theme={Theme}
                  key={index}
                  index={index}
                  data={item}
                  airlineLogos={airlineLogos}
                  selectedData={SelectedDatas[1]}
                  SelectTheData={ChangeTheSelectedData}
                  mode={1}
                />
              );
            })}
          </div>
          {/* //  */}
        </div>
      </div>
    </div>
  );
}

export default RoundwayData_Domestic;

{
  /* <div className="frame-255">
<div className="frame-232-15">
  <div className="frame-2-1">
    <div className="frame-17-1 frame-17-4">
      <div className="frame-232">
        <div className="frame-104">
          <div className="frame-9">
            <div className="frame-17-2 frame-17-4">
              <div className="frame-223">
                <div className="frame-172"></div>
              </div>
              <div className="frame-224">
                <div className="frame-173">
                  <img
                    className="unnamed-1 unnamed-3"
                    src="img/unnamed.png"
                    alt="unnamed"
                  />
                </div>
              </div>
              <div className="frame-223">
                <div className="frame-224-1">
                  <img
                    className="png-transparent-luft"
                    src="img/png-transparent-lufthansa-logo-lufthansa-cargo-flight-airline-lo.png"
                    alt="png-transparent-lufthansa-logo-lufthansa-cargo-flight-airline-logo-airline-miscellaneous-text-otto-firle 1"
                  />
                </div>
              </div>
            </div>
            <div className="frame-174">
              <div className="frame-173-1 frame-173-3">
                <div className="turkish-s proxima_medium color_eerie-black font16">
                  Turkish, S...
                </div>
              </div>
              <div className="frame-5">
                <div className="ai-123-sj-123 proxima_light color_gravel font13">
                  Ai 123, Sj 123...
                </div>
              </div>
            </div>
          </div>
          <div className="frame-172-1">
            <div className="frame-169">
              <div className="frame-10">
                <img
                  className="frame-169-4"
                  src="img/frame-169.svg"
                  alt="Frame 169"
                />
                <div className="text valign-text-middle proxima_regular color_eerie-black font22">
                  82,456
                </div>
              </div>
              <div className="per-adult-1 per-adult-16 proxima_regular color_gravel font13">
                per adult
              </div>
            </div>
          </div>
          <div className="frame-11">
            <div className="rectangle-17"></div>
          </div>
        </div>
        <div className="frame-232-1">
          <div className="frame-2-2">
            <div className="frame-2-2">
              <div className="frame-20">
                <div className="frame-1-4">
                  <div className="frame-12">
                    <div className="text-397 text-1 proxima_regular color_eerie-black font22">
                      08:40
                    </div>
                  </div>
                  <div className="frame-13">
                    <div className="bom proxima_regular color_eerie-black font14">
                      Bom,
                    </div>
                    <div className="place proxima_regular color_gravel font14">
                      Mumbai
                    </div>
                  </div>
                </div>
                <div className="frame-17-3 frame-17-4">
                  <div className="frame-168-2 frame-168-6">
                    <div className="frame-170-1">
                      <img
                        className="line"
                        src="img/line-5.svg"
                        alt="Line 5"
                      />
                      <div className="frame-202">
                        <div className="group-206">
                          <div className="overlap-group">
                            <img
                              className="vector-50"
                              src="img/vector-50.svg"
                              alt="Vector 50"
                            />
                          </div>
                        </div>
                        <div className="x28h-40m proxima_regular color_gravel font13">
                          28h 40m
                        </div>
                      </div>
                      <img
                        className="line"
                        src="img/line-8-4.svg"
                        alt="Line 8"
                      />
                    </div>
                  </div>
                  <div className="frame-172-8">
                    <div className="frame-14">
                      <div className="frame-175-2">
                        <div className="non-stop non proxima_regular color_endeavour font14">
                          Non-Stop
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="frame-1-5">
                  <div className="text-398 text-1 proxima_regular color_eerie-black font22">
                    08:30
                  </div>
                  <div className="frame-13">
                    <div className="atq proxima_regular color_eerie-black font14">
                      Atq,
                    </div>
                    <div className="place-1 proxima_regular color_gravel font14">
                      Amritsar
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="frame-177">
        <div className="non proxima_medium color_crimson font13">
          Non - Refundable
        </div>
        <div className="frame-203"></div>
      </div>
    </div>
  </div>
</div>
</div> */
}
