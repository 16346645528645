import React, { useCallback, useEffect, useState, useRef } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import "../../Draggers/CommonCss.css"
import images from "../../../../../../../../Utils/importImages";

const convertMinutesToTime = (totalMinutes) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return hours < 1 ? `${minutes} min` : `${hours} hr ${minutes} min`
};

const ValueTimePicker = ({type , min, max, onChange , text , minDefault, maxDefault , Theme}) => {

  const [minVal, setMinVal] = useState(minDefault);
  const [maxVal, setMaxVal] = useState(maxDefault);

  console.log("the ref is  : " , type , " , min : " , min , " and max is : " , max , " minVal is : " , minVal , " maxVal is : " , maxVal);
  const minValRef = useRef(null);
  const maxValRef = useRef(null);
  const range = useRef(null);

  // console.log(`refs ${type} are : `, minValRef , " , "  , maxValRef , " , " , range );

  const getPercent = useCallback(
    (value) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  );

  useEffect(() => {
    setMinVal(minDefault);
    setMaxVal(maxDefault);
  }, [minDefault, maxDefault]);
  
  useEffect(() => {
    if (maxValRef.current) {
      const minPercent = getPercent(minVal);
      const maxPercent = getPercent(+maxValRef.current.value);

      if (range.current) {
        range.current.style.left = `${minPercent}%`;
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [minVal, getPercent]);

  useEffect(() => {
    if (minValRef.current) {
      const minPercent = getPercent(+minValRef.current.value);
      const maxPercent = getPercent(maxVal);

      if (range.current) {
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [maxVal, getPercent]);

 // Handle min value change
 const handleMinChange = (event) => {
  const value = Math.min(+event.target.value, maxVal - 1);
  setMinVal(value);
  event.target.value = value.toString();

};

// Handle max value change
const handleMaxChange = (event) => {
  const value = Math.max(+event.target.value, minVal + 1);
  setMaxVal(value);
  event.target.value = value.toString();
};

// Call onChange when mouse/touch interaction ends
const handleChange = () => {
  onChange(minVal, maxVal);
};


  return (
    <>
      <div className="frame-1-2">
        <div className="frame-175">
          <div className="frame-201">
            <div className={`one-way-price valign-text-middle ${Theme}_regular color_magic-potion font18`}>
             {text}
            </div>
          </div>
        </div>
        <div className="frame-170">
          <div className="frame-175-1">
            <div className="frame-201-1">
              <input
                type="range"
                min={min}
                max={max}
                value={minVal}
                ref={minValRef}
                onChange={handleMinChange}
                onMouseUp={handleChange}
                onTouchEnd={handleChange}
                className={classnames("thumb thumb--zindex-3", {
                  "thumb--zindex-5": minVal > max - 100
                })}
              />
              <input
                type="range"
                min={min}
                max={max}
                value={maxVal}
                ref={maxValRef}
                onChange={handleMaxChange}
                onMouseUp={handleChange}
                onTouchEnd={handleChange}
                className="thumb thumb--zindex-4"
              />

              <div className="slider">
                <div className="slider__track" />
                <div ref={range} className={`slider__range ${Theme}`} />
              </div>
            </div>
          </div>
          <div className="frame-202-7">
            <div className="frame-5">
              <div className="frame-170-2">
                <div className={`number-5 number-34 ${Theme}_regular color_eerie-black font16`}>
                  {convertMinutesToTime(minVal)}
                </div>
              </div>
            </div>
            <div className="frame-5">
              <div className="frame-170-2">
                <div className={`number-6 number-34 ${Theme}_regular color_eerie-black font16`}>
                  {convertMinutesToTime(maxVal)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

ValueTimePicker.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired
};

export default ValueTimePicker;
