import React, { useCallback, useEffect, useState, useRef } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import "../../Draggers/CommonCss.css"
import images from "../../../../../../../../Utils/importImages";

const ValueRangePicker = ({ min, max, onChange, minDefault, maxDefault , mode , Theme}) => {

  const [minVal, setMinVal] = useState(minDefault);
  const [maxVal, setMaxVal] = useState(maxDefault);
  const minValRef = useRef(null);
  const maxValRef = useRef(null);
  const range = useRef(null);

  // Convert to percentage
  const getPercent = useCallback(
    (value) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  );

  // Set minVal and maxVal when min and max props change
  useEffect(() => {
    setMinVal(minDefault);
    setMaxVal(maxDefault);
  }, [minDefault, maxDefault]);

  // Update range styles on input change
  useEffect(() => {
    if (maxValRef.current) {
      const minPercent = getPercent(minVal);
      const maxPercent = getPercent(+maxValRef.current.value);

      if (range.current) {
        range.current.style.left = `${minPercent}%`;
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [minVal, maxVal, getPercent]);

  // Update range styles on input change
  useEffect(() => {
    if (minValRef.current) {
      const minPercent = getPercent(+minValRef.current.value);
      const maxPercent = getPercent(maxVal);

      if (range.current) {
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [minVal, maxVal, getPercent]);

  // Handle min value change
  const handleMinChange = (event) => {
    const value = Math.min(+event.target.value, maxVal - 1);
    setMinVal(value);
  };

  // Handle max value change
  const handleMaxChange = (event) => {
    const value = Math.max(+event.target.value, minVal + 1);
    setMaxVal(value);
  };

  // Call onChange when mouse/touch interaction ends
  const handleChange = () => {
    onChange(minVal, maxVal , mode);
  };

  return (
    <>
      <div className="frame-1-2">
        <div className="frame-175">
          <div className="frame-201">
            <div className={`one-way-price valign-text-middle ${Theme}_regular color_magic-potion font18`}>
              Price
            </div>
          </div>
        </div>
        <div className="frame-170">
          <div className="frame-175-1">
            <div className="frame-201-1">
              <input
                type="range"
                min={min}
                max={max}
                value={minVal}
                ref={minValRef}
                onChange={handleMinChange}
                onMouseUp={handleChange}
                onTouchEnd={handleChange}
                className={classnames("thumb thumb--zindex-3", {
                  "thumb--zindex-5": minVal > max - 100,
                })}
              />
              <input
                type="range"
                min={min}
                max={max}
                value={maxVal}
                ref={maxValRef}
                onChange={handleMaxChange}
                onMouseUp={handleChange}
                onTouchEnd={handleChange}
                className="thumb thumb--zindex-4"
              />

              <div className="slider">
                <div className="slider__track" />
                <div ref={range} className={`slider__range ${Theme}`} />
              </div>
            </div>
          </div>
          <div className="frame-202-7">
            <div className="frame-5">
              <div className="frame-170-2">
                <img
                  className="vector-1"
                  src={images["vector-34.svg"]}
                  alt="Vector"
                />
                <div className={`number-5 number-34 ${Theme}_regular color_eerie-black font16`}>
                  {minVal}
                </div>
              </div>
            </div>
            <div className="frame-5">
              <div className="frame-170-2">
                <img
                  className="vector-1"
                  src={images["vector-34.svg"]}
                  alt="Vector"
                />
                <div className={`number-6 number-34 ${Theme}_regular color_eerie-black font16`}>
                  {maxVal}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

ValueRangePicker.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  minDefault: PropTypes.number.isRequired,
  maxDefault: PropTypes.number.isRequired,
};

export default ValueRangePicker;
