import React, { useEffect, useRef, useState } from "react";
import "./Calendar.css";
import images from "../../../../Utils/importImages";
import CalendarHeader from "./CalendarHeader";
import MonthsPopup from "./MonthsPopup";
import CalendarGridGenerator from "./CalendarGridGenerator";
export default function RangeCalender({
  open,
  position,

  marginTop,
  left,
  top,

  onClose,
  onClickClose,
  showTopButtons,
  Buttonelements,
  onClickReturn,
  onClickDepart,

  showReturnDate,
  showNumberOfDaysCount,
  activeHeaderBar,

  returnDateValue,
  minimumDate,
  selectedDate,
  setSelectedDate,
  CalenderValues,

  isReturnCalender,
  range,

  Theme
}) {

// console.log("the minumum date is  :  " , minimumDate);
  const popupRef = useRef(null);

  // Month Bar code start

  let [monthDropdown, setmonthDropDown] = useState(0);
  let [currentMonth, setcurrentMonth] = useState(new Date());

  const selectedMonth1 = currentMonth?.toLocaleString("default", {
    month: "long",
    year: "numeric",
  });

  // Create a new date object for the next month
  const nextMonthDate = new Date(
    currentMonth?.getFullYear(),
    currentMonth?.getMonth() + 1
  );
  const selectedMonth2 = nextMonthDate.toLocaleString("default", {
    month: "long",
    year: "numeric",
  });

  const [month1, setSelectedMonth1] = useState(selectedMonth1);
  const [month2, setSelectedMonth2] = useState(selectedMonth2);

  const handleMonthSelect1 = (month) => {
    setSelectedMonth1(month);
    const [monthName, year] = month.split(" ");
    const newMonth = new Date(`${monthName} 1, ${year}`);
    newMonth.setMonth(newMonth.getMonth() + 1);
    setSelectedMonth2(
      newMonth.toLocaleString("default", { month: "long", year: "numeric" })
    );
    setmonthDropDown(0);
  };

  const handleMonthSelect2 = (month) => {
    setSelectedMonth2(month);
    const [monthName, year] = month.split(" ");
    const newMonth = new Date(`${monthName} 1, ${year}`);
    newMonth.setMonth(newMonth.getMonth() - 1);
    setSelectedMonth1(
      newMonth.toLocaleString("default", { month: "long", year: "numeric" })
    );
    setmonthDropDown(0);
  };

  const nextYearMonth = new Date(
    currentMonth.getFullYear(),
    currentMonth.getMonth() + 12
  );

  const handleBackArrowClick = () => {
    const newDate1 = new Date(month1);
    const newDate2 = new Date(month2);

    // Check if moving one month back is within bounds
    if (
      newDate1.getFullYear() > currentMonth.getFullYear() ||
      (newDate1.getFullYear() === currentMonth.getFullYear() &&
        newDate1.getMonth() > currentMonth.getMonth())
    ) {
      newDate1.setMonth(newDate1.getMonth() - 1);
      setSelectedMonth1(
        newDate1.toLocaleString("default", { month: "long", year: "numeric" })
      );

      newDate2.setMonth(newDate2.getMonth() - 1);
      setSelectedMonth2(
        newDate2.toLocaleString("default", { month: "long", year: "numeric" })
      );
    }
  };

  const handleForwardArrowClick = () => {
    const newDate1 = new Date(month1);
    const newDate2 = new Date(month2);

    // Check if moving one month forward is within bounds
    if (
      newDate2.getFullYear() < nextYearMonth.getFullYear() ||
      (newDate2.getFullYear() === nextYearMonth.getFullYear() &&
        newDate2.getMonth() < nextYearMonth.getMonth())
    ) {
      newDate1.setMonth(newDate1.getMonth() + 1);
      setSelectedMonth1(
        newDate1.toLocaleString("default", { month: "long", year: "numeric" })
      );

      newDate2.setMonth(newDate2.getMonth() + 1);
      setSelectedMonth2(
        newDate2.toLocaleString("default", { month: "long", year: "numeric" })
      );
    }
  };

  const canGoBack = () => {
    const newDate1 = new Date(month1);
    return (
      newDate1.getFullYear() > currentMonth.getFullYear() ||
      (newDate1.getFullYear() === currentMonth.getFullYear() &&
        newDate1.getMonth() > currentMonth.getMonth())
    );
  };

  const canGoForward = () => {
    const newDate2 = new Date(month2);
    return (
      newDate2.getFullYear() < nextYearMonth.getFullYear() ||
      (newDate2.getFullYear() === nextYearMonth.getFullYear() &&
        newDate2.getMonth() < nextYearMonth.getMonth())
    );
  };

  // Month Bar code end

  // Calendar-Grid code

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open, onClose]);

  if (!open) {
    return null;
  }

  return (
 
        <div
        ref={popupRef}
          style={{
            marginTop:marginTop,
            position: "absolute",
            zIndex: 10000,
            top: top ? top : "35px",
            background : "var(--white)",
            left: left,
            borderRadius: "1px",
            minWidth:"665.68px",
            minHeight:"342.12px",
            border: "1.2px solid",
            borderColor: "#e6e6e6",
            backgroundColor: "rgb(255, 255, 255)"
          }}
        >
          {showTopButtons === true && Buttonelements}

          <div
            className="calendar-departure-overlay-proxima2"
          >
            {/* <div className="calendar"> */}
              <CalendarHeader
              Theme={Theme}
                showNumberOfDaysCount={showNumberOfDaysCount}
                onClickReturn={onClickReturn}
                selectedDate={selectedDate}
                returnDateValue={returnDateValue}
                activeHeaderBar={activeHeaderBar}
                onClickDepart={onClickDepart}
              />

              <div
                className="calendar-frame-45"
                style={{ marginTop: "10px !important" }}
              >
                <div className="calendar-frame-103-1">
                  <div className="calendar-month-2024-01-february">
                    <div className="calendar-header">
                      <div>
                        <div
                          className="calendar-atoms-mouth"
                          onClick={() =>
                            setmonthDropDown(monthDropdown === 1 ? 0 : 1)
                          }
                        >
                         
                          <div className={`calendar-text calendar-valign-text-middle ${Theme}_semibold color_eerie-black font16_7`}>
                            {month1}
                          </div>

                          <img
                            className="calendar-arrow"
                            src={images["arrow-10.svg"]}
                            alt="Arrow"
                          />
                        </div>
                        {monthDropdown === 1 && (
                          <MonthsPopup
                          Theme={Theme}
                            left={"70px"}
                            minimumMonth={selectedDate}
                            maxMonth={
                              new Date(
                                selectedDate.getFullYear(),
                                selectedDate.getMonth() + 11
                              )
                            }
                            onSelect={(month) => handleMonthSelect1(month)}
                          />
                        )}
                      </div>
                      {canGoBack() && (
                        <img
                          onClick={() => handleBackArrowClick()}
                          className="calendar-arrow-1 calendar-arrow-3"
                          src={images["arrow-9.svg"]}
                          alt="calendar-Arrow"
                        />
                      )}
                    </div>
                    <CalendarGridGenerator
                          Theme={Theme}
                      month={month1}
                      minimumDate={minimumDate}
                      maximumDate={new Date(currentMonth).setFullYear(
                        currentMonth.getFullYear() + 1
                      )}
                      selectedDate={selectedDate}
                      CalenderValues={CalenderValues}
                      onChange={setSelectedDate}
                      todaysDate={`${currentMonth.getDate()}-${
                        currentMonth.getMonth() + 1
                      }-${currentMonth.getFullYear()}`}
                      isReturnCalender={isReturnCalender}
                      returnDateValue={returnDateValue}
                      range={range}
                      onClickClose={onClickClose}
                    />
                  </div>
                  <div className="calendar-month-2024-01-february">
                    <div className="calendar-header-1">
                      <div>
                        <div
                          className="calendar-atoms-mouth-1"
                          onClick={() =>
                            setmonthDropDown(monthDropdown === 2 ? 0 : 2)
                          }
                        >
                          <div className={`calendar-text calendar-valign-text-middle ${Theme}_semibold color_eerie-black font16_7`}>
                            {month2}
                          </div>
                          <img
                            className="calendar-vector"
                            src={images["vector-13.svg"]}
                            alt="Vector"
                          />

                          {monthDropdown === 2 && (
                            <MonthsPopup
                            Theme={Theme}
                              left={"-15px"}
                              minimumMonth={nextMonthDate}
                              maxMonth={
                                new Date(
                                  selectedDate.getFullYear(),
                                  selectedDate.getMonth() + 12
                                )
                              }
                              onSelect={(month) => handleMonthSelect2(month)}
                            />
                          )}
                        </div>
                      </div>

                      {canGoForward() && (
                        <img
                          onClick={() => handleForwardArrowClick()}
                          className="calendar-arrow-2 calendar-arrow-33"
                          src={images["arrow-11.svg"]}
                          alt="Arrow"
                        />
                      )}
                    </div>
                    <CalendarGridGenerator
                      Theme={Theme}
                      month={month2}
                      minimumDate={minimumDate}
                      maximumDate={new Date(currentMonth).setFullYear(
                        currentMonth.getFullYear() + 1
                      )}
                      selectedDate={selectedDate}
                      CalenderValues={CalenderValues}
                      onChange={setSelectedDate}
                      todaysDate={`${currentMonth.getDate()}-${
                        currentMonth.getMonth() + 1
                      }-${currentMonth.getFullYear()}`}
                      isReturnCalender={isReturnCalender}
                      returnDateValue={returnDateValue}
                      range={range}
                      onClickClose={onClickClose}
                    />
                  </div>
                </div>
              </div>
            {/* </div> */}
          </div>
        </div>
   
  );
}
