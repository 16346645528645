import React, { useMemo } from "react";
import { RouterProvider } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { UserLocationFetcher } from "./Redux/Slices/User_Slices/User-SLICE";
import useOffline from "./Hooks/useOffline";

import router from "./Routes/Route";

function App() {
  const dispatch = useDispatch();

  const isOffline = useOffline();

  console.log(" offline : ", isOffline);
  useMemo(() => {
    // These are the functions which will be loaded when user visits/revisits the site
    dispatch(UserLocationFetcher());
  }, [dispatch]);

  return (
    <>
      {isOffline ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            textAlign: "center",
            backgroundColor: "#f0f0f0",
            color: "#333",
            margin: 0,
            padding: 0,
          }}
        >
          {" "}
          <h1 style={{ fontSize: "2.5rem", marginBottom: "0.5rem" }}>
            YOU ARE OFFLINE
          </h1>
          <p style={{ fontSize: "1.25rem" }}>Please check your connection  .... Refresh The Page After Connecting</p>
        </div>
      ) : (
        <RouterProvider router={router} />
      )}
    </>
  );
}

export default App;
