import React, { useState } from "react";
import images from "../../../../../Utils/importImages";
import { useDispatch, useSelector } from "react-redux";
import SpecialFareOptions from "./SpecialFareOptions";
import {
  convertDateStringResultPage,
  formatDate,
} from "../../../../../Utils/Date_Time_Calendar_Functions";
import {
  searchTheFlightUrlCreator,
  setExchangeSearchParas,
  setMultiRowNumber,
  setSearchBarMode,
  setSearchSegmentsParams,
  setTheDates,
  setTravellersAndClasses,
  setTravellersErrors,
} from "../../../../../Redux/Slices/Flight_Slices/ResultPage-Flights-SLICE";
import FlightsOptionsPopup from "../../../../Components/Popups/FlightsOptionsPopup";
import ErrorMessagePopup from "../../../../Components/Popups/ErrorMessagePopup";
import TravellersPopup from "../../../../Components/Popups/TravellersPopup";
import { TravellersFareMessage } from "../../../../../Constants/ConstantsFlights";
import { getLocalStorageJSON } from "../../../../../Utils/localStorageFunc";
import From_To_Popup from "../../../../Components/Popups/From_To_Popup";
import RangeCalender from "../../../../Components/Popups/Calendars/RangeCalender";
export default function MultiwaySearchBar({
  loadingPorgessBar,
  isSticky,
  openPopup,
  setopenPopup,
  openPopupActual,
  setopenPopupActual,
  Theme: FontTheme,
}) {
  let searchBarData = useSelector((state) => state.ResultPageFlightsFlight);

  let travellersData = useSelector(
    (state) => state.ResultPageFlightsFlight.searchParamsTravellers
  );
  let classData = useSelector(
    (state) => state.ResultPageFlightsFlight.searchParamsFare
  );

  let [openTheSearchBar, setopenTheSearchBar] = useState(false);

  const isButtonDisabled = () => {
    return (
      searchBarData.SearchResultsStatus === "Loading" ||
      searchBarData.disableSearch === true ||
      searchBarData.searchParamsTravellers.TravellersAndClassesErrorIn ===
        true ||
      searchBarData.searchParamsTravellers.TravellersAndClassesErrorOut ===
        true ||
      searchBarData.DateError1[0] === true ||
      searchBarData.DateError2[0] === true ||
      searchBarData.DateError3[0] === true ||
      searchBarData.DateError4[0] === true ||
      searchBarData.AirportsToError1[0] === true ||
      searchBarData.AirportsToError2[0] === true ||
      searchBarData.AirportsToError3[0] === true ||
      searchBarData.AirportsToError4[0] === true ||
      searchBarData.AirportsFromError1[0] === true ||
      searchBarData.AirportsFromError2[0] === true ||
      searchBarData.AirportsFromError3[0] === true ||
      searchBarData.AirportsFromError4[0] === true
    );
  };

  let dispatch = useDispatch();

  let getViasData = (multiRowNumber, searchParams) => {
    if (multiRowNumber === 1) {
      return "";
    } else if (multiRowNumber === 2) {
      if (
        searchParams?.[0]?.DestinyCode != "" &&
        searchParams?.[1]?.OriginCode != ""
      ) {
        return `Via ${searchParams?.[0]?.DestinyCode} ${searchParams?.[0]?.DestinationCity} , ${searchParams?.[1]?.OriginCode} ${searchParams?.[1]?.OriginCity}`;
      }
    } else if (multiRowNumber === 3) {
      if (
        searchParams?.[0]?.DestinyCode != "" &&
        searchParams?.[1]?.OriginCode != "" &&
        searchParams?.[1]?.DestinyCode != "" &&
        searchParams?.[2]?.OriginCode != ""
      ) {
        return `Via ${searchParams?.[0]?.DestinyCode} ${searchParams?.[0]?.DestinationCity} , ${searchParams?.[1]?.OriginCode} ${searchParams?.[1]?.OriginCity} , ${searchParams?.[1]?.DestinyCode} ${searchParams?.[1]?.DestinationCity}  , ${searchParams?.[2]?.OriginCode} ${searchParams?.[2]?.OriginCity}`;
      }
    } else if (multiRowNumber === 4) {
      if (
        searchParams?.[0]?.DestinyCode != "" &&
        searchParams?.[1]?.OriginCode != "" &&
        searchParams?.[1]?.DestinyCode != "" &&
        searchParams?.[2]?.OriginCode != "" &&
        searchParams?.[2]?.DestinyCode != "" &&
        searchParams?.[3]?.OriginCode != ""
      ) {
        // return `Via ${searchParams?.[0]?.DestinyCode} ${searchParams?.[0]?.DestinationCity} , ${searchParams?.[1]?.OriginCode} ${searchParams?.[1]?.OriginCity} , ${searchParams?.[1]?.DestinyCode} ${searchParams?.[1]?.DestinationCity}  , ${searchParams?.[2]?.OriginCode} ${searchParams?.[2]?.OriginCity}  , ${searchParams?.[2]?.DestinyCode} ${searchParams?.[2]?.DestinationCity} , ${searchParams?.[3]?.OriginCode} ${searchParams?.[3]?.OriginCity}`;
        return `Via ${searchParams?.[0]?.DestinationCity} , ${searchParams?.[1]?.OriginCity} , ${searchParams?.[1]?.DestinationCity}  , ${searchParams?.[2]?.OriginCity}  , ${searchParams?.[2]?.DestinationCity} , ${searchParams?.[3]?.OriginCity}`;
      }
    }
  };

  let changeTheSearchBarMode = (option) => {
    if (option === "One") {
      dispatch(setSearchBarMode(1));
      dispatch(setMultiRowNumber(1));
    } else if (option === "Round") {
      dispatch(setSearchBarMode(2));
      dispatch(setMultiRowNumber(1));
    } else if (option === "Multi") {
      dispatch(setSearchBarMode(3));
    }
  };

  let changeTheDate1 = (date) => {
    let selectedDate1 = date;
    let selectedDate2 =
      searchBarData?.searchParams[1]?.OriginDate !== null
        ? new Date(searchBarData?.searchParams[1]?.OriginDate)
        : null;
    let selectedDate3 =
      searchBarData?.searchParams[2]?.OriginDate !== null
        ? new Date(searchBarData?.searchParams[2]?.OriginDate)
        : null;
    let selectedDate4 =
      searchBarData?.searchParams[3]?.OriginDate !== null
        ? new Date(searchBarData?.searchParams[3]?.OriginDate)
        : null;
    let ReturnDate = searchBarData?.searchParams[0]?.ReturnDate
      ? new Date(searchBarData?.searchParams[0]?.ReturnDate)
      : null;

    if (ReturnDate != null && selectedDate1 != null) {
      if (selectedDate1 > ReturnDate) {
        const newDate = new Date(selectedDate1);
        newDate.setDate(selectedDate1.getDate() + 1);
        ReturnDate = newDate;
      }
    }

    if (selectedDate1 !== null && selectedDate2 !== null) {
      if (selectedDate1 > selectedDate2) {
        const newDate = new Date(selectedDate1);
        newDate.setDate(selectedDate1.getDate() + 1);
        selectedDate2 = newDate;
      }
    }

    if (selectedDate2 !== null && selectedDate3 !== null) {
      if (selectedDate2 > selectedDate3) {
        const newDate = new Date(selectedDate2);
        newDate.setDate(selectedDate2.getDate() + 1);
        selectedDate3 = newDate;
      }
    }

    if (selectedDate3 !== null && selectedDate4 !== null) {
      if (selectedDate3 > selectedDate4) {
        const newDate = new Date(selectedDate3);
        newDate.setDate(selectedDate3.getDate() + 1);
        selectedDate4 = newDate;
      }
    }

    let Obj = [
      {
        OriginDate: formatDate(selectedDate1),
        ReturnDate: formatDate(ReturnDate),
      },
      {
        OriginDate: formatDate(selectedDate2),
        ReturnDate: null,
      },
      {
        OriginDate: formatDate(selectedDate3),
        ReturnDate: null,
      },
      {
        OriginDate: formatDate(selectedDate4),
        ReturnDate: null,
      },
    ];

    dispatch(setTheDates(Obj));
  };

  let changeTheDate2 = (date) => {
    let selectedDate1 =
      searchBarData?.searchParams[0]?.OriginDate !== null
        ? new Date(searchBarData?.searchParams[0]?.OriginDate)
        : null;
    let selectedDate2 = date;
    let selectedDate3 =
      searchBarData?.searchParams[2]?.OriginDate !== null
        ? new Date(searchBarData?.searchParams[2]?.OriginDate)
        : null;
    let selectedDate4 =
      searchBarData?.searchParams[3]?.OriginDate !== null
        ? new Date(searchBarData?.searchParams[3]?.OriginDate)
        : null;
    let ReturnDate = searchBarData?.searchParams[0]?.ReturnDate
      ? new Date(searchBarData?.searchParams[0]?.ReturnDate)
      : null;

    if (selectedDate1 !== null && selectedDate2 !== null) {
      if (selectedDate1 > selectedDate2) {
        const newDate = new Date(selectedDate1);
        newDate.setDate(selectedDate1.getDate() + 1);
        selectedDate2 = newDate;
      }
    }

    if (selectedDate2 !== null && selectedDate3 !== null) {
      if (selectedDate2 > selectedDate3) {
        const newDate = new Date(selectedDate2);
        newDate.setDate(selectedDate2.getDate() + 1);
        selectedDate3 = newDate;
      }
    }

    if (selectedDate3 !== null && selectedDate4 !== null) {
      if (selectedDate3 > selectedDate4) {
        const newDate = new Date(selectedDate3);
        newDate.setDate(selectedDate3.getDate() + 1);
        selectedDate4 = newDate;
      }
    }

    let Obj = [
      {
        OriginDate: formatDate(selectedDate1),
        ReturnDate: formatDate(ReturnDate),
      },
      {
        OriginDate: formatDate(selectedDate2),
        ReturnDate: null,
      },
      {
        OriginDate: formatDate(selectedDate3),
        ReturnDate: null,
      },
      {
        OriginDate: formatDate(selectedDate4),
        ReturnDate: null,
      },
    ];

    dispatch(setTheDates(Obj));
  };

  let changeTheDate3 = (date) => {
    let selectedDate1 =
      searchBarData?.searchParams[0]?.OriginDate !== null
        ? new Date(searchBarData?.searchParams[0]?.OriginDate)
        : null;
    let selectedDate2 =
      searchBarData?.searchParams[1]?.OriginDate !== null
        ? new Date(searchBarData?.searchParams[1]?.OriginDate)
        : null;
    let selectedDate3 = date;
    let selectedDate4 =
      searchBarData?.searchParams[3]?.OriginDate !== null
        ? new Date(searchBarData?.searchParams[3]?.OriginDate)
        : null;

    let ReturnDate = new Date(searchBarData?.searchParams[0]?.ReturnDate);

    if (selectedDate3 !== null && selectedDate4 !== null) {
      if (selectedDate3 > selectedDate4) {
        const newDate = new Date(selectedDate3);
        newDate.setDate(selectedDate3.getDate() + 1);
        selectedDate4 = newDate;
      }
    }

    let Obj = [
      {
        OriginDate: formatDate(selectedDate1),
        ReturnDate: formatDate(ReturnDate),
      },
      {
        OriginDate: formatDate(selectedDate2),
        ReturnDate: null,
      },
      {
        OriginDate: formatDate(selectedDate3),
        ReturnDate: null,
      },
      {
        OriginDate: formatDate(selectedDate4),
        ReturnDate: null,
      },
    ];

    dispatch(setTheDates(Obj));
  };

  let changeTheDate4 = (date) => {
    let selectedDate1 =
      searchBarData?.searchParams[0]?.OriginDate !== null
        ? new Date(searchBarData?.searchParams[0]?.OriginDate)
        : null;
    let selectedDate2 =
      searchBarData?.searchParams[1]?.OriginDate !== null
        ? new Date(searchBarData?.searchParams[1]?.OriginDate)
        : null;
    let selectedDate3 =
      searchBarData?.searchParams[2]?.OriginDate !== null
        ? new Date(searchBarData?.searchParams[2]?.OriginDate)
        : null;
    let selectedDate4 = date;
    let ReturnDate = searchBarData?.searchParams[0]?.ReturnDate
      ? new Date(searchBarData?.searchParams[0]?.ReturnDate)
      : null;

    let Obj = [
      {
        OriginDate: formatDate(selectedDate1),
        ReturnDate: formatDate(ReturnDate),
      },
      {
        OriginDate: formatDate(selectedDate2),
        ReturnDate: null,
      },
      {
        OriginDate: formatDate(selectedDate3),
        ReturnDate: null,
      },
      {
        OriginDate: formatDate(selectedDate4),
        ReturnDate: null,
      },
    ];

    dispatch(setTheDates(Obj));
  };

  let handleClose = (item, PopupType) => {
    // console.log("item is : ", item);
    // console.log("PopupType is : ", PopupType);
    let StructuredAirport = {
      City: item.city_name,
      Code: item.airport_code,
      AirportName: item.airport_name,
      CountryCode: item.country_code,
      CountryName: item.country_name,

      type: PopupType[0],
      PopupType: PopupType[1],

      airport: item,
    };

    dispatch(setSearchSegmentsParams(StructuredAirport));

    setopenPopup(openPopup + 1);
    setopenPopupActual(openPopup + 1);
  };

  const handleSearch = () => {
    dispatch(searchTheFlightUrlCreator());
  };

  return (
    <div
      className={`result-page-flights result-page-flights-search-bar ${
        isSticky ? "sticky" : ""
      }`}
    >
      <div className={`result-page-flights-frame-97 ${FontTheme}`}>
        <div className="result-page-flights-frame-27" style={{marginBottom:"1px"}}>
          <div className="result-page-flights-frame-33">
            <div className="result-page-flights-frame-35">
              <div
                className="result-page-flights-frame-101"
                onClick={() => setopenPopup(openPopup === 12 ? 0 : 12)}
              >
                <div
                  className={`result-page-flights-trip-type ${FontTheme}_light color_white font12`}
                >
                  TRIP TYPE
                </div>
                <div className="result-page-flights-frame-168-6">
                  <div
                    className={`result-page-flights-one-way ${FontTheme}_regular color_white font16`}
                  >
                    Multi Way
                  </div>
                  <img
                    src={images["arrow.png"]}
                    alt="arrow"
                    style={{
                      position: "relative",
                      transform: openPopup === 3 ? "rotate(180deg)" : null,
                    }}
                  />
                </div>

                {openPopup === 12 && (
                  <FlightsOptionsPopup
                    options={["One", "Round"]}
                    open={openPopup === 12}
                    onClickClose={changeTheSearchBarMode}
                    position={openPopup}
                    onClose={() => {
                      setopenPopup(0);
                      setopenPopupActual(0);
                    }}
                    Theme={FontTheme}
                  />
                )}
              </div>

              <div className="result-page-flights-frame-99-77">
                <div
                  className="result-page-flights-frame-288"
                  onClick={() => setopenTheSearchBar(!openTheSearchBar)}
                >
                  <div className="result-page-flights-frame-99-8">
                    <div
                      className={`trip ${FontTheme}_light color_white font12`}
                    >
                      TRIP
                    </div>
                  </div>
                  {/* <div className="result-page-flights-frame-232-2 result-page-flights-frame-232-3"> */}
                  <div>
                    <div
                      className={`result-page-flights-del-new-delhi ${FontTheme}_regular color_white font16`}
                    >
                      <span>
                        {" "}
                        {searchBarData?.searchParams?.[0]?.OriginCode}{" "}
                        {searchBarData?.searchParams?.[0]?.OriginCode !== "" &&
                          " , "}
                        {searchBarData?.searchParams?.[0]?.OriginCity}
                      </span>

                      <span>
                        {(() => {
                          const getDestinyCode = () => {
                            if (
                              searchBarData?.searchParams?.[
                                searchBarData?.multiRowNumber - 1
                              ]?.DestinyCode !== ""
                            ) {
                              return searchBarData?.searchParams?.[
                                searchBarData?.multiRowNumber - 1
                              ]?.DestinyCode;
                            }
                            if (
                              searchBarData?.searchParams?.[
                                searchBarData?.multiRowNumber - 2
                              ]?.DestinyCode !== ""
                            ) {
                              return searchBarData?.searchParams?.[
                                searchBarData?.multiRowNumber - 2
                              ]?.DestinyCode;
                            }
                            if (
                              searchBarData?.searchParams?.[
                                searchBarData?.multiRowNumber - 3
                              ]?.DestinyCode !== ""
                            ) {
                              return searchBarData?.searchParams?.[
                                searchBarData?.multiRowNumber - 3
                              ]?.DestinyCode;
                            }
                            return searchBarData?.searchParams?.[
                              searchBarData?.multiRowNumber - 4
                            ]?.DestinyCode;
                          };

                          const getDestinationCity = () => {
                            if (
                              searchBarData?.searchParams?.[
                                searchBarData?.multiRowNumber - 1
                              ]?.DestinationCity !== ""
                            ) {
                              return searchBarData?.searchParams?.[
                                searchBarData?.multiRowNumber - 1
                              ]?.DestinationCity;
                            }
                            if (
                              searchBarData?.searchParams?.[
                                searchBarData?.multiRowNumber - 2
                              ]?.DestinationCity !== ""
                            ) {
                              return searchBarData?.searchParams?.[
                                searchBarData?.multiRowNumber - 2
                              ]?.DestinationCity;
                            }
                            if (
                              searchBarData?.searchParams?.[
                                searchBarData?.multiRowNumber - 3
                              ]?.DestinationCity !== ""
                            ) {
                              return searchBarData?.searchParams?.[
                                searchBarData?.multiRowNumber - 3
                              ]?.DestinationCity;
                            }
                            return searchBarData?.searchParams?.[
                              searchBarData?.multiRowNumber - 4
                            ]?.DestinationCity;
                          };

                          const destinyCode = getDestinyCode();
                          const destinationCity = getDestinationCity();

                          return (
                            <>
                              {destinyCode && destinationCity ? (
                                <img
                                  className="exchange_alt"
                                  src={images["round-trip-icon.svg"]}
                                  alt="exchange-alt"
                                />
                              ) : (
                                ""
                              )}
                              {destinyCode}
                              {destinyCode && destinationCity ? " , " : ""}
                              {destinationCity}
                              &nbsp;
                            </>
                          );
                        })()}
                      </span>

                      <span
                        className={`result-page-flights-via-sydney-toronto-brampton ${FontTheme}_regular color_pumice font14`}
                      >
                        {getViasData(
                          searchBarData?.multiRowNumber,
                          searchBarData?.searchParams
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div style={{minWidth:"20%"}}>
                <div
                  className="result-page-flights-frame-32"
                  onClick={() => {
                    setopenPopup(13);
                    setopenPopupActual(13);
                  }}
                >
                  <div
                    className={`result-page-flights-travellers-class ${FontTheme}_light color_white font12`}
                  >
                    TRAVELLERS &amp; CLASS
                  </div>
                  <div className="result-page-flights-frame-1-9">
                    <div
                      className={`result-page-flights-address-18 address-19 ${FontTheme}_regular color_white font16`}
                    >
                      {searchBarData?.searchParamsTravellers?.Adults +
                        searchBarData?.searchParamsTravellers?.Childs +
                        searchBarData?.searchParamsTravellers?.Infrants}{" "}
                      Travellers ,{" "}
                      {searchBarData?.searchParamsFare?.class === "Economy" && (
                        <>
                          {searchBarData?.searchParamsFare?.class}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </>
                      )}
                      {searchBarData?.searchParamsFare?.class ===
                        "Business" && (
                        <>
                          {searchBarData?.searchParamsFare?.class}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </>
                      )}
                      {searchBarData?.searchParamsFare?.class === "First" && (
                        <>
                          {searchBarData?.searchParamsFare?.class}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </>
                      )}
                      {searchBarData?.searchParamsFare?.class ==
                        "Premium Economy" &&
                        searchBarData?.searchParamsFare?.class}
                    </div>
                  </div>
                  {searchBarData?.searchParamsTravellers
                    ?.TravellersAndClassesErrorOut === true && (
                    <ErrorMessagePopup
                      Theme={FontTheme}
                      style={{
                        display: "flex",
                        alignItems: "end",
                        width: "max-content",
                        zIndex: 1,
                        backgroundColor: "#FFE0DA",
                        position: "absolute",
                        top: "108%",
                        left: "-30%",
                        padding: "4px 8px",
                      }}
                      // addClass={searchBarData?.searchClicked}
                      message={TravellersFareMessage(
                        searchBarData?.searchParamsFare?.type
                      )}
                    />
                  )}
                </div>

                {openPopup === 13 && openPopupActual === 13 && (
                  <TravellersPopup
                    Theme={FontTheme}
                    open={openPopup === 13}
                    position={openPopup}
                    onClickClose={() => {
                      setopenPopup(0);
                      setopenPopupActual(0);
                    }}
                    onClose={() => {
                      setopenPopup(0);
                      setopenPopupActual(0);
                    }}
                    travellersData={travellersData}
                    classData={classData}
                    timeOutFunc={setTravellersErrors}
                    setTravellersAndClassesFunc={setTravellersAndClasses}
                  />
                )}
              </div>

              <div
                className={`result-page-flights-frame-20-2 result-page-flights-frame-20-3 ${
                  isButtonDisabled() ? "disabled" : ""
                } ${FontTheme}`}
                onClick={!isButtonDisabled() ? handleSearch : null}
                style={{width : "10%" , display:"flex"}}
              >
                <div
                  className={`result-page-flights-search-1 ${FontTheme}_semibold color_${
                    FontTheme == "proxima" ? "endeavour" : "optera-galaxy"
                  } font18`}
                >
                  SEARCH
                </div>
              </div>
            </div>
          </div>
        </div>
        {openTheSearchBar && (
          <>
            <div className="result-page-flights-frame-3" style={{marginBottom:"3px"}}>
              <div className="result-page-flights-frame-101-1-1">
                <div
                  className={`result-page-flights-trip-5 ${FontTheme}_light color_white font12`}
                >
                  TRIP 1
                </div>
              </div>
              <div className="result-page-flights-frame-99-7-7">
                <div style={{minWidth:"50%"}}>
                  <div
                    className="result-page-flights-frame-28-1"
                    onClick={() => {
                      setopenPopup(14);
                      setopenPopupActual(14);
                    }}
                  >
                    <div className="result-page-flights-frame-99-8">
                      <div
                        className={`result-page-flights-place-32 ${FontTheme}_light color_white font12`}
                      >
                        FROM
                      </div>
                    </div>
                    <div
                      className={`result-page-flights-innextText-2 ${FontTheme}_regular color_white font16`}
                    >
                      {searchBarData?.searchParams?.[0]?.OriginCode}{" "}
                      {searchBarData?.searchParams?.[0]?.OriginCode !== "" &&
                        " , "}
                      {searchBarData?.searchParams?.[0]?.OriginCity}{" "}
                      {searchBarData?.searchParams?.[0]?.OriginCity !== "" &&
                        " , "}
                      {searchBarData?.searchParams?.[0]?.OriginCountry}
                    </div>
                  </div>
                  {searchBarData?.AirportsFromError1[0] === true && (
                    <ErrorMessagePopup
                      Theme={FontTheme}
                      style={{
                        display: "flex",
                        alignItems: "end",
                        width: "max-content",
                        zIndex: 1,
                        backgroundColor: "#FFE0DA",
                        position: "absolute",
                        top: "115%",
                        left: "-10px",
                        padding: "4px 8px",
                      }}
                      // addClass={searchBarData?.searchClicked}
                      message={searchBarData?.AirportsFromError1[1]}
                    />
                  )}
                  {openPopup === 14 && openPopupActual === 14 && (
                    <From_To_Popup
                      Theme={FontTheme}
                      popular_cities={searchBarData?.popularAirports}
                      recent_searches={
                        getLocalStorageJSON("fts-hm-fa") === null
                          ? []
                          : getLocalStorageJSON("fts-hm-fa")
                      }
                      open={openPopup === 14}
                      position={openPopup}
                      PopupType={[0, 0]}
                      onClickClose={handleClose}
                      onClose={() => {
                        setopenPopup(0);
                        setopenPopupActual(0);
                      }}
                      left={"0px"}
                      top={"25px"}
                      placeholder={"From"}
                      positionType={"absolute"}
                    />
                  )}
                </div>

                <div  style={{minWidth:"50%"}}>
                  <div
                    className="result-page-flights-frame-29-1"
                    onClick={() => {
                      setopenPopup(15);
                      setopenPopupActual(15);
                    }}
                  >
                    <div className="result-page-flights-frame-99-9">
                      <div
                        className={`result-page-flights-to ${FontTheme}_light color_white font12`}
                      >
                        TO
                      </div>
                    </div>

                    <div
                      className={`result-page-flights-innextText-2 ${FontTheme}_regular color_white font16`}
                    >
                      {searchBarData?.searchParams?.[0]?.DestinyCode}{" "}
                      {searchBarData?.searchParams?.[0]?.DestinyCode !== "" &&
                        " , "}
                      {searchBarData?.searchParams?.[0]?.DestinationCity}{" "}
                      {searchBarData?.searchParams?.[0]?.DestinationCity !==
                        "" && " , "}
                      {searchBarData?.searchParams?.[0]?.DestinationCountry}
                    </div>
                  </div>

                  {searchBarData?.AirportsToError1[0] === true && (
                    <ErrorMessagePopup
                      Theme={FontTheme}
                      style={{
                        display: "flex",
                        alignItems: "end",
                        width: "max-content",
                        zIndex: 1,
                        backgroundColor: "#FFE0DA",
                        position: "absolute",
                        top: "115%",
                        left: "250px",
                        padding: "4px 8px",
                      }}
                      // addClass={searchBarData?.searchClicked}
                      message={searchBarData?.AirportsToError1[1]}
                    />
                  )}
                  {openPopup === 15 && openPopupActual === 15 && (
                    <From_To_Popup
                      Theme={FontTheme}
                      popular_cities={searchBarData?.popularAirports}
                      recent_searches={
                        getLocalStorageJSON("fts-hm-ta") === null
                          ? []
                          : getLocalStorageJSON("fts-hm-ta")
                      }
                      open={openPopup === 15}
                      position={openPopup}
                      PopupType={[0, 1]}
                      onClickClose={handleClose}
                      onClose={() => {
                        setopenPopup(0);
                        setopenPopupActual(0);
                      }}
                      left={"303px"}
                      top={"25px"}
                      placeholder={"To"}
                      positionType={"absolute"}
                    />
                  )}
                </div>

                <img
                  style={{
                    position: "absolute",
                    top: "20px",
                    // right: "-9px",
                  }}
                  src={images["exchange-alt.png"]}
                  alt="exchange-alt"
                  onClick={() => dispatch(setExchangeSearchParas(0))}
                />
              </div>
              <div className="result-page-flights-frame-100-10">
                <div>
                  <div
                    className="result-page-flights-frame-30"
                    style={{ padding: "9px 12px" }}
                    onClick={() => {
                      setopenPopup(16);
                      setopenPopupActual(16);
                    }}
                  >
                    <div className="result-page-flights-frame-103-6">
                      <div
                        className={`result-page-flights-departure ${FontTheme}_light color_white font12`}
                      >
                        DEPARTURE
                      </div>
                    </div>
                    <div
                      className={`result-page-flights-tue-30-jan-9 ${FontTheme}_regular color_white font16`}
                    >
                      {convertDateStringResultPage(
                        searchBarData?.searchParams?.[0]?.OriginDate
                      )}
                    </div>
                  </div>
                </div>
                {searchBarData?.DateError1[0] === true && (
                  <ErrorMessagePopup
                    Theme={FontTheme}
                    style={{
                      display: "flex",
                      alignItems: "end",
                      width: "max-content",
                      zIndex: 1,
                      backgroundColor: "#FFE0DA",
                      position: "absolute",
                      top: "120%",
                      left: "10px",
                      padding: "4px 8px",
                    }}
                    // addClass={searchBarData?.searchClicked}
                    message={searchBarData?.DateError1[1]}
                  />
                )}
                {openPopup === 16 && openPopupActual === 16 && (
                  <RangeCalender
                    Theme={FontTheme}
                    marginTop={"5px"}
                    top={"20px"}
                    left={"-70px"}
                    showTopButtons={false}
                    open={openPopup === 16}
                    position={openPopup}
                    onClickClose={() => {
                      setopenPopup(16);
                      setopenPopupActual(16);
                    }}
                    onClose={() => {
                      setopenPopup(0);
                      setopenPopupActual(0);
                    }}
                    minimumDate={new Date().setDate(new Date().getDate() - 1)}
                    CalenderValues={null}
                    selectedDate={
                      new Date(searchBarData?.searchParams[0]?.OriginDate)
                    }
                    setSelectedDate={changeTheDate1}
                    showReturnDate={false}
                    showNumberOfDaysCount={false}
                    onClickReturn={null}
                    activeHeaderBar={1}
                    returnDateValue={null}
                    onClickDepart={null}
                    isReturnCalender={false}
                    range={[null, null]}
                  />
                )}
              </div>

              {/* // from here add onclick  */}

              <div className="result-page-flights-frame-104">
                <div
                  style={
                    searchBarData?.multiRowNumber < 2
                      ? {}
                      : { visibility: "hidden" }
                  }
                  className={`result-page-flights-frame-4444 ${FontTheme}`}
                  onClick={() => dispatch(setMultiRowNumber(2))}
                >
                  <img
                 
                    src={images["white-plus-icon.svg"]}
                    alt="Plus"
                  />
                  <div
                    className={`result-page-flights-add-city ${FontTheme}_regular color_white font14`}
                  >
                    ADD&nbsp;&nbsp;CITY
                  </div>
                </div>
              </div>
              <div className="result-page-flights-frame-103-1"></div>
            </div>

            {searchBarData?.multiRowNumber > 1 && (
              <div className="result-page-flights-frame-3" style={{marginBottom:"3px"}}>
                <div className="result-page-flights-frame-101-1-1">
                  <div
                    className={`result-page-flights-trip-5 ${FontTheme}_light color_white font12`}
                  >
                    TRIP 2
                  </div>
                </div>
                <div className="result-page-flights-frame-99-7-7">
                  <div style={{minWidth : "50%"}}>
                    <div
                      className="result-page-flights-frame-28-1"
                      onClick={() => {
                        setopenPopup(17);
                        setopenPopupActual(17);
                      }}
                    >
                      <div className="result-page-flights-frame-99-8">
                        <div
                          className={`result-page-flights-place-32 ${FontTheme}_light color_white font12`}
                        >
                          FROM
                        </div>
                      </div>
                      <div
                        className={`result-page-flights-innextText-2 ${FontTheme}_regular color_white font16`}
                      >
                        {searchBarData?.searchParams?.[1]?.OriginCode}{" "}
                        {searchBarData?.searchParams?.[1]?.OriginCode !== "" &&
                          " , "}
                        {searchBarData?.searchParams?.[1]?.OriginCity}{" "}
                        {searchBarData?.searchParams?.[1]?.OriginCity !== "" &&
                          " , "}
                        {searchBarData?.searchParams?.[1]?.OriginCountry}
                      </div>
                    </div>

                    {searchBarData?.AirportsFromError2[0] === true && (
                      <ErrorMessagePopup
                        Theme={FontTheme}
                        style={{
                          display: "flex",
                          alignItems: "end",
                          width: "max-content",
                          zIndex: 1,
                          backgroundColor: "#FFE0DA",
                          position: "absolute",
                          top: "115%",
                          left: "-10px",
                          padding: "4px 8px",
                        }}
                        // addClass={searchBarData?.searchClicked}
                        message={searchBarData?.AirportsFromError2[1]}
                      />
                    )}
                    {openPopup === 17 && openPopupActual === 17 && (
                      <From_To_Popup
                        Theme={FontTheme}
                        popular_cities={searchBarData?.popularAirports}
                        recent_searches={
                          getLocalStorageJSON("fts-hm-fa") === null
                            ? []
                            : getLocalStorageJSON("fts-hm-fa")
                        }
                        open={openPopup === 17}
                        position={openPopup}
                        PopupType={[1, 0]}
                        onClickClose={handleClose}
                        onClose={() => {
                          setopenPopup(0);
                          setopenPopupActual(0);
                        }}
                        left={"0px"}
                        top={"25px"}
                        placeholder={"From"}
                        positionType={"absolute"}
                      />
                    )}
                  </div>

                  <div style={{minWidth : "50%"}}>
                    <div
                      className="result-page-flights-frame-29-1"
                      onClick={() => {
                        setopenPopup(18);
                        setopenPopupActual(18);
                      }}
                    >
                      <div className="result-page-flights-frame-99-9">
                        <div
                          className={`result-page-flights-to ${FontTheme}_light color_white font12`}
                        >
                          TO
                        </div>
                      </div>

                      <div
                        className={`result-page-flights-innextText-2 ${FontTheme}_regular color_white font16`}
                      >
                        {searchBarData?.searchParams?.[1]?.DestinyCode}{" "}
                        {searchBarData?.searchParams?.[1]?.DestinyCode !== "" &&
                          " , "}
                        {searchBarData?.searchParams?.[1]?.DestinationCity}{" "}
                        {searchBarData?.searchParams?.[1]?.DestinationCity !==
                          "" && " , "}
                        {searchBarData?.searchParams?.[1]?.DestinationCountry}
                      </div>
                    </div>

                    {searchBarData?.AirportsToError2[0] === true && (
                      <ErrorMessagePopup
                        Theme={FontTheme}
                        style={{
                          display: "flex",
                          alignItems: "end",
                          width: "max-content",
                          zIndex: 1,
                          backgroundColor: "#FFE0DA",
                          position: "absolute",
                          top: "115%",
                          left: "250px",
                          padding: "4px 8px",
                        }}
                        // addClass={searchBarData?.searchClicked}
                        message={searchBarData?.AirportsToError2[1]}
                      />
                    )}
                    {openPopup === 18 && openPopupActual === 18 && (
                      <From_To_Popup
                        Theme={FontTheme}
                        popular_cities={searchBarData?.popularAirports}
                        recent_searches={
                          getLocalStorageJSON("fts-hm-ta") === null
                            ? []
                            : getLocalStorageJSON("fts-hm-ta")
                        }
                        open={openPopup === 18}
                        position={openPopup}
                        PopupType={[1, 1]}
                        onClickClose={handleClose}
                        onClose={() => {
                          setopenPopup(0);
                          setopenPopupActual(0);
                        }}
                           left={"303px"}
                        top={"25px"}
                        placeholder={"To"}
                        positionType={"absolute"}
                      />
                    )}
                  </div>

                  <img
                    style={{
                      position: "absolute",
                      top: "20px",
                      // right: "-9px",
                    }}
                    src={images["exchange-alt.png"]}
                    alt="exchange-alt"
                    onClick={() => dispatch(setExchangeSearchParas(1))}
                  />
                </div>
                <div className="result-page-flights-frame-100-10">
                  <div>
                    <div
                      className="result-page-flights-frame-30"
                      style={{ padding: "9px 12px" }}
                      onClick={() => {
                        setopenPopup(19);
                        setopenPopupActual(19);
                      }}
                    >
                      <div className="result-page-flights-frame-103-6">
                        <div
                          className={`result-page-flights-departure ${FontTheme}_light color_white font12`}
                        >
                          DEPARTURE
                        </div>
                      </div>
                      <div
                        className={`result-page-flights-tue-30-jan-9 ${FontTheme}_regular color_white font16`}
                      >
                        {searchBarData?.searchParams?.[1]?.OriginDate ===
                        null ? (
                          <span style={{ visibility: "hidden" }}>
                            aaa, aa aaa, aa
                          </span>
                        ) : (
                          convertDateStringResultPage(
                            searchBarData?.searchParams?.[1]?.OriginDate
                          )
                        )}
                      </div>
                    </div>
                    {searchBarData?.DateError2[0] === true && (
                      <ErrorMessagePopup
                        Theme={FontTheme}
                        style={{
                          display: "flex",
                          alignItems: "end",
                          width: "max-content",
                          zIndex: 1,
                          backgroundColor: "#FFE0DA",
                          position: "absolute",
                          top: "120%",
                          left: "10px",
                          padding: "4px 8px",
                        }}
                        // addClass={searchBarData?.searchClicked}
                        message={searchBarData?.DateError2[1]}
                      />
                    )}
                    {openPopup === 19 && openPopupActual === 19 && (
                      <RangeCalender
                        Theme={FontTheme}
                        marginTop={"5px"}
                        top={"20px"}
                        left={"-70px"}
                        showTopButtons={false}
                        open={openPopup === 19}
                        position={openPopup}
                        onClickClose={() => {
                          setopenPopup(0);
                          setopenPopupActual(0);
                        }}
                        onClose={() => {
                          setopenPopup(0);
                          setopenPopupActual(0);
                        }}
                        minimumDate={
                          searchBarData?.searchParams[0]?.OriginDate === null
                            ? new Date().setDate(new Date().getDate() - 1)
                            : new Date(
                                searchBarData?.searchParams[0]?.OriginDate
                              ).setDate(
                                new Date(
                                  searchBarData?.searchParams[0]?.OriginDate
                                ).getDate() - 1
                              )
                        }
                        selectedDate={
                          new Date(searchBarData?.searchParams[1]?.OriginDate)
                        }
                        CalenderValues={null}
                        setSelectedDate={changeTheDate2}
                        showReturnDate={false}
                        showNumberOfDaysCount={false}
                        onClickReturn={null}
                        activeHeaderBar={1}
                        returnDateValue={null}
                        onClickDepart={null}
                        isReturnCalender={false}
                        range={[null, null]}
                      />
                    )}
                  </div>
                </div>
                <div className="result-page-flights-frame-104">
                  <div
                    style={
                      searchBarData?.multiRowNumber < 3
                        ? {}
                        : { visibility: "hidden" }
                    }
                    className={`result-page-flights-frame-4444 ${FontTheme}`}
                    onClick={() => dispatch(setMultiRowNumber(3))}
                  >
                    <img
                   
                      src={images["white-plus-icon.svg"]}
                      alt="Plus"
                    />
                    <div
                      className={`result-page-flights-add-city ${FontTheme}_regular color_white font14`}
                    >
                      ADD&nbsp;&nbsp;CITY
                    </div>
                  </div>
                </div>

                <div
                  style={
                    searchBarData?.multiRowNumber < 3
                      ? {}
                      : { visibility: "hidden" }
                  }
                  className="result-page-flights-frame-103-1"
                  onClick={() => dispatch(setMultiRowNumber(1))}
                >
                  <img
                    className="frame-205"
                    src={images["frame-205-2.svg"]}
                    alt="Frame 205"
                  />
                </div>
              </div>
            )}
            {searchBarData?.multiRowNumber > 2 && (
              <div className="result-page-flights-frame-3" style={{marginBottom:"3px"}}>
                <div className="result-page-flights-frame-101-1-1">
                  <div
                    className={`result-page-flights-trip-5 ${FontTheme}_light color_white font12`}
                  >
                    TRIP 3
                  </div>
                </div>
                <div className="result-page-flights-frame-99-7-7">
                  <div style={{minWidth : "50%"}}>
                    <div
                      className="result-page-flights-frame-28-1"
                      onClick={() => {
                        setopenPopup(20);
                        setopenPopupActual(20);
                      }}
                    >
                      <div className="result-page-flights-frame-99-8">
                        <div
                          className={`result-page-flights-place-32 ${FontTheme}_light color_white font12`}
                        >
                          FROM
                        </div>
                      </div>
                      <div
                        className={`result-page-flights-innextText-2 ${FontTheme}_regular color_white font16`}
                      >
                        {searchBarData?.searchParams?.[2]?.OriginCode}{" "}
                        {searchBarData?.searchParams?.[2]?.OriginCode !== "" &&
                          " , "}
                        {searchBarData?.searchParams?.[2]?.OriginCity}{" "}
                        {searchBarData?.searchParams?.[2]?.OriginCity !== "" &&
                          " , "}
                        {searchBarData?.searchParams?.[2]?.OriginCountry}
                      </div>
                    </div>

                    {searchBarData?.AirportsFromError3[0] === true && (
                      <ErrorMessagePopup
                        Theme={FontTheme}
                        style={{
                          display: "flex",
                          alignItems: "end",
                          width: "max-content",
                          zIndex: 1,
                          backgroundColor: "#FFE0DA",
                          position: "absolute",
                          top: "115%",
                          left: "-10px",
                          padding: "4px 8px",
                        }}
                        // addClass={searchBarData?.searchClicked}
                        message={searchBarData?.AirportsFromError3[1]}
                      />
                    )}
                    {openPopup === 20 && openPopupActual === 20 && (
                      <From_To_Popup
                        Theme={FontTheme}
                        popular_cities={searchBarData?.popularAirports}
                        recent_searches={
                          getLocalStorageJSON("fts-hm-fa") === null
                            ? []
                            : getLocalStorageJSON("fts-hm-fa")
                        }
                        open={openPopup === 20}
                        position={openPopup}
                        PopupType={[2, 0]}
                        onClickClose={handleClose}
                        onClose={() => {
                          setopenPopup(0);
                          setopenPopupActual(0);
                        }}
                        left={"0px"}
                        top={"25px"}
                        placeholder={"From"}
                        positionType={"absolute"}
                      />
                    )}
                  </div>

                  <div style={{minWidth : "50%"}}>
                    <div
                      className="result-page-flights-frame-29-1"
                      onClick={() => {
                        setopenPopup(21);
                        setopenPopupActual(21);
                      }}
                    >
                      <div className="result-page-flights-frame-99-9">
                        <div
                          className={`result-page-flights-to ${FontTheme}_light color_white font12`}
                        >
                          TO
                        </div>
                      </div>

                      <div
                        className={`result-page-flights-innextText-2 ${FontTheme}_regular color_white font16`}
                      >
                        {searchBarData?.searchParams?.[2]?.DestinyCode}{" "}
                        {searchBarData?.searchParams?.[2]?.DestinyCode !== "" &&
                          " , "}
                        {searchBarData?.searchParams?.[2]?.DestinationCity}{" "}
                        {searchBarData?.searchParams?.[2]?.DestinationCity !==
                          "" && " , "}
                        {searchBarData?.searchParams?.[2]?.DestinationCountry}
                      </div>
                    </div>

                    {searchBarData?.AirportsToError3[0] === true && (
                      <ErrorMessagePopup
                        Theme={FontTheme}
                        style={{
                          display: "flex",
                          alignItems: "end",
                          width: "max-content",
                          zIndex: 1,
                          backgroundColor: "#FFE0DA",
                          position: "absolute",
                          top: "115%",
                          left: "250px",
                          padding: "4px 8px",
                        }}
                        // addClass={searchBarData?.searchClicked}
                        message={searchBarData?.AirportsToError3[1]}
                      />
                    )}
                    {openPopup === 21 && openPopupActual === 21 && (
                      <From_To_Popup
                        Theme={FontTheme}
                        popular_cities={searchBarData?.popularAirports}
                        recent_searches={
                          getLocalStorageJSON("fts-hm-ta") === null
                            ? []
                            : getLocalStorageJSON("fts-hm-ta")
                        }
                        open={openPopup === 21}
                        position={openPopup}
                        PopupType={[2, 1]}
                        onClickClose={handleClose}
                        onClose={() => {
                          setopenPopup(0);
                          setopenPopupActual(0);
                        }}
                           left={"303px"}
                        top={"25px"}
                        placeholder={"To"}
                        positionType={"absolute"}
                      />
                    )}
                  </div>

                  <img
                    style={{
                      position: "absolute",
                      top: "20px",
                      // right: "-9px",
                    }}
                    src={images["exchange-alt.png"]}
                    alt="exchange-alt"
                    onClick={() => dispatch(setExchangeSearchParas(2))}
                  />
                </div>
                <div className="result-page-flights-frame-100-10">
                  <div>
                    <div
                      className="result-page-flights-frame-30"
                      style={{ padding: "9px 12px" }}
                      onClick={() => {
                        setopenPopup(22);
                        setopenPopupActual(22);
                      }}
                    >
                      <div className="result-page-flights-frame-103-6">
                        <div
                          className={`result-page-flights-departure ${FontTheme}_light color_white font12`}
                        >
                          DEPARTURE
                        </div>
                      </div>
                      <div
                        className={`result-page-flights-tue-30-jan-9 ${FontTheme}_regular color_white font16`}
                      >
                        {searchBarData?.searchParams?.[2]?.OriginDate ===
                        null ? (
                          <span style={{ visibility: "hidden" }}>
                            aaa, aa aaa, aa
                          </span>
                        ) : (
                          convertDateStringResultPage(
                            searchBarData?.searchParams?.[2]?.OriginDate
                          )
                        )}
                      </div>
                    </div>
                    {searchBarData?.DateError3[0] === true && (
                      <ErrorMessagePopup
                        Theme={FontTheme}
                        style={{
                          display: "flex",
                          alignItems: "end",
                          width: "max-content",
                          zIndex: 1,
                          backgroundColor: "#FFE0DA",
                          position: "absolute",
                          top: "120%",
                          left: "10px",
                          padding: "4px 8px",
                        }}
                        // addClass={searchBarData?.searchClicked}
                        message={searchBarData?.DateError3[1]}
                      />
                    )}
                    {openPopup === 22 && openPopupActual === 22 && (
                      <RangeCalender
                        Theme={FontTheme}
                        top={"20px"}
                        marginTop={"5px"}
                        left={"-70px"}
                        showTopButtons={false}
                        open={openPopup === 22}
                        position={openPopup}
                        onClickClose={() => {
                          setopenPopup(0);
                          setopenPopupActual(0);
                        }}
                        onClose={() => {
                          setopenPopup(0);
                          setopenPopupActual(0);
                        }}
                        minimumDate={
                          searchBarData?.searchParams[1]?.OriginDate === null
                            ? searchBarData?.searchParams[0]?.OriginDate ===
                              null
                              ? new Date().setDate(new Date().getDate() - 1)
                              : new Date(
                                  searchBarData?.searchParams[0]?.OriginDate
                                ).setDate(
                                  new Date(
                                    searchBarData?.searchParams[0]?.OriginDate
                                  ).getDate() - 1
                                )
                            : new Date(
                                searchBarData?.searchParams[1]?.OriginDate
                              ).setDate(
                                new Date(
                                  searchBarData?.searchParams[1]?.OriginDate
                                ).getDate() - 1
                              )
                        }
                        selectedDate={
                          new Date(searchBarData?.searchParams[2]?.OriginDate)
                        }
                        CalenderValues={null}
                        setSelectedDate={changeTheDate3}
                        activeHeaderBar={1}
                        returnDateValue={null}
                        onClickDepart={null}
                        showReturnDate={false}
                        showNumberOfDaysCount={false}
                        onClickReturn={null}
                        isReturnCalender={false}
                        range={[null, null]}
                      />
                    )}
                  </div>
                </div>
                <div className="result-page-flights-frame-104">
                  <div
                    style={
                      searchBarData?.multiRowNumber < 4
                        ? {}
                        : { visibility: "hidden" }
                    }
                    className={`result-page-flights-frame-4444 ${FontTheme}`}
                    onClick={() => dispatch(setMultiRowNumber(4))}
                  >
                    <img
                   
                      src={images["white-plus-icon.svg"]}
                      alt="Plus"
                    />
                    <div
                      className={`result-page-flights-add-city ${FontTheme}_regular color_white font14`}
                    >
                      ADD&nbsp;&nbsp;CITY
                    </div>
                  </div>
                </div>

                <div
                  style={
                    searchBarData?.multiRowNumber < 4
                      ? {}
                      : { visibility: "hidden" }
                  }
                  className="result-page-flights-frame-103-1"
                  onClick={() => dispatch(setMultiRowNumber(2))}
                >
                  <img
                    className="frame-205"
                    src={images["frame-205-2.svg"]}
                    alt="Frame 205"
                  />
                </div>
              </div>
            )}
            {searchBarData?.multiRowNumber > 3 && (
              <div className="result-page-flights-frame-3" style={{marginBottom:"3px"}}>
                <div className="result-page-flights-frame-101-1-1" style={{minWidth:"142px"}}>
                  <div
                    className={`result-page-flights-trip-5 ${FontTheme}_light color_white font12`}
                  >
                    TRIP 4
                  </div>
                </div>
                <div className="result-page-flights-frame-99-7-7">
                  <div style={{minWidth : "50%"}}>
                    <div
                      className="result-page-flights-frame-28-1"
                      onClick={() => {
                        setopenPopup(23);
                        setopenPopupActual(23);
                      }}
                    >
                      <div className="result-page-flights-frame-99-8">
                        <div
                          className={`result-page-flights-place-32 ${FontTheme}_light color_white font12`}
                        >
                          FROM
                        </div>
                      </div>
                      <div
                        className={`result-page-flights-innextText-2 ${FontTheme}_regular color_white font16`}
                      >
                        {searchBarData?.searchParams?.[3]?.OriginCode}{" "}
                        {searchBarData?.searchParams?.[3]?.OriginCode !== "" &&
                          " , "}
                        {searchBarData?.searchParams?.[3]?.OriginCity}{" "}
                        {searchBarData?.searchParams?.[3]?.OriginCity !== "" &&
                          " , "}
                        {searchBarData?.searchParams?.[3]?.OriginCountry}
                      </div>
                    </div>

                    {searchBarData?.AirportsFromError4[0] === true && (
                      <ErrorMessagePopup
                        Theme={FontTheme}
                        style={{
                          display: "flex",
                          alignItems: "end",
                          width: "max-content",
                          zIndex: 1,
                          backgroundColor: "#FFE0DA",
                          position: "absolute",
                          top: "115%",
                          left: "-10px",
                          padding: "4px 8px",
                        }}
                        // addClass={searchBarData?.searchClicked}
                        message={searchBarData?.AirportsFromError4[1]}
                      />
                    )}
                    {openPopup === 23 && openPopupActual === 23 && (
                      <From_To_Popup
                        Theme={FontTheme}
                        popular_cities={searchBarData?.popularAirports}
                        recent_searches={
                          getLocalStorageJSON("fts-hm-fa") === null
                            ? []
                            : getLocalStorageJSON("fts-hm-fa")
                        }
                        open={openPopup === 23}
                        position={openPopup}
                        PopupType={[3, 0]}
                        onClickClose={handleClose}
                        onClose={() => {
                          setopenPopup(0);
                          setopenPopupActual(0);
                        }}
                        left={"0px"}
                        top={"25px"}
                        placeholder={"From"}
                        positionType={"absolute"}
                      />
                    )}
                  </div>

                  <div style={{minWidth : "50%"}}>
                    <div
                      className="result-page-flights-frame-29-1"
                      onClick={() => {
                        setopenPopup(24);
                        setopenPopupActual(24);
                      }}
                    >
                      <div className="result-page-flights-frame-99-9">
                        <div
                          className={`result-page-flights-to ${FontTheme}_light color_white font12`}
                        >
                          TO
                        </div>
                      </div>

                      <div
                        className={`result-page-flights-innextText-2 ${FontTheme}_regular color_white font16`}
                      >
                        {searchBarData?.searchParams?.[3]?.DestinyCode}{" "}
                        {searchBarData?.searchParams?.[3]?.DestinyCode !== "" &&
                          " , "}
                        {searchBarData?.searchParams?.[3]?.DestinationCity}{" "}
                        {searchBarData?.searchParams?.[3]?.DestinationCity !==
                          "" && " , "}
                        {searchBarData?.searchParams?.[3]?.DestinationCountry}
                      </div>
                    </div>
                    {searchBarData?.AirportsToError4[0] === true && (
                      <ErrorMessagePopup
                        Theme={FontTheme}
                        style={{
                          display: "flex",
                          alignItems: "end",
                          width: "max-content",
                          zIndex: 1,
                          backgroundColor: "#FFE0DA",
                          position: "absolute",
                          top: "115%",
                          left: "250px",
                          padding: "4px 8px",
                        }}
                        // addClass={searchBarData?.searchClicked}
                        message={searchBarData?.AirportsToError4[1]}
                      />
                    )}
                    {openPopup === 24 && openPopupActual === 24 && (
                      <From_To_Popup
                        Theme={FontTheme}
                        popular_cities={searchBarData?.popularAirports}
                        recent_searches={
                          getLocalStorageJSON("fts-hm-ta") === null
                            ? []
                            : getLocalStorageJSON("fts-hm-ta")
                        }
                        open={openPopup === 24}
                        position={openPopup}
                        PopupType={[3, 1]}
                        onClickClose={handleClose}
                        onClose={() => {
                          setopenPopup(0);
                          setopenPopupActual(0);
                        }}
                           left={"303px"}
                        top={"25px"}
                        placeholder={"To"}
                        positionType={"absolute"}
                      />
                    )}
                  </div>

                  <img
                    style={{
                      position: "absolute",
                      top: "20px",
                      // right: "-9px",
                    }}
                    src={images["exchange-alt.png"]}
                    alt="exchange-alt"
                    onClick={() => dispatch(setExchangeSearchParas(3))}
                  />
                </div>
                <div className="result-page-flights-frame-100-10">
                  <div>
                    <div
                      className="result-page-flights-frame-30"
                      style={{ padding: "9px 12px" }}
                      onClick={() => {
                        setopenPopup(25);
                        setopenPopupActual(25);
                      }}
                    >
                      <div className="result-page-flights-frame-103-6">
                        <div
                          className={`result-page-flights-departure ${FontTheme}_light color_white font12`}
                        >
                          DEPARTURE
                        </div>
                      </div>
                      <div
                        className={`result-page-flights-tue-30-jan-9 ${FontTheme}_regular color_white font16`}
                      >
                        {searchBarData?.searchParams?.[3]?.OriginDate ===
                        null ? (
                          <span style={{ visibility: "hidden" }}>
                            aaa, aa aaa, aa
                          </span>
                        ) : (
                          convertDateStringResultPage(
                            searchBarData?.searchParams?.[3]?.OriginDate
                          )
                        )}
                      </div>
                    </div>
                    {searchBarData?.DateError4[0] === true && (
                      <ErrorMessagePopup
                        Theme={FontTheme}
                        style={{
                          display: "flex",
                          alignItems: "end",
                          width: "max-content",
                          zIndex: 1,
                          backgroundColor: "#FFE0DA",
                          position: "absolute",
                          top: "120%",
                          left: "10px",
                          padding: "4px 8px",
                        }}
                        // addClass={searchBarData?.searchClicked}
                        message={searchBarData?.DateError4[1]}
                      />
                    )}
                    {openPopup === 25 && openPopupActual === 25 && (
                      <RangeCalender
                        Theme={FontTheme}
                        top={"20px"}
                        marginTop={"-20px"}
                        left={"-70px"}
                        showTopButtons={false}
                        open={openPopup === 25}
                        position={openPopup}
                        onClickClose={() => {
                          setopenPopup(0);
                          setopenPopupActual(0);
                        }}
                        onClose={() => {
                          setopenPopup(0);
                          setopenPopupActual(0);
                        }}
                        minimumDate={
                          searchBarData?.searchParams[2]?.OriginDate === null
                            ? searchBarData?.searchParams[1]?.OriginDate ===
                              null
                              ? searchBarData?.searchParams[0]?.OriginDate ===
                                null
                                ? new Date().setDate(new Date().getDate() - 1)
                                : new Date(
                                    searchBarData?.searchParams[0]?.OriginDate
                                  ).setDate(
                                    new Date(
                                      searchBarData?.searchParams[0]?.OriginDate
                                    ).getDate() - 1
                                  )
                              : new Date(
                                  searchBarData?.searchParams[1]?.OriginDate
                                ).setDate(
                                  new Date(
                                    searchBarData?.searchParams[1]?.OriginDate
                                  ).getDate() - 1
                                )
                            : new Date(
                                searchBarData?.searchParams[2]?.OriginDate
                              ).setDate(
                                new Date(
                                  searchBarData?.searchParams[2]?.OriginDate
                                ).getDate() - 1
                              )
                        }
                        selectedDate={
                          new Date(searchBarData?.searchParams[3]?.OriginDate)
                        }
                        CalenderValues={null}
                        returnDateValue={null}
                        isReturnCalender={false}
                        setSelectedDate={changeTheDate4}
                        showReturnDate={false}
                        showNumberOfDaysCount={false}
                        onClickReturn={null}
                        activeHeaderBar={1}
                        onClickDepart={null}
                        range={[null, null]}
                      />
                    )}
                  </div>
                </div>
                <div
                  className="result-page-flights-frame-103-1"
                  onClick={() => dispatch(setMultiRowNumber(3))}
                >
                  <img
                    style={{ paddingLeft: "14px" }}
                    className="frame-205"
                    src={images["frame-205-2.svg"]}
                    alt="Frame 205"
                  />
                </div>
                <div className="result-page-flights-frame-104">
                  <div
                    style={{ visibility: "hidden" }}
                    className={`result-page-flights-frame-4444 ${FontTheme}`}
                    onClick={() => dispatch(setMultiRowNumber(4))}
                  >
                    <img
                   
                      src={images["white-plus-icon.svg"]}
                      alt="Plus"
                    />
                    <div
                      className={`result-page-flights-add-city ${FontTheme}_regular color_white font14`}
                    >
                      ADD&nbsp;&nbsp;CITY
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}

{
      ((searchBarData?.SearchResultType == 1 || searchBarData?.SearchResultType == 2 || searchBarData?.SearchResultType == 3 || searchBarData?.SearchResultType == 5 || searchBarData?.SearchResultType == 7 || searchBarData?.SearchResultType == 9) && FontTheme == "averta") ? null : 

          <SpecialFareOptions
          Theme={FontTheme}
            selectedOption={searchBarData?.searchParamsFare?.type}
            showTheToggler={
              (searchBarData?.SearchResultType === 4 ||
                searchBarData?.SearchResultType === 6 ||
                searchBarData?.SearchResultType === 8 ||
                searchBarData?.SearchResultType === 10) &&
              searchBarData?.SearchResultsStatus != "Loading"
            }
            ResultView={searchBarData?.ResultView}
          />
  }
     
      </div>
      {loadingPorgessBar}
    </div>
  );
}
