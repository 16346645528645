import React from "react";
import "./index.css";

export default function HomePageSearchBar() {
  return (
    <div className="container-const">
      <div className="construction-sign">
        <div className="crane"></div>
      </div>
    </div>
  );
}
