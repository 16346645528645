import React from 'react'
import AirlinesLogContainer from '../../Components/AirlinesLogContainer';
import { useDispatch } from 'react-redux'
import { formatDateResultBar, formatTimeRangeAMPM } from '../../../../../../Utils/Date_Time_Calendar_Functions';
import images from '../../../../../../Utils/importImages';
import { setbarModeSelected } from '../../../../../../Redux/Slices/Flight_Slices/ResultPage-Flights-SLICE';

function Section({ startValue, ResultPageData, SelectedDatas, popupMode ,setOpenPopup , Theme}) {

    let dispatch = useDispatch();

    return (
        <div
            className={`frame-237 ${Theme}`}
            onClick={() => dispatch(setbarModeSelected(startValue))}
        >
            {SelectedDatas.Segments && (
                <div
                    className={
                        ResultPageData?.barModeSelected === startValue
                            ? `frame-175-4-4 ${Theme}`
                            : `frame-175-4 ${Theme}`
                    }
                >
                    <div className="frame-6">
                        <div className="frame-232">
                            <div className="frame-232-1">
                                <div className="frame-232-2">
                                    <div className="frame-232-3">
                                        <AirlinesLogContainer
                                            allAirlines={SelectedDatas.AirlinesDetails}
                                        />
                                        <div className="frame-203-3">
                                            <div
                                                className={
                                                    ResultPageData?.barModeSelected === startValue
                                                        ? `new-delhi-bangalore ${Theme}_semibold color_white font16`
                                                        : `new-delhi-bangalore ${Theme}_semibold color_eerie-black font16`
                                                }
                                            >
                                                {
                                                    SelectedDatas.Segments[0].Origin.Airport
                                                        .CityName
                                                }{" "}
                                                -{" "}
                                                {
                                                    SelectedDatas.Segments[
                                                        SelectedDatas.Segments.length - 1
                                                    ].Destination.Airport.CityName
                                                }
                                            </div>
                                            <div className="frame-5">
                                                <div
                                                    className={
                                                        ResultPageData?.barModeSelected === startValue
                                                            ? `tue-30-jan-24 ${Theme}_regular color_white font13`
                                                            : `tue-30-jan-24 ${Theme}_regular color_gravel font13`
                                                    }
                                                >
                                                    {formatDateResultBar(
                                                        SelectedDatas.Segments[0].Origin.DepTime
                                                    )}
                                                </div>
                                                <div className="frame-242">
                                                    <div
                                                    onClick={() => setOpenPopup(popupMode , true) }
                                                        className={
                                                            ResultPageData?.barModeSelected === startValue

                                                             ? `flight-details-19 ${Theme}_semibold color_${Theme == "proxima" ? "robins-egg-blue" : "medium-pale-border"} font15`
                                                                : `flight-details-19 ${Theme}_semibold color_${Theme == "proxima" ? "endeavour" : "medium-pale-border"} font15`

                                                      
                                                        }
                                                    >
                                                        Flight Details
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="frame-18">
                                <div className="frame-2-3 frame-2-4">
                                    <div
                                        className={
                                            ResultPageData?.barModeSelected === startValue
                                                ? `x0500-am-1200-pm ${Theme}_regular color_white font13`
                                                : `x0500-am-1200-pm ${Theme}_regular color_gravel font13`
                                        }
                                    >
                                        {formatTimeRangeAMPM(
                                            SelectedDatas.Segments[0].Origin.DepTime,
                                            SelectedDatas.Segments[
                                                SelectedDatas.Segments.length - 1
                                            ].Destination.ArrTime
                                        )}
                                    </div>
                                </div>
                                <div className="frame-15">
                                    <img
                                        // className="frame-169-23"
                                        src={images[ResultPageData?.barModeSelected === startValue ? "white-rupee-icon.svg" : "gray-rupee-icon.svg"]}
                                        alt="Frame 169"
                                    />
                                    <div
                                        className={
                                            ResultPageData?.barModeSelected === startValue
                                                ? `text-214 text-6 ${Theme}_semibold color_white font16`
                                                : `text-214 text-6 ${Theme}_semibold color_gravel font16`
                                        }
                                    >
                                        {Math.ceil(+SelectedDatas.FareFamilies.FFList[0].Travellers
                                            .Adults[0].Fare +
                                            +SelectedDatas.FareFamilies.FFList[0]
                                                .Travellers.Adults[0].Tax)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Section